// Libs
import React, { Component } from 'react';
import moment from 'moment';

// Services
import CustomerService  from 'services/CustomerService';

// Components
import Loader           from 'components/reusable/Loader';
import ClientRating     from 'components/reusable/ClientRating';
import ProfilePhoto     from 'components/reusable/ProfilePhoto';
import GlobalStateService from '../../../services/GlobalStateService';

//-------------------------------------------------------------------------------------------------------------------

export default class CustomerSummary extends Component {

    constructor(props) {
        super(props);
        this.state = {
        };
    }

    static getDerivedStateFromProps(props, state) {
        let isCustomerChanged = false;
        if ((!props.customer && state.customer) || (props.customer && !state.customer)) {
            return { customer: props.customer };
        }
        return null;
    }

    updateField(field, value) {
        const newCustomer = { ...this.state.customer };
        newCustomer[field] = value;
        this.setState({ customer: newCustomer });
    }

    onBlur() {
        if (this.props.onChange) {
            this.props.onChange(this.state.customer);
        }
    }

    //--------------------------------------------------------------------------------------------------------------------
    // Render
    //--------------------------------------------------------------------------------------------------------------------

    renderInner() {
        const customer = this.state.customer;
        const clientInfo = GlobalStateService.getValue('clientInfo');
        
        return (<>

            <div className="customer-summary-top">

                <span>New Customer</span>

                {this.props.onChangeClicked &&
                    <button className="button customer-summary-change-button" onClick={this.props.onChangeClicked}>
                        <span className="fa fa-times"></span>
                    </button>
                }

            </div>

            <div className="customer-summary-middle">
                <div className="customer-summary-right">

                    <input 
                        type="text"
                        autoFocus={true}
                        autoComplete="off"
                        onChange={e => this.updateField('firstName', e.target.value)}
                        onBlur={e => this.onBlur()}
                        value={customer.firstName}
                        placeholder="First Name"
                    />
                    <input 
                        type="text"
                        autoComplete="off"
                        onChange={e => this.updateField('lastName', e.target.value)}
                        onBlur={e => this.onBlur()}
                        value={customer.lastName}
                        placeholder="Last Name" />
                    <div className={clientInfo.newCustomerMobileRequired ? 'required-indicator' : ''}>
                        <input 
                            type="text"
                            autoComplete="off"
                            onChange={e => this.updateField('mobileTel', e.target.value)}
                            onBlur={e => this.onBlur()}
                            value={customer.mobileTel}
                            placeholder="Mobile Number"
                            style={{ width: '85%' }}
                        />
                        <div className="info-tooltip" style={{ display: 'inline' }} data-tip="Please note: for international numbers please add the relevant country code. For example if you are a UK salon with an Irish client, you would add +353 at the beginning and remove '0'.">
                            <div className="help-icon" />
                        </div>
                    </div>
                    <div className={clientInfo.newCustomerEmailRequired ? 'required-indicator' : ''}>
                        <input
                            type="text"
                            autoComplete="off"
                            onChange={e => this.updateField('email', e.target.value)}
                            onBlur={e => this.onBlur()}
                            value={customer.email}
                            placeholder="Email Address" />
                    </div>
                </div>
                    
            </div>

        </>);
    }

    render() {
        return (
            <div className={'panel customer-summary-panel ' + (this.props.className || '')}>
                <div className="panel-body">
                    <div className="panel-body-inner">
                        {this.renderInner()}
                    </div>
                </div>
            </div>
        );
    }
}
