import React, { Component } from 'react';
import moment from 'moment';
import Modal from 'react-bootstrap/Modal';

import Loader from 'components/reusable/Loader';
import DiaryService from 'services/DiaryService';
import GlobalStateService from 'services/GlobalStateService';

import CancelApptModal from 'components/pages/diary/CancelApptModal';

export default class PendingRefundsModal extends Component {
    constructor(props) {
        super(props);

        this.cancelApptModalRef = React.createRef();

        this.state = {
            isLoading: true
        };

        this.load = this.load.bind(this);
    }
    show(opts) {
        return new Promise((resolve, reject) => {
            this.resolve = resolve;

            this.setState({
                isLoading: true,
                isOpen: true,
            }, () => {
                this.load();
            })
        })
    }


    hide() {
        this.setState({
            isOpen: false
        });
    }

    async load() {
        const appts = await DiaryService.listPendingRefunds();

        this.setState({
            isLoading: false,
            appointments: appts
        });
    }

    viewCustomer(customerID) {
        this.hide();
        window.appHistory.push('/customer/' + customerID);
    }

    async refund(appointmentID) {
        await this.cancelApptModalRef.current.show({
            type: 'cancel',
            appointmentID
        });
        this.load();
    }

    render() {
        if (!this.state.isOpen) {
            return null;
        }


        return (<>
            <Modal
                show
                className="online-bookings-modal"
                onHide={e => this.hide()}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Pending Refunds</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {this.renderPendingRefunds()}
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="button button-primary ml-auto" onClick={e => this.hide()}>
                        Close
                    </button>
                </Modal.Footer>
            </Modal>

            <CancelApptModal ref={this.cancelApptModalRef} />
        </>);
    }

    renderPendingRefunds() {
        const {
            appointments
        } = this.state;

        if (this.state.isLoading) {
            return (<Loader />)
        }

        if (!appointments) {
            return (<div className="empty-text">
                No pending refunds
            </div>)
        }

        const loginDetails = GlobalStateService.getValue('loginDetails');

        return (<>
            <table className="table">
                <thead>
                    <tr>
                        <th>Booked</th>
                        <th>Customer</th>
                        <th>Date</th>
                        <th>Service(s)</th>
                        <th className="actions-column">Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {appointments.map(a =>
                        <React.Fragment key={a.appointmentID}>
                            <tr>
                                <td>{moment(a.dateAdded).format('DD/MM/YYYY HH:mm')}</td>
                                <td>{a.customer.firstName || ''} {a.customer.lastName || ''}</td>
                                <td>{moment(a.date).format('ddd, Do MMM')}</td>
                                <td>
                                    {a.appointmentServices.map(asv =>
                                        <div key={asv.appointmentServiceID}>
                                            <b>{moment(asv.time).format('HH:mm')}</b>: {asv.service.name} with {asv.stylistName}
                                        </div>
                                    )}
                                </td>
                            </tr>
                            <tr className="button-row">
                                <td colSpan={4}>

                                    <button className="button button-secondary button-small" onClick={e => this.refund(a.appointmentID)} disabled={!loginDetails.permissions['DiaryCancelAppointments']}>
                                        <span className="fa fa-times"></span>{' '}
                                        Refund
                                    </button>

                                </td>
                            </tr>
                        </React.Fragment>
                    )}
                </tbody>
            </table>
        </>);
    }



}