import React, { Component } from 'react';
import moment from 'moment';
import Modal from 'react-bootstrap/Modal';

import Loader from 'components/reusable/Loader';
import WaitingApptService from 'services/WaitingApptService';
import SendSMSModal from 'components/SendSMSModal';

export default class WaitingListModal extends Component {
    constructor(props) {
        super(props);

        this.sendSMSModalRef = React.createRef();

        this.state = {
            isLoading: true
        };
    }

    show(opts) {
        return new Promise((resolve, reject) => {
            this.resolve = resolve;
            this.setState({
                isLoading: true,
                isOpen: true
            }, () => {
                this.load();
            })
        })
    }

    hide() {
        this.setState({
            isOpen: false
        });
    }

    async load() {
        const waitingAppts = await WaitingApptService.list();
        this.setState({
            waitingAppts,
            isLoading: false
        });
    }

    async deleteWaitingAppt(waitingApptID) {
        await WaitingApptService.delete(waitingApptID);
        this.setState({
            isLoading: true
        });
        await this.load();
    }

    async showSendSMSModal(customerID, waitingApptID) {
        await this.sendSMSModalRef.current.show({
            customerID,
            waitingApptID
        });
    }

    bookWaitingAppt(waitingApptID) {
        this.hide();
        this.props.onBookClicked(waitingApptID);
    }

    editWaitingAppt(waitingApptID) {
        this.hide();
        this.props.onEditClicked(waitingApptID);
    }

    viewCustomer(customerID) {
        this.hide();
        window.appHistory.push('/customer/' + customerID);
    }

    render() {
        if (!this.state.isOpen) {
            return null;
        }


        return (<>
            <SendSMSModal ref={this.sendSMSModalRef} />
            <Modal
                show
                className="waiting-list-modal"
                onHide={e => this.hide()}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Waiting List</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {this.renderInner()}
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="button button-primary ml-auto" onClick={e => this.hide()}>
                        Close
                    </button>
                </Modal.Footer>
            </Modal>
        </>);
    }

    renderInner() {
        const {
            waitingAppts
        } = this.state;

        if (this.state.isLoading) {
            return (<Loader />)
        }

        return (<>
            <table className="table waiting-list-table">
                <thead>
                    <tr>
                        <th>Preferred Dates</th>
                        <th>Client</th>
                        <th>Services</th>
                    </tr>
                </thead>
                <tbody>
                    {waitingAppts.map(wAppt =>
                        <React.Fragment key={wAppt.waitingApptID}>
                            <tr className="no-bottom-border">
                                <td>
                                    {moment(wAppt.primaryDate).format('DD/MM/YYYY')}<br />
                                    {wAppt.secondaryDate && <>
                                        {moment(wAppt.secondaryDate).format('DD/MM/YYYY')}<br />
                                    </>}
                                    {wAppt.tertiaryDate && <>
                                        {moment(wAppt.tertiaryDate).format('DD/MM/YYYY')}<br />
                                    </>}
                                    <br />
                                    {(wAppt.timeOfDay || wAppt.timeNotes) && <>
                                        <b>Preferred Time:</b><br/>
                                    </>}
                                    {wAppt.timeOfDay && <>
                                        {wAppt.timeOfDay}
                                    </>}<br />
                                    {wAppt.timeNotes && <>
                                        {wAppt.timeNotes}
                                    </>}
                                </td>
                                <td>
                                    <div className="waiting-list-customer">
                                        {wAppt.customer.firstName} {wAppt.customer.lastName}
                                        <button className="button customer-summary-info-button" onClick={e => this.viewCustomer(wAppt.customerID)}>
                                            <span className="fas fa-info"></span>
                                        </button>
                                    </div>
                                    {wAppt.customer.mobileTel}
                                    <br />
                                    <br />
                                    {(wAppt.lastOutComm || wAppt.lastInComm) && <>
                                        <b>Last message sent:</b><br/>
                                    </>}
                                    {wAppt.lastOutComm && <>
                                        {(wAppt.lastOutComm.method == "email" ? 'Email sent ' : 'SMS sent ') + moment(wAppt.lastOutComm.date).format('DD/MM/YYYY [ at ] h:mm a')} <br/>
                                    </>}
                                    {wAppt.lastInComm && <>
                                        {(wAppt.lastInComm.method == "email" ? 'Email received ' : 'SMS received ') + moment(wAppt.lastInComm.date).format('DD/MM/YYYY [ at ] h:mm a')}
                                    </>}
                                </td>
                                <td>
                                    {wAppt.waitingApptServices.map((wApptS, index) =>
                                        <React.Fragment key={index}>
                                            {wApptS.service.name} by
                                            {wApptS.stylistUserID && <> {wApptS.stylistUser.nickname}</>}
                                            {!wApptS.stylistUserID && <> Any</>}
                                            <br />
                                        </React.Fragment>
                                    )}
                                    {!!wAppt.notes && <>
                                        <br />
                                        <strong>Notes</strong><br />
                                        {wAppt.notes}
                                    </>}
                                </td>
                            </tr>
                            <tr className="button-row">
                                <td colSpan={3}>
                                    {!wAppt.isBooked && <>
                                        <button className="button button-primary button-small me-1" onClick={e => this.bookWaitingAppt(wAppt.waitingApptID)}>
                                            <span className="fa fa-thumbs-up"></span>{' '}
                                            Book
                                        </button>
                                    </>}
                                    {wAppt.isBooked && <>
                                        <button className="button button-danger button-small me-1" onClick={e => this.bookWaitingAppt(wAppt.waitingApptID)}>
                                            <span className="fa fa-check"></span>{' '}
                                            Booked
                                        </button>
                                    </>}
                                    <button className="button button-secondary button-small me-1" onClick={e => this.showSendSMSModal(wAppt.customerID, wAppt.waitingApptID)}>
                                        <span className="fas fa-mobile-alt"></span>{' '}
                                        Send SMS
                                    </button>
                                    <button className="button button-secondary button-small me-1" onClick={e => this.editWaitingAppt(wAppt.waitingApptID)}>
                                        <span className="fa fa-pencil-alt"></span>{' '}
                                        Edit
                                    </button>
                                    <button className="button button-secondary button-small" onClick={e => this.deleteWaitingAppt(wAppt.waitingApptID)}>
                                        <span className="fa fa-times"></span>{' '}
                                        Remove
                                    </button>
                                </td>
                            </tr>
                        </React.Fragment>
                    )}
                </tbody>
            </table>
        </>);
    }
}