// Libs
import React from 'react';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/en-gb';
import DatePicker from 'components/reusable/DatePicker';

// Services
import GlobalStateService   from 'services/GlobalStateService';

// Components
import FloomlyComponent                     from 'components/FloomlyComponent';
import InfoBar                          from 'components/layout/InfoBar'
import DashboardOverview                from 'components/pages/dashboard/DashboardOverview';
import DashboardClientCount             from 'components/pages/dashboard/DashboardClientCount';
import DashboardByStylist               from 'components/pages/dashboard/DashboardByStylist';

//-------------------------------------------------------------------------------------------------------------------

class DashboardPage extends FloomlyComponent {

    constructor(props) {
        super(props);
        
        const clientInfo = GlobalStateService.getValue('clientInfo');
        this.state = {
            screen: null,
            date: moment().startOf('day').toDate(),
            dashboardType: clientInfo.defaultDashboardType
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        let oldScreen = '', newScreen = '';
        if (prevState.match) {
            oldScreen = prevState.match.params.screen;
        }
        if (nextProps.match) {
            newScreen = nextProps.match.params.screen;
        }
        if (newScreen !== oldScreen) {
            return { screen: newScreen || 'overview' };
        }
        return null
    }

    //--------------------------------------------------------------------------------------------------------------------
    //  Render
    //--------------------------------------------------------------------------------------------------------------------

    renderInfoBar() {
        const {
            screen,
            dashboardType,
            date
        } = this.state;


        const loginDetails = GlobalStateService.getValue('loginDetails');

        return (
            <InfoBar className="dashboard-info-bar">
                
                {(screen == 'client-count') &&
                    <div className="info-bar-panel-section">
                        <button className="button" onClick={() => this.props.history.push('/dashboard')} style={{ width: 90 }}>
                            <span className="fa fa-chevron-left"></span>{' '}
                            Back
                        </button>
                    </div>
                }

                <div className="info-bar-panel-section info-bar-panel-section-text">
                    {moment(date).format('ddd Do MMM YYYY')}
                </div>

                {(screen == 'overview' || screen == 'by-stylist') &&
                    <div className="info-bar-panel-section date-picker">
                        
                        <DatePicker
                            value={date}
                            onChange={date => this.setState({ date })}
                        />

                    </div>
                }

                <div className="info-bar-panel-section net-gross">

                    <div className="button-group" role="group">
                        <button type="button" className="button" onClick={() => this.setState({ dashboardType: 'net' })}>
                            {dashboardType == 'net' && <>
                                <span className="fas fa-money-bill"></span>{' '}
                            </>}
                            N<span className="desktop-only">et</span>
                        </button>
                        <button type="button" className="button" onClick={() => this.setState({ dashboardType: 'gross' })}>
                            {dashboardType == 'gross' && <>
                                <span className="fas fa-money-bill"></span>{' '}
                            </>}
                            G<span className="desktop-only">ross</span>
                        </button>
                    </div>

                </div>

                {screen == 'overview' && loginDetails.permissions['Office'] &&
                    <div className="info-bar-panel-section the-office">
                        <button className="button" onClick={() => this.props.history.push('/the-office')}>
                            <span className="fa fa-laptop-house"></span>{' '}
                            The Office!
                        </button>
                    </div>
                }

                {screen == 'client-count' &&
                    <div className="info-bar-panel-section">
                        <button className="button" onClick={() => this.props.history.push('/dashboard/by-stylist')} style={{ width: 120 }}>
                            <span className="fa fa-cut"></span>{' '}
                            By Stylist
                        </button>
                    </div>
                }

                {screen == 'by-stylist' && loginDetails.permissions['Dashboard'] &&
                    <div className="info-bar-panel-section desktop-only">
                        <button className="button whole-salon-button" onClick={() => this.props.history.push('/dashboard')}>
                            <span className="fa fa-store-alt"></span>{' '}
                            Whole Salon
                        </button>
                    </div>
                }

            </InfoBar>
        );
    }

    renderMainContent() {
        const {
            screen,
            dashboardType,
            date
        } = this.state;

        switch (screen) {
            case 'overview':
                return (
                    <DashboardOverview
                        dashboardType={dashboardType}
                        date={date}
                    />
                );
            case 'client-count':
                return (
                    <DashboardClientCount
                        dashboardType={dashboardType}
                        date={date}
                    />
                );
            case 'by-stylist':
                return (
                    <DashboardByStylist
                        dashboardType={dashboardType}
                        date={date}
                    />
                );
        }
    }

    render() {

        return (<>
            <div className="page-content">

                <div className="page-content-left">
                    {/* Empty */}
                </div>

                <div className="page-content-right">

                    <div className="page-content-right-inner">
                        {this.renderInfoBar()}
                    </div>
                    
                </div>

            </div>
            
            {this.renderMainContent()}
        </>);
    }
}

export default withRouter(DashboardPage);