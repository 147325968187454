import { Component } from 'react';

//-------------------------------------------------------------------------------------------------------------------

class FloomlyComponent extends Component {

    setStateAsync(state) {
        const self = this;
        return new Promise(function (resolve, reject) {
            self.setState(state, () => resolve(state));
        });
    }

}

export default FloomlyComponent;