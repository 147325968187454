import BaseService from 'services/BaseService';


export default class EmailTemplateService
    extends BaseService {

    static async list() {
        return BaseService.callAPI('email-templates/list');
    }

    static async preDefinedTemplatesList() {
        return BaseService.callAPI('email-templates/pre-defined-templates-list');
    }

    static async get(emailTemplateId) {
        return BaseService.callAPI('email-templates/get/' + emailTemplateId);
    }

    static async getHistory(emailTemplateId) {
        return BaseService.callAPI('email-templates/get-template-history/' + emailTemplateId);
    }

    static async listClientLists() {
        return BaseService.callAPI('email-templates/email-client-lists');
    }

    static async save(emailTemplate) {
        return BaseService.callAPI('email-templates/save', emailTemplate);
    }

    static async delete(id) {
        return BaseService.callAPI('email-templates/delete/' + id);
    }

    static async uploadImage(file) {
        return BaseService.postFile('email-templates/upload-image', file);
    }

    static async sendEmailBatch(emailBatch) {
        return BaseService.callAPI('email-templates/send-email-batch', emailBatch);
    }

    static async listUnSubscribedRecipients(clientListID) {
        return BaseService.callAPI('email-templates/unsubscribed-recipients/' + clientListID);
    }
}