// Libs
import React from 'react';
import { Route } from 'react-router';
import { Link, withRouter } from 'react-router-dom';

// Services & Helpers
import SMSService from 'services/SMSService';
import FormHelper from 'helpers/FormHelper';

// Components
import FloomlyComponent from 'components/FloomlyComponent';
import InfoBar from 'components/layout/InfoBar'
import Loader from 'components/reusable/Loader';
import SuperTable from 'components/reusable/SuperTable';
import NumberFormat from 'react-number-format';

//-------------------------------------------------------------------------------------------------------------------

class SettingsSMSAccountEdit extends FloomlyComponent {

    constructor(props) {
        super(props);

        // Init state
        this.state = {
            isLoading: true,
            smsAccount: null
        };

        this.updateField = this.updateField.bind(this);
        
        this.formHelper = new FormHelper({
            fields: {
                name: { label: 'Name' },
                accountType: {
                    label: 'Account Type',
                    type: 'single-select',
                    getOptions: () => [ { id: 'SMTP', name: 'SMTP' } ]
                },
                fromSMS: { label: 'From SMS' },
                fromName: { label: 'From Name' },
                authInfoJSON: { label: 'Auth Info', type: 'multiline-text' },
            },
            getValue: (fieldName, fieldInfo) => this.state.smsAccount[fieldName],
            setValue: this.updateField
        });
    }

    componentDidMount() {
        this.load();
    }

    //--------------------------------------------------------------------------------------------------------------------

    async load() {
        const smsAccountID = Number(this.props.match.params.smsAccountID) || 0;

        let smsAccount;
        if (smsAccountID) {
            smsAccount = await SMSService.getAccount(smsAccountID);
        } else {
            smsAccount = {
                accountType: 'Twilio'
            };
        }

        // Update UI
        this.setState({
            isLoading: false,
            smsAccount: smsAccount
        });
    }

    goBack() {
        this.props.history.push('/settings/sms-accounts');
    }

    async save() {
        this.setState({ isLoading: true });
        const smsAccount = { ...this.state.smsAccount };
        await SMSService.saveAccount(smsAccount);
        this.goBack();
    }

    updateField(fieldName, value, fieldInfo) {
        const smsAccount = { ...this.state.smsAccount };
        smsAccount[fieldName] = value;
        this.setState({
            smsAccount: smsAccount
        });
    }

    //--------------------------------------------------------------------------------------------------------------------
    //  Render
    //--------------------------------------------------------------------------------------------------------------------

    renderInfoBar() {
        const {
            smsAccount
        } = this.state;

        return (
            <InfoBar className="settings-info-bar" sticky={true}>

                <div className="info-bar-panel-section">

                    <button className="button" onClick={() => this.goBack()}>
                        <span className="fa fa-chevron-left"></span>{' '}
                        Back
                    </button>

                </div>

                <div className="info-bar-panel-section info-bar-panel-section-text">
                    {smsAccount.smsAccountID ? smsAccount.name : 'New SMS account'}
                </div>
                
                <div className="info-bar-panel-section ml-auto">

                    <button className="button" onClick={() => this.save()}>
                        <span className="fa fa-check"></span>{' '}
                        Save
                    </button>
                    
                </div>

            </InfoBar>
        );
    }
    
    render() {
        const {
            isLoading,
            smsAccount
        } = this.state;

        if (isLoading) {
            return (<Loader />);
        }

        return (<>
            {this.renderInfoBar()}

            <div className="settings-page-main">
                <div className="panel">
                    <div className="panel-body">

                        {this.formHelper.renderFormGroups([
                            'name',
                            'accountType',
                            'fromSMS',
                            'fromName',
                            'authInfoJSON'
                        ])}

                    </div>
                </div>

            </div>

        </>);
    }

};

export default withRouter(SettingsSMSAccountEdit);