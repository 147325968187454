// Libs
import React, { Component } from 'react';
import moment from 'moment';
import Modal from 'react-bootstrap/Modal';

// Services & Helpers
import DiaryService from 'services/DiaryService';
import BootboxHelper from 'helpers/BootboxHelper';
import TextHelpers from 'helpers/TextHelpers';
import DateHelpers from 'helpers/DateHelpers';

// Components
import FloomlyComponent from 'components/FloomlyComponent';
import Loader from 'components/reusable/Loader';

//--------------------------------------------------------------------------------------

export default class EditRotaDateRangeModal extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true
        };
    }

    show(opts) {
        return new Promise((resolve, reject) => {
            this.resolve = resolve;
            console.log(opts);
            this.setState({
                isLoading: true,
                isOpen: true,
                stylistUserID: opts.stylistUserID,
                fromDate: opts.fromDate,
				toDate: opts.toDate,
                rotaDate: {}
            }, () => {
                this.load();
            });
        });
    }

    async load() {
        // Load date info
        const dateInfo = await DiaryService.getDate(this.state.fromDate, this.state.stylistUserID);
        const stylist = dateInfo.stylists[0];

        let rotaDate = {
                overrideStandard: false,
                workingType: 'notWorking',
            };
        rotaDate.fromDate = this.state.fromDate;
		rotaDate.toDate = this.state.toDate;
        rotaDate.userID = this.state.stylistUserID;

        // Update UI
        this.setState({
            isLoading: false,
            rotaDate: rotaDate,
            stylist: stylist
        });
    }
    
    updateFields(values) {
        const rotaDate = { ...this.state.rotaDate };
        for (var fieldName in values) {
            const value = values[fieldName];
            if (rotaDate[fieldName] != value) {
                this.setState({
                    haveSettingsChanged: true
                });
            }
            rotaDate[fieldName] = value;
        }   
        this.setState({
            rotaDate
        });
    }

    async save() {
        this.setState({ isLoading: true });
        await DiaryService.saveRotaDateRange(this.state.rotaDate);
        this.setState({ isOpen: false });
        this.resolve();
    }

    //---------------------------------------------------------------------

    render() {
        const {
            isOpen,
            isLoading,
            fromDate,
			toDate,
            stylist
        } = this.state;

        if (!isOpen) {
            return null;
        }

        return (
            <Modal
                show
                className="edit-rota-date-modal"
                onHide={e => this.setState({ isOpen: false })}
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        {isLoading ?
                            <>Rota</> :
                            <>Rota for {stylist.nickname} from {moment(fromDate).format('ddd, Do MMM YYYY')} <br/>to {moment(toDate).format('ddd, Do MMM YYYY')}</>
                        }
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    {this.renderInner()}

                </Modal.Body>
                <Modal.Footer>
                    {!isLoading && <>
                        <button type="button" className="button button-secondary mr-auto" onClick={e => this.setState({ isOpen: false })}>
                            Cancel
                        </button>
                        <button type="button" className="button button-primary ml-auto" onClick={e => this.save()}>
                            Save
                        </button>
                    </>}
                </Modal.Footer>
            </Modal>
        );
    }

    renderInner() {
        const {
            isOpen,
            isLoading,
            rotaDate
        } = this.state;

        if (isLoading) {
            return (<Loader />);
        }

        return (<>

            <div className="form-check mb-3" style={{ paddingLeft: 0 }}>
                <label>
                    <input
                        style={{ marginRight: 10 }}
                        type="checkbox"
                        checked={rotaDate.overrideStandard}
                        onChange={e => this.updateFields({ overrideStandard: e.target.checked })}
                    />{' '}
                    Override the standard rota
                </label>
            </div>

            {rotaDate.overrideStandard && <>

                <div className="form-group">
                    <label>Status</label>
                    <select
                        value={rotaDate.workingType}
                        onChange={e => this.updateFields({ workingType: e.target.value })}
                    >
                        <option value="notWorking">Not working</option>
                        <option value="college">College</option>
                        <option value="holidayPaid">Holiday (paid)</option>
                        <option value="absenceNotPaid">Unpaid leave</option>
                        <option value="sick">Sick</option>
                        <option value="maternity">Maternity</option>
                        <option value="bankHoliday">Bank holiday</option>
                        <option value="dayInLieu">Day in lieu</option>
                        <option value="wedding">Wedding</option>
                    </select>
                </div>
            </>}
        </>);
    }
}
