// Libs
import React, { Component } from 'react';

// Services
import YPTService from 'services/YPTService';

// Images
import profitImage from 'images/ypt-profit-image.png';

//-------------------------------------------------------------------------------------------------------------------

class BusinessCategoriesSlide extends Component {

    constructor(props) {
        super(props);
        this.state = {

        };
        this.isChanged = {};
    }

    renderSimple() {
        return (<>

            <div className="ypt-field-title">Sales Tax:</div>
            <p>
                Now enter the sales tax (VAT) for your country i.e. for the UK, this would be 20%
                <span className="help-icon"></span>
            </p>

            <button className="button button-secondary" onClick={e => this.props.onChange('isAdvancedTaxMode', true) }>
                Switch to Advanced Mode
            </button>

            <div className="ypt-field-large">
                <input
                    type="number"
                    className="has-suffix"
                    value={(Math.round(this.props.salonFocusResult.taxRate * 10000) / 100) || ''}
                    onChange={e => {
                        this.isChanged[null] = true;
                        this.props.onChange('taxRate', Number(e.target.value) / 100);
                    }}
                    onBlur={e => {
                        if (this.isChanged[null]) {
                            delete this.isChanged[null];
                            this.props.onSave();
                        }
                    }}
                />
                <span className="ypt-field-suffix">%</span>
            </div>

        </>);
    }

    renderAdvanced() {
        return (<>

            <div className="ypt-field-title">Sales Tax:</div>
            <p>
                Now enter the sales tax (VAT) for your country i.e. for the UK, this would be 20%
                <span className="help-icon"></span>
            </p>

            <button className="button button-secondary" onClick={e => this.props.onChange('isAdvancedTaxMode', false) }>
                Switch to Simple Mode
            </button>

            {this.props.salonFocusResult.businessCategories.map(businessCategory =>
                <React.Fragment key={businessCategory.businessCategoryID}>
                    <div className="ypt-field-title-upper">{businessCategory.name}</div>

                    <div className="flex-row">

                        <div>
                            <div className="ypt-field-title">Percentage:</div>

                            <div className="ypt-field-medium">
                                <input
                                    type="number"
                                    className="has-suffix"
                                    value={(Math.round(businessCategory.sfcRatio * 10000) / 100) || ''}
                                    onChange={e => {
                                        this.isChanged[businessCategory.businessCategoryID] = true;
                                        this.props.onBusinessCategoryChange(businessCategory.businessCategoryID, 'sfcRatio', Number(e.target.value) / 100);
                                    }}
                                    onBlur={e => {
                                        if (this.isChanged[businessCategory.businessCategoryID]) {
                                            delete this.isChanged[businessCategory.businessCategoryID];
                                            this.props.onSave();
                                        }
                                    }}
                                />
                                <span className="ypt-field-suffix">%</span>
                            </div>
                            <span className="help-icon" />

                        </div>

                        <div>
                            <div className="ypt-field-title">Sales Tax:</div>

                            <div className="ypt-field-medium">
                                <input
                                    type="number"
                                    className="has-suffix"
                                    value={(Math.round(businessCategory.sfcTaxRate * 10000) / 100 || '')}
                                    onChange={e => {
                                        this.isChanged[businessCategory.businessCategoryID] = true;
                                        this.props.onBusinessCategoryChange(businessCategory.businessCategoryID, 'sfcTaxRate', Number(e.target.value) / 100);
                                    }}
                                    onBlur={e => {
                                        if (this.isChanged[businessCategory.businessCategoryID]) {
                                            delete this.isChanged[businessCategory.businessCategoryID];
                                            this.props.onSave();
                                        }
                                    }}
                                />
                                <span className="ypt-field-suffix">%</span>
                            </div>
                            <span className="help-icon" />

                        </div>

                    </div>

                </React.Fragment>
            )}

        </>);
    }

    render() {

        return (
            <div className="ypt-slide ypt-slide-business-categories">

                <div className="ypt-title">
                    Sales Tax
                </div>

                <div className="ypt-row">
                    <div>

                        {this.props.salonFocusResult.isAdvancedTaxMode &&
                            this.renderAdvanced()}

                        {!this.props.salonFocusResult.isAdvancedTaxMode &&
                            this.renderSimple()}

                        <div className="ypt-controls-row">

                            <button className="button button-primary ypt-back-button" onClick={e => this.props.onBack()}>
                                Back
                            </button>

                            <button className="button button-secondary ypt-continue-button" onClick={e => this.props.onContinue()}>
                                Continue
                            </button>

                        </div>

                    </div>
                    <div>
                        <img src={profitImage} />
                    </div>
                </div>
            </div>
        );
    }
}

export default BusinessCategoriesSlide;
