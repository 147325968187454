import BaseService from 'services/BaseService';

//-------------------------------------------------------------------------------------------------------------------

export default class YPTService
    extends BaseService {

    static async load(id) {
        return BaseService.callAPI('your-profit-target/get/' + id);
    }

    static async loadLatest() {
        return BaseService.callAPI('your-profit-target/get-latest');
    }

    static async save(salonFocusResult) {
        return BaseService.callAPI('your-profit-target/save', salonFocusResult);
    }

    static async complete() {
        return BaseService.callAPI('your-profit-target/complete');
    }

}
