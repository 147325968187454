import BaseService from 'services/BaseService';

//-------------------------------------------------------------------------------------------------------------------

export default class UserService
    extends BaseService {
    
    static async list() {
        return BaseService.callAPI('user/list');
    }

    static async listDiary() {
        return BaseService.callAPI('user/list-diary');
    }

    static async get(userID) {
        return BaseService.callAPI('user/get/' + userID);
    }

    static async save(user) {
        return BaseService.callAPI('user/save', user);
    }

    static async delete(id) {
        return BaseService.callAPI('user/delete/' + id);
    }

    static async rearrangeDiary(userIDs) {
        return BaseService.callAPI('user/rearrange-diary', {
            userIDs
        });
    }

    static async getRandomPIN() {
        return BaseService.callAPI('user/get-random-pin');
    }

    static async sendInviteThroughSms(userId) {
        return BaseService.callAPI('user/send-invite-through-sms/' + userId);
    }

    static async sendInviteThroughEmail(userId) {
        return BaseService.callAPI('user/send-invite-through-email/' + userId);
    }

    static async getUserDetails(invitationCode) {
        return BaseService.callAPI('user/get-user-details/' + invitationCode);
    }
}
