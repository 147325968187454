// Libs
import React from 'react';
import { withRouter } from 'react-router-dom';

// Services & Helpers
import ServiceCategoryService from 'services/ServiceCategoryService';
import TextHelpers    from 'helpers/TextHelpers';

// Components
import FloomlyComponent from 'components/FloomlyComponent';
import InfoBar      from 'components/layout/InfoBar'
import Loader       from 'components/reusable/Loader';
import SuperTable   from 'components/reusable/SuperTable';

//-------------------------------------------------------------------------------------------------------------------

class SettingsServiceCategoryList extends FloomlyComponent {

    constructor(props) {
        super(props);

        // Init state
        this.state = {
            isLoading: true,
            serviceCategories: []
        };
    }

    componentDidMount() {
        this.load();
    }

    //--------------------------------------------------------------------------------------------------------------------

    async load() {
        const serviceCategories = await ServiceCategoryService.list();
        
        this.setState({
            isLoading: false, 
            serviceCategories
        });
    }

    edit(id) {
        this.props.history.push('/settings/service-categories/' + id);
    }

    add() {
        this.props.history.push('/settings/service-categories/new');
    }

    rearrangeServiceCategory(serviceCategoryID, newIndex) {
        // Rearrange
        const serviceCategories = [...this.state.serviceCategories];
        const currentIndex = serviceCategories.findIndex(u => u.serviceCategoryID == serviceCategoryID);
        const serviceCategor = serviceCategories.splice(currentIndex, 1)[0];
        serviceCategories.splice(newIndex, 0, serviceCategor);

        // Update UI
        this.setState({
            serviceCategories
        });

        // Send to server
        const serviceCategoryIDs = serviceCategories.map(u => u.serviceCategoryID);
        ServiceCategoryService.rearrange(serviceCategoryIDs);
    }

    //--------------------------------------------------------------------------------------------------------------------
    //  Render
    //--------------------------------------------------------------------------------------------------------------------

    renderInfoBar() {
        return (
            <InfoBar className="settings-info-bar" containerClassName="floating-on-mobile" sticky={true}>

                <div className="info-bar-panel-section info-bar-panel-section-text desktop-only">
                    Service Categories
                </div>
                <div className="info-bar-panel-section ml-auto">

                    <button className="button" onClick={() => this.add()}>
                        <span className="fa fa-plus"></span>{' '}
                        Add New
                    </button>

                </div>

            </InfoBar>
        );
    }

    render() {
        const {
            isLoading,
            serviceCategories
        } = this.state;

        if (isLoading) {
            return (<Loader />);
        }

        return (<>
            {this.renderInfoBar()}

            <div className="settings-page-main">
                <div className="panel">
                    <div className="panel-body">

                        <SuperTable
                            className="settings-table"
                            containerClassName="mb-0"
                            rows={serviceCategories}
                            keyAccessor={service => service.serviceCategoryID}
                            cols={{
                                name: { label: 'Name' },
                                numServices: {
                                    label: '# Services',
                                    getValue: (colInfo, serviceCategory) => serviceCategory.services.length
                                }
                            }}
                            onClick={(serviceCategory, e) => this.edit(serviceCategory.serviceCategoryID)}
                            reorder={{
                                enabled: true,
                                droppableID: 'service-categories',
                                onDragEnd: (serviceCategoryID, destination) => {
                                    this.rearrangeServiceCategory(serviceCategoryID, destination.index);
                                }
                            }}
                        />
                        
                    </div>
                </div>

            </div>

        </>);
    }

};

export default withRouter(SettingsServiceCategoryList);