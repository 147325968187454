// Libs
import React, { Component } from 'react';

// Components
import Money from 'components/reusable/Money';

// Images
import targetImage from 'images/ypt-target-image.png';

//-------------------------------------------------------------------------------------------------------------------

class AnnualTargetSlide extends Component {

    constructor(props) {
        super(props);

        this.state = {
        };
    }

    render() {
        const salonFocusResult = this.props.salonFocusResult;

        return (
            <div className="ypt-slide ypt-slide-input-annual-target">

                <div className="ypt-title">
                    Your Profit-based Sales Target!
                </div>

                <div className="ypt-row">
                    <div>

                        <p>TA-DA! Here we are! If you hit these sales targets and stick to your expenses budget, you will make the profit you want. If these numbers look scary to you - wait - it's okay - relax, make a cup of tea and remind yourself of a few things:</p>
                        <p>Firstly this target is for year two remember - you aren't expected to do it next week!</p>
                        <p>Secondly, maybe you have been a bit unrealistic, or your expenses are too high right now - it's okay - remember you can go back and change the numbers. We call this the 'what if' game - what if I reduced my wage and/or stock % - what if I increase/decrease my profit etc. Just have a play until the numbers start to make more sense if you need to. And finally, remember that we're here to help you - this is our area of expertise, so relax - your coach will help you to get this right!</p>

                        <table className="targets-table">
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>Net: <span className="help-icon" /></th>
                                    <th>Gross: <span className="help-icon" /></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th>Annual:</th>
                                    <td><Money amount={salonFocusResult.targetAnnualSalesNet} decimalPlaces={0} /></td>
                                    <td><Money amount={salonFocusResult.targetAnnualSalesGross} decimalPlaces={0} /></td>
                                </tr>
                                <tr>
                                    <th>Weekly:</th>
                                    <td><Money amount={salonFocusResult.targetAverageWeeklySalesNet} decimalPlaces={0} /></td>
                                    <td><Money amount={salonFocusResult.targetAverageWeeklySalesGross} decimalPlaces={0} /></td>
                                </tr>
                            </tbody>
                        </table>

                        <div className="ypt-controls-row">

                            <button className="button button-primary ypt-back-button" onClick={e => this.props.onBack()}>
                                Back
                            </button>

                            <button className="button button-secondary ypt-continue-button" onClick={e => this.props.onContinue()}>
                                Continue
                            </button>

                        </div>

                    </div>
                    <div>
                        <img src={targetImage} />
                    </div>
                </div>
            </div>
        );
    }
}

export default AnnualTargetSlide;
