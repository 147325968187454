import BaseService from 'services/BaseService'

export default class WaitingApptService extends BaseService {

    static async get(waitingApptID) {
        return BaseService.callAPI('waitingAppt/get/' + waitingApptID);
    }

    static async list() {
        return BaseService.callAPI('waitingAppt/list');
    }

    static async save(waitingAppt) {
        return BaseService.callAPI('waitingAppt/save/', waitingAppt);
    }

    static async book(waitingApptID) {
        return BaseService.callAPI('waitingAppt/book/' + waitingApptID);
    }

    static async delete(waitingApptID) {
        return BaseService.callAPI('waitingAppt/delete/'+waitingApptID);
    }
}