// https://medium.com/@alex_escalante/react-select-alloptionoptions-with-a-single-click-1ebf5a33fe31
// With amendments by KA

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { default as ReactSelect } from 'react-select';

class MultiSelect extends Component {

    constructor(props) {
        super(props);
    }

    shouldComponentUpdate(nextProps) {
        if (MultiSelect.areIDListsDifferent(this.props.value, nextProps.value)) {
            return true;
        }
        if (MultiSelect.areIDListsDifferent(this.props.options, nextProps.options)) {
            return true;
        }
        return false;
    }

    static areIDListsDifferent(oldVal, newVal) {
        const oldIDsList = oldVal.map(v => v.value);
        const newIDsList = newVal.map(v => v.value);
        if (oldIDsList.length != newIDsList.length) {
            return true;
        }
        const oldIDs = MultiSelect.arrayToObject(oldVal);
        const newIDs = MultiSelect.arrayToObject(newVal);
        for (var i = 0; i < oldIDsList.length; i++) {
            if (!newIDs[oldIDsList[i]]) {
                return true;
            }
        }
        for (var i = 0; i < newIDsList.length; i++) {
            if (!oldIDs[newIDsList[i]]) {
                return true;
            }
        }
    }

    static arrayToObject(array, field) {
        if (typeof (field) == 'undefined') {
            field = 'id';
        }
        const obj = {};
        array.forEach(item => {
            obj[item[field]] = item;
        });
        return obj;
    }


    render() {
        const {
            allowSelectAll,
            allowSelectNone,
            allOption,
            noneOption
        } = this.props;

        let options = [];

        if (allowSelectAll/* && props.value.length < props.options.length*/) {
            options.push(allOption);
        }
        if (allowSelectNone/* && props.value.length > 0*/) {
            options.push(noneOption);
        }

        options = options.concat(this.props.options);

        return (
            <ReactSelect
                {...this.props}
                options={options}
                onChange={selected => {
                    if (selected.length > 0) {
                        const val = selected[selected.length - 1].value;
                        if (val == allOption.value) {
                            return this.props.onChange(this.props.options);
                        } else if (val == noneOption.value) {
                            return this.props.onChange([]);
                        }
                    }
                    return this.props.onChange(selected);
                }}
            />
        );
    }
};

MultiSelect.propTypes = {
    options: PropTypes.array,
    value: PropTypes.any,
    onChange: PropTypes.func,
    allowSelectAll: PropTypes.bool,
    allowSelectNone: PropTypes.bool,
    allOption: PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string
    })
};

MultiSelect.defaultProps = {
    isMulti: true,
    controlShouldRenderValue: false,
    closeMenuOnSelect: false,
    hideSelectedOptions: false,
    isClearable: false,

    allowSelectAll: true,
    allowSelectNone: true,
    allOption: {
        value: 'ALL',
        text: 'Select all'
    },
    noneOption: {
        value: 'NONE',
        text: 'Clear selection'
    }
};

export default MultiSelect;