// Libs
import React from 'react';

// Services & helpers
import FormHelper from 'helpers/FormHelper';
import GlobalStateService from 'services/GlobalStateService';
import SalonSpyService from 'services/SalonSpyService';
import SMSService from 'services/SMSService';

// Components
import FloomlyComponent from 'components/FloomlyComponent';
import Loader from 'components/reusable/Loader';

//-------------------------------------------------------------------------------------------------------------------

class SalonSpyTab extends FloomlyComponent {

    constructor(props) {
        super(props);

        // Init state
        this.state = {
            isLoading: true,
            salon: null
        };

        this.loginDetails = GlobalStateService.getValue('loginDetails');

        this.formHelper = new FormHelper({
            fields: {
                isSalonSpyEnabled: {
                    checkboxLabel: 'Automatically ask clients for a review via SMS when they check out',
                    type: 'checkbox'
                },
                salonSpySMSNumberID: {
                    label: 'Use the following number',
                    type: 'single-select',
                    emptyText: '(None selected)',
                    getOptions: () => this.state.smsNumbers.map(sn => ({
                        id: sn.id,
                        name: sn.numberOrSenderID
                    }))
                },
                salonSpyFrequency: {
                    label: 'Send an SMS to a client...',
                    type: 'single-select',
                    getOptions: () => {
                        return [
                            { id: '1', name: 'After every appointment' },
                            { id: '2', name: 'After every other appointment' },
                            { id: '3', name: 'After every 3rd appointment' }//,
                            //{ id: '4', name: 'After 4th appointment' },
                            //{ id: '0', name: 'Once per client' }
                        ];
                    }
                },
                email: {
                    label: 'Salon email address (required by SalonSpy)',
                    type: 'email'
                },
                ['tradingAddress.postcode']: {
                    label: 'Salon postcode (required by SalonSpy)',
                    type: 'text'
                }
            },
            getValue: (fieldName, fieldInfo) =>
                fieldName == 'tradingAddress.postcode' ? this.state.salon.tradingAddress.postcode : 
                this.state.salon[fieldName],
            setValue: this.updateField.bind(this)
        });
    }

    componentDidMount() {
        this.load();
    }

    //--------------------------------------------------------------------------------------------------------------------

    async load() {
        const salon = await SalonSpyService.getSettings();
        const smsNumbers = await SMSService.listNumbers();

        this.setState({
            salon,
            smsNumbers,
            isLoading: false
        });
    }

    updateField(fieldName, value) {
        const salon = { ...this.state.salon };
        if (fieldName == 'tradingAddress.postcode') {
            salon.tradingAddress.postcode = value;
        } else {
            salon[fieldName] = value;
        }
        this.setState({ salon });
    }

    async save() {
        this.setState({ isLoading: true });
        await SalonSpyService.saveSettings(this.state.salon);
        this.setState({ isLoading: false });
    }

    //--------------------------------------------------------------------------------------------------------------------
    //  Render
    //--------------------------------------------------------------------------------------------------------------------

    render() {
        const {
            isLoading,
            salon
        } = this.state;

        if (isLoading) {
            return (<Loader />);
        }

        return (
            <div className="salon-spy-tab">

                {this.formHelper.renderFormGroups([
                    'isSalonSpyEnabled',
                    salon.isSalonSpyEnabled && 'salonSpySMSNumberID',
                    salon.isSalonSpyEnabled && 'salonSpyFrequency',
                    salon.isSalonSpyEnabled && 'email',
                    salon.isSalonSpyEnabled && 'tradingAddress.postcode'
                ])}

            </div>
        );
    }

};

export default SalonSpyTab;