import React from 'react';
import Cookies from 'js-cookie';

//-------------------------------------------------------------------------------------------------------------------

class MainContent extends React.Component {

    constructor(props) {
        super(props);
        var minimalMode = (Cookies.get('minimalMode') == 'true');
        if (minimalMode) {
            document.body.classList.add('minimal-mode');
        }
    }

    toggleMinimalMode() {
        if (document.body.classList.contains('minimal-mode')) {
            document.body.classList.remove('minimal-mode');
            Cookies.set('minimalMode', 'false');
            document.querySelector('.side-nav-scroller').style.width = '';
        } else {
            document.body.classList.add('minimal-mode');
            Cookies.set('minimalMode', 'true');
        }
    }

    render() {
        return (
            <div className="main-content">
                <div className="main-content-inner">
                    {this.props.children}
                </div>

                <div className="minimise-side-nav-button" onClick={this.toggleMinimalMode}>
                    <button>
                        <span className="fa fa-chevron-left"></span>
                    </button>
                </div>

            </div>
        );
    }
};

export default MainContent