// Libs
import React from 'react';

//-------------------------------------------------------------------------------------------------------------------

class BookingConfirmed extends React.Component {

    constructor(props) {
        super(props);
        this.isUnmounted = false;
    }

    componentDidMount() {
        window.setTimeout(() => {
            if (this.isUnmounted) return;
            this.props.onContinueClicked();
        }, 3 * 1000);
    }

    componentWillUnmount() {
        this.isUnmounted = true;
    }

    render() {
        return (<>

            <div className="panel booking-confirmed-panel">

                <div className="panel-body">

                    That's all booked in!

                </div>

            </div>

            <button className="button button-primary" onClick={this.props.onContinueClicked}>
                <i className="fas fa-check"></i>{' '}
                {this.props.isPreBooking ? 'Back to check-out' : 'Back to Diary'}
            </button>

            <button className="button button-secondary" onClick={this.props.onBookAnotherClicked}>
                <i className="fa fa-cheron-right"></i>{' '}
                Book Another
            </button>

        </>);
    }
}

export default BookingConfirmed;