// Libs
import React from 'react';
import { withRouter } from 'react-router-dom';

// Services
import GlobalStateService from 'services/GlobalStateService';

// Components
import FloomlyComponent from 'components/FloomlyComponent';
import InfoBar from 'components/layout/InfoBar'
import Loader from 'components/reusable/Loader';
import TabPanel, { Tab } from 'components/reusable/TabPanel';
import Reports from 'components/pages/the-office/Reports';
import EndOfDayBanking from 'components/pages/the-office/EndOfDayBanking';
import TransactionSummary from 'components/pages/the-office/TransactionSummary';
import Budget from 'components/pages/the-office/Budget.js';

//-------------------------------------------------------------------------------------------------------------------

class TheOfficePage extends FloomlyComponent {

    constructor(props) {
        super(props);

        // Init state
        this.state = {
            isLoading: false
        };
    }

    //--------------------------------------------------------------------------------------------------------------------
    //  Render
    //--------------------------------------------------------------------------------------------------------------------

    renderInfoBar() {
        const {
        } = this.state;

        const loginDetails = GlobalStateService.getValue('loginDetails');

        return (
            <InfoBar className="the-office-info-bar" sticky={true}>

                <div className="info-bar-panel-section info-bar-panel-section-text">
                    The Office
                </div>

                {loginDetails.permissions['Dashboard'] &&
                    < div className="info-bar-panel-section ml-auto">
                        <button className="button" onClick={() => this.props.history.push('/dashboard')}>
                            <span className="fa fa-tachometer-alt"></span>{' '}
                            Go To Dashboard
                        </button>
                    </div>
                }

            </InfoBar>
        );
    }

    renderMainContent() {
        const {
            isLoading
        } = this.state;

        if (isLoading) {
            return (<Loader />);
        }
        const loginDetails = GlobalStateService.getValue('loginDetails');

        let tab = this.props.match.params.tab;
        if (!tab) {
            if (loginDetails.permissions['OfficeViewEndOfDayBanking']) {
                tab = 'end-of-day';
            }
            else if (loginDetails.permissions['OfficeReports']) {
                tab = 'reports';
            }
            else if (loginDetails.permissions['OfficeViewBudget']) {
                tab = 'budget';
            }
            else if (loginDetails.permissions['OfficeTargets']) {
                tab = 'targets';
            }
        }

        return (<>

            <TabPanel tab={tab} onChangeTab={tab => {
                this.props.history.push('/the-office/' + tab);
            }}>

                {loginDetails.permissions['OfficeViewEndOfDayBanking'] &&
                    <Tab id="end-of-day" title="End of Day Banking" tabClassName="tab-teal">

                        <EndOfDayBanking tab={this.props.match.params.subTab} />

                    </Tab>
                }

                {loginDetails.permissions['OfficeViewEndOfDayBanking'] &&
                    <Tab id="transaction-summary" title="Transaction Summary" tabClassName="tab-pink">

                        <TransactionSummary tab={this.props.match.params.subTab} />

                    </Tab>
                }

                {loginDetails.permissions['OfficeReports'] &&
                    <Tab id="reports" title="Reports" tabClassName="tab-green">

                        <Reports
                            tab={this.props.match.params.subTab}
                            tabParam={this.props.match.params.subTabParam}
                            onChangeTab={tab => this.props.history.push('/the-office/reports/' + tab)}
                        />

                    </Tab>
                }

                {/*
                {loginDetails.permissions['OfficeViewBudget'] &&
                    <Tab id="budget" title="Budget" tabClassName="tab-green">

                        <Budget />

                    </Tab>
                }
                */}

                {loginDetails.permissions['OfficeTargets'] &&
                    <Tab id="targets" title="Targets" tabClassName="tab-yellow desktop-only">

                        <div className="targets-tab">

                            {loginDetails.permissions['OfficeStylistTargets'] &&
                                <button className="button button-secondary button-small desktop-only" onClick={e => this.props.history.push('/stylist-targets')}>
                                    Stylist Targets
                                </button>
                            }

                            {loginDetails.permissions['OfficeYourProfitTarget'] &&
                                <button className="button button-secondary button-small desktop-only" onClick={e => this.props.history.push('/your-profit-target')}>
                                    Go to Your Profit Target
                                </button>
                            }

                        </div>

                    </Tab>
                }

            </TabPanel>

        </>);
    }

    render() {
        return (<>
            <div className="page-content">
                <div className="page-content-left">
                </div>
                <div className="page-content-right">
                    <div className="page-content-right-inner">
                        {this.renderInfoBar()}
                    </div>
                </div>
            </div>

            <div className="the-office-page">

                {this.renderMainContent()}

            </div>
        </>);
    }

};

export default withRouter(TheOfficePage);