import BaseService from 'services/BaseService';

//-------------------------------------------------------------------------------------------------------------------

export default class BusinessCategoryService
    extends BaseService {

    static async list() {
        return BaseService.callAPI('business-category/list');
    }

}