// Libs
import React from 'react';
import { Route } from 'react-router';
import { Link, withRouter } from 'react-router-dom';

// Services & Helpers
import ServiceBandService from 'services/ServiceBandService';

// Components
import FloomlyComponent from 'components/FloomlyComponent';
import InfoBar      from 'components/layout/InfoBar'
import Loader       from 'components/reusable/Loader';
import SuperTable   from 'components/reusable/SuperTable';

//-------------------------------------------------------------------------------------------------------------------

class SettingsServiceBandList extends FloomlyComponent {

    constructor(props) {
        super(props);

        // Init state
        this.state = {
            isLoading: true,
            serviceBands: []
        };
    }

    componentDidMount() {
        this.load();
    }

    //--------------------------------------------------------------------------------------------------------------------

    async load() {
        const serviceBands = await ServiceBandService.list();
        
        this.setState({
            isLoading: false, 
            serviceBands: serviceBands
        });
    }

    edit(id) {
        this.props.history.push('/settings/service-bands/' + id);
    }

    add() {
        this.props.history.push('/settings/service-bands/new');
    }

    rearrange(serviceBandID, newIndex) {
        // Rearrange
        const serviceBands = [...this.state.serviceBands];
        const currentIndex = serviceBands.findIndex(u => u.serviceBandID == serviceBandID);
        const serviceBand = serviceBands.splice(currentIndex, 1)[0];
        serviceBands.splice(newIndex, 0, serviceBand);

        // Update UI
        this.setState({
            serviceBands
        });

        // Send to server
        const serviceBandIDs = serviceBands.map(u => u.serviceBandID);
        ServiceBandService.rearrange(serviceBandIDs);
    }

    //--------------------------------------------------------------------------------------------------------------------
    //  Render
    //--------------------------------------------------------------------------------------------------------------------

    renderInfoBar() {
        return (
            <InfoBar className="settings-info-bar" containerClassName="floating-on-mobile" sticky={true}>

                <div className="info-bar-panel-section info-bar-panel-section-text desktop-only">
                    Stylist Levels
                </div>
                <div className="info-bar-panel-section ml-auto">

                    <button className="button" onClick={() => this.add()}>
                        <span className="fa fa-plus"></span>{' '}
                        Add New
                    </button>

                </div>

            </InfoBar>
        );
    }

    render() {
        const {
            isLoading,
            serviceBands
        } = this.state;

        if (isLoading) {
            return (<Loader />);
        }

        return (<>
            {this.renderInfoBar()}

            <div className="settings-page-main">
                <div className="panel">
                    <div className="panel-body">

                        <SuperTable
                            className="settings-table"
                            containerClassName="mb-0"
                            rows={serviceBands}
                            keyAccessor={serviceBand => serviceBand.serviceBandID}
                            cols={{
                                name: { label: 'Name' }
                            }}
                            onClick={(serviceBand, e) => this.edit(serviceBand.serviceBandID)}
                            reorder={{
                                enabled: true,
                                droppableID: 'service-bands',
                                onDragEnd: (serviceBandID, destination) => {
                                    this.rearrange(serviceBandID, destination.index);
                                }
                            }}
                        />
                        
                    </div>
                </div>

            </div>

        </>);
    }

};

export default withRouter(SettingsServiceBandList);