import BaseService from 'services/BaseService';

//-------------------------------------------------------------------------------------------------------------------

export default class BlockBookingService
    extends BaseService {

    static async generate(blockBooking) {
        return BaseService.callAPI('block-booking/generate', blockBooking);
    }

    static async save(blockBooking, appointments) {
        return BaseService.callAPI('block-booking/save', {
            blockBooking, appointments
        });
    }

}