// Libs
import React from 'react';
import Modal from 'react-bootstrap/Modal';

// Services & Helpers

// Components
import Loader from 'components/reusable/Loader';
import RetailRefund from '../diary/retail2/RetailRefund';

//--------------------------------------------------------------------------------------

export default class RetailRefundModal extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			isLoading: true,
			buttons: [],
		};
	}

	show(opts) {
		return new Promise((resolve, reject) => {
			this.resolve = resolve;
			this.setState({
				isLoading: true,
				isOpen: true,
				refundID: opts.refundID,
			}, () => {
				this.load();
			});
		});
	}

	async load() {
		this.setState({
			isLoading: false,
		});
	}

	setButtons(buttons) {
		this.setState({ buttons });
	}

	close() {
		this.setState({ isOpen: false }); 
		this.resolve(); 
	}
	getButtonclass(button){
		let classname=''
		if(button.color)
			classname += `button button-${button.color} mr-auto`;
		else
			classname += "button " + (button.primary ? "button-primary mr-auto" : "button button-secondary ml-auto");
		classname += ((button.active ? button.active() : true) ? "" : " disabled");
		return classname ;
	}
	//---------------------------------------------------------------------

	render() {
		const {
			isOpen,
			isLoading,
			buttons,
		} = this.state;

		if (!isOpen) {
			return null;
		}

		return (
			<Modal
				show
				className="edit-rota-date-modal"
				onHide={_ => this.close()}
			>
				<Modal.Header closeButton>
					<Modal.Title>
						Retail Purchase Refund
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>

					{this.renderInner()}

				</Modal.Body>
				<Modal.Footer>
					{!isLoading && <>
						{buttons.map(button => <>
							<button type="button"
									className={this.getButtonclass(button)}
									onClick={_ => button.click()} 
									key={button.key}>
								{button.text}
							</button>
						</>)}
					</>}
				</Modal.Footer>
			</Modal>
		);
	}

	renderInner() {
		const {
			isLoading,
			refundID,
		} = this.state;

		if (isLoading) {
			return (<Loader />);
		}

		return (<>
			<RetailRefund
				isStandalone={true}
				refundID={refundID}

				onButtons={buttons => this.setButtons(buttons)}
				onClose={_ => this.close()}
			/>
		</>);
	}
}
