import BaseService from 'services/BaseService';

//-------------------------------------------------------------------------------------------------------------------

export default class TaxRateService
    extends BaseService {

    static async list(forceShowID) { 
        return (await BaseService.callAPI('tax-rate/list', { forceShowID }));
    }

    static async get(id) {
        return BaseService.callAPI('tax-rate/get/' + id);
    }

    static async save(taxRate) {
        return BaseService.callAPI('tax-rate/save', taxRate);
    }

    static async delete(id) {
        return BaseService.callAPI('tax-rate/delete/' + id);
    }
}