import BaseService from 'services/BaseService';

//-------------------------------------------------------------------------------------------------------------------

export default class UploadService
    extends BaseService {

    static async listForCustomer(customerID, type) {
        const response = await BaseService.callAPI('upload/list', {
            customerID: customerID,
            type: type
        });
        return response;
    }

    static async uploadFromFile(file, param) {
        return BaseService.postFile('upload', file, param);
    }

}
