// Libs
import React from 'react';

// Services & Helpers
import GlobalStateService from 'services/GlobalStateService';

// Components
import FloomlyComponent from 'components/FloomlyComponent';
import Loader from 'components/reusable/Loader';

//-------------------------------------------------------------------------------------------------------------------

class ClientRecordNotes extends FloomlyComponent {

    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            clientRecord: null
        };

    }

    updateField(field, value) {
        const clientRecord = { ...this.props.clientRecord };
        clientRecord[field] = value;
        this.props.onUpdate(clientRecord);
        this.props.onApptUpdated(true);
    }
    
    //--------------------------------------------------------------------------------------------------------------------
    // Render
    //--------------------------------------------------------------------------------------------------------------------
    
    render() {
        const {
            clientRecord
        } = this.props;

        if (!clientRecord) {
            return (<Loader />);
        }

        const loginDetails = GlobalStateService.getValue('loginDetails');

        return (<>
            <div className="panel-info-header">
                Colour Notes
            </div>

            <textarea
                rows="3" value={clientRecord.colourNotes || ''}
                onChange={e => this.updateField('colourNotes', e.target.value)}
                onBlur={e => this.props.onBlur && this.props.onBlur('colourNotes', e.target.value)}
                disabled={!loginDetails.permissions['ClientCardEditAppointmentNotes']}
            />

            <div className="panel-info-header">
                Appointment Notes
            </div>

            <textarea
                rows="3"
                value={clientRecord.notes || ''}
                onChange={e => this.updateField('notes', e.target.value)}
                onBlur={e => this.props.onBlur && this.props.onBlur('notes', e.target.value)}
                disabled={!loginDetails.permissions['ClientCardEditAppointmentNotes']}
            />
        </>);
    }

}

export default ClientRecordNotes;