// Libs
import React from 'react';
import { Link, withRouter } from 'react-router-dom';

// Services & Helpers
import EmailService from 'services/EmailService';
import UploadService from 'services/UploadService';
import GlobalStateService from 'services/GlobalStateService';
import FormHelper from 'helpers/FormHelper';
import BaseService from 'services/BaseService';

// Components
import FloomlyComponent from 'components/FloomlyComponent';
import InfoBar from 'components/layout/InfoBar'
import Loader from 'components/reusable/Loader';
import UploadButton from 'components/reusable/UploadButton';

//-------------------------------------------------------------------------------------------------------------------

class SettingsEmailSettings extends FloomlyComponent {

    constructor(props) {
        super(props);

        // Init state
        this.state = {
            isLoading: true,
            emailSettings: null
        };

        this.updateField = this.updateField.bind(this);

        this.formHelper = new FormHelper({
            fields: {
                //logoImagePath: {
                //},
                bgColour: {
                    label: 'Background Colour',
                    type: 'color'
                },
                headerTextColour: {
                    label: 'Header Text Colour',
                    type: 'color'
                },
                headerBGColour: {
                    label: 'Header Background Colour',
                    type: 'color'
                },
                bodyTextColour: {
                    label: 'Body Text Colour',
                    type: 'color'
                },
                bodyBGColour: {
                    label: 'Body Background Colour',
                    type: 'color'
                },
                footerTextColour: {
                    label: 'Footer Text Colour',
                    type: 'color'
                },
                footerBGColour: {
                    label: 'Footer Background Colour',
                    type: 'color'
                },
                buttonTextColour: {
                    label: 'Button Text Colour',
                    type: 'color'
                },
                buttonBGColour: {
                    label: 'Button Background Colour',
                    type: 'color'
                },
                footerContent: {
                    label: 'Footer Text',
                    type: 'multiline-text'
                }
            },
            getValue: (fieldName, fieldInfo) => this.state.emailSettings[fieldName],
            setValue: this.updateField,
            onBlur: (fieldName) => {
                this.updatePreview();
            }
        });
    }

    componentDidMount() {
        this.load();
    }

    //--------------------------------------------------------------------------------------------------------------------

    async load() {
        const emailSettings = await EmailService.getSettings() || {};

        this.setState({
            emailSettings,
            isLoading: false
        }, () => {
            this.updatePreview();
        });
    }

    updateField(fieldName, value, fieldInfo, callback) {
        const emailSettings = { ...this.state.emailSettings };
        emailSettings[fieldName] = value;
        this.setState({ emailSettings }, callback);
    }

    async save() {
        this.setState({ isLoading: true });
        await EmailService.saveSettings(this.state.emailSettings);
        this.props.history.push('/settings');
    }

    async updatePreview() {
        const previewMeta = await EmailService.getPreviewMeta(this.state.salonAutomationTrigger, null);
        this.setState({
            emailPreviewSubject: previewMeta.subject
        }, () => {
            const salonCode = GlobalStateService.getValue('salonCode');
            let url = `/api/email/get-preview?salon-code=${salonCode}`;
            BaseService.postToIframe({
                ...this.state.emailSettings
            }, url, 'preview-iframe');
        });
    }

    async uploadLogo(file) {
        const response = await UploadService.uploadFromFile(file, {
            type: 'EmailLogo'
        });
        this.updateField('logoGUID', response.content, null, () => {
            this.updatePreview();
        });
    }

    removeLogo() {
        this.updateField('logoGUID', '', null, () => {
            this.updatePreview();
        });
    }

    //--------------------------------------------------------------------------------------------------------------------
    //  Render
    //--------------------------------------------------------------------------------------------------------------------

    renderInfoBar() {
        return (
            <InfoBar className="settings-info-bar" containerClassName="floating-on-mobile" sticky={true}>

                <div className="info-bar-panel-section info-bar-panel-section-text desktop-only">
                    Email Look & Feel
                </div>
                <div className="info-bar-panel-section ml-auto">

                    <button className="button" onClick={() => this.save()}>
                        <span className="fa fa-check"></span>{' '}
                        Save
                    </button>
                    
                </div>

            </InfoBar>
        );
    }

    render() {
        const {
            isLoading,
            emailSettings,
            previewURL
        } = this.state;

        if (isLoading) {
            return (<Loader />);
        }

        return (<>
            {this.renderInfoBar()}

            <div className="settings-page-main">

                <div className="panel">
                    <div className="panel-body">

                        <div className="row mb-3">

                            <div className="col-md-4">

                                <div className="form-group">
                                    <label>Logo</label>
                                    {!!emailSettings.logoGUID &&
                                        <div style={{
                                            backgroundColor: '#efefef',
                                            padding: 10,
                                            borderRadius: 10,
                                            marginBottom: 10
                                        }}>
                                            <img src={`/api/upload/view/${emailSettings.logoGUID}`} />
                                        </div>
                                    }

                                    <UploadButton
                                        onUpload={file => this.uploadLogo(file)}
                                        className="button upload-button button-secondary"
                                    >
                                        <span className="fa fa-plus"></span>{' '}
                                        Upload Logo
                                    </UploadButton>

                                    {!!emailSettings.logoGUID &&
                                        <button className="button button-small button-block button-tertiary" onClick={() => this.removeLogo()}>
                                            Remove Logo
                                        </button>
                                    }

                                </div>

                                {this.formHelper.renderFormGroups([
                                    'bgColour',
                                    'headerTextColour',
                                    'headerBGColour',
                                    'bodyTextColour',
                                    'bodyBGColour',
                                    'footerTextColour',
                                    'footerBGColour',
                                    'buttonTextColour',
                                    'buttonBGColour'
                                ])}

                            </div>

                            <div className="col-md-8">

                                <iframe name="preview-iframe" src="about:blank" className="preview-iframe" />

                            </div>

                        </div>

                        {this.formHelper.renderFormGroups([
                            'footerContent'
                        ])}
                    </div>
                </div>

            </div>
        </>);
    }

};

export default withRouter(SettingsEmailSettings);