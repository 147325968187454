import BaseService from 'services/BaseService';

//-------------------------------------------------------------------------------------------------------------------

export default class CompanyService
    extends BaseService {

    static async list() {
        return BaseService.callAPI('company/list');
    }

}
