// Libs
import React from 'react';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import TabPanel, { Tab } from 'components/reusable/TabPanel';

// Services & helpers
import ManagedEmailService from 'services/ManagedEmailService';
import TextHelpers from 'helpers/TextHelpers';
import GlobalStateService from 'services/GlobalStateService';

// Components
import FloomlyComponent  from 'components/FloomlyComponent';
import Loader        from 'components/reusable/Loader';

//-------------------------------------------------------------------------------------------------------------------

class CampaignsTab extends FloomlyComponent {

    constructor(props) {
        super(props);

        // Init state
        this.state = {
            isLoading: true,
            isAuth: false,
            isBusyCampaign: {}
        };

    }

    componentDidMount() {
        this.load();
    }

    componentWillUnmount() {
        clearInterval(this.refreshInterval);
        clearInterval(this.checkAuthAfterConnectTimeout);
    }

    async load() {
        clearTimeout(this.checkAuthAfterConnectTimeout);

        // Check auth
        const authDetails = await ManagedEmailService.checkAuth();

        if (authDetails.isAuth) {

            try {

                // Get list of months
                const campaignMonths = await ManagedEmailService.listCampaignMonths();

                // Default tab
                let status = this.props.match.params.subTab;
                let month;
                if (!status && campaignMonths.length > 0) {
                    status = 'sent';
                    month = campaignMonths[0];
                }

                // Update UI and load campaigns
                this.setState({
                    campaignMonths,
                    status,
                    month,
                    isAuth: true,
                    isLoading: false
                }, () => {
                    this.loadCampaigns(false);

                    // Also set up an interval to re-load campaigns
                    this.refreshInterval = setInterval(() => {
                        this.loadCampaigns(true);
                    }, 60 * 1000);
                });

            } catch (e) {
                this.setState({
                    isAuth: false,
                    isLoading: false
                });
                this.setCheckAuthInterval();
            }

        } else {
            this.setState({
                isAuth: false,
                isLoading: false
            });
            this.setCheckAuthInterval();
        }
    }

    showCampaigns(status, month) {
        // If showing sent, default to first month in the list
        if (status == 'sent' && !month && this.state.campaignMonths) {
            month = this.state.campaignMonths[0];
        }

        // Update UI and reload campaigns
        this.setState({
            status,
            month
        }, () => {
            this.loadCampaigns(false);
        });
    }

    async loadCampaigns(isSilent) {
        if (!isSilent) {
            this.setState({ isLoading: true });
        }
        try {
            const campaigns = await ManagedEmailService.listCampaigns(this.state.status, this.state.month);
            this.setState({
                isLoading: false,
                campaigns
            });
        } catch (e) {
            this.setState({
                isAuth: false
            });
        }
    }

    async editCampaign(id) {
        let isBusyCampaign = { ...this.state.isBusyCampaign };
        if (isBusyCampaign[id]) {
            return;
        }
        isBusyCampaign[id] = true;
        this.setState({ isBusyCampaign });
        
        const url = await ManagedEmailService.getCampaignURL(id);
        if (url) {
            window.open(url);
        }

        isBusyCampaign = { ...this.state.isBusyCampaign };
        delete isBusyCampaign[id];
        this.setState({ isBusyCampaign });
    }
    
    async createCampaign() {
        if (this.state.isCreatingCampaign) {
            return;
        }
        this.setState({ isCreatingCampaign: true });
        const url = await ManagedEmailService.createCampaign();
        if (url) {
            window.open(url);
            //this.loadCampaigns(false);
        }
        this.setState({ isCreatingCampaign: false });
    }

    connectConstantContact() {
        const salonCode = GlobalStateService.getValue('salonCode');
        window.open('/api/managed-email/auth/constantcontact?salon-code=' + salonCode);
        this.setCheckAuthInterval();
    }

    setCheckAuthInterval() {
        this.checkAuthAfterConnectTimeout = setTimeout(() => {
            this.load();
        }, 1000);
    }

    async logOut() {
        await ManagedEmailService.logOut();
        this.setCheckAuthInterval();
    }
    //--------------------------------------------------------------------------------------------------------------------
    //  Render
    //--------------------------------------------------------------------------------------------------------------------
    
    render() {

        return (
            <div className="campaigns-tab">

                {this.renderInner()}

            </div>
        );
    }

    renderInner() {
        const {
            isLoading,
            isAuth,
            campaignMonths,
            isCreatingCampaign,
            status,
            month
        } = this.state;
        const subTab = this.props.match.params.subTab;

        if (isLoading) {
            return (<Loader />);
        }

        if (!isAuth) {
            return (<>

                <h2 className="mb-3">Connect to your email provider...</h2>

                <button className="button button-primary" onClick={e => this.connectConstantContact()}>
                    Connect to Constant Contact
                </button>

            </>);
        }

        return (<>

            <TabPanel tab={subTab || 'sent'} onChangeTab={tab => {
                this.props.history.push(`/marketing/campaigns/${tab}`);
                this.showCampaigns(tab);
            }}>

                <Tab id="sent" title="Sent" tabClassName="tab-teal">

                    {campaignMonths &&
                        <div className="nav-buttons">

                            {campaignMonths.map(m =>
                                <button
                                    key={moment(m).format('YYYY-MM-DD')}
                                    className={'button button-small ' + (status == 'sent' && m == month ? 'button-primary' : 'button-secondary')}
                                    onClick={e => this.showCampaigns('sent', m)}
                                >
                                    {moment(m).format('MMM YYYY')}
                                </button>
                            )}

                        </div>
                    }

                    {this.renderCampaigns()}

                </Tab>

                <Tab id="draft" title="Draft" tabClassName="tab-pink">

                    {this.renderCampaigns()}

                </Tab>

                <Tab id="scheduled" title="Scheduled" tabClassName="tab-yellow">

                    {this.renderCampaigns()}

                </Tab>

            </TabPanel>

            <button className="button button-primary new-campaign-button" onClick={e => this.createCampaign()}>
                {isCreatingCampaign ?
                    <Loader isInline /> :
                <>
                    <span className="fa fa-plus"></span>{' '}
                    New campaign
                </>}
            </button>

            {isAuth &&
                <button className="button button-primary logout" onClick={e => this.logOut()}>
                    Log out of Constant Contact
                </button>
            }

        </>);
    }

    renderCampaigns() {
        const {
            campaigns,
            isLoading
        } = this.state;

        if (isLoading) {
            return (<Loader />);
        }

        if (!campaigns || campaigns.length == 0) {
            return (
                <div className="empty-text">
                    There are no campaigns to show here
                </div>
            );
        }

        return (
            <ul className="campaign-list">
                {campaigns.map(campaign =>
                    <React.Fragment key={campaign.id}>
                        {this.renderCampaign(campaign)}
                    </React.Fragment>
                )}
            </ul>
        );
    }

    renderCampaign(campaign) {
        const {
            isBusyCampaign
        } = this.state;

        let status;
        switch (campaign.status) {
            case 'draft': status = 'Draft'; break;
            case 'scheduled': status = 'Scheduled'; break;
            case 'sending': status = 'Sending'; break;
            case 'sent': status = 'Sent'; break;
            case 'error': status = 'Error'; break;
        }

        const formatPercent = (num) => campaign.numSent > 0 ? (TextHelpers.formatPercentage(num / campaign.numSent, 1) + '%') : '—';
        
        return (
            <li>
                <div className="left">
                    <div className="name">
                        {campaign.name}
                    </div>
                    <div className="status">
                        <span className={'status-tag status-' + campaign.status}>
                            {status}
                        </span>
                        {campaign.dateSent && <>

                            <span className="status-text">
                                on {moment(campaign.dateSent).format('Mo MMMM, YYYY')}
                            </span>

                            <button className="button button-tertiary edit-button" onClick={e => this.editCampaign(campaign.id)}>
                                {isBusyCampaign[campaign.id] ?
                                    <Loader isInline={true} /> :
                                    <>
                                        <span className="fa fa-eye"></span>{' '}
                                        Results
                                    </>
                                }
                            </button>
                        </>}
                    </div>
                </div>
                {campaign.dateSent ?
                    <div className="right sent">

                        <figure>
                            <span className="stat">
                                {campaign.numSent}
                            </span>
                            <figcaption>Sent</figcaption>
                        </figure>

                        <figure className="hover-percent">
                            <span className="stat">
                                <span className="number">{campaign.numOpened}</span>
                                <span className="percent">{formatPercent(campaign.numOpened)}</span>
                            </span>
                            <figcaption>Opened</figcaption>
                        </figure>

                        <figure className="hover-percent">
                            <span className="stat">
                                <span className="number">{campaign.numClicked}</span>
                                <span className="percent">{formatPercent(campaign.numClicked)}</span>
                            </span>
                            <figcaption>Clicked</figcaption>
                        </figure>

                        <figure className="hover-percent">
                            <span className="stat">
                                <span className="number">{campaign.numBounced}</span>
                                <span className="percent">{formatPercent(campaign.numBounced)}</span>
                            </span>
                            <figcaption>Bounced</figcaption>
                        </figure>

                        <figure className="hover-percent">
                            <span className="stat">
                                <span className="number">{campaign.numOptOut}</span>
                                <span className="percent">{formatPercent(campaign.numOptOut)}</span>
                            </span>
                            <figcaption>Opt-out</figcaption>
                        </figure>

                    </div>
                    :
                    <div className="right draft">

                        <button className="button button-primary edit-button" onClick={e => this.editCampaign(campaign.id)}>
                            {isBusyCampaign[campaign.id] ?
                                <Loader isInline={true} /> :
                                <>
                                    <span className="fa fa-pencil-alt"></span>{' '}
                                    Edit
                                </>
                            }
                        </button>

                    </div>
                }
            </li>
        );
    }

};

export default withRouter(CampaignsTab);