// Libs
import React, { Component } from 'react';

// Services & Helpers
import StockService from 'services/StockService';
import ProductService from 'services/ProductService';
import SearchService from 'services/SearchService';
import GlobalStateService from 'services/GlobalStateService';

// Components
import FloomlyComponent from 'components/FloomlyComponent';
import Search from 'components/reusable/Search';
import Loader from 'components/reusable/Loader';

//-------------------------------------------------------------------------------------------------------------------

class ApptProductsAndNotesEditor extends FloomlyComponent {

    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            isAddingProduct: {},
            productCategories: []
        };

        this.appointmentProductSequence = -1;
    }

    componentDidMount() {
        this.load();
    }

    async load() {
        const { appt } = this.props;

        // Load info
        const productCategories = await StockService.listCategories(true);

        //// Group existing products by category
        //const productCategoriesLookup = {};
        //productCategories.forEach(pc => {
        //    if (!productCategoriesLookup[pc.id]) {
        //        productCategoriesLookup[pc.id] = result.length;
        //        result.push({
        //            ...pc,
        //            appointmentProducts: []
        //        });
        //    }
        //});

        //let productIndex = 0;
        //appt.appointmentProducts.forEach(ap => {
        //    const index = productCategoriesLookup[ap.productCategoryID];
        //    if (index >= 0) {
        //        ap.index = productIndex;
        //        result[index].appointmentProducts.push(ap);
        //    }
        //    productIndex++;
        //});

        // Update UI
        this.setState({
            isLoading: false,
            productCategories
        });
    }

    //groupProductsByType() {
    //    const result = [];
    //    const productCategoriesHash = {};

    //    // Create a hash lookup for product categories
    //    this.state.productCategories.forEach(pc => {
    //        if (!productCategoriesHash[pc.id]) {
    //            productCategoriesHash[pc.id] = result.length;
    //            result.push({
    //                ...pc,
    //                appointmentProducts: []
    //            });
    //        }
    //    });

    //    // Put each appointmentProduct into the correct productCategory
    //    let productIndex = 0;
    //    this.props.appt.appointmentProducts.forEach(ap => {
    //        const index = productCategoriesHash[ap.productCategoryID];
    //        if (index >= 0) {
    //            ap.index = productIndex;
    //            result[index].appointmentProducts.push(ap);
    //        }
    //        productIndex++;
    //    });

    //    return result;
    //}

    toggleAddingProduct(productCategoryID, adding) {
        const isAddingProduct = { ...this.state.isAddingProduct };
        isAddingProduct[productCategoryID] = adding;
        this.setState({ isAddingProduct });
    }

    async selectProduct(result) {
        const product = await ProductService.get(result.id);

        const appt = { ...this.props.appt };
        appt.appointmentProducts.push({
            appointmentProductID: this.appointmentProductSequence--,
            productID: product.productID,
            productCategoryID: product.productCategoryID,
            name: product.name,
            stockUnit: product.stockUnit,
            amountUsed: ''
        });
        this.props.onUpdate(appt);
        this.props.onApptUpdated(true);
    }

    updateAppointmentProductField(appointmentProductID, field, value) {
        const appt = { ...this.props.appt };
        const appointmentProducts = [...appt.appointmentProducts];
        const index = appointmentProducts.findIndex(ap => ap.appointmentProductID == appointmentProductID);
        appointmentProducts[index][field] = value;
        appt.appointmentProducts = appointmentProducts;       
        this.props.onUpdate(appt);
        this.props.onApptUpdated(true);
    }

    removeAppointmentProduct(appointmentProductID) {
        const appt = { ...this.props.appt };
        appt.appointmentProducts = [...appt.appointmentProducts];
        const index = appt.appointmentProducts.findIndex(ap => ap.appointmentProductID == appointmentProductID);
        appt.appointmentProducts.splice(index, 1);
        this.props.onUpdate(appt);
        this.props.onApptUpdated(true);
    }

    updateField(field, value) {
        const appt = { ...this.props.appt };
        appt[field] = value;
        this.props.onUpdate(appt);
        this.props.onApptUpdated(true);
    }

    //--------------------------------------------------------------------------------------------------------------------
    // Render
    //--------------------------------------------------------------------------------------------------------------------

    render() {
        const { appt } = this.props;
        const { isLoading } = this.state;

        if (isLoading) {
            return (<Loader />);
        }

        const loginDetails = GlobalStateService.getValue('loginDetails');

        return (<>

            {this.renderProducts('Technical')}

            <div className="panel-info-header">
                Colour Notes
            </div>

            <textarea
                className="w-100"
                rows="3" value={appt.colourNotes || ''}
                onChange={e => this.updateField('colourNotes', e.target.value)}
                onBlur={e => this.props.onBlur && this.props.onBlur('colourNotes', e.target.value)}
                disabled={!loginDetails.permissions['ClientCardEditAppointmentNotes']}
            />
            
            {this.renderProducts('Hair Products')}

            <div className="panel-info-header">
                Appointment Notes
            </div>

            <textarea
                className="w-100"
                rows="3"
                value={appt.notes || ''}
                onChange={e => this.updateField('notes', e.target.value)}
                onBlur={e => this.props.onBlur && this.props.onBlur('notes', e.target.value)}
                disabled={!loginDetails.permissions['ClientCardEditAppointmentNotes']}
            />

        </>);
    }

    renderProducts(categoryName) {
        const { appt } = this.props;
        const { productCategories, isAddingProduct } = this.state;
        const productCategory = productCategories.find(pc => pc.name == categoryName);
        if (!productCategory) return null;
        const apptProducts = appt.appointmentProducts.filter(ap => ap.productCategoryID == productCategory.id);

        return (<>
            <div className="panel-info-header">
                {categoryName}
            </div>
            <div className="mb-3">
                <ul className="list service-list">
                    {apptProducts.map((ap, index) => 
                        <li key={ap.appointmentProductID} className="non-selectable">
                            <span className="service-list-name">
                                {ap.name}
                            </span>
                            <span className="service-list-price">
                                <input
                                    type="number"
                                    autoFocus={true}
                                    value={ap.amountUsed}
                                    onChange={e => this.updateAppointmentProductField(ap.appointmentProductID, 'amountUsed', Number(e.target.value))}
                                />
                                {' '}
                                {ap.stockUnit}
                            </span>
                            <span className="service-list-icon-right">
                                <button className="button customer-summary-change-button" onClick={e => this.removeAppointmentProduct(ap.appointmentProductID)}>
                                    <span className="fa fa-times"></span>
                                </button>
                            </span>
                        </li>
                    )}
                </ul>
                {!isAddingProduct[productCategory.id] &&
                    <button className="button button-tertiary search-box-button" onClick={e => this.toggleAddingProduct(productCategory.id, true)}>
                        <span className="fa fa-plus"></span>{' '}
                        Add a product
                    </button>
                }
                {isAddingProduct[productCategory.id] &&
                    <div className="product-search search-box-absolute">
                        <Search
                            className="search-box"
                            autoFocus={true}
                            placeholder="Search for a product..."
                            onBlur={e => this.toggleAddingProduct(productCategory.id, false)}
                            search={async (query, setResults, maxResults) => {
                                const results = await SearchService.search(query, ['Product'], {
                                    filters: { stockProductCategoryID: productCategory.id },
                                    maxResults
                                });
                                setResults(results);
                            }}
                            renderResult={(result, index, info) =>
                                <div key={index} className="search-result list-item" onMouseDown={e => {
                                    this.selectProduct(result);
                                    info.clearSearch();
                                    info.focus();
                                }}>
                                    <div className="list-item-name">
                                        {result.name}
                                    </div>
                                </div>
                            }
                        />
                    </div>
                }
            </div>
        </>);
        
        //const appointmentProductsGrouped = this.groupProductsByType();

        //return (<>

        //    {appointmentProductsGrouped.map((productCategory) =>
        //        <React.Fragment key={productCategory.id}>
        //            <div className="panel-info-header">
        //                {productCategory.name}
        //            </div>

        //            <ul className="list service-list">

        //                {productCategory.appointmentProducts.map((appointmentProduct) =>
        //                    <li key={appointmentProduct.appointmentProductID} className="non-selectable">

        //                        <span className="service-list-name">
        //                            {appointmentProduct.name}
        //                        </span>

        //                        <span className="service-list-price">

        //                            <input
        //                                type="number"
        //                                autoFocus={true}
        //                                value={appointmentProduct.amountUsed}
        //                                onChange={e => this.updateAppointmentProductField(appointmentProduct.appointmentProductID, 'amountUsed', Number(e.target.value))}
        //                            />
        //                            {' '}
        //                            {appointmentProduct.stockUnit}

        //                        </span>

        //                        <span className="service-list-icon-right">

        //                            <button className="button customer-summary-change-button" onClick={e => this.removeAppointmentProduct(appointmentProduct.appointmentProductID)}>
        //                                <span className="fa fa-times"></span>
        //                            </button>

        //                        </span>

        //                    </li>
        //                )}

        //            </ul>


        //            

        //        </React.Fragment>
        //    )}

        //</>);

        return null;
    }
}

export default ApptProductsAndNotesEditor;