// Libs
import React from 'react';
import { Route } from 'react-router';
import { Link, withRouter } from 'react-router-dom';

// Services & Helpers
import TaxRateService from 'services/TaxRateService';
import FormHelper from 'helpers/FormHelper';
import GlobalStateService from 'services/GlobalStateService';
import BootboxHelper from 'helpers/BootboxHelper';

// Components
import FloomlyComponent from 'components/FloomlyComponent';
import InfoBar from 'components/layout/InfoBar'
import Loader from 'components/reusable/Loader';
import SuperTable from 'components/reusable/SuperTable';

//-------------------------------------------------------------------------------------------------------------------

class SettingsTaxRateEdit extends FloomlyComponent {

    constructor(props) {
        super(props);

        // Init state
        this.state = {
            isLoading: true,
            taxRate: null
        };

        this.updateField = this.updateField.bind(this);
        
        this.formHelper = new FormHelper({
            fields: {
                name: { label: 'Name' },
                rate: { label: 'Rate' },
                isDefaultServices: {
                    checkboxLabel: 'Default for services',
                    type: 'checkbox'
                },
                isDefaultRetail: {
                    checkboxLabel: 'Default for retail',
                    type: 'checkbox'
                },
                //isDefaultProfessional: {
                //    checkboxLabel: 'Default for professional',
                //    type: 'checkbox'
                //}
            },
            getValue: (fieldName) => this.state.taxRate[fieldName],
            setValue: this.updateField
        });
    }

    componentDidMount() {
        this.load();
    }

    //--------------------------------------------------------------------------------------------------------------------

    async load() {
        const taxRateID = Number(this.props.match.params.taxRateID) || 0;

        // Load taxRate
        let taxRate;
        if (taxRateID) {
            taxRate = await TaxRateService.get(taxRateID);
        } else {
            taxRate = {
            };
        }

        // Update UI
        this.setState({
            isLoading: false,
            taxRate
        });
    }

    goBack() {
        this.props.history.push('/settings/tax-rates');
    }

    async save() {
        this.setState({ isLoading: true });
        await TaxRateService.save(this.state.taxRate);
        this.goBack();
    }

    updateField(fieldName, value) {
        const taxRate = { ...this.state.taxRate };
        taxRate[fieldName] = value;
        this.setState({
            taxRate
        });
    }

    async confirmDelete() {
        const confirm = await BootboxHelper.confirm('Are you sure you want to delete this tax rate?');
        if (confirm) {
            this.delete();
        }
    }

    async delete() {
        await TaxRateService.delete(this.state.taxRate.id);
        this.props.history.replace('/settings/tax-rates');
    }

    //--------------------------------------------------------------------------------------------------------------------
    //  Render
    //--------------------------------------------------------------------------------------------------------------------

    renderInfoBar() {
        const {
            taxRate
        } = this.state;

        return (
            <InfoBar className="settings-info-bar" containerClassName="floating-on-mobile" sticky={true}>

                <div className="info-bar-panel-section">

                    <button className="button" onClick={() => this.goBack()}>
                        <span className="fa fa-chevron-left"></span>{' '}
                        Back
                    </button>

                </div>

                <div className="info-bar-panel-section info-bar-panel-section-text desktop-only">
                    {taxRate.id ? taxRate.name : 'New tax rate'}
                </div>
                
                <div className="info-bar-panel-section ml-auto">

                    {taxRate.id &&
                        <button className="button" style={{ marginRight: 10 }} onClick={()=> this.confirmDelete() }>
                            <span className="fa fa-times"></span>{' '}
                            Delete
                        </button>
                    }
                    <button className="button" onClick={() => this.save()}>
                        <span className="fa fa-check"></span>{' '}
                        Save
                    </button>
                    
                </div>

            </InfoBar>
        );
    }

    render() {
        const {
            isLoading
        } = this.state;

        if (isLoading) {
            return (<Loader />);
        }

        return (<>
            {this.renderInfoBar()}

            <div className="settings-page-main">
                <div className="panel">
                    <div className="panel-body">

                        {this.formHelper.renderFormGroups([
                            'name',
                            'rate',
                            'isDefaultServices',
                            'isDefaultRetail',
                            //'isDefaultProfessional'
                        ])}

                    </div>

                </div>

            </div>

        </>);
    }

};

export default withRouter(SettingsTaxRateEdit);