// Libs
import React, { useState } from 'react';

//-------------------------------------------------------------------------------------------------------------------

const Alert = (props) => {

    // TODO get the correct style
    
    return (
        <div className="alert alert-danger">
            {props.children}
        </div>
    );

};

export default Alert;