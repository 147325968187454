// Libs
import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import * as moment from 'moment';

// Services
import SearchService from 'services/SearchService';
import GlobalStateService from 'services/GlobalStateService';
import NotificationService from 'services/NotificationService';
import SalonService from 'services/SalonService';

// Components
import Search from 'components/reusable/Search';

// Images
import userIconImage from 'images/user-icon-image-placeholder.jpg';
import logoSmallImage from 'images/perfect-client/heart.png';
import NotificationArea from '../NotificationArea';

//-------------------------------------------------------------------------------------------------------------------

class NavMenu extends Component {

    constructor(props) {
        super(props);

        this.notificationAreaRef = React.createRef();

        this.onSearchResultClick = this.onSearchResultClick.bind(this);
        this.onUpdateNewVersion = this.onUpdateNewVersion.bind(this);
        this.addCustomer = this.addCustomer.bind(this);

        this.state = {
            time: null,
            notification: null,
            time: moment().format('HH:mm'),
            salonList: [],
            notificationsEnabled: false
        };
    }

    async componentDidMount() {
        setInterval(() => {
            const newTime = moment().format('HH:mm');

            if (newTime != this.state.time) {
                this.setState({
                    time: newTime
                });
            }
        }, 1000);


        const notificationsEnabled = await NotificationService.getNotificationsEnabled();
        this.setState({ notificationsEnabled });
        if (notificationsEnabled) {
            this.checkForNotifications();
            this.getNumUnreadNotificationsInterval = setInterval(() => {
                this.checkForNotifications();
            }, 60 * 1000);
        }
        this.load();
        GlobalStateService.subscribe('newVersion', this.onUpdateNewVersion);
    }

    componentWillUnmount() {
        clearInterval(this.getNumUnreadNotificationsInterval);
        GlobalStateService.unsubscribe('newVersion', this.onUpdateNewVersion);
    }

    onUpdateNewVersion(newVersion) {
        this.setState({
            newVersion
        });
    }

    onSearchResultClick(searchResult, info) {
        let route = '';

        switch (searchResult.type) {
            case 'Customer':
                route = '/customer/' + searchResult.id;
                break;
        }

        if (route) {
            this.props.history.push(route);
            info.clearSearch();
        }
    }

    addCustomer(query) {
        this.props.history.push('/customer/0/details/' + query);
    }

    async checkForNotifications() {
        const numNotifications = await NotificationService.getNumUnreadNotifications();
        if (numNotifications > this.state.numNotifications) {
            this.animateBell();
        }
        this.setState({
            numNotifications
        });
    }

    showNotificationsArea() {
        if (this.state.notificationsEnabled) {
            this.notificationAreaRef.current.show({});
        }
    }

    async load() {
        const loginDetails = GlobalStateService.getValue('loginDetails');
        const user = loginDetails.user;
        let salonList = [];
        if (user.isGroupUser) {
            salonList = await SalonService.listAllSalons();
        }

        this.setState({
            salonList
        });
    }

    // Redirect to salon with in the group
    redirectToSalon(salonCode) {
        GlobalStateService.setValue('salonCode', salonCode);
        const salonGroupCode = GlobalStateService.getValue('salonGroupCode');
        window.location.replace(`/group/${salonGroupCode}/${salonCode}`);
        this.setState({ salonCode });
    }

    animateBell() {
        const bells = document.querySelectorAll('.user-notification-icon');
        bells.forEach(bell => {
            bell.classList.add('bell-shake');
            setTimeout(() => {
                bell.classList.remove('bell-shake');
            }, 2000);
        });
    }

    render() {
        const {
            time,
            newVersion,
            numNotifications,
            salonList
        } = this.state;
        const clientInfo = GlobalStateService.getValue('clientInfo');
        const loginDetails = GlobalStateService.getValue('loginDetails');
        const salonCode = GlobalStateService.getValue('salonCode');
        const salonGroupCode = GlobalStateService.getValue('salonGroupCode');
        const user = loginDetails.user;
        const canAdd = loginDetails.permissions['ClientCardEditContactDetails'];

        return (
            <>
                <NotificationArea ref={this.notificationAreaRef}
                    updateNumOfNotifications={this.checkForNotifications.bind(this)}
                />
                <nav className="top-nav">

                    <div className="top-nav-left">
                        <div className="clock">
                            <div className="clock-figure">
                                &nbsp;
                            </div>
                            <div className="clock-face">{time}</div>
                        </div>
                    </div>

                    <div className="top-nav-right">

                        <div className="top-nav-right-upper">

                            {/* SEARCH PANEL */}
                            <div className="panel global-search-panel search-box-absolute">

                                <img
                                    src={logoSmallImage}
                                    className="mobile-logo"
                                    onClick={e => {
                                        window.alert(`${clientInfo.name}\r\nFloomly v${clientInfo.version}`);
                                    }}
                                />

                                <Search
                                    className="search-box"
                                    placeholder="Search for a customer..."
                                    minLength={2}
                                    maxResults={50}
                                    search={async (query, setResults, maxResults, state) => {
                                        const results = await SearchService.search(query, ['Customer'], { maxResults, state });
                                        setResults(results);
                                    }}
                                    renderWrapper={(input, results, info) => <>
                                        {input}
                                        {results &&
                                            <div className={'list search-results ' + (info.isActive ? 'active' : '')}>
                                                {results}
                                                {results.length == 0 && !!info.noResultsText &&
                                                    <p className="empty-text">{info.noResultsText}</p>
                                                }
                                                {canAdd &&
                                                    <div className="add-customer-button-container">
                                                        <button className="button button-primary" onMouseDown={e => this.addCustomer(info.query)}>
                                                            <span className="fa fa-plus"></span>{' '}
                                                            Add Customer...
                                                        </button>
                                                    </div>
                                                }
                                            </div>
                                        }
                                    </>}
                                    renderResult={(result, index, info) =>
                                        <div key={index} className="search-result list-item" onMouseDown={e => this.onSearchResultClick(result, info)}>
                                            <div className="list-item-name">
                                                {result.name}
                                                <div className="list-subitem-number">
                                                    {result.customerTel} {result.customerTel && result.customerEmail && <>-</>} {result.customerEmail}
                                                </div>
                                            </div>
                                        </div>
                                    }
                                />

                                <span className="far fa-bell user-notification-icon mobile-only" onClick={e => this.showNotificationsArea()}></span>
                                {numNotifications > 0 &&
                                    <span className="menu-item-badge mobile-only" onClick={e => this.showNotificationsArea()}>{numNotifications}</span>
                                }

                                <button
                                    type="button"
                                    className="mobile-menu-toggle"
                                    onClick={() => GlobalStateService.getValue('toggleMobileNav')()}
                                >
                                    <span className="fa-solid fa-bars open-icon" />
                                    <span className="fa-solid fa-times close-icon" />
                                </button>

                            </div>
                            {/* /SEARCH PANEL */}

                            {/* USER DETAILS PANEL */}
                            <div className="panel user-details-panel">
                                <span className="far fa-bell user-notification-icon" onClick={e => this.showNotificationsArea()}></span>
                                {numNotifications > 0 &&
                                    <span className="menu-item-badge" onClick={e => this.showNotificationsArea()}>{numNotifications}</span>
                                }
                                <div className="user-notification-area">
                                    {!!newVersion &&
                                        <span className="slow-blink" onClick={() => window.location.reload()}>
                                            A new version of Floomly (v{newVersion}) is available. Please click here to update.
                                        </span>
                                    }
                                </div>
                                <div className="user-details-area">
                                    <img alt="" src={userIconImage} className="user-icon" />
                                    <span className="user-name">{user.nickname}</span>
                                </div>
                                {!!salonGroupCode && salonList.length > 1 && <div>
                                    <select
                                        value={salonCode || ''}
                                        style={{ width: '100%' }}
                                        onChange={e => this.redirectToSalon(e.target.value)}
                                    >
                                        {salonList.map(s =>
                                            <option key={s.salonID} value={s.code}>{s.name}</option>
                                        )}
                                    </select>
                                </div>}
                            </div>

                        </div>

                    </div>

                </nav>
            </>
        );
    }

}

export default withRouter(NavMenu);