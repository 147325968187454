import React, { Component } from 'react';

//-------------------------------------------------------------------------------------------------------------------

class UploadButton extends Component {

    render() {
        return (
            <label className={this.props.className}>
                <input type="file" accept={this.props.accept} onChange={e => {
                    if (e.target.files && e.target.files.length > 0) {
                        this.props.onUpload(e.target.files[0]);
                        e.target.value = '';
                    }
                }} />
                {this.props.children}
            </label>
        );
    }
};

UploadButton.defaultProps = {
    className: 'button upload-button',
    accept: 'image/*',
    onUpload: ((e) => console.log('onUpload not specified for <UploadButton>'))
}

export default UploadButton;
