import BaseService from 'services/BaseService';

//-------------------------------------------------------------------------------------------------------------------

export default class OnlineBookingService
    extends BaseService {
    
    static async getSettings() {
        return BaseService.callAPI('online-booking/get-settings');
    }

    static async saveSettings(obSettings) {
        return BaseService.callAPI('online-booking/save-settings', obSettings);
    }
}
