// Libs
import React from 'react';
import { Route } from 'react-router';
import { Link, withRouter } from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/en-gb';
import NumberFormat from 'react-number-format';

// Services
import BudgetService     from 'services/BudgetService';
import BootboxHelper from 'helpers/BootboxHelper';
import SalonService from 'services/SalonService';
import TextHelpers       from 'helpers/TextHelpers';

// Components
import FloomlyComponent      from 'components/FloomlyComponent';
import InfoBar           from 'components/layout/InfoBar'
import Loader            from 'components/reusable/Loader';
import Money             from 'components/reusable/Money';

//-------------------------------------------------------------------------------------------------------------------

class BudgetPage extends FloomlyComponent {

    constructor(props) {
        super(props);

        // Init state
        this.state = {
            isLoading: true,
            incomeLines: [],
            expenseLines: [],
            wagePercentage: 0,
            stockPercentage: 0,
            isEditMode: false,
            isAddingLine: {}
        };
    }

    componentDidMount() {
        this.load(this.state.month);
    }

    static getDerivedStateFromProps(props, state) {
        const month = props.match.params.month || '';
        if (month != state.month) {
            return {
                month: moment(moment(month || undefined).format('YYYY-MM') + '-01').toDate()
            };
        } else {
            return null;
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const thisMonth = (this.state.month ? moment(this.state.month).format('YYYY-MM') : '');
        const prevMonth = (prevState.month ? moment(prevState.month).format('YYYY-MM') : '');
        if (thisMonth != prevMonth) {
            this.load(this.state.month);
        }
    }

    //--------------------------------------------------------------------------------------------------------------------
    
    async load(month) {
        this.setState({
            isLoading: true
        });

        // Load
        const getBudgetResponse = await BudgetService.get(month);
        const budget = getBudgetResponse;

        // Process each line
        budget.budgetLines.forEach(line => {
            this.updateBudgetLineTotals(line);
        });

        // Split lines
        const incomeLines = budget.budgetLines.filter(bl => bl.type == 'income');
        const expenseLines = budget.budgetLines.filter(bl => bl.type == 'expense');
        delete budget.budgetLines;
        
        // Update UI
        this.setState({
            isLoading: false,
            budget: budget,
            incomeLines: incomeLines,
            expenseLines: expenseLines
        });
        this.updateBudgetTotals(incomeLines, expenseLines);

        // If newly added, save in order to update Brought/Carry forward throughout salon
        if (budget.isNew) {
            this.saveBudget();
        }
    }

    toggleEditMode() {
        this.setState({
            isEditMode: !this.state.isEditMode
        });
    }

    updateBudgetLine(type, index, field, value) {
        let updateTotals = false;
        switch (field) {
            case 'budgetedAmount':
            case 'actualAmount':
                value = Number(value);
                updateTotals = true;
                break;
        }

        if (type == 'income') {
            const incomeLines = [...this.state.incomeLines];
            const line = { ...incomeLines[index] };
            line[field] = value;
            if (updateTotals) {
                this.updateBudgetLineTotals(line);
            }
            incomeLines[index] = line;
            if (updateTotals) {
                this.updateBudgetTotals(incomeLines, this.state.expenseLines);
            }
            this.setState({
                incomeLines: incomeLines
            });
        } else if (type == 'expense') {
            const expenseLines = [...this.state.expenseLines];
            const line = { ...expenseLines[index] };
            line[field] = value;
            if (updateTotals) {
                this.updateBudgetLineTotals(line);
            }
            expenseLines[index] = line;
            if (updateTotals) {
                this.updateBudgetTotals(this.state.incomeLines, expenseLines);
            }
            this.setState({
                expenseLines: expenseLines
            });
        }

        this.isChanged = true;
    }

    async saveBudgetLine(type, index) {
        if (this.isChanged) {
            this.isChanged = false;
            
            const budgetLine = (type == 'income' ? this.state.incomeLines[index] :
                                type == 'expense' ? this.state.expenseLines[index] :
                                null);
            const saveBudgetLineResult = await BudgetService.saveLine(budgetLine);
            const budgetLineID = saveBudgetLineResult;
            if (budgetLine.budgetLineID <= 0) {
                this.updateBudgetLine(type, index, 'budgetLineID', budgetLineID);
            }

            this.saveBudget();
        }
    }

    async addLine(type) {
        if (this.state.isAddingLine[type]) return;
        
        this.setState({
            isAddingLine: { ...this.state.isAddingLine, [type]: true }
        });

        // Build line
        const line = {
            budgetID: this.state.budget.budgetID,
            
            month: this.state.month,
            name: '',
            type: type,
            budgetedAmount: 0,
            actualAmount: 0
        };

        // Add to DB and get the new ID
        const saveLineResult = await BudgetService.saveLine(line);
        line.budgetLineID = saveLineResult;
        this.highlightBudgetLineID = line.budgetLineID;

        // Add to correct array and update UI
        if (type == 'income') {
            const incomeLines = [...this.state.incomeLines];
            incomeLines.push(line);
            this.setState({
                incomeLines: incomeLines,
                isAddingLine: { ...this.state.isAddingLine, [type]: false }
            });
        } else if (type == 'expense') {
            const expenseLines = [...this.state.expenseLines];
            expenseLines.push(line);
            this.setState({
                expenseLines: expenseLines,
                isAddingLine: { ...this.state.isAddingLine, [type]: false }
            });
        }
    }
    
    updateBudgetLineTotals(line) {
        line.delta = ((line.actualAmount || 0) - (line.budgetedAmount || 0));
        if (line.type == 'expense') {
            line.delta = -line.delta;
        }
    }

    updateBudgetTotals(incomeLines, expenseLines) {
        if (!incomeLines) incomeLines = this.state.incomeLines;
        if (!expenseLines) expenseLines = this.state.expenseLines;
        const budget = { ...this.state.budget };

        // Reset
        budget.totalBudgetedIncome = 0;
        budget.totalBudgetedFixedExpenses = 0;
        budget.totalBudgetedVariableExpenses = 0;
        budget.totalBudgetedExpenses = 0;
        budget.totalBudgeted = 0;
        budget.totalActualIncome = 0;
        budget.totalActualFixedExpenses = 0;
        budget.totalActualVariableExpenses = 0;
        budget.totalActualExpenses = 0;
        budget.totalActual = 0;
        budget.totalDelta = 0;
        budget.actualWagePercentage = 0;
        budget.actualStockPercentage = 0;
        
        // Sum each line
        incomeLines.forEach(line => {
            budget.totalBudgetedIncome += (line.budgetedAmount || 0);
            budget.totalActualIncome += (line.actualAmount || 0);
            budget.totalDelta += (line.delta || 0);
        });
        expenseLines.forEach(line => {
            budget.totalBudgetedFixedExpenses += (line.budgetedAmount || 0);
            budget.totalBudgetedExpenses += (line.budgetedAmount || 0);
            budget.totalActualFixedExpenses += (line.actualAmount || 0);
            budget.totalActualExpenses += (line.actualAmount || 0);
            budget.totalDelta += (line.delta || 0);
        });
        budget.totalBudgeted = budget.totalBudgetedIncome - budget.totalBudgetedExpenses;
        budget.totalActual = budget.totalActualIncome - budget.totalActualExpenses;

        // Wage & Stock
        budget.budgetedWageAmount = budget.totalBudgetedIncome * budget.budgetedWagePercentage;
        budget.budgetedStockAmount = budget.totalBudgetedIncome * budget.budgetedStockPercentage;
        budget.totalBudgetedVariableExpenses += budget.budgetedWageAmount;
        budget.totalBudgetedExpenses += budget.budgetedWageAmount;
        budget.totalBudgetedVariableExpenses += budget.budgetedStockAmount;
        budget.totalBudgetedExpenses += budget.budgetedStockAmount;
        budget.totalActualVariableExpenses += budget.actualWageAmount;
        budget.totalActualVariableExpenses += budget.actualStockAmount;
        budget.stockDelta = budget.budgetedStockAmount - budget.actualStockAmount;
        budget.wageDelta = budget.budgetedWageAmount - budget.actualWageAmount;
        budget.actualWagePercentage = (budget.totalActualIncome != 0 ? (budget.actualWageAmount / budget.totalActualIncome) : null);
        budget.actualStockPercentage = (budget.totalActualIncome != 0 ? (budget.actualStockAmount / budget.totalActualIncome) : null);
        budget.totalDelta += budget.stockDelta;
        budget.totalDelta += budget.wageDelta;

        // Carry forward
        budget.carryForward = budget.broughtForward + budget.totalDelta;

        // Update UI
        this.setState({
            budget: budget
        });
        return budget;
    }

    async confirmDeleteLine(type, index) {
        const confirm = await BootboxHelper.confirm('Delete this budget line?');
        if (confirm) {
            this.deleteLine(type, index);
        }
    }

    deleteLine(type, index) {
        if (type == 'income') {
            const incomeLines = [...this.state.incomeLines];
            const line = incomeLines.splice(index, 1)[0];
            BudgetService.deleteLine(line);
            this.setState({
                incomeLines: incomeLines
            });
        } else if (type == 'expense') {
            const expenseLines = [...this.state.expenseLines];
            const line = expenseLines.splice(index, 1)[0];
            BudgetService.deleteLine(line);
            this.setState({
                expenseLines: expenseLines
            });
        }
    }

    updateField(field, value) {
        const budget = { ...this.state.budget };
        switch (field) {
            case 'actualStockAmount':
            case 'actualWageAmount':
                value = Number(value);
        }
        budget[field] = value;
        this.setState({
            budget: budget
        });
    }

    async saveBudget() {
        const budget = this.updateBudgetTotals();
        await BudgetService.save(budget);
    }

    move(amount) {
        const monthMoment = moment(this.state.month);
        monthMoment.add(amount, 'months');
        this.props.history.push('/budget/' + monthMoment.format('YYYY-MM'));
    }

    //--------------------------------------------------------------------------------------------------------------------
    //  Render
    //--------------------------------------------------------------------------------------------------------------------

    renderBudgetLine(line, index) {
        const {
            isEditMode
        } = this.state;
        const currency = SalonService.getCurrentCurrency();

        return (
            <tr className="budget-item-row">
                <td>
                    <div>
                        {isEditMode && !line.isLocked && <>
                            <input
                                type="text"
                                value={line.name}
                                placeholder="Enter description..."
                                autoFocus={line.budgetLineID == this.highlightBudgetLineID}
                                onChange={e => this.updateBudgetLine(line.type, index, 'name', e.target.value)}
                            />
                            {line.budgetLineID > 0 &&
                                <button className="button remove-button" tabIndex="-1" onClick={e => this.confirmDeleteLine(line.type, index)}>
                                    <span className="fa fa-times"></span>
                                </button>
                            }
                        </>}
                        
                        {(!isEditMode || line.isLocked) &&
                            <>{line.name}</>
                        }
                    </div>
                </td>
                <td className="budget-table-budget-column">
                    {isEditMode && 
                        <NumberFormat
                            thousandSeparator={currency.thousandSeparator}
                            decimalSeparator={currency.decimalSeparator}
                            prefix={currency.symbol}
                            className="has-prefix"
                            placeholder="Budgeted amount"
                            value={line.budgetedAmount || ''}
                            onValueChange={(values) => this.updateBudgetLine(line.type, index, 'budgetedAmount', values.value)}
                            onBlur={e => this.saveBudgetLine(line.type, index)}
                        />
                    }
                    {!isEditMode &&
                        <Money amount={line.budgetedAmount} blankIfZero={true} />
                    }
                </td>
                <td className="budget-table-actual-column">
                    {isEditMode && !line.isLocked &&
                        <NumberFormat
                            thousandSeparator={currency.thousandSeparator}
                            decimalSeparator={currency.decimalSeparator}
                            prefix={currency.symbol}
                            className="has-prefix"
                            placeholder="Actual amount"
                            value={line.actualAmount || ''}
                            onValueChange={(values) => this.updateBudgetLine(line.type, index, 'actualAmount', values.value)}
                            onBlur={e => this.saveBudgetLine(line.type, index)}
                        />
                    }
                    {(!isEditMode || line.isLocked) &&
                        <Money amount={line.actualAmount} blankIfZero={true} />
                    }
                </td>
                <td className="budget-table-delta-column">

                    {!!line.delta &&
                        <Money amount={line.delta} blankIfZero={true} plusIfPositive={true} />
                    }

                </td>
            </tr>
        );
    }

    renderMainContent() {
        const {
            isLoading,
            budget,
            incomeLines,
            expenseLines,
            isEditMode,
            isAddingLine
        } = this.state;

        if (isLoading) {
            return (<Loader />);
        }

        setTimeout(() => {
            this.highlightBudgetLineID = null;
        }, 0);

        const currency = SalonService.getCurrentCurrency();

        return (<>

            <table className="budget-table">
                <thead>
                    <tr>
                        <th>Item</th>
                        <th>Budget</th>
                        <th>Actual</th>
                        <th>+/-</th>
                    </tr>
                </thead>
                <tbody>

                    {/* Income */}

                    <tr className="budget-heading-row">
                        <td colSpan="4">
                            Income
                        </td>
                    </tr>
                    {incomeLines.map((line, index) =>
                        <React.Fragment key={line.budgetLineID}>
                            {this.renderBudgetLine(line, index)}
                        </React.Fragment>
                    )}
                    {isEditMode &&
                        <tr className="budget-table-add-row">
                            <td colSpan="4">

                                <button className="button button-primary button-small" onClick={e => this.addLine('income')}>
                                    {!isAddingLine['income'] && <>
                                        <span className="fa fa-plus"></span>{' '}
                                            Add income line
                                        </>
                                    }
                                    {isAddingLine['income'] && <>
                                        <span className="spinner-border"></span>{' '}
                                            Please wait...
                                        </>
                                    }
                                </button>

                            </td>
                        </tr>
                    }
                    <tr className="budget-table-total-row">
                        <td>Total Income</td>
                        <td>
                            <Money amount={budget.totalBudgetedIncome} blankIfZero={true} />
                        </td>
                        <td>
                            <Money amount={budget.totalActualIncome} blankIfZero={true} />
                        </td>
                        <td></td>
                    </tr>

                    {/* Expenses */}
                    
                    <tr className="budget-heading-row">
                        <td colSpan="4">
                            Expenses
                        </td>
                    </tr>

                    <tr className="budget-table-stock-wage-row">
                        <td>
                            <div>
                                <span>Wages</span>
                                {isEditMode &&
                                    <input
                                        type="number"
                                        value={budget.budgetedWagePercentage ? budget.budgetedWagePercentage * 100 : ''}
                                        onChange={e => this.updateField('budgetedWagePercentage', e.target.value ? (Number(e.target.value) / 100) : '')}
                                        onBlur={e => this.saveBudget()}
                                    />
                                }
                                {!isEditMode && (budget.budgetedWagePercentage * 100)}
                                %
                            </div>
                        </td>
                        <td>
                            <Money amount={budget.budgetedWageAmount} blankIfZero={true} /> 
                        </td>
                        <td>

                            {isEditMode &&
                                <NumberFormat
                                    thousandSeparator={currency.thousandSeparator}
                                    decimalSeparator={currency.decimalSeparator}
                                    prefix={currency.symbol}
                                    className="has-prefix"
                                    placeholder="Actual amount"
                                    value={budget.actualWageAmount || ''}
                                    onValueChange={(values) => this.updateField('actualWageAmount', values.value)}
                                    onBlur={e => this.saveBudget()}
                                />
                            }
                            {!isEditMode && <>
                                <Money amount={budget.actualWageAmount} blankIfZero={true} />
                                {budget.actualWagePercentage !== null &&
                                    <div className="budget-actual-percentage">
                                        {TextHelpers.formatPercentage(budget.actualWagePercentage, 0)}%
                                    </div>
                                }
                            </>}

                        </td>
                        <td>

                            <Money amount={budget.wageDelta} blankIfZero={true} plusIfPositive={true} />

                        </td>
                    </tr>

                    <tr className="budget-table-stock-wage-row">
                        <td>
                            <div>
                                <span>Stock</span>
                                {isEditMode &&
                                    <input
                                        type="number"
                                        value={budget.budgetedStockPercentage ? budget.budgetedStockPercentage * 100 : ''}
                                        onChange={e => this.updateField('budgetedStockPercentage', e.target.value ? (Number(e.target.value) / 100) : '')}
                                        onBlur={e => this.saveBudget()}
                                    />
                                }
                                {!isEditMode && (budget.budgetedStockPercentage * 100)}
                                %
                            </div>
                        </td>
                        <td>
                            <Money amount={budget.budgetedStockAmount} blankIfZero={true} />
                        </td>
                        <td>

                            {isEditMode &&
                                <NumberFormat
                                    thousandSeparator={currency.thousandSeparator}
                                    decimalSeparator={currency.decimalSeparator}
                                    prefix={currency.symbol}
                                    className="has-prefix"
                                    placeholder="Actual amount"
                                    value={budget.actualStockAmount || ''}
                                    onValueChange={(values) => this.updateField('actualStockAmount', values.value)}
                                    onBlur={e => this.saveBudget()}
                                />
                            }
                            {!isEditMode && <>
                                <Money amount={budget.actualStockAmount} blankIfZero={true} />
                                {budget.actualStockPercentage !== null &&
                                    <div className="budget-actual-percentage">
                                        {TextHelpers.formatPercentage(budget.actualStockPercentage, 0)}%
                                    </div>
                                }
                            </>}

                        </td>
                        <td>

                            <Money amount={budget.stockDelta} blankIfZero={true} plusIfPositive={true} />

                        </td>
                    </tr>

                    <tr className="budget-table-total-row">
                        <td>Total Variable Expenses</td>
                        <td>
                            <Money amount={budget.totalBudgetedVariableExpenses} blankIfZero={true} />
                        </td>
                        <td>
                            <Money amount={budget.totalActualVariableExpenses} blankIfZero={true} />
                        </td>
                        <td></td>
                    </tr>

                    {expenseLines.map((line, index) =>
                        <React.Fragment key={line.budgetLineID}>
                            {this.renderBudgetLine(line, index)}
                        </React.Fragment>
                    )}
                    {isEditMode &&
                        <tr className="budget-table-add-row">
                            <td colSpan="4">

                                <button className="button button-primary button-small" onClick={e => this.addLine('expense')}>
                                    {!isAddingLine['expense'] && <>
                                        <span className="fa fa-plus"></span>{' '}
                                        Add expense line
                                    </>}
                                    {isAddingLine['expense'] && <>
                                        <span className="spinner-border spinner-border-sm"></span>{' '}
                                        Please wait...
                                    </>}
                                </button>

                            </td>
                        </tr>
                    }
                    <tr className="budget-table-total-row">
                        <td>Total Fixed Expenses</td>
                        <td>
                            <Money amount={budget.totalBudgetedFixedExpenses} blankIfZero={true} />
                        </td>
                        <td>
                            <Money amount={budget.totalActualFixedExpenses} blankIfZero={true} />
                        </td>
                        <td></td>
                    </tr>

                    <tr className="budget-heading-row">
                        <td colSpan="4">
                            Summary
                        </td>
                    </tr>
                    <tr className="budget-table-summary-row">
                        <td colSpan="3">Total</td>
                        <td>
                            <Money amount={budget.totalDelta} blankIfZero={true} plusIfPositive={true} />
                        </td>
                    </tr>
                    <tr className="budget-table-summary-row">
                        <td colSpan="3">Brought Forward</td>
                        <td>
                            <Money amount={budget.broughtForward} blankIfZero={false} plusIfPositive={true} />
                        </td>
                    </tr>
                    <tr className="budget-table-summary-row">
                        <td colSpan="3">Carry Forward</td>
                        <td>
                            <Money amount={budget.carryForward} blankIfZero={false} plusIfPositive={true} />
                        </td>
                    </tr>
                </tbody>
            </table>

        </>);
    }

    renderInfoBar() {
        const {
            isLoading,
            isEditMode,
            month
        } = this.state;

        return (
            <InfoBar className="budget-info-bar" sticky={true}>

                <div className="info-bar-panel-section">
                    <div className="button-group" role="group">
                        <button type="button" className="button" onClick={() => this.move(-1)}>
                            <span className="fa fa-chevron-left"></span>
                        </button>
                        <button type="button" className="button" onClick={() => this.move(1)}>
                            <span className="fa fa-chevron-right"></span>
                        </button>
                    </div>
                </div>

                <div className="info-bar-panel-section info-bar-panel-section-text">
                    Budget - {moment(month).format('MMMM YYYY')}
                </div>

                <div className="info-bar-panel-section ml-auto">

                    <button className="button edit-mode-button" onClick={() => this.toggleEditMode()}>
                        {!isEditMode && <>
                            <span className="fa fa-pencil-alt"></span>{' '}
                            Edit mode
                        </>}
                        {isEditMode && <>
                            <span className="far fa-eye"></span>{' '}
                            View mode
                        </>}
                    </button>

                </div>
            </InfoBar>
        );
    }

    render() {
        return (<>
            <div className="page-content">
                <div className="page-content-left">
                </div>
                <div className="page-content-right">
                    <div className="page-content-right-inner">
                        {this.renderInfoBar()}
                    </div>
                </div>
            </div>

            <div className="budget-page">

                <div className="budget-panel">

                    <div className="panel-body">

                        {this.renderMainContent()}

                    </div>

                </div>

            </div>
        </>);
    }

};

export default withRouter(BudgetPage);