// Libs
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

// Services
import DashboardService     from 'services/DashboardService';

// Components
import FloomlyComponent         from 'components/FloomlyComponent';
import Loader               from 'components/reusable/Loader';
import Money                from 'components/reusable/Money';

//-------------------------------------------------------------------------------------------------------------------

class DashboardClientCount extends FloomlyComponent {

    //--------------------------------------------------------------------------------------------------------------------
    //  Logic
    //--------------------------------------------------------------------------------------------------------------------

    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            dashboard: null
        }
    }

    componentDidMount() {
        this.load();
    }

    async load() {
        const dashboard = await DashboardService.getClientCount();
        this.setState({
            isLoading: false,
            dashboard: dashboard
        });
    }

    //--------------------------------------------------------------------------------------------------------------------
    //  Render
    //--------------------------------------------------------------------------------------------------------------------

    renderRow1() {
        const { dashboard } = this.state;

        return (
            <div className="dashboard-row dashboard-row-1">

                <div className="dashboard-block dashboard-col-1">
                    <span className="dashboard-title">
                        Regular Clients
                    </span>
                    <div className="panel">
                        <div className="panel-body">

                            <input
                                type="number"
                                step="1"
                                min="0"
                                defaultValue="1"
                            />
                            {/*
                                onChange={(e) => this.inputStylistClientCountHandler(e, index, 'regularClientCount')}

                                 * x.regularClientCount*/}

                        </div>
                    </div>
                </div>

                <div className="dashboard-block dashboard-col-2">
                    <span className="dashboard-title">
                        New Clients
                    </span>
                    <div className="panel">
                        <div className="panel-body">

                            <input type="number" />

                        </div>
                    </div>
                </div>

            </div>
        );
    }

    renderRow2() {
        const { dashboard } = this.state;   

        return (
            <div className="dashboard-row dashboard-row-1">

                <div className="dashboard-block dashboard-col-1">
                    <span className="dashboard-title">
                        Regular Clients
                    </span>
                    <div className="panel">
                        <div className="panel-body">
                            <span className="dashboard-figure">
                                0
                            </span>
                        </div>
                    </div>
                </div>

                <div className="dashboard-block dashboard-col-2">
                    <span className="dashboard-title">
                        New Clients
                    </span>
                    <div className="panel">
                        <div className="panel-body">
                            <span className="dashboard-figure">
                                0
                            </span>
                        </div>
                    </div>
                </div>

            </div>
        );
    }

    render() {
        const { isLoading, dashboard } = this.state;

        if (isLoading) {
            return (<Loader />);
        }

        return (
            <div className="dashboard-content-level-two">

                <div className="dashboard-heading">
                    Weekly Target
                </div>

                {this.renderRow1()}

                <div className="dashboard-heading">
                    Week To Date
                </div>

                {this.renderRow2()}

            </div>
        );
    }

}

export default withRouter(DashboardClientCount);