// Libs
import React from 'react';
import { Route } from 'react-router';
import { Link, withRouter } from 'react-router-dom';

// Services & Helpers
import InternalApptTypeService from 'services/InternalApptTypeService';
import DiaryService from 'services/DiaryService';
import FormHelper from 'helpers/FormHelper';
import BootboxHelper from 'helpers/BootboxHelper';

// Components
import FloomlyComponent from 'components/FloomlyComponent';
import InfoBar from 'components/layout/InfoBar'
import Loader from 'components/reusable/Loader';
import SuperTable from 'components/reusable/SuperTable';

//-------------------------------------------------------------------------------------------------------------------

class SettingsInternalApptTypeEdit extends FloomlyComponent {

    constructor(props) {
        super(props);

        // Init state
        this.state = {
            isLoading: true,
            internalApptType: null
        };

        this.updateField = this.updateField.bind(this);
        
        this.formHelper = new FormHelper({
            fields: {
                name: { label: 'Name', autoFocus: true },
                defaultDuration: { label: 'Default duration', type: 'number' },
                workingType: {
                    label: 'Working type',
                    type: 'single-select',
                    getOptions: () => [
                        'working',
                        'notWorking'
                    ].map(wt => ({ id: wt, name: DiaryService.getWorkingTypeFriendly(wt) }))
                },
                isProductive: { checkboxLabel: 'Counts towards Team Utilisation %', type: 'checkbox' },
                subtractFromHolidayTime: { checkboxLabel: 'Subtract from holiday time if it overlaps', type: 'checkbox' }
            },
            getValue: (fieldName, fieldInfo) => this.state.internalApptType[fieldName],
            setValue: this.updateField
        });
    }

    componentDidMount() {
        this.load();
    }

    //--------------------------------------------------------------------------------------------------------------------

    async load() {
        const internalApptTypeID = Number(this.props.match.params.internalApptTypeID) || 0;

        let internalApptType;
        if (internalApptTypeID) {
            const getInternalApptTypeResponse = await InternalApptTypeService.get(internalApptTypeID);
            internalApptType = getInternalApptTypeResponse;
        } else {
            // New
            internalApptType = {
                defaultDuration: 30,
                defaultIsAllDay: false,
                workingType: 'working'
            };
        }

        this.setState({
            isLoading: false,
            internalApptType: internalApptType
        });
    }

    goBack() {
        this.props.history.push('/settings/internal-appt-types');
    }

    async save() {
        this.setState({ isLoading: true });
        const internalApptType = { ...this.state.internalApptType };
        await InternalApptTypeService.save(internalApptType);
        this.goBack();
    }

    updateField(fieldName, value, fieldInfo) {
        const internalApptType = { ...this.state.internalApptType };
        internalApptType[fieldName] = value;
        this.setState({
            internalApptType: internalApptType
        });
    }

    async confirmDelete() {
        const confirm = await BootboxHelper.confirm('Are you sure you want to delete this internal appointment?');
        if (confirm) {
            this.delete();
        }
    }

    async delete() {
        await InternalApptTypeService.delete(this.state.internalApptType.internalApptTypeID);
        this.props.history.replace('/settings/internal-appt-types');
    }

    //--------------------------------------------------------------------------------------------------------------------
    //  Render
    //--------------------------------------------------------------------------------------------------------------------

    renderInfoBar() {
        const {
            internalApptType
        } = this.state;

        return (
            <InfoBar className="settings-info-bar" containerClassName="floating-on-mobile" sticky={true}>

                <div className="info-bar-panel-section">

                    <button className="button" onClick={() => this.goBack()}>
                        <span className="fa fa-chevron-left"></span>{' '}
                        Back
                    </button>

                </div>

                <div className="info-bar-panel-section info-bar-panel-section-text desktop-only">
                    {internalApptType.internalApptTypeID ? internalApptType.name : 'New Internal Appointment Type'}
                </div>
                
                <div className="info-bar-panel-section ml-auto">

                    {internalApptType.internalApptTypeID &&
                        <button className="button" style={{ marginRight: 10 }} onClick={() => this.confirmDelete()}>
                            <span className="fa fa-times"></span>{' '}
                            Delete
                        </button>
                    }
                    <button className="button" onClick={() => this.save()}>
                        <span className="fa fa-check"></span>{' '}
                        Save
                    </button>
                    
                </div>

            </InfoBar>
        );
    }

    render() {
        const {
            isLoading,
            internalApptType
        } = this.state;

        if (isLoading) {
            return (<Loader />);
        }

        return (<>
            {this.renderInfoBar()}

            <div className="settings-page-main">
                <div className="panel">
                    <div className="panel-body">

                        {this.formHelper.renderFormGroups([
                            'name',
                            'defaultDuration',
                            'workingType',
                            'isProductive',
                            'subtractFromHolidayTime'
                        ])}

                    </div>
                </div>

            </div>

        </>);
    }

};

export default withRouter(SettingsInternalApptTypeEdit);