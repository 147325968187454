// Libs
import React, { Component } from 'react';
import moment from 'moment';
import Modal from 'react-bootstrap/Modal';

// Services & Helpers
import DiaryService         from 'services/DiaryService';
import BootboxHelper        from 'helpers/BootboxHelper';
import GlobalStateService   from 'services/GlobalStateService';

// Components
import Loader               from 'components/reusable/Loader';
import CancelApptModal from 'components/pages/diary/CancelApptModal';

//--------------------------------------------------------------------------------------

export default class OnlineBookingsModal extends Component {
    
    constructor(props) {
        super(props);

        this.cancelApptModalRef = React.createRef();

        this.state = {
            isLoading: true,
            isBusyAppts: {}
        };
    }

    show(opts) {
        return new Promise((resolve, reject) => {
            this.resolve = resolve;
            this.anyConfirmed = false;
            this.setState({
                isLoading: true,
                isOpen: true,
                isBusyAppts: {}
            }, () => {
                this.load();
            });
        });
    }

    hide() {
        if (this.anyConfirmed) {
            this.resolve();
        }
        this.setState({
            isOpen: false
        });
    }

    async load() {
        const appointments = await DiaryService.listOnlineBookings();

        // Update UI
        this.setState({
            isLoading: false,
            appointments: appointments
        });
    }

    async confirm(appointment) {
        this.anyConfirmed = true;
        this.doAction(appointment, 'confirm');
    }

    async cancel(appointmentID) {
        await this.cancelApptModalRef.current.show({
            type: 'cancel',
            appointmentID
        });
        this.load();
    }

    async doAction(appointment, action) {
        // Set busy
        let isBusyAppts = { ...this.state.isBusyAppts };
        isBusyAppts[appointment.appointmentID] = true;
        this.setState({ isBusyAppts });

        // Do it
        switch (action) {
            case 'confirm':
                await DiaryService.updateStatus(appointment.appointmentID, 'unconfirmed');
                break;
            case 'cancel':
                await DiaryService.cancelAppointment(appointment.appointmentID);
                break;
        }

        // Unset busy
        isBusyAppts = { ...this.state.isBusyAppts };
        delete isBusyAppts[appointment.appointmentID];
        this.setState({ isBusyAppts });

        // Load
        this.load();
    }

    edit(appointment) {
        this.hide();
        window.appHistory.push(`/diary/${moment(appointment.date).format('YYYY-MM-DD')}/${appointment.appointmentID}`);
    }

    //---------------------------------------------------------------------

    render() {
        const {
            isOpen
        } = this.state;

        if (!isOpen) {
            return null;
        }

        return (<>
            <Modal
                show
                className="online-bookings-modal"
                onHide={e => this.hide()}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Online bookings</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    {this.renderInner()}

                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="button button-primary ml-auto" onClick={e => this.hide()}>
                        Close
                    </button>
                </Modal.Footer>
            </Modal>

            <CancelApptModal ref={this.cancelApptModalRef} />

        </>);
    }

    renderInner() {
        const {
            isOpen,
            isLoading,
            appointments,
            isBusyAppts
        } = this.state;

        if (isLoading) {
            return (<Loader />);
        }

        if (appointments.length == 0) {
            return (
                <div className="empty-text">

                    There are no online bookings waiting to be confirmed

                </div>
            );
        }
        const loginDetails = GlobalStateService.getValue('loginDetails');

        return (<>

            <table className="table super-table">
                <thead>
                    <tr>
                        <th>Booked</th>
                        <th>Customer</th>
                        <th>Date</th>
                        <th>Service(s)</th>
                    </tr>
                </thead>
                <tbody>
                    {appointments.map(a =>
                        <React.Fragment key={a.appointmentID}>
                            <tr className="no-bottom-border">
                                <td>{moment(a.dateAdded).format('DD/MM/YYYY HH:mm')}</td>
                                <td>{a.customer.firstName || ''} {a.customer.lastName || ''}</td>
                                <td>{moment(a.date).format('ddd, Do MMM')}</td>
                                <td>
                                    {a.appointmentServices.map(asv =>
                                        <div key={asv.appointmentServiceID}>
                                            <b>{moment(asv.time).format('HH:mm')}</b>: {asv.service.name} with {asv.stylistName}
                                        </div>
                                    )}
                                </td>
                            </tr>
                            <tr className="button-row">
                                <td colSpan={4}>

                                    {isBusyAppts[a.appointmentID] ?
                                        <Loader />
                                    : <>
                                        <button className="button button-primary button-small" onClick={e => this.confirm(a)} disabled={!loginDetails.permissions['DiaryConfirmOnlineBookings']}>
                                            <span className="fa fa-thumbs-up"></span>{' '}
                                            Confirm
                                        </button>

                                        <button className="button button-secondary button-small" onClick={e => this.edit(a)} disabled={!loginDetails.permissions['DiaryEditAppointments']}>
                                            <span className="fa fa-pencil-alt"></span>{' '}
                                            View / Edit
                                        </button>

                                        <button className="button button-secondary button-small" onClick={e => this.cancel(a.appointmentID)} disabled={!loginDetails.permissions['DiaryCancelAppointments']}>
                                            <span className="fa fa-times"></span>{' '}
                                            Cancel
                                        </button>
                                    </>}

                                </td>
                            </tr>
                        </React.Fragment>
                    )}
                </tbody>
            </table>

        </>);
    }
}
