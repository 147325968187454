import * as signalR from '@microsoft/signalr';
import GlobalStateService from 'services/GlobalStateService';

//-------------------------------------------------------------------------------------------------------------------

export default class WebSocketService {

    static subscriptions = {};

    static async init() {
        try {
            if (!WebSocketService.wsConnection) {
                const salonCode = GlobalStateService.getValue('salonCode');
                WebSocketService.wsConnection = new signalR.HubConnectionBuilder()
                    .withUrl('/ws-floomly?salon-code=' + salonCode)
                    .withAutomaticReconnect()
                    .build();
            }
            if (WebSocketService.wsConnection.state == 'Disconnected') {
                await WebSocketService.wsConnection.start();
            }
        } catch (e) {
            console.log('WS error', e);
        }
    }

    static async joinPage(page) {
        await WebSocketService.init();
        await WebSocketService.wsConnection.invoke('JoinPage', page);
    }

    static async leavePage(page) {
        if (WebSocketService.wsConnection) {
            await WebSocketService.wsConnection.invoke('LeavePage', page);
        }
    }

    static async subscribe(name, fn) {
        await WebSocketService.init();
        WebSocketService.unsubscribe(name);
        WebSocketService.subscriptions[name] = fn;
        WebSocketService.wsConnection.on(name, fn);
    }

    static unsubscribe(name) {
        if (WebSocketService.subscriptions[name]) {
            delete WebSocketService.subscriptions[name];
            if (WebSocketService.wsConnection) {
                WebSocketService.wsConnection.off(name, WebSocketService.subscriptions[name]);
            }
        }
    }

    static async stop() {
        if (WebSocketService.wsConnection) {
            await WebSocketService.wsConnection.stop();
        }
    }
    
}

