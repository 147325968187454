import BaseService from 'services/BaseService';

//-------------------------------------------------------------------------------------------------------------------

export default class StylistService
    extends BaseService {

    static async getTargets() {
        const response = await BaseService.callAPI('stylist/get-targets');
        return response;
    }

    static async saveTargets(stylistTargets) {
        const response = await BaseService.callAPI('stylist/save-targets', stylistTargets);
        return response;
    }

    static async saveStylistTarget(stylistTarget) {
        const response = await BaseService.callAPI('stylist/save-stylist-target', stylistTarget);
        return response;
    }
    
}