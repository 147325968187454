// Libs
import React from 'react';
import { Route, Switch } from 'react-router';
import { Link, withRouter } from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/en-gb';

// Components
import FloomlyComponent              from 'components/FloomlyComponent';
import SettingsSalon                 from 'components/pages/settings/SettingsSalon';
import SettingsOnlineBooking         from 'components/pages/settings/SettingsOnlineBooking';
import SettingsDiary                 from 'components/pages/settings/SettingsDiary';
import SettingsUserList              from 'components/pages/settings/SettingsUserList';
import SettingsUserEdit              from 'components/pages/settings/SettingsUserEdit';
import SettingsHolidayManager        from 'components/pages/settings/SettingsHolidayManager';
import SettingsRoleList              from 'components/pages/settings/SettingsRoleList';
import SettingsRoleEdit              from 'components/pages/settings/SettingsRoleEdit';
import SettingsInternalApptTypeList  from 'components/pages/settings/SettingsInternalApptTypeList';
import SettingsInternalApptTypeEdit  from 'components/pages/settings/SettingsInternalApptTypeEdit';
import SettingsServiceList           from 'components/pages/settings/SettingsServiceList';
import SettingsServiceEdit           from 'components/pages/settings/SettingsServiceEdit';
import SettingsPackageList           from 'components/pages/settings/SettingsPackageList';
import SettingsPackageEdit           from 'components/pages/settings/SettingsPackageEdit';
import SettingsServiceCategoryEdit   from 'components/pages/settings/SettingsServiceCategoryEdit';
import SettingsServiceCategoryList   from 'components/pages/settings/SettingsServiceCategoryList';
import SettingsServiceBandList       from 'components/pages/settings/SettingsServiceBandList';
import SettingsServiceBandEdit       from 'components/pages/settings/SettingsServiceBandEdit';
import SettingsCapabilityList        from 'components/pages/settings/SettingsCapabilityList';
import SettingsCapabilityEdit        from 'components/pages/settings/SettingsCapabilityEdit';
import SettingsEmailAccountList      from 'components/pages/settings/SettingsEmailAccountList';
import SettingsEmailAccountEdit      from 'components/pages/settings/SettingsEmailAccountEdit';
import SettingsSMSAccountList        from 'components/pages/settings/SettingsSMSAccountList';
import SettingsSMSAccountEdit        from 'components/pages/settings/SettingsSMSAccountEdit';
import SettingsEmailSettings         from 'components/pages/settings/SettingsEmailSettings';
import SettingsTaxRateList           from 'components/pages/settings/SettingsTaxRateList';
import SettingsTaxRateEdit           from 'components/pages/settings/SettingsTaxRateEdit';
import SettingsThermalPrinterList    from 'components/pages/settings/SettingsThermalPrinterList';
import SettingsThermalPrinterEdit    from 'components/pages/settings/SettingsThermalPrinterEdit';
import SettingsFormList              from 'components/pages/settings/SettingsFormList';
import SettingsFormEdit              from 'components/pages/settings/SettingsFormEdit';
import SettingsECommerceConnectionList from 'components/pages/settings/SettingsECommerceConnectionList';
import SettingsECommerceConnectionEdit from 'components/pages/settings/SettingsECommerceConnectionEdit';
import SettingsCommissionList from 'components/pages/settings/SettingsCommissionList';
import SettingsCommissionEdit from 'components/pages/settings/SettingsCommissionEdit';
import SettingsPromoCodeList         from 'components/pages/settings/SettingsPromoCodeList';
import SettingsPromoCodeEdit         from 'components/pages/settings/SettingsPromoCodeEdit';

//-------------------------------------------------------------------------------------------------------------------

class SettingsPage extends FloomlyComponent {

    constructor(props) {
        super(props);

        // Init state
        this.state = {
        };
    }

    componentDidMount() {
    }

    //--------------------------------------------------------------------------------------------------------------------
    //  
    //--------------------------------------------------------------------------------------------------------------------

    //--------------------------------------------------------------------------------------------------------------------
    //  Render
    //--------------------------------------------------------------------------------------------------------------------

    renderMainContent() {
        return (
            
            <Switch>
                <Route path="/settings/salon" component={SettingsSalon} />
                <Route path="/settings/online-booking" component={SettingsOnlineBooking} />
                <Route path="/settings/diary" component={SettingsDiary} />
                <Route path="/settings/users/:userID" component={SettingsUserEdit} />
                <Route path="/settings/holidays" component={SettingsHolidayManager} />
                <Route path="/settings/users" component={SettingsUserList} />
                <Route path="/settings/roles/:roleID" component={SettingsRoleEdit} />
                <Route path="/settings/roles" component={SettingsRoleList} />
                <Route path="/settings/service-bands/:serviceBandID" component={SettingsServiceBandEdit} />
                <Route path="/settings/service-bands" component={SettingsServiceBandList} />
                <Route path="/settings/capabilities/:capabilityID" component={SettingsCapabilityEdit} />
                <Route path="/settings/capabilities" component={SettingsCapabilityList} />
                <Route path="/settings/services/:serviceID" component={SettingsServiceEdit} />
                <Route path="/settings/services" component={SettingsServiceList} />
                <Route path="/settings/promo-codes/:promoCodeID" component={SettingsPromoCodeEdit} />
                <Route path="/settings/promo-codes" component={SettingsPromoCodeList} />
                <Route path="/settings/packages/:packageID" component={SettingsPackageEdit} />
                <Route path="/settings/packages" component={SettingsPackageList} />
                <Route path="/settings/service-categories/:serviceCategoryID" component={SettingsServiceCategoryEdit} />
                <Route path="/settings/service-categories" component={SettingsServiceCategoryList} />
                <Route path="/settings/internal-appt-types/:internalApptTypeID" component={SettingsInternalApptTypeEdit} />
                <Route path="/settings/internal-appt-types" component={SettingsInternalApptTypeList} />
                <Route path="/settings/email-accounts/:emailAccountID" component={SettingsEmailAccountEdit} />
                <Route path="/settings/email-accounts" component={SettingsEmailAccountList} />
                <Route path="/settings/sms-accounts/:smsAccountID" component={SettingsSMSAccountEdit} />
                <Route path="/settings/sms-accounts" component={SettingsSMSAccountList} />
                <Route path="/settings/email-settings" component={SettingsEmailSettings} />
                <Route path="/settings/tax-rates/:taxRateID" component={SettingsTaxRateEdit} />
                <Route path="/settings/tax-rates" component={SettingsTaxRateList} />
                <Route path="/settings/thermal-printers/:thermalPrinterID" component={SettingsThermalPrinterEdit} />
                <Route path="/settings/thermal-printers" component={SettingsThermalPrinterList} />
                <Route path="/settings/forms/:formTypeID" component={SettingsFormEdit} />
                <Route path="/settings/forms" component={SettingsFormList} />
                <Route path="/settings/ecommerce-connections" component={SettingsECommerceConnectionList} />
                <Route path="/settings/ecommerce-connection/:id" component={SettingsECommerceConnectionEdit} />
                <Route path="/settings/commissions/:commissionLevelID" component={SettingsCommissionEdit} />
                <Route path="/settings/commissions" component={SettingsCommissionList} />
                <Route path="/settings/promo-codes" component={SettingsPromoCodeList} />
                <Route path="/settings/promo-code/:promoCodeID" component={SettingsPromoCodeEdit} />
            </Switch>
        );
    }

    renderSideContent() {
        const url = this.props.history.location;
        const currentArea = url.pathname.length > 0 ? url.pathname.split('/')[2] : '';
        const getButtonClass = (area) => (currentArea == area ? 'button-selectable button-selected' : 'button-selectable');
        
        return (<>

            {currentArea &&
                <Link className="button button-secondary button-small mobile-only mb-1" to="/settings">
                    <span className="fa fa-chevron-left" />{' '}
                    All settings
                </Link>
            }

            <div className={'settings-nav sticky-top ' + (currentArea ? 'desktop-only' : '')}>

                <div className="panel">

                    <div className="panel-body">

                        <Link to="/settings/salon" className={'button ' + getButtonClass('salon')}>
                            <span className="fas fa-store-alt"></span>
                            Salon
                        </Link>

                        <Link to="/settings/tax-rates" className={'button ' + getButtonClass('tax-rates')}>
                            <span className="fas fa-percentage"></span>
                            Tax Rates
                        </Link>

                        <Link to="/settings/users" className={'button ' + getButtonClass('users')}>
                            <span className="fas fa-users"></span>
                            Team
                        </Link>
                        
                        <Link to="/settings/roles" className={'button ' + getButtonClass('roles')}>
                            <span className="fas fa-users-cog"></span>
                            Roles
                        </Link>

                        <Link to="/settings/service-bands" className={'button ' + getButtonClass('service-bands')}>
                            <span className="fas fa-tags"></span>
                            Stylist Levels
                        </Link>

                        <Link to="/settings/commissions" className={'button ' + getButtonClass('commissionss')}>
                            <span className="fas fa-percent"></span>
                            Commissions
                        </Link>

                        <Link to="/settings/service-categories" className={'button ' + getButtonClass('service-categories')}>
                            <span className="fas fa-cut"></span>
                            Service Categories
                        </Link>

                        <Link to="/settings/services" className={'button ' + getButtonClass('services')}>
                            <span className="fas fa-cut"></span>
                            Services
                        </Link>

                        <Link to="/settings/packages" className={'button ' + getButtonClass('packages')}>
                            <span className="fas fa-cubes"></span>
                            Packages
                        </Link>
                        
                        <Link to="/settings/internal-appt-types" className={'button ' + getButtonClass('internal-appt-types')}>
                            <span className="fas fa-tasks"></span>
                            Internal Appointments
                        </Link>

                        <Link to="/settings/capabilities" className={'button ' + getButtonClass('capabilities')}>
                            <span className="fas fa-user-check"></span>
                            Capabilities
                        </Link>

                        <Link to="/settings/diary" className={'button ' + getButtonClass('diary')}>
                            <span className="fas fa-calendar-alt"></span>
                            Diary
                        </Link>

                        <Link to="/settings/promo-codes" className={'button ' + getButtonClass('promo-code')}>
                            <span className="fas fa-gift"></span>
                            Promo Codes
                        </Link>

                        <Link to="/settings/email-accounts" className={'button ' + getButtonClass('email-accounts')}>
                            <span className="fas fa-envelope"></span>
                            Email Accounts
                        </Link>

                        <Link to="/settings/email-settings" className={'button ' + getButtonClass('email-settings')}>
                            <span className="fas fa-envelope"></span>
                            Email Look & Feel
                        </Link>

                        <Link to="/settings/online-booking" className={'button ' + getButtonClass('online-booking')}>
                            <span className="fas fa-mouse-pointer"></span>
                            Online Booking
                        </Link>

                        <Link to="/settings/thermal-printers" className={'button ' + getButtonClass('thermal-printers')}>
                            <span className="fas fa-print"></span>
                            Thermal Printers
                        </Link>

                        <Link to="/settings/ecommerce-connections" className={'button ' + getButtonClass('ecommerce')}>
                            <span className="fas fa-shop"></span>
                            E-Commerce
                        </Link>
                        
                        <Link to="/settings/forms" className={'button ' + getButtonClass('forms')}>
                            <span className="fas fa-clipboard"></span>
                            Forms
                        </Link>

                        {/*
                        <Link to="/settings/sms-accounts" className={'button ' + getButtonClass('sms-accounts')}>
                            <span className="fas fa-envelope"></span>
                            SMS Accounts
                        </Link>
                        */}

                    </div>
                </div>

            </div>

        </>);
    }

    render() {
        return (
            <div className="page-content">
                <div className="page-content-left">
                    {this.renderSideContent()} 
                </div>
                <div className="page-content-right settings-page">
                    {this.renderMainContent()}  
                </div>
            </div>
        );
    }

};

export default withRouter(SettingsPage);