// Libs
import React from 'react';
import { Link,withRouter } from 'react-router-dom';

//Services
import GlobalStateService from 'services/GlobalStateService';


// Components
import FloomlyComponent from 'components/FloomlyComponent';
import InfoBar from 'components/layout/InfoBar'
import TabPanel, { Tab } from 'components/reusable/TabPanel';
import CreateNewStockOrderTab from 'components/pages/stock/stock-ordering/CreateNewStockOrderTab';
import PreviousOrdersListTab from 'components/pages/stock/stock-ordering/PreviousOrdersListTab';
import ExistingOrderTab from 'components/pages/stock/stock-ordering/ExistingOrderTab';

class StockOrderingPage extends FloomlyComponent {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            isVisible:false
        };
    }

    componentDidMount() {
        this.load();
    }
   
    async load() {
        this.setState({
            isLoading: false
        });
    }

    renderInfoBar() {
        const loginDetails = GlobalStateService.getValue('loginDetails');

        return (
            <InfoBar className="stock-info-bar" containerClassName="desktop-only" sticky={true}>
                <div className="info-bar-panel-section info-bar-panel-section-text">
                    Stock Ordering
                </div>
                <div className="info-bar-panel-section ml-auto">
                    {loginDetails.permissions['StockroomViewStockManager'] &&
                        <Link className="button" to="/stock/manager">
                            <span className="fa fa-boxes"></span>{' '}
                            Stock Manager
                        </Link>
                    }
                </div>
            </InfoBar>
        );
    }

    renderMainContent() {
        let {
            tab
        } = this.props.match.params;
        return (<>

            <TabPanel tab={tab || 'create-new-stock-order'} onChangeTab={tab => {
                this.props.history.push(`/stock/stock-ordering/${tab}`) }}>

                <Tab id="create-new-stock-order" title="Create new stock order" tabClassName="tab-teal">
                    {(tab == 'create-new-stock-order' || (tab || '') == '') &&
                        <CreateNewStockOrderTab key="create-new-stock-order" id="create-new-stock-order" />
                    }
                </Tab>

                <Tab id="previous-orders-list" title="Previous orders list" tabClassName="tab-pink">
                    {tab == 'previous-orders-list' &&
                        <PreviousOrdersListTab key="previous-orders-list" id="previous-orders-list" />
                    }
                </Tab>
                <Tab id="existing-order" title="View existing order" tabClassName={'tab-green ' + (tab != 'existing-order' ? 'existing-order' : ' ')}>
                    {tab == 'existing-order' &&
                        <ExistingOrderTab key="existing-order" id="existing-order"/>
                    }
                </Tab>
            </TabPanel>
        </>
        )
    }

    render() {
        return (<>
            <div className="page-content">
                <div className="page-content-left">
                </div>
                <div className="page-content-right">
                    <div className="page-content-right-inner">
                        {this.renderInfoBar()}
                    </div>
                </div>
            </div>

            <div className="stock-marketing-page">
            {this.renderMainContent()}
            </div>
        </>);
    }
}

export default withRouter(StockOrderingPage);