// Libs
import React, { Component } from 'react';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import NumberFormat from 'react-number-format';

// Services & Helpers
import SalonService from 'services/SalonService';
import TextHelpers from 'helpers/TextHelpers';

//-------------------------------------------------------------------------------------------------------------------

class AdjustTargetSlide extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        const salonFocusResult = this.props.salonFocusResult;
        const currency = SalonService.getCurrentCurrency();
        
        return (
            <div className="ypt-slide ypt-slide-input-adjust-target">

                <div className="ypt-title">
                    Fantastic Salon Standard
                </div>

                <p>Now we start to get real with this - we know from experience that the next two pages will help you see how achievable that target really is.</p>
                 
                <p>Alan created the Fantastic Salon Standard when he was looking for a way to measure how ‘fantastic' a salon (or stylist etc.) is. Although it's a financial target that is linked to your profit target - it's so much more than that. I really recommend that you click the question mark to watch a short video from Alan as he explains this measurement and how to fill in this page.</p>

                <p>
                    
                    <span className="help-icon"></span>
                </p>

                <table className="targets-table">
                    <thead>
                        <tr>
                            <th></th>
                            <th><span>Avg. weekly <br /> client count <span className="help-icon"></span></span></th>
                            <th></th>
                            <th><span>Avg. client <br /> spend <span className="help-icon"></span></span></th>
                            <td></td>
                            <th><span>Avg. weekly <br /> sales <span className="help-icon"></span></span></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th>Current: <span className="help-icon"></span></th>
                            <td>

                                <div className="ypt-field-medium">

                                    <NumberFormat
                                        thousandSeparator={currency.thousandSeparator}
                                        decimalSeparator={currency.decimalSeparator}
                                        value={salonFocusResult.currentWeeklyAvgClientCount || ''}
                                        onValueChange={values => {
                                            this.isChanged = true;
                                            this.props.onChange('currentWeeklyAvgClientCount', Number(values.value))
                                        }}
                                        onBlur={e => {
                                            if (this.isChanged) {
                                                this.isChanged = false;
                                                this.props.onSave(e)
                                            }
                                        }}
                                    />

                                </div>

                            </td>
                            <td>@</td>
                            <td>
                                <div className="ypt-field-medium">

                                    <NumberFormat
                                        thousandSeparator={currency.thousandSeparator}
                                        decimalSeparator={currency.decimalSeparator}
                                        prefix={currency.symbol}
                                        value={salonFocusResult.currentWeeklyAvgBill || ''}
                                        onValueChange={values => {
                                            this.isChanged = true;
                                            this.props.onChange('currentWeeklyAvgBill', Number(values.value))
                                        }}
                                        onBlur={e => {
                                            if (this.isChanged) {
                                                this.isChanged = false;
                                                this.props.onSave(e)
                                            }
                                        }}
                                    />

                                </div>
                            </td>
                            <td>=</td>
                            <td>
                                <div className="ypt-field-medium ypt-field-disabled">

                                    <NumberFormat
                                        thousandSeparator={currency.thousandSeparator}
                                        decimalSeparator={currency.decimalSeparator}
                                        prefix={currency.symbol}
                                        value={salonFocusResult.currentWeeklyAvgSales || ''}
                                        disabled={true}
                                    />

                                </div>
                            </td>
                        </tr>
                        <tr>
                            <th>Target: <span className="help-icon"></span></th>
                            <td>

                                <div className="ypt-field-medium ypt-field-disabled">

                                    <NumberFormat
                                        thousandSeparator={currency.thousandSeparator}
                                        decimalSeparator={currency.decimalSeparator}
                                        disabled={true}
                                        value={salonFocusResult.targetWeeklyAvgClientCount}
                                    />

                                </div>

                            </td>
                            <td>@</td>
                            <td>
                                <div className="ypt-field-medium ypt-field-disabled">

                                    <NumberFormat
                                        thousandSeparator={currency.thousandSeparator}
                                        decimalSeparator={currency.decimalSeparator}
                                        prefix={currency.symbol}
                                        disabled={true}
                                        value={TextHelpers.roundCurrency(salonFocusResult.targetWeeklyAvgBillGross, 0)}
                                    />

                                </div>
                            </td>
                            <td>=</td>
                            <td>
                                <div className="ypt-field-medium ypt-field-disabled">

                                    <NumberFormat
                                        thousandSeparator={currency.thousandSeparator}
                                        decimalSeparator={currency.decimalSeparator}
                                        prefix={currency.symbol}
                                        disabled={true}
                                        value={TextHelpers.roundCurrency(salonFocusResult.targetAverageWeeklySalesGross, 0)}
                                    />

                                </div>
                            </td>
                        </tr>
                        <tr>
                            <th>Difference: <span className="help-icon"></span></th>
                            <td>

                                <div className="ypt-field-medium ypt-field-disabled">

                                    <NumberFormat
                                        thousandSeparator={currency.thousandSeparator}
                                        decimalSeparator={currency.decimalSeparator}
                                        disabled={true}
                                        value={salonFocusResult.targetWeeklyAvgClientCount - salonFocusResult.currentWeeklyAvgClientCount}
                                    />

                                </div>

                            </td>
                            <td>@</td>
                            <td>
                                <div className="ypt-field-medium ypt-field-disabled">

                                    <NumberFormat
                                        thousandSeparator={currency.thousandSeparator}
                                        decimalSeparator={currency.decimalSeparator}
                                        prefix={currency.symbol}
                                        disabled={true}
                                        value={TextHelpers.roundCurrency(salonFocusResult.targetWeeklyAvgBillGross - salonFocusResult.currentWeeklyAvgBill, 0) || 0}
                                    />

                                </div>
                            </td>
                            <td>=</td>
                            <td>
                                <div className="ypt-field-medium ypt-field-disabled">

                                    <NumberFormat
                                        thousandSeparator={currency.thousandSeparator}
                                        decimalSeparator={currency.decimalSeparator}
                                        prefix={currency.symbol}
                                        disabled={true}
                                        value={TextHelpers.roundCurrency(salonFocusResult.targetAverageWeeklySalesGross - salonFocusResult.currentWeeklyAvgSales, 0)}
                                    />

                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <div className="ypt-heart-slider-container">

                    <div className="ypt-field-title">
                        Adjust your key numbers:
                    </div>

                    <div className="flex-row">

                        <div>

                            <Slider
                                min={0}
                                max={1}
                                step={0.025}
                                value={1 - salonFocusResult.clientCountOrAvgBillBias}
                                onChange={value => {
                                    this.isChanged = true;
                                    this.props.onChange('clientCountOrAvgBillBias', 1 - value)
                                }}
                                onBlur={e => {
                                    if (this.isChanged) {
                                        this.isChanged = false;
                                        this.props.onSave(e)
                                    }
                                }}
                            />

                        </div>

                        <div>

                            <Slider
                                min={0}
                                max={1}
                                step={0.025}
                                value={salonFocusResult.clientCountOrAvgBillBias}
                                onChange={value => {
                                    this.isChanged = true;
                                    this.props.onChange('clientCountOrAvgBillBias', value)
                                }}
                                onBlur={e => {
                                    if (this.isChanged) {
                                        this.isChanged = false;
                                        this.props.onSave(e)
                                    }
                                }}
                            />

                        </div>

                    </div>

                    <div className="ypt-heart-slider-results">

                        <div>
                            Target Client Count
                            <span>{salonFocusResult.targetWeeklyAvgClientCount}</span>
                        </div>

                        <div>
                            @
                        </div>

                        <div>
                            Target Average Bill
                            <span>{TextHelpers.formatCurrencyNew(salonFocusResult.targetWeeklyAvgBillGross, { numDP: 0 })}</span>
                        </div>


                    </div>

                </div>

                <div className="ypt-controls-row">

                    <button className="button button-primary ypt-back-button" onClick={e => this.props.onBack()}>
                        Back
                    </button>

                    <button className="button button-secondary ypt-continue-button" onClick={e => this.props.onContinue()}>
                        Continue
                    </button>

                </div>
            </div>
        );
    }
}

export default AdjustTargetSlide;
