// Libs
import React, { Component } from 'react';

// Services & Helpers
import StockService from 'services/StockService';
import ProductService from 'services/ProductService';
import SearchService from 'services/SearchService';

// Components
import FloomlyComponent from 'components/FloomlyComponent';
import Search from 'components/reusable/Search';
import Loader from 'components/reusable/Loader';

//-------------------------------------------------------------------------------------------------------------------

class ClientRecordProducts extends FloomlyComponent {

    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            isAddingProduct: {},
            productCategories: []
        };

        this.appointmentProductSequence = -1;
    }

    componentDidMount() {
        this.load();
    }

    async load() {
        // Load info
        const productCategories = await StockService.listCategories(true);

        // Create a lookup to determine if the search box should appear for each product type
        const isAddingProduct = {};
        productCategories.forEach(pc => {
            isAddingProduct[pc.id] = false;
        });

        // Update UI
        await this.setStateAsync({
            isLoading: false,
            productCategories,
            isAddingProduct
        });
    }

    groupProductsByType() {
        const result = [];
        const productCategoriesHash = {};

        // Create a hash lookup for product categories
        this.state.productCategories.forEach(pc => {
            if (!productCategoriesHash[pc.id]) {
                productCategoriesHash[pc.id] = result.length;
                result.push({
                    ...pc,
                    appointmentProducts: []
                });
            }
        });

        // Put each appointmentProduct into the correct productCategory
        let productIndex = 0;
        this.props.clientRecord.appointmentProducts.forEach(ap => {
            const index = productCategoriesHash[ap.productCategoryID];
            if (index >= 0) {
                ap.index = productIndex;
                result[index].appointmentProducts.push(ap);
            }
            productIndex++;
        });

        return result;
    }

    toggleAddingProduct(productCategoryID, adding) {
        const isAddingProduct = { ...this.state.isAddingProduct };
        isAddingProduct[productCategoryID] = adding;
        this.setState({
            isAddingProduct
        });
    }

    async selectProduct(result) {
        const product = await ProductService.get(result.id);

        const clientRecord = { ...this.props.clientRecord };
        clientRecord.appointmentProducts.push({
            appointmentProductID: this.appointmentProductSequence--,
            productID: product.productID,
            productCategoryID: product.productCategoryID,

            name: product.name,
            stockUnit: product.stockUnit,
            amountUsed: ''
        });
        this.props.onUpdate(clientRecord);
        this.props.onApptUpdated(true);
    }

    updateAppointmentProductField(appointmentProductID, field, value) {
        const clientRecord = { ...this.props.clientRecord };
        const appointmentProducts = [...clientRecord.appointmentProducts];
        const index = appointmentProducts.findIndex(ap => ap.appointmentProductID == appointmentProductID);
        appointmentProducts[index][field] = value;
        clientRecord.appointmentProducts = appointmentProducts;       
        this.props.onUpdate(clientRecord);
        this.props.onApptUpdated(true);
    }

    confirmRemoveAppointmentProduct(appointmentProductID) {
        // TODO confirmation
        this.removeAppointmentProduct(appointmentProductID);
    }

    removeAppointmentProduct(appointmentProductID) {
        const clientRecord = { ...this.props.clientRecord };
        clientRecord.appointmentProducts = [...clientRecord.appointmentProducts];
        const index = clientRecord.appointmentProducts.findIndex(ap => ap.appointmentProductID == appointmentProductID);
        clientRecord.appointmentProducts.splice(index, 1);
        this.props.onUpdate(clientRecord);
        this.props.onApptUpdated(true);
    }

    //--------------------------------------------------------------------------------------------------------------------
    // Render
    //--------------------------------------------------------------------------------------------------------------------

    render() {
        const {
            productCategories,
            isAddingProduct,
            isLoading
        } = this.state;

        if (isLoading) {
            return (<Loader />);
        }

        const appointmentProductsGrouped = this.groupProductsByType();

        return (<>

            {appointmentProductsGrouped.map((productCategory) =>
                <React.Fragment key={productCategory.id}>
                    <div className="panel-info-header">
                        {productCategory.name}
                    </div>

                    <ul className="list service-list">

                        {productCategory.appointmentProducts.map((appointmentProduct) =>
                            <li key={appointmentProduct.appointmentProductID} className="non-selectable">

                                <span className="service-list-name">
                                    {appointmentProduct.name}
                                </span>

                                <span className="service-list-price">

                                    <input
                                        type="number"
                                        autoFocus={true}
                                        value={appointmentProduct.amountUsed}
                                        onChange={e => this.updateAppointmentProductField(appointmentProduct.appointmentProductID, 'amountUsed', Number(e.target.value))}
                                    />
                                    {' '}
                                    {appointmentProduct.stockUnit}

                                </span>

                                <span className="service-list-icon-right">

                                    <button className="button customer-summary-change-button" onClick={e => this.confirmRemoveAppointmentProduct(appointmentProduct.appointmentProductID)}>
                                        <span className="fa fa-times"></span>
                                    </button>

                                </span>

                            </li>
                        )}

                    </ul>

                    {!isAddingProduct[productCategory.id] &&
                        <button className="button button-tertiary search-box-button" onClick={e => this.toggleAddingProduct(productCategory.id, true)}>
                            <span className="fa fa-plus"></span>{' '}
                            Add a product
                        </button>
                    }

                    {isAddingProduct[productCategory.id] &&
                        <div className="product-search search-box-absolute">
                            <Search
                                className="search-box"
                                autoFocus={true}
                                placeholder="Search for a product..."
                                onBlur={e => this.toggleAddingProduct(productCategory.id, false)}
                                search={async (query, setResults, maxResults) => {
                                    const results = await SearchService.search(query, ['Product'], { filters: { stockProductCategoryID: productCategory.id }, maxResults });
                                    setResults(results);
                                }}
                                renderResult={(result, index, info) =>
                                    <div key={index} className="search-result list-item" onMouseDown={e => {
                                        this.selectProduct(result);
                                        info.clearSearch();
                                        info.focus();
                                    }}>
                                        <div className="list-item-name">
                                            {result.name}
                                        </div>
                                    </div>
                                }
                            />
                        </div>
                    }

                </React.Fragment>
            )}

        </>);
    }

}

export default ClientRecordProducts;