// Libs
import React, { Component } from 'react';
import NumberFormat from 'react-number-format';

// Services
import SalonService from 'services/SalonService';

// Images
import profitImage from 'images/ypt-profit-image.png';

//-------------------------------------------------------------------------------------------------------------------

class InputProfitSlide extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        const currency = SalonService.getCurrentCurrency();
        let desiredProfit = this.props.salonFocusResult.desiredProfit;
        if (desiredProfit == 0) desiredProfit = '';

        return (
            <div className="ypt-slide ypt-slide-input-profit">

                <div className="ypt-title">
                    How much Profit do you want?
                </div>

                <div className="ypt-row">
                    <div>
                        <div className="ypt-field-title">Profit:</div>

                        <p>Now let's get down to the nitty gritty and set your Profit Target. Before we do though, here's a short video from Alan about why so many salon owners are not setting their profit targets in the right way.
                        So have a watch of this and then decide how much profit you want from your business and enter below:</p>
                        {/*<span className="help-icon"></span>*/}

                        <div className="ypt-field-large">
                            <NumberFormat
                                thousandSeparator={currency.thousandSeparator}
                                decimalSeparator={currency.decimalSeparator}
                                className="has-prefix"
                                value={desiredProfit}
                                onChange={e => {
                                    this.isChanged = true;
                                    this.props.onChange('desiredProfit', e.target.value)
                                }}
                                onBlur={e => {
                                    if (this.isChanged) {
                                        this.isChanged = false;
                                        this.props.onSave(e)
                                    }
                                }}
                            />
                            <span className="ypt-field-prefix">{currency.symbol}</span>
                        </div>

                        <div className="ypt-controls-row">

                            <button className="button button-primary ypt-back-button" onClick={e => this.props.onBack()}>
                                Back
                            </button>

                            <button className="button button-secondary ypt-continue-button" onClick={e => this.props.onContinue()}>
                                Continue
                            </button>

                        </div>

                    </div>
                    <div>
                        <img src={profitImage} />
                    </div>
                </div>
            </div>
        );
    }
}

export default InputProfitSlide;
