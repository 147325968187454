// Libs
import React, { Component } from 'react';

// Images
import profitImage from 'images/ypt-profit-image.png';

//-------------------------------------------------------------------------------------------------------------------

class InputStockSlide extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        let stockPercentage = Math.round((this.props.salonFocusResult.stockPercentage || 0) * 10000) / 100;
        if (stockPercentage == 0) stockPercentage = '';

        return (
            <div className="ypt-slide ypt-slide-input-profit">

                <div className="ypt-title">
                    Your Stock Percentage
                </div>

                <div className="ypt-row">
                    <div>
                        <div className="ypt-field-title">Stock Percentage:</div>

                        <p>Nearly there! Only two more numbers and you will see exactly what you have to put in your till every week to get the profit you want!!</p>
                         
                        <p>So this one is your stock percentage. Ideally, this figure is for the year as you may sometimes spend more in one month if you are investing in a new product line or a good deal. If you don't know how to work any of these figures out - it's okay - we've been teaching salon owners how to do this for over 30 years - just click on the question mark and Alan will show you how to do it :)</p>

                        <span className="help-icon"></span>

                        <div className="ypt-field-large">
                            <input
                                type="number"
                                step="1"
                                min="0"
                                max="100"
                                className="has-suffix"
                                value={stockPercentage}
                                onChange={e => {
                                    this.isChanged = true;
                                    this.props.onChange('stockPercentage', e.target.value / 100)
                                }}
                                onBlur={e => {
                                    if (this.isChanged) {
                                        this.isChanged = false;
                                        this.props.onSave(e)
                                    }
                                }}
                            />
                            <span className="ypt-field-suffix">%</span>
                        </div>

                        <div className="ypt-controls-row">

                            <button className="button button-primary ypt-back-button" onClick={e => this.props.onBack()}>
                                Back
                            </button>

                            <button className="button button-secondary ypt-continue-button" onClick={e => this.props.onContinue()}>
                                Continue
                            </button>

                        </div>

                    </div>
                    <div>
                        <img src={profitImage} />
                    </div>
                </div>
            </div>
        );
    }
}

export default InputStockSlide;
