import BaseService from 'services/BaseService';

//-------------------------------------------------------------------------------------------------------------------

export default class ClientFinderService
    extends BaseService {

    static async listFilters() {
        return BaseService.callAPI('client-finder/list-filters');
    }

    static async getCounts(selectedFilters) {
        return BaseService.callAPI('client-finder/get-counts', {
            selectedFilters: selectedFilters || []
        });
    }
}
