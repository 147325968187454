// Libs
import React from 'react';
import { Link, withRouter } from 'react-router-dom';

// Services & Helpers
import ServiceCategoryService from 'services/ServiceCategoryService';
import FormHelper from 'helpers/FormHelper';
import BootboxHelper from 'helpers/BootboxHelper';

// Components
import FloomlyComponent from 'components/FloomlyComponent';
import InfoBar from 'components/layout/InfoBar'
import Loader from 'components/reusable/Loader';

//-------------------------------------------------------------------------------------------------------------------

class SettingsServiceCategoryEdit extends FloomlyComponent {

    constructor(props) {
        super(props);

        // Init state
        this.state = {
            isLoading: true,
            serviceCategory: null
        };

        this.updateField = this.updateField.bind(this);
        
        this.formHelper = new FormHelper({
            fields: {
                name: { label: 'Name' },
                hasBookingPopupNote: { 
                    checkboxLabel: 'Show a pop-up message when someone books this service online',
                    type: 'checkbox'
                },
                bookingPopupNoteMessage: {
                    label: 'Message',
                    type: 'text'
                }
            },
            getValue: (fieldName, fieldInfo) => this.state.serviceCategory[fieldName],
            setValue: this.updateField
        });
    }

    componentDidMount() {
        this.load();
    }

    async confirmDelete() {
        const confirm = await BootboxHelper.confirm('Are you sure you want to delete this service category?');
        if (confirm) {
            this.delete();
        }
    }

    async delete() {
        await ServiceCategoryService.delete(this.state.serviceCategory.serviceCategoryID);
        this.props.history.replace('/settings/service-categories');
    }

    //--------------------------------------------------------------------------------------------------------------------

    async load() {
        const serviceCategoryID = Number(this.props.match.params.serviceCategoryID) || 0;

        let serviceCategory;
        if (serviceCategoryID) {
            serviceCategory = await ServiceCategoryService.get(serviceCategoryID);;
        } else {
            serviceCategory = {};
        }

        // Update UI
        this.setState({
            isLoading: false,
            serviceCategory
        });
    }

    goBack() {
        this.props.history.push('/settings/service-categories');
    }

    async save() {
        this.setState({ isLoading: true });
        const serviceCategory = { ...this.state.serviceCategory };
        await ServiceCategoryService.save(serviceCategory);
        this.goBack();
    }

    updateField(fieldName, value, fieldInfo) {
        const serviceCategory = { ...this.state.serviceCategory };
        serviceCategory[fieldName] = value;
        this.setState({
            serviceCategory
        });
    }

    //--------------------------------------------------------------------------------------------------------------------
    //  Render
    //--------------------------------------------------------------------------------------------------------------------

    renderInfoBar() {
        const {
            serviceCategory
        } = this.state;

        return (
            <InfoBar className="settings-info-bar" containerClassName="floating-on-mobile" sticky={true}>

                <div className="info-bar-panel-section">

                    <button className="button" onClick={() => this.goBack()}>
                        <span className="fa fa-chevron-left"></span>{' '}
                        Back
                    </button>

                </div>

                <div className="info-bar-panel-section info-bar-panel-section-text desktop-only">
                    {serviceCategory.serviceCategoryID ? serviceCategory.name : 'New service category'}
                </div>

                <div className="info-bar-panel-section ml-auto" style={{ whiteSpace: 'nowrap' }}>

                    {serviceCategory.serviceCategoryID &&
                        <button className="button" style={{ marginRight: 10 }} onClick={() => this.confirmDelete()}>
                            <span className="fa fa-times"></span>{' '}
                            Delete
                        </button>
                    }

                    <button className="button" onClick={() => this.save()}>
                        <span className="fa fa-check"></span>{' '}
                        Save
                    </button>

                </div>

            </InfoBar>
        );
    }

    render() {
        const {
            isLoading,
            serviceCategory
        } = this.state;

        if (isLoading) {
            return (<Loader />);
        }

        return (<>
            {this.renderInfoBar()}

            <div className="settings-page-main">
                <div className="panel">
                    <div className="panel-body">

                        {this.formHelper.renderFormGroups([
                            'name',
                            'hasBookingPopupNote',
                            (serviceCategory.hasBookingPopupNote && 'bookingPopupNoteMessage')
                        ])}

                    </div>
                </div>

            </div>

        </>);
    }

};

export default withRouter(SettingsServiceCategoryEdit);