// Libs
import React from 'react';
import { withRouter } from 'react-router-dom';

// Services & Helpers
import FormHelper from 'helpers/FormHelper';
import BootboxHelper from 'helpers/BootboxHelper';

// Components
import FloomlyComponent from 'components/FloomlyComponent';
import InfoBar from 'components/layout/InfoBar'
import Loader from 'components/reusable/Loader';
import FormService from '../../../services/FormService';

//-------------------------------------------------------------------------------------------------------------------

class SettingsFormEdit extends FloomlyComponent {

    constructor(props) {
        super(props);

        // Init state
        this.state = {
            isLoading: true,
            formType: null
        };

        this.updateField = this.updateField.bind(this);

        this.formHelper = new FormHelper({
            fields: {
                type: {
                    label: 'Type',
                    type: 'single-select',
                    getOptions: () => {
                        return [
                            { id: 'consultation', name: 'Consultation Form' },
                        ];
                    }
                },
                name: { label: 'Name' }
            },
            getValue: (fieldName, fieldInfo) => this.state.formType[fieldName],
            setValue: this.updateField
        });
    }

    componentDidMount() {
        this.load();
    }

    //--------------------------------------------------------------------------------------------------------------------

    async load() {
        const formTypeID = Number(this.props.match.params.formTypeID) || 0;

        let formType;
        if (formTypeID) {
            formType = await FormService.getFormType(formTypeID);
        } else {
            formType = {

            }
        }

        // Update UI
        this.setState({
            isLoading: false,
            formType: formType
        });
    }

    goBack() {
        this.props.history.push('/settings/forms');
    }

    async save() {
        this.setState({ isLoading: true });
        const formType = { ...this.state.formType };
        const formTypeID = await FormService.saveFormType(formType);
        this.setState({ isLoading: false });
        return formTypeID;
    }

    async saveAndGoBack() {
        const id = await this.save();
        if (this.state.formType.formTypeID) {
            this.goBack();
        } else {
            this.props.history.replace(`/settings/forms/${id}`);
            this.load();
        }
    }

    updateField(fieldName, value, fieldInfo) {
        const formType = { ...this.state.formType };
        formType[fieldName] = value;
        this.setState({
            formType: formType
        });
    }


    async confirmDelete() {
        const confirm = await BootboxHelper.confirm('Are you sure you want to delete this form?');
        if (confirm) {
            this.delete();
        }
    }

    async delete() {
        await FormService.deleteFormType(this.state.formType.formTypeID);
        this.props.history.replace('/settings/forms');
    }

    //--------------------------------------------------------------------------------------------------------------------
    //  Render
    //--------------------------------------------------------------------------------------------------------------------

    renderInfoBar() {
        const {
            formType
        } = this.state;

        return (
            <InfoBar className="settings-info-bar" containerClassName="floating-on-mobile" sticky={true}>

                <div className="info-bar-panel-section">

                    <button className="button" onClick={() => this.goBack()}>
                        <span className="fa fa-chevron-left"></span>{' '}
                        Back
                    </button>

                </div>

                <div className="info-bar-panel-section info-bar-panel-section-text desktop-only">
                    {formType.formTypeID ? formType.name : 'New Form'}
                </div>

                <div className="info-bar-panel-section ml-auto">

                    {!!formType.formTypeID &&
                        <button className="button" style={{ marginRight: 10 }} onClick={() => this.confirmDelete()}>
                            <span className="fa fa-times"></span>{' '}
                            Delete
                        </button>
                    }

                    <button className="button" onClick={() => this.saveAndGoBack()}>
                        <span className="fa fa-check"></span>{' '}
                        Save
                    </button>

                </div>


            </InfoBar>
        );
    }

    render() {
        const {
            isLoading,
            formType
        } = this.state;

        if (isLoading) {
            return (<Loader />);
        }
        return (<>
            {this.renderInfoBar()}

            <div className="settings-page-main">
                <div className="panel">
                    <div className="panel-body">

                        {this.formHelper.renderFormGroups([
                            'type',
                            'name'
                        ])}

                    </div>
                </div>

            </div>

        </>);
    }
};

export default withRouter(SettingsFormEdit);