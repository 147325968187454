import BaseService from 'services/BaseService';

//-------------------------------------------------------------------------------------------------------------------

export default class PackageService
    extends BaseService {

    static async list(param) {
        return BaseService.callAPI('package/list', param);
    }

    static async get(packageID) {
        return BaseService.callAPI('package/get/' + packageID);
    }
    
    static async save(servicePackage) {
        return BaseService.callAPI('package/save', servicePackage);
    }

    static async delete(id) {
        return BaseService.callAPI('package/delete/' + id);
    }

}