import BaseService from "services/BaseService";

export default class FormService
    extends BaseService {

    static async listFormTypes() {
        return BaseService.callAPI('form/list-form-types');
    }

    static async listAllCustomerForms(customerID) {
        return BaseService.callAPI('form/list-all-customer-forms/' + customerID);
    }

    static async listCustomerForms(customerID) {
        return BaseService.callAPI('form/list-customer-forms/' + customerID);
    }

    static async listDiaryForms(customerIDs, formTypeID) {
        return BaseService.callAPI('form/list-diary-forms', {customerIDs: customerIDs, formTypeID: formTypeID});
    }

    static async getFormType(formTypeID) {
        return BaseService.callAPI('form/get-form-type/' + formTypeID);
    }

    static async getForm(formID) {
        return BaseService.callAPI('form/get-form/' + formID);
    }

    static async getFormResponse(formID) {
        return BaseService.callAPI('form/get-form-response/' + formID);
    }

    static async getFormLink(formID) {
        return BaseService.callAPI('form/get-form-link/' + formID);
    }

    static async saveFormType(formType) {
        var formTypeID = await BaseService.callAPI('form/save-form-type', formType);
        return formTypeID;
    }

    static async saveForm(form) {
        var formID = await BaseService.callAPI('form/save-form', form);
        return formID;
    }

    static async saveFormResponse(formID, response) {
        await BaseService.callAPI('form/save-form-response', { formID: formID, formResponse: response });
        return formID;
    }

    static async sendFormViaEmail(formID) {
        return BaseService.callAPI('form/send-form-via-email/'+ formID);
    }

    static async sendFormViaSMS(formID) {
        return BaseService.callAPI('form/send-form-via-sms/'+ formID);
    }

    static async deleteFormType(formTypeID) {
        return BaseService.callAPI('form/delete-form-type/' + formTypeID);
    }

    static async deleteForm(formID) {
        return BaseService.callAPI('form/delete-form/' + formID);
    }
}