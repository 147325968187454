import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

// Components
import MainContent  from 'components/layout/MainContent';
import SideNav      from 'components/layout/SideNav';
import TopNav       from 'components/layout/TopNav';
import ErrorBoundary from 'components/ErrorBoundary';

//-------------------------------------------------------------------------------------------------------------------

class Layout extends Component {
    render() {
        return (
            <>

                <SideNav {...this.props} />

                <MainContent>

                    <TopNav { ...this.props } />

                    <ErrorBoundary>
                        {this.props.children}
                    </ErrorBoundary>

                </MainContent>

            </>
        );
    }
}

export default withRouter(Layout);