// Libs
import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import ReactDOMServer from 'react-dom/server';
import ReactTooltip from 'react-tooltip';

// Services & Helpers
import DiaryService from 'services/DiaryService';
import UserService from 'services/UserService';

// Components
import FloomlyComponent from 'components/FloomlyComponent';
import InfoBar from 'components/layout/InfoBar'
import Loader from 'components/reusable/Loader';
import FormHelper from 'helpers/FormHelper';
import EditRotaDateModal    from 'components/pages/diary/EditRotaDateModal';
import EditRotaDateRangeModal    from 'components/pages/diary/EditRotaDateRangeModal';

//-------------------------------------------------------------------------------------------------------------------

class SettingsHolidayManager extends FloomlyComponent {

	constructor(props) {
		super(props);

		// refs
		this.calendarTable = React.createRef();
		this.editRotaDateModelRef = React.createRef();
		this.editRotaDateRangeModelRef = React.createRef();

		// Init state
		this.state = {
			isLoading: true,
			years: [2020, 2021, 2022, 2023, 2024, 2025],	//TODO: load from backend
			users: [],

			year: this.getCurrentYear(),
			teamMember: null,

			workingDays: Array.from({length: 7}, () => true),
			
			selectedDate: null,
			selectedEndDate: null,
		};

		this.months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
		this.daysOfWeek = ['Mon','Tue','Wed','Thu','Fri','Sat','Sun'];
		this.daysOfWeekShort = ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su'];
		this.cols = Array.from({length: 37}, (_,i) => i);

		this.updateField = this.updateField.bind(this);

		this.formHelper = new FormHelper({
			fields: {
				teamMember: {
					label: 'Team Member',
					type: 'single-select',
					getOptions: () => this.state.users.map(user => ({ id: user.userID, name: user.nickname }))
				},
				year: {
					label: 'Year',
					type: 'single-select',
					getOptions: () => {
						return this.state.years.map(yr => ({
							id: yr,
							name: yr
						}));
					}
				}
			},
			getValue: (fieldName, fieldInfo) => {
				switch (fieldName) {
					case 'teamMember':
						return this.state.teamMember;
					case 'year':
						return this.state.year;

					default:
						return this.state.settings[fieldName];
				}
			},
			setValue: this.updateField,
			onBlur: (fieldName, value, fieldInfo) => {
			}
		})
	}

	componentDidMount() {
		this.load();
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.state.teamMember !== prevState.teamMember || this.state.year !== prevState.year) {
			this.load();
		}
	}

	//--------------------------------------------------------------------------------------------------------------------

	async load() {
		const diarySettings = await DiaryService.getSettings();
		const dows = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
		const workingDays = Array.from({length: 7}, (_, i) => i)
			.map(i => diarySettings.salonPeriod.salonOpeningTimes.find(t => t.dayOfWeek == dows[i]).isOpen);

		const users = (await UserService.list()).filter(user => user.isOnDiary);

		const teamMember = this.state.teamMember || (users && users.length > 0 ? users[0].userID : null);

		const rota = await DiaryService.getYearlyRota(this.state.year, teamMember);

		this.setState({
			isLoading: false,
			workingDays: workingDays,
			users: users,
			teamMember: teamMember,
			rota: rota,
		});
	}

	goBack() {
		this.props.history.push('/settings/users');
	}

	updateField(fieldName, value) {

		switch (fieldName) {
			case 'teamMember':
				this.setState({ teamMember: value });
				break;

			case 'year':
				this.setState({ year: value });
				break;

			default:
				break;
		}
	}

	getDaysInMonth(month) {
		return new Date(this.state.year, month + 1, 0).getDate();
	}

	getCurrentYear() {
		return new Date().getFullYear();
	}

	getWorkingTypeName(workingType) {
		switch (workingType) {
			case 'holidayPaid':
				return <><div className='box holidayPaid'></div>Holiday</>;
			case 'sick':
				return <><div className='box sick'></div>Sick</>;
			case 'absenceNotPaid':
				return <><div className='box absenceNotPaid'></div>Unpaid</>;
			case 'maternity':
				return <><div className='box maternity'></div>Maternity</>;
			case 'dayInLieu':
				return <><div className='box dayInLieu'></div>Lieu</>;
			case 'college':
				return <><div className='box college'></div>College</>;
			case 'bankHoliday':
				return <><div className='box closed'></div>Bank Holiday</>;
			case 'notWorking':
				return <><div className='box notWorking'></div>Not working</>;
			case 'wedding':
				return <><div className='box wedding'></div>Wedding</>;
			case 'closed':
				return <><div className='box closed'></div>Closed</>;

			default:
				return <></>;
		}
	}

	getDayCellTip(stylist) {

		if (stylist.rotaDayMulti.every(rd => rd.workingType === 'working')) {
			return null;
		}

		let items = [];
		let key = 1;
		stylist.rotaDayMulti.forEach(rd => {
			if (rd.workingType !== 'working') {
				items.push({key:'tip' + key, text:this.getWorkingTypeName(rd.workingType)});
				key++;
			}
		});

		return ReactDOMServer.renderToStaticMarkup(<>
			<div className="event-tooltip-body calendar-tooltip">
				<ul>
					{items.map(item => <li key={item.key}>{item.text}</li>)}
				</ul>
			</div>
		</>);
	}

	getWholeDayTip(workingType) {
		return ReactDOMServer.renderToStaticMarkup(<>
			<div className="event-tooltip-body calendar-tooltip">
				<ul>
					<li>{this.getWorkingTypeName(workingType)}</li>
				</ul>
			</div>
		</>);
	}

	getDayCell(month, dayOfWeek) {
		const key = `${month}{dayOfWeek}`;

		if (!this.state.year)
			return {
				text: "",
				className: "none",
				date: null,
				key: key,
			};

		var fdom = new Date(this.state.year, month, 1).getDay();
		if (fdom == 0) fdom = 7;

		var result = dayOfWeek - fdom + 2;

		if (dayOfWeek + 1 < fdom || result > this.getDaysInMonth(month)) {
			return  {
				text: "",
				className: "none",
				date: null,
				key: key,
			};
		}

		const day = dayOfWeek - fdom + 2;

		const isWorking = this.state.workingDays[(day + fdom - 1) % 7];
		
		const date = new Date(this.state.year, month, day, 12, 0, 0);

		let isSelected = false;

		let dateEnd = this.selectionStarted ? (this.selectedCell ? this.selectedCell.date : null) : this.state.selectedEndDate;
		let dateStart = this.selectionStarted ? (this.selectedCellStart ? this.selectedCellStart.date : null) : this.state.selectedDate;

		if (dateStart && dateEnd) {
			if (dateStart > dateEnd) {
				[dateStart, dateEnd] = [dateEnd, dateStart];
			}

			isSelected = date >= dateStart && date <= dateEnd;
		} else if (dateStart) {
			isSelected = +date == +dateStart;
		}

		let tip = null, dayStyle = "day" + (isSelected ? " selected" : "");
		if (this.state.rota) {
			const m = (month+1).toString().padStart(2, "0");
			const d = day.toString().padStart(2, "0");

			const day_index = `${this.state.year}-${m}-${d}`;

			const rota = this.state.rota.diary[day_index];

			if (rota) {
				if (rota.isOpen) {
					if (rota.stylists && rota.stylists.length>0 && rota.stylists[0].rotaDayMulti && rota.stylists[0].rotaDayMulti.length) {

						const stylist = rota.stylists[0];
						if (stylist.numRotas === 0) {
							dayStyle += (stylist.rotaDayMulti.length > 1 ? " multi " + stylist.rotaDayMulti[1].workingType + " " : " ") + stylist.rotaDayMulti[0].workingType;
							tip = this.getDayCellTip(stylist);
						}
						else if (stylist.rotaDayMulti.length > 1) {
							if (stylist.isRotaWorkingDay || stylist.rotaDayMulti.findIndex(rd => rd.workingType === "working") >= 0) {
								dayStyle += " multi " + stylist.rotaDayMulti[0].workingType + " " + stylist.rotaDayMulti[1].workingType;
								tip = this.getDayCellTip(stylist);
							} else {
								dayStyle += " notWorking";
								tip = this.getWholeDayTip('notWorking');
							}
						} else {
							//if (stylist.isRotaWorkingDay || stylist.rotaDayMulti[0].workingType === "working") {							
								dayStyle += " " + stylist.rotaDayMulti[0].workingType;
								tip = this.getDayCellTip(stylist);
							//} else {
							//	dayStyle += " notWorking";
							//	tip = this.getWholeDayTip('notWorking');
							//}
						}
					} else {
						dayStyle += " notWorking";
						tip = this.getWholeDayTip('notWorking');
					}
				} else {
					dayStyle += " closed";
					tip = this.getWholeDayTip('closed');
				}
			} else {
				dayStyle += " notWorking";
				tip = this.getWholeDayTip('notWorking');
			}
		}

		return {
			text: day,
			className: dayStyle,
			date: date,
			key: key,
			tip: tip,
		};
	}

	cellEnter(cell) {
		if (cell.date) {
			this.setState({ info: cell.date ? cell.date.toString() : "---" });

			this.selectedCell = cell;
		}
	}

	cellLeave(cell) {
		this.selectedCell = null;
	}

	selectStart(event) {

		if (event.button !== 0)
			return;

		this.selectedCellStart = this.selectedCell;
		this.selectionStarted = true;
	}

	async selectEnd(event) {

		if (event.button !== 0)
			return;

		this.selectedCellEnd = this.selectedCell;
		this.selectionStarted = false;

		if (this.selectedCellStart != null && this.selectedCellEnd != null) {
	
			let [startDate, endDate] = [this.selectedCellStart.date, this.selectedCellEnd.date];
			if (startDate > endDate) {
				[startDate, endDate] = [endDate, startDate];
			}

			if (startDate == endDate) {
				endDate = null;
			}
	
			// this.setState({
			// 	selectedDate: startDate,
			// 	selectedEndDate: endDate,
			// });

			await this.showEditRotaDateModal(this.state.teamMember, startDate, endDate);
		} else {
			this.setState({
				selectedDate: null,
				selectedEndDate: null,
			});
		}
	}

	async showEditRotaDateModal(stylistUserID, startDate, endDate) {
        
		if (endDate) {
			await this.editRotaDateRangeModelRef.current.show({
	            stylistUserID: stylistUserID,
	            fromDate: startDate,
				toDate: endDate,
	        });
		} else {
			await this.editRotaDateModelRef.current.show({
	            stylistUserID: stylistUserID,
	            date: startDate,
	        });
		}
        this.load();
    }

	//--------------------------------------------------------------------------------------------------------------------
	//  Render
	//--------------------------------------------------------------------------------------------------------------------

	formatDate(date) {
		if (date) {
			const year = date.getFullYear();
			const month = ("0" + (date.getMonth() + 1)).slice(-2);
			const day = ("0" + date.getDate()).slice(-2);
			return day + "/" + month + "/" + year;
		} else {
			return '';
		}
	}

	renderInfoBar() {
		return (
			<InfoBar className="settings-info-bar" containerClassName="desktop-only" sticky={true}>

				<div className="info-bar-panel-section info-bar-panel-section-text settings-team">
					Diary Manager
				</div>

			</InfoBar>
		);
	}

	renderInfoPanel() {
		return (<>
			<div>Info panel</div>
			<div>
				<label>Selected range:</label>
				<span>{this.formatDate(this.state.selectedDate)}</span> - <span>{this.formatDate(this.state.selectedEndDate)}</span>
			</div>
		</>);
	}

	renderExtras() {
		return (<>
			<EditRotaDateModal ref={this.editRotaDateModelRef} />
			<EditRotaDateRangeModal ref={this.editRotaDateRangeModelRef} />
			<ReactTooltip
                html={true}
                place="right"
                type="info"
                effect="float"
                delayShow={100}
				class="SettingsHoliday"
                overridePosition={(pos) => {
                    if (pos.top < 75) pos.top = 75;
                    return pos;
                }}
            />
		</>);
	}

	render() {
		const {
			isLoading,
		} = this.state;

		if (isLoading) {
			return (<Loader />);
		}

		setTimeout(() => {
            ReactTooltip.rebuild();
        }, 0);

		return (<>
			{this.renderExtras()}
			
			{this.renderInfoBar()}

			{this.formHelper.renderFormGroups([
				'teamMember',
				'year'
			])}

			<div className="settings-page-main">
				<div className="panel">
					<div className="panel-body">

						<div className="table-container">
							<table ref={this.calendarTable}
								className="holiday-calendar diary" 
								onPointerDown={ev => this.selectStart(ev)}
								onPointerUp={ev => this.selectEnd(ev)}>
								<thead>
									<tr>
										<th>&nbsp;</th>
										{this.cols.map(col => (<>
											<th key={col}>
												<span className='long-text'>{this.daysOfWeek[col % 7]}</span>
												<span className='short-text'>{this.daysOfWeekShort[col % 7]}</span>
											</th>
										</>))}
									</tr>
								</thead>
								<tbody>
									{this.months.map((month, monthIndex) => (<>
										<tr key={month}>
											<th>{month}</th>
											{this.cols.map(col => {
												const cell = this.getDayCell(monthIndex, col);
												return (<>
												<td
													key={cell.key} 
													className={cell.className}
													onPointerEnter={() => this.cellEnter(cell)}
													onPointerLeave={() => this.cellLeave(cell)}
													data-tip={cell.tip}
												>{cell.text}</td>
											</>)})}
										</tr>
									</>))}

								</tbody>
							</table>
						
						</div>
					</div>
				</div>

				<div className="diary-calendar-summary diary">
					<label className="total">Totals:</label>

					<div className="diary-calendar-summary-item">
						<div className="holidayPaid"><span>{this.state.rota.holiday}</span></div>
						<label> = Holiday</label>
					</div>
					<div className="diary-calendar-summary-item">
						<div className="sick"><span>{this.state.rota.sick}</span></div>
						<label> = Sick</label>
					</div>
					<div className="diary-calendar-summary-item">
						<div className="absenceNotPaid"><span>{this.state.rota.unpaid}</span></div>
						<label> = Unpaid</label>
					</div>
					<div className="diary-calendar-summary-item">
						<div className="maternity"><span>{this.state.rota.maternity}</span></div>
						<label> = Maternity</label>
					</div>
					<div className="diary-calendar-summary-item">
						<div className="wedding"><span>{this.state.rota.wedding}</span></div>
						<label> = Wedding</label>
					</div>
					<div className="diary-calendar-summary-item">
						<div className="dayInLieu"><span>{this.state.rota.lieu}</span></div>
						<label> = Lieu</label>
					</div>

					<div className="diary-calendar-summary-separator"></div>

					<div className="diary-calendar-summary-item">
						<div className="working">&nbsp;</div>
						<label> = Working</label>
					</div>

					<div className="diary-calendar-summary-item">
						<div className="college">&nbsp;</div>
						<label> = College</label>
					</div>
					<div className="diary-calendar-summary-item">
						<div className="notWorking">&nbsp;</div>
						<label> = Not working</label>
					</div>

					<div className="diary-calendar-summary-item">
						<div className="closed">&nbsp;</div>
						<label> = Closed</label>
					</div>
				</div>
			</div>
		</>);
	}

};

export default withRouter(SettingsHolidayManager);
