// Libs
import React from 'react';
import { Route } from 'react-router';
import { Link, withRouter } from 'react-router-dom';

// Services & Helpers
import ThermalPrinterService from 'services/ThermalPrinterService';
import FormHelper from 'helpers/FormHelper';
import BootboxHelper from 'helpers/BootboxHelper';

// Components
import FloomlyComponent from 'components/FloomlyComponent';
import InfoBar from 'components/layout/InfoBar'
import Loader from 'components/reusable/Loader';

//-------------------------------------------------------------------------------------------------------------------

class SettingsThermalPrinterEdit extends FloomlyComponent {

    constructor(props) {
        super(props);

        // Init state
        this.state = {
            isLoading: true,
            thermalPrinter: null
        };

        this.formHelper = new FormHelper({
            fields: {
                name: { label: 'Name' },
                enablePrinting: {
                    checkboxLabel: 'Print receipts with this printer',
                    type: 'checkbox'
                },
                printWidth: {
                    type: 'number',
                    label: 'Print width (number of characters)'
                },
                printCodePage: {
                    type: 'number',
                    label: 'Code page'
                },
                printCurrencySymbol: {
                    type: 'checkbox',
                    checkboxLabel: 'Print currency symbol'
                },
                cashDrawer1Type: {
                    label: 'Cash drawer 1 type',
                    type: 'single-select',
                    getOptions: () => [
                        { id: '', name: '(None)' },
                        { id: 'epson', name: 'Epson' },
                        { id: 'm80', name: 'M80' },
                    ]
                },
                openCashDrawer1OnCheckout: {
                    checkboxLabel: 'Open cash drawer 1 automatically when cash is handled',
                    type: 'checkbox'
                },
                cashDrawer2Type: {
                    label: 'Cash drawer 2 type',
                    type: 'single-select',
                    getOptions: () => [
                        { id: '', name: '(None)' },
                        { id: 'epson', name: 'Epson' },
                        { id: 'm80', name: 'M80' },
                    ]
                },
                openCashDrawer2OnCheckout: {
                    checkboxLabel: 'Open cash drawer 2 automatically when cash is handled',
                    type: 'checkbox'
                }
            },
            getValue: (fieldName) => this.state.thermalPrinter[fieldName],
            setValue: (fieldName, value) => this.updateFields({ [fieldName]: value })
        });
    }

    componentDidMount() {
        this.load();
    }

    //--------------------------------------------------------------------------------------------------------------------

    async load() {
        const thermalPrinterID = Number(this.props.match.params.thermalPrinterID) || 0;

        // Load thermal printer
        let thermalPrinter;
        if (thermalPrinterID) {
            thermalPrinter = await ThermalPrinterService.get(thermalPrinterID);
        } else {
            thermalPrinter = {
                cashDrawer1Type: 'Epson',
                openCashDrawer1OnCheckout: true,
                enablePrinting: true,
                printWidth: 48,
                printCodePage: 16,
                printCurrencySymbol: true
            };
        }

        // Update UI
        this.setState({
            isLoading: false,
            thermalPrinter
        }, () => {
            if (thermalPrinter.id) {
                this.checkConnection();
            }
        });
    }

    async checkConnection() {
        const { thermalPrinter } = this.state;
        const devices = await navigator.usb.getDevices();
        const device = ThermalPrinterService.findMatchingDevice(devices, thermalPrinter);
        this.setState({
            isConnected: !!device,
            isDisconnected: !device
        });
    }

    goBack() {
        this.props.history.push('/settings/thermal-printers');
    }

    async save() {
        this.setState({ isLoading: true });
        await ThermalPrinterService.save(this.state.thermalPrinter);
        this.goBack();
    }

    updateFields(newValues) {
        const thermalPrinter = { ...this.state.thermalPrinter };
        for (let fieldName in newValues) {
            const value = newValues[fieldName];
            thermalPrinter[fieldName] = value;
        }
        this.setState({
            thermalPrinter
        });
    }

    async confirmDelete() {
        const confirm = await BootboxHelper.confirm('Are you sure you want to delete this thermal printer?');
        if (confirm) {
            this.delete();
        }
    }

    async delete() {
        await ThermalPrinterService.delete(this.state.thermalPrinter.id);
        this.props.history.replace('/settings/thermal-printers');
    }

    async connect() {
        const { thermalPrinter } = this.state;
        const device = await navigator.usb.requestDevice({ filters: [] });
        if (device) {
            if (
                (thermalPrinter.usbVendorID && thermalPrinter.usbVendorID != device.vendorId) ||
                (thermalPrinter.usbProductID && thermalPrinter.usbProductID != device.productId) ||
                (thermalPrinter.serialNumber && thermalPrinter.serialNumber != device.serialNumber)
            ) {
                BootboxHelper.alert(
                    'This seems to be a different printer to the one that was originally set up. ' + 
                    'In order to connect this printer, please add it as a new Thermal Printer in the settings area.'
                );
                device.forget();
                return;
            }

            this.updateFields({
                name: device.productName,
                productName: device.productName,
                manufacturerName: device.manufacturerName,
                serialNumber: device.serialNumber,
                usbVendorID: device.vendorId,
                usbProductID: device.productId
            }, () => {
                this.checkConnection();
            });
        }
    }

    async confirmDisconnect() {
        const { thermalPrinter } = this.state;
        const confirm = await BootboxHelper.confirm(
            'If you disconnect this printer, you won\'t be able to use it on this computer until you re-connect it again. ' +
            'Do you want to continue?'
        );
        if (confirm) {
            const devices = await navigator.usb.getDevices();
            const device = ThermalPrinterService.findMatchingDevice(devices, thermalPrinter);
            if (device) {
                device.forget();
                this.checkConnection();
            }
        }
    }

    //--------------------------------------------------------------------------------------------------------------------
    //  Render
    //--------------------------------------------------------------------------------------------------------------------

    renderInfoBar() {
        const {
            thermalPrinter
        } = this.state;

        return (
            <InfoBar className="settings-info-bar" containerClassName="floating-on-mobile" sticky={true}>

                <div className="info-bar-panel-section">

                    <button className="button" onClick={() => this.goBack()}>
                        <span className="fa fa-chevron-left"></span>{' '}
                        Back
                    </button>

                </div>

                <div className="info-bar-panel-section info-bar-panel-section-text desktop-only">
                    {thermalPrinter.id ? thermalPrinter.name : 'New thermal printer'}
                </div>
                
                <div className="info-bar-panel-section ml-auto">

                    {thermalPrinter.id &&
                        <button className="button" style={{ marginRight: 10 }} onClick={()=> this.confirmDelete() }>
                            <span className="fa fa-times"></span>{' '}
                            Delete
                        </button>
                    }
                    <button className="button" onClick={() => this.save()}>
                        <span className="fa fa-check"></span>{' '}
                        Save
                    </button>
                    
                </div>

            </InfoBar>
        );
    }

    render() {
        const {
            isLoading,
            thermalPrinter,
            isConnected,
            isDisconnected
        } = this.state;

        if (isLoading) {
            return (<Loader />);
        }

        return (<>
            {this.renderInfoBar()}

            <div className="settings-page-main">
                <div className="panel">
                    <div className="panel-body">

                        {isConnected &&
                            <div className="alert alert-success mb-3">
                                Printer is connected ok.
                                <br /><br />

                                <button className="button button-secondary button-small" onClick={() => this.confirmDisconnect()}>
                                    Disconnect
                                </button>
                            </div>
                        }

                        {isDisconnected &&
                            <div className="alert alert-danger mb-3">
                                Your web browser does not seem to be connected to this printer.<br/>
                                Please click the button below to re-connect it.<br/>
                                When prompted, please select the printer named: <b>{thermalPrinter.productName}</b>
                                <br /><br />

                                <button className="button button-secondary button-small" onClick={() => this.connect()}>
                                    Re-connect
                                </button>
                            </div>
                        }

                        {!!thermalPrinter.serialNumber ?
                            this.formHelper.renderFormGroups([
                                'name',
                                'enablePrinting',
                                thermalPrinter.enablePrinting && 'printWidth',
                                thermalPrinter.enablePrinting && 'printCodePage',
                                thermalPrinter.enablePrinting && 'printCurrencySymbol',
                                'cashDrawer1Type',
                                'openCashDrawer1OnCheckout',
                                'cashDrawer2Type',
                                'openCashDrawer2OnCheckout'
                            ]) : <>

                                <button type="button" className="button button-primary" onClick={() => this.connect()}>
                                    Connect to a new printer
                                </button>

                            </>
                        }

                    </div>

                </div>

            </div>

        </>);
    }

};

export default withRouter(SettingsThermalPrinterEdit);