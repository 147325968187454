// Libs
import React from 'react';
import { withRouter } from 'react-router-dom';

// Services & Helpers
import ServiceService from 'services/ServiceService';
import PackageService from 'services/PackageService';
import TextHelpers    from 'helpers/TextHelpers';

// Components
import FloomlyComponent from 'components/FloomlyComponent';
import InfoBar      from 'components/layout/InfoBar'
import Loader       from 'components/reusable/Loader';
import SuperTable   from 'components/reusable/SuperTable';

//-------------------------------------------------------------------------------------------------------------------

class SettingsServiceList extends FloomlyComponent {

    constructor(props) {
        super(props);

        // Init state
        this.state = {
            isLoading: true,
            services: [],
            filteredServices : [],
            search :''
        };
    }

    componentDidMount() {
        this.load();
    }

    //--------------------------------------------------------------------------------------------------------------------

    async load() {
        const services = await ServiceService.list();

        // Update UI
        this.setState({
            isLoading: false,
            services,
            filteredServices: services
        });
    }

    edit(id) {
        this.props.history.push('/settings/services/' + id);
        document.querySelector('.main-content-inner').scrollTop = 0;
    }

    add() {
        this.props.history.push('/settings/services/new');
    }

    async search(query) {
        const services = [...this.state.services];
        const filteredServices = (query.length >= 2 ? services
            .filter((service) => {
                return query === ''
                    ? service
                    : service.name.toLowerCase().includes(query.toLowerCase());
            }) : services);
        this.setState({
            filteredServices
        });
    }

    //--------------------------------------------------------------------------------------------------------------------
    //  Render
    //--------------------------------------------------------------------------------------------------------------------

    renderInfoBar() {
        return (
            <InfoBar className="settings-info-bar" containerClassName="floating-on-mobile" sticky={true}>

                <div className="info-bar-panel-section info-bar-panel-section-text desktop-only">
                    Services
                </div>
                <div className="info-bar-panel-section ml-auto">

                    <button className="button" onClick={() => this.add()}>
                        <span className="fa fa-plus"></span>{' '}
                        Add New
                    </button>

                </div>

            </InfoBar>
        );
    }

    render() {
        const {
            isLoading,
            filteredServices,
        } = this.state;

        if (isLoading) {
            return (<Loader />);
        }

        return (<>
            {this.renderInfoBar()}

            <div className="settings-page-main">
                <div className="panel">
                    <div className="panel-body">
                        <div className="form-group">
                            <input
                                type="search"
                                placeholder="Search for a service..."
                                onChange={e => this.search(e.target.value)}
                            />
                        </div>
                        <SuperTable
                            className="settings-table services-table"
                            containerClassName="mb-0"
                            rows={filteredServices}
                            keyAccessor={service => `${service.packageID}-${service.serviceID}`}
                            emptyText="No services found"
                            cols={{
                                name: {
                                    className: 'name-col',
                                    label: 'Name'
                                },
                                serviceCategoryNames: {
                                    className: 'service-categories-col',
                                    label: 'Categories',
                                    getValue: (colInfo, service) => service.serviceCategoryNames.join(', ')
                                },
                                capabilityName: {
                                    className: 'capability-col',
                                    label: 'Capability'
                                },
                                priceRange: {
                                    className: 'price-range-col',
                                    label: 'Price range',
                                    getValue: (colInfo, service) => {
                                        if (service.isPriceOnConsultation) {
                                            return 'POC';
                                        }
                                        if (service.minPrice == service.maxPrice) {
                                            return TextHelpers.formatCurrencyNew(service.minPrice, { numDP: 2, includeSymbol: true });
                                        } else {
                                            return TextHelpers.formatCurrencyNew(service.minPrice, { numDP: 2, includeSymbol: true }) + ' - ' +
                                                    TextHelpers.formatCurrencyNew(service.maxPrice, { numDP: 2, includeSymbol: true });
                                        }
                                    }
                                },
                                duration: {
                                    className: 'duration-col',
                                    label: 'Duration',
                                    getValue: (colInfo, service) => {
                                        const parts = [
                                            service.durationMins
                                        ];
                                        if (service.cooldownPeriodMins) {
                                            parts.push('(+ ' + service.cooldownPeriodMins + ')');
                                        }
                                        return parts.join(' ');
                                    }
                                }
                            }}
                            onClick={(service, e) => this.edit(service.serviceID)}
                        />
                        
                    </div>
                </div>

            </div>

        </>);
    }

};

export default withRouter(SettingsServiceList);