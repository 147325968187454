// Libs
import React from 'react';

// Components
import FloomlyComponent from 'components/FloomlyComponent';
import Loader from 'components/reusable/Loader';
import TabPanel, { Tab } from 'components/reusable/TabPanel';
import StandardReports from 'components/pages/the-office/StandardReports';
import CustomReport from 'components/pages/the-office/CustomReport';
import SavedCustomReports from 'components/pages/the-office/SavedCustomReports';

//-------------------------------------------------------------------------------------------------------------------

class Reports extends FloomlyComponent {

    constructor(props) {
        super(props);

        // Init state
        this.state = {
            isLoading: false
        };
    }

    //--------------------------------------------------------------------------------------------------------------------
    //  Render
    //--------------------------------------------------------------------------------------------------------------------

    render() {
        const {
            isLoading
        } = this.state;
        const {
            tab
        } = this.props  ;

        if (isLoading) {
            return (<Loader />);
        }

        return (<>

            <TabPanel tab={tab || 'sales-report'} onChangeTab={tab => {
                this.props.onChangeTab(tab);
            }}>

                <Tab id="sales-report" title="Sales" tabClassName="tab-teal">

                    {(tab == 'sales-report' || (tab || '') == '') &&
                        <StandardReports key="sales-report" id="sales-report" />
                    }

                </Tab>

                <Tab id="wages-report" title="Wages" tabClassName="tab-pink">

                    {tab == 'wages-report' &&
                        <StandardReports key="wages-report" id="wages-report" />
                    }

                </Tab>

                <Tab id="short-report" title="Short" tabClassName="tab-green">

                    {tab == 'short-report' &&
                        <StandardReports key="short-report" id="short-report" />
                    }

                </Tab>

                <Tab id="perfect-client-report" title="Perfect Client" tabClassName="tab-yellow">

                    {tab == 'perfect-client-report' &&
                        <StandardReports key="perfect-client-report" id="perfect-client-report" />
                    }

                </Tab>

                <Tab id="custom-report" title="Custom Report" tabClassName="tab-teal">

                    <CustomReport id={this.props.tabParam} />
                     
                </Tab>

                <Tab id="my-reports" title="My Reports" tabClassName="tab-pink">

                    <SavedCustomReports />

                </Tab>

            </TabPanel>

        </>);
    }

};

export default Reports;