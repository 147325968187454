import BaseService from 'services/BaseService';

//-------------------------------------------------------------------------------------------------------------------

export default class MarketingService
    extends BaseService {

    static async listCampaignMonths() {
        return BaseService.callAPI('managed-email/list-campaign-months');
    }
    
    static async listCampaigns(status, month) {
        return BaseService.callAPI('managed-email/list-campaigns', {
            status, month
        });
    }

    static async getCampaignURL(id) {
        return BaseService.callAPI('managed-email/get-campaign-url/' + id);
    }

    static async createCampaign() {
        return BaseService.callAPI('managed-email/create-campaign');
    }
    
    static async checkAuth() {
        return BaseService.callAPI('managed-email/check-auth');
    }

    static async logOut() {
        return BaseService.callAPI('managed-email/log-out');
    }
}
