// Libs
import React from 'react';
import { withRouter } from 'react-router-dom';

// Services & Helpers
import ECommerceService from 'services/ECommerceService';

// Components
import FloomlyComponent from 'components/FloomlyComponent';
import InfoBar from 'components/layout/InfoBar'
import Loader from 'components/reusable/Loader';
import SuperTable from 'components/reusable/SuperTable';

//-------------------------------------------------------------------------------------------------------------------

class SettingsECommerceConnectionList extends FloomlyComponent {

    constructor(props) {
        super(props);

        // Init state
        this.state = {
            isLoading: true
        };
    }

    componentDidMount() {
        document.querySelector('.main-content-inner').scrollTop = 0;
        this.load();
    }

    async load() {
        const eCommerceConnections = await ECommerceService.listConnections();
        this.setState({
            isLoading: false,
            eCommerceConnections
        });
    }

    add() {
        this.props.history.push('/settings/ecommerce-connection/new');
    }

    edit(id) {
        this.props.history.push('/settings/ecommerce-connection/' + id);
    }

    //--------------------------------------------------------------------------------------------------------------------
    //  Render
    //--------------------------------------------------------------------------------------------------------------------

    render() {
        const {
            isLoading,
            eCommerceConnections
        } = this.state;

        if (isLoading) {
            return (<Loader />);
        }

        return (<>
            {this.renderInfoBar()}

            <div className="settings-page-main">
                <div className="panel">
                    <div className="panel-body">

                        <SuperTable
                            className="settings-table"
                            containerClassName="mb-0"
                            rows={eCommerceConnections}
                            keyAccessor={ecc => ecc.id}
                            cols={{
                                shopType: {
                                    label: 'Shop type',
                                    getValue: (colInfo, ecc) => ECommerceService.getShopTypeFriendly(ecc.shopType)
                                },
                                shopDomain: { label: 'Domain' },
                                name: { label: 'Friendly name' },
                                status: {
                                    label: 'Status',
                                    getValue: (colInfo, ecc) => ECommerceService.getConnectionStatus(ecc)
                                }
                            }}
                            onClick={(ecc, e) => this.edit(ecc.id)}
                            emptyText="No e-commerce connections added yet"
                        />

                    </div>
                </div>

            </div>

        </>);
    }

    renderInfoBar() {
        return (
            <InfoBar className="settings-info-bar" containerClassName="floating-on-mobile" sticky={true}>

                <div className="info-bar-panel-section info-bar-panel-section-text desktop-only">
                    E-Commerce connections
                </div>
                <div className="info-bar-panel-section ml-auto">

                    <button className="button" onClick={() => this.add()}>
                        <span className="fa fa-plus"></span>{' '}
                        Add New
                    </button>

                </div>

            </InfoBar>
        );
    }
};

export default withRouter(SettingsECommerceConnectionList);