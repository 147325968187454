// Libs
import React from 'react';
import { withRouter } from 'react-router-dom';

// Services & Helpers
import CommissionService from 'services/CommissionService';
import FormHelper from 'helpers/FormHelper';

// Components
import FloomlyComponent from 'components/FloomlyComponent';
import InfoBar from 'components/layout/InfoBar'
import Loader from 'components/reusable/Loader';
import SuperTable from 'components/reusable/SuperTable';

class SettingsCommission extends FloomlyComponent {

    constructor(props) {
        super(props);

        // Init state
        this.state = {
            isLoading: true,
            commissionsList: [],
            isSplitCommissionEnabled: false
        };
        this.updateField = this.updateField.bind(this);
        this.formHelper = new FormHelper({
            fields: {
                splitCommission: {
                    label: 'Allow team members to share commission',
                    type: 'checkbox'
                }
            },
            getValue: (fieldName) => this.state.isSplitCommissionEnabled,
            setValue: this.updateField
        });
    }

    componentDidMount() {
        this.load();
    }

    //--------------------------------------------------------------------------------------------------------------------

    async load() {
        const commissionsList = await CommissionService.list();
        const isSplitCommissionEnabled = await CommissionService.getSplitCommissionStatus();
        this.setState({
            isLoading: false,
            commissionsList,
            isSplitCommissionEnabled
        });
    }

    edit(id) {
        this.props.history.push('/settings/commissions/' + id);
    }

    add() {
        this.props.history.push('/settings/commissions/new');
    }

    async save() {
        await CommissionService.saveSplitCommissionStatus(this.state.isSplitCommissionEnabled);
        this.props.history.push('/settings');
    }

    updateField(fieldName, value) {
        this.setState({
            isSplitCommissionEnabled : value
        });
    }

    //--------------------------------------------------------------------------------------------------------------------
    //  Render
    //--------------------------------------------------------------------------------------------------------------------

    renderInfoBar() {
        return (
            <InfoBar className="settings-info-bar" containerClassName="floating-on-mobile" sticky={true}>

                <div className="info-bar-panel-section info-bar-panel-section-text desktop-only">
                   Commissions
                </div>

                <div className="info-bar-panel-section ml-auto">

                    <button className="button" style={{ marginRight: 10 }} onClick={() => this.add()}>
                        <span className="fa fa-plus"></span>{' '}
                        Add New
                    </button>

                    <button className="button" onClick={() => this.save()}>
                        <span className="fa fa-check"></span>{' '}
                        Save
                    </button>

                </div>

            </InfoBar>
        );
    }

    render() {
        const {
            isLoading,
            commissionsList
        } = this.state;

        if (isLoading) {
            return (<Loader />);
        }

        return (<>
            {this.renderInfoBar()}

            <div className="settings-page-main">
                <div className="panel">
                    <div className="panel-body">
                        {this.formHelper.renderFormGroups([
                            'splitCommission'
                        ])}
                        <SuperTable
                            className="settings-table"
                            rows={commissionsList}
                            keyAccessor={commissionLevel => commissionLevel.id}
                            cols={{
                                name: { label: 'Name' },
                                commissionPercentage: {
                                    label: 'Commission %',
                                    getValue: (colInfo, commissionLevel) => Math.round(commissionLevel.commissionPercentage * 10000) / 100 + '%'
                                }
                            }}
                            onClick={(commissionLevel, e) => this.edit(commissionLevel.id)}
                        />

                    </div>
                </div>

            </div>

        </>);
    }
}

export default withRouter(SettingsCommission);