import BaseService from 'services/BaseService';

//-------------------------------------------------------------------------------------------------------------------

export default class MarketingService
    extends BaseService {

    static async list() {
        return BaseService.callAPI('client-list/list');
    }

    static async create(clientList) {
        return BaseService.callAPI('client-list/create', clientList);
    }

    static async get(id, page) {
        return BaseService.callAPI('client-list/get/' + id + '/' + (page || 0));
    }

    static async refresh(id) {
        return BaseService.callAPI('client-list/refresh/' + id);
    }

    static async delete(id) {
        return BaseService.callAPI('client-list/delete/' + id);
    }

    static async exportToExcel(id, pageNum) {
        return await BaseService.callAPI('client-list/exportToExcel', {            
            id,pageNum,
            isDownload: true
            
        });
    }
}
