// Libs
import React from 'react';
import Modal from 'react-bootstrap/Modal';

import GlobalStateService from 'services/GlobalStateService';

//-------------------------------------------------------------------------------------------------------------------

export default class ViewUploadModal extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    show(opts) {
        return new Promise((resolve, reject) => {
            this.resolve = resolve;
            this.setState({
                isOpen: true,
                guid: opts.guid
            });
        });
    }

    hide() {
        this.setState({
           isOpen: false
        });
    }

    render() {
        const {
            isOpen
        } = this.state;

        if (!isOpen) {
            return null;
        }

        return (
            <Modal
                className="view-upload-modal"
                show={isOpen}
                onHide={e => this.hide()}
            >
                {this.renderInner()}
            </Modal>
        );
    }

    renderInner() {
        const { guid } = this.state;
        const salonCode = GlobalStateService.getValue('salonCode');

        return (<>
            <Modal.Header closeButton>
            </Modal.Header>
            <Modal.Body>

                <iframe src={`/api/upload/view/${guid}?isInline=true#toolbar=0`} />

            </Modal.Body>
            <Modal.Footer>
                <button type="button" className="btn btn-primary" onClick={e => this.hide()}>
                    Close
                </button>
            </Modal.Footer>
        </>);
    }

}
