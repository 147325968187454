// Libs
import React, { Component } from 'react';
import NumberFormat from 'react-number-format';

// Services
import SalonService from 'services/SalonService';

// Images
import successLadderImage from 'images/ypt-success-ladder.png';

//-------------------------------------------------------------------------------------------------------------------

class SuccessLadderSlide extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        const salonFocusResult = this.props.salonFocusResult;
        const targetSteps = salonFocusResult.targetSteps || [];
        targetSteps.forEach(targetStep =>
            targetStep.isDisabled = (targetStep.stepNum == 0 || targetStep.stepNum == targetSteps.length - 1)
        );
        const currency = SalonService.getCurrentCurrency();

        return (
            <div className="ypt-slide ypt-slide-input-success-ladder">

                <div className="ypt-title">
                    The Success Ladder
                </div>

                <p>That's it, you're done! But before you rush off to get back to making people look and feel fantastic, let me show you this wonderful little tool - we call it the Success Ladder. At the bottom of the ladder is where you are starting from and then each step up represents your journey to success - with your ultimate profit target at the top!</p>
                <p>Dream big - Step small. Stepping small without dreaming big won't lead you to your goals - but dreaming big without the small steps can be daunting - this way you have both! You've got this!
                 
                    <span className="help-icon"></span>

                    </p>


                <hr />

                <div className="ypt-row">

                    <div>

                        <img src={successLadderImage} />

                    </div>

                    <div>

                        <table className="success-ladder-table">
                            <thead>
                                <tr>
                                    <th></th>
                                    <th><div>Clients</div></th>
                                    <th className="separator-col"></th>
                                    <th><div>Avg. Bill</div></th>
                                    <th className="separator-col"></th>
                                    <th className="total-col"><div>Wkly Takings</div></th>
                                </tr>
                            </thead>
                            <tbody>
                                {targetSteps.map((targetStep, index) =>
                                    <tr key={targetStep.stepNum} data-step={targetStep.stepNum}>
                                        <th>
                                            <label>
                                                {targetStep.stepNum > 0 &&
                                                    <input type="radio" checked={targetStep.isSelected} onChange={e => this.props.onSelect(index)} />
                                                }

                                                Step {targetStep.stepNum + 1}:
                                                {targetStep.stepNum == 0 &&
                                                    <div>(Current)</div>
                                                }
                                            </label>
                                        </th>
                                        <td>
                                            <div className={'ypt-field-medium ' + (targetStep.isDisabled ? 'ypt-field-disabled' : '')}>
                                                <input
                                                    type="number"
                                                    value={targetStep.clientCount}
                                                    disabled={targetStep.isDisabled}
                                                    onChange={e => {
                                                        this.isChanged = true;
                                                        this.props.onChange(targetStep.stepNum, 'clientCount', e.target.value)
                                                    }}
                                                    onBlur={e => {
                                                        if (this.isChanged) {
                                                            this.isChanged = false;
                                                            this.props.onSave(e)
                                                        }
                                                    }}
                                                />
                                            </div>
                                        </td>
                                        <td className="separator-col">@</td>
                                        <td>
                                            <div className={'ypt-field-medium ' + (targetStep.isDisabled ? 'ypt-field-disabled' : '')}>
                                                <input
                                                    type="number"
                                                    value={Math.round(targetStep.averageBillGross)}
                                                    disabled={targetStep.isDisabled}
                                                    onChange={e => {
                                                        this.isChanged = true;
                                                        this.props.onChange(targetStep.stepNum, 'averageBillGross', e.target.value)
                                                    }}
                                                    onBlur={e => {
                                                        if (this.isChanged) {
                                                            this.isChanged = false;
                                                            this.props.onSave(e)
                                                        }
                                                    }}
                                                />
                                                {/*TEMP: '{targetStep.averageBillNet}'*/}
                                            </div>
                                        </td>
                                        <td className="separator-col">=</td>
                                        <td className="total-col">
                                            <div className={'ypt-field-medium ' + (targetStep.isDisabled ? 'ypt-field-disabled' : '')}>
                                                <NumberFormat
                                                    thousandSeparator={currency.thousandSeparator}
                                                    decimalSeparator={currency.decimalSeparator}
                                                    prefix={currency.symbol}
                                                    className="no-border"
                                                    value={Math.round(targetStep.salesGross)}
                                                    disabled={targetStep.isDisabled}
                                                    onValueChange={values => {
                                                        this.isChanged = true;
                                                        this.props.onChange(targetStep.stepNum, 'salesGross', values.value)
                                                    }}
                                                    onBlur={e => {
                                                        if (this.isChanged) {
                                                            this.isChanged = false;
                                                            this.props.onSave(e)
                                                        }
                                                    }}
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>

                        <div className="ypt-controls-row">

                            <button className="button button-primary ypt-back-button" onClick={e => this.props.onBack()}>
                                Back
                            </button>

                            <button className="button button-secondary ypt-continue-button" onClick={e => this.props.onContinue()}>
                                All done!
                            </button>

                        </div>

                    </div>

                </div>

            </div>
        );
    }
}

export default SuccessLadderSlide;
