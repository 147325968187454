import React, { Component } from 'react';

//// Images
//import placeholderImage from 'images/user-icon-image-placeholder.jpg';

//-------------------------------------------------------------------------------------------------------------------

export default class ProfilePhoto extends Component {

    //static getDerivedStateFromProps(props, state) {
        
    //}

    render() {
        if (this.props.uploadID) {
            return (
                <img src={'/api/upload/view/' + this.props.uploadID} />
            );
        } else {
            return (
                <></>
            );
                //<img src={placeholderImage} />
        }
    }

};
