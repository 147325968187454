// Libs
import React from 'react';
import { withRouter } from 'react-router-dom';

// Services & Helpers
import CommissionService from 'services/CommissionService';
import FormHelper from 'helpers/FormHelper';
import BootboxHelper from 'helpers/BootboxHelper';

// Components
import FloomlyComponent from 'components/FloomlyComponent';
import InfoBar from 'components/layout/InfoBar'
import Loader from 'components/reusable/Loader';

class SettingsCommissionEdit extends FloomlyComponent {

    constructor(props) {
        super(props);

        // Init state
        this.state = {
            isLoading: true,
            commission: null
        };

        this.updateField = this.updateField.bind(this);

        this.formHelper = new FormHelper({
            fields: {
                name: { label: 'Name' },
                commissionPercentage: { label: 'Commission %' }
            },
            getValue: (fieldName) => this.state.commission[fieldName],
            setValue: this.updateField
        });
    }

    componentDidMount() {
        this.load();
    }

    //--------------------------------------------------------------------------------------------------------------------

    async load() {
        const commissionLevelID = Number(this.props.match.params.commissionLevelID) || 0;

        let commission;
        if (commissionLevelID) {
            commission = await CommissionService.get(commissionLevelID);
        } else {
            commission = {
            };
        }

        // Update UI
        this.setState({
            isLoading: false,
            commission
        });
    }

    goBack() {
        this.props.history.push('/settings/commissions');
    }

    async save() {
        this.setState({ isLoading: true });
        await CommissionService.save(this.state.commission);
        this.goBack();
    }

    updateField(fieldName, value) {
        const commission = { ...this.state.commission };
        commission[fieldName] = value;
        this.setState({
            commission
        });
    }

    async confirmDelete() {
        const confirm = await BootboxHelper.confirm('Are you sure you want to delete this commission?');
        if (confirm) {
            this.delete();
        }
    }

    async delete() {
        await CommissionService.delete(this.state.commission.id);
        this.props.history.replace('/settings/commissions');
    }

    //--------------------------------------------------------------------------------------------------------------------
    //  Render
    //--------------------------------------------------------------------------------------------------------------------

    renderInfoBar() {
        const {
            commission
        } = this.state;

        return (
            <InfoBar className="settings-info-bar" containerClassName="floating-on-mobile" sticky={true}>

                <div className="info-bar-panel-section">

                    <button className="button" onClick={() => this.goBack()}>
                        <span className="fa fa-chevron-left"></span>{' '}
                        Back
                    </button>

                </div>

                <div className="info-bar-panel-section info-bar-panel-section-text desktop-only">
                    {commission.id ? commission.name : 'New commission'}
                </div>

                <div className="info-bar-panel-section ml-auto">

                    {commission.id &&
                        <button className="button" style={{ marginRight: 10 }} onClick={() => this.confirmDelete()}>
                            <span className="fa fa-times"></span>{' '}
                            Delete
                        </button>
                    }
                    <button className="button" onClick={() => this.save()}>
                        <span className="fa fa-check"></span>{' '}
                        Save
                    </button>

                </div>

            </InfoBar>
        );
    }

    render() {
        const {
            isLoading
        } = this.state;

        if (isLoading) {
            return (<Loader />);
        }

        return (<>
            {this.renderInfoBar()}

            <div className="settings-page-main">
                <div className="panel">
                    <div className="panel-body">

                        {this.formHelper.renderFormGroups([
                            'name',
                            'commissionPercentage',
                        ])}

                    </div>

                </div>

            </div>

        </>);
    }
}

export default withRouter(SettingsCommissionEdit);