// Libs
import React from 'react';
import { Route } from 'react-router';
import { Link, withRouter } from 'react-router-dom';

// Services & Helpers
import CapabilityService from 'services/CapabilityService';

// Components
import FloomlyComponent from 'components/FloomlyComponent';
import InfoBar      from 'components/layout/InfoBar'
import Loader       from 'components/reusable/Loader';
import SuperTable   from 'components/reusable/SuperTable';

//-------------------------------------------------------------------------------------------------------------------

class SettingsCapabilityList extends FloomlyComponent {

    constructor(props) {
        super(props);

        // Init state
        this.state = {
            isLoading: true,
            capabilities: []
        };
    }

    componentDidMount() {
        this.load();
    }

    //--------------------------------------------------------------------------------------------------------------------

    async load() {
        const capabilities = await CapabilityService.list();
        
        this.setState({
            isLoading: false, 
            capabilities: capabilities
        });
    }

    edit(id) {
        this.props.history.push('/settings/capabilities/' + id);
    }

    add() {
        this.props.history.push('/settings/capabilities/new');
    }

    //--------------------------------------------------------------------------------------------------------------------
    //  Render
    //--------------------------------------------------------------------------------------------------------------------

    renderInfoBar() {
        return (
            <InfoBar className="settings-info-bar" containerClassName="floating-on-mobile" sticky={true}>

                <div className="info-bar-panel-section info-bar-panel-section-text desktop-only">
                    Capabilities
                </div>
                <div className="info-bar-panel-section ml-auto">

                    <button className="button" onClick={() => this.add()}>
                        <span className="fa fa-plus"></span>{' '}
                        Add New
                    </button>

                </div>

            </InfoBar>
        );
    }

    render() {
        const {
            isLoading,
            capabilities
        } = this.state;

        if (isLoading) {
            return (<Loader />);
        }

        return (<>
            {this.renderInfoBar()}

            <div className="settings-page-main">
                <div className="panel">
                    <div className="panel-body">

                        <SuperTable
                            className="settings-table"
                            containerClassName="mb-0"
                            rows={capabilities}
                            keyAccessor={capability => capability.capabilityID}
                            cols={{
                                name: { label: 'Name' }
                            }}
                            onClick={(capability, e) => this.edit(capability.capabilityID)}
                            emptyText="No capabilities added yet"
                        />
                        
                    </div>
                </div>

            </div>

        </>);
    }

};

export default withRouter(SettingsCapabilityList);