// Libs
import React from 'react';

// Services & Helpers
import SearchService from 'services/SearchService';
import ServiceService from 'services/ServiceService';
import TextHelpers from 'helpers/TextHelpers';

// Components
import FloomlyComponent from 'components/FloomlyComponent';
import Loader from 'components/reusable/Loader';
import Search from 'components/reusable/Search';
import Money from 'components/reusable/Money';

//-------------------------------------------------------------------------------------------------------------------

class Quotation extends FloomlyComponent {

    constructor(props) {
        super(props);

        this.state = {
            serviceCategory: null,
            services: [],
            packages: [],
            quotation: {
                services: [],
                packages: []
            }
        };

        this.serviceSequenceID = -1;
        this.packageSequenceID = -1;
        this.deselectServiceCategory = this.deselectServiceCategory.bind(this);
    }
    
    async updateServiceField(index, field, value) {
        const services = [ ...this.state.quotation.services];
        const service = { ...services[index] };
        service[field] = value;

        // Get new price
        if (field == 'stylistUserID') {
            const price = this.props.getServicePrice(parseInt(service.service.serviceID), value);
            service.total = price;
        }

        services[index] = service;
        
        this.updateFields({
            services
        });
    }

    updateFields(values) {
        const quotation = { ...this.state.quotation };
        for (let field in values) {
            const value = values[field];
            quotation[field] = value;

            // Sum total
            if (field == 'services' || field == 'discountType' || field == 'discountAmount') {
                quotation.total = 0;
                quotation.services.forEach(s => {
                    quotation.total += (Number(s.total) || 0);
                });
                switch (quotation.discountType) {
                    case 'fixed':
                        quotation.total -= (Number(quotation.discountAmount) || 0);
                        break;
                    case 'percentage':
                        quotation.total -= (Number(quotation.discountAmount / 100) || 0) * quotation.total;
                        break;
                }
            }
        }
        this.setState({ quotation });
    }

    selectServiceCategory(serviceCategory) {
        const services = this.props.services.filter(s => s.serviceCategoryIDs.findIndex(i => String(i) == String(serviceCategory.serviceCategoryID)) != -1 && !s.isPackageOnly);
        this.setState({
            serviceCategory: serviceCategory,
            allServices: this.props.services,
            allPackages: this.props.packages,

            // Show all services & packages that belong to this category
            services,
            packages: this.props.packages.filter(p => p.serviceCategoryID == serviceCategory.serviceCategoryID)
        }, () => {
            document.querySelector('.main-content-inner').scrollTop = 130;
        });
    }

    deselectServiceCategory() {
        this.setState({
            serviceCategory: null,
            services: [],
            packages: []
        });
    }

    async addPackage(pkg, serviceCategory) {
        const packages = [...this.props.quotation.packages || []];

        // Add the package
        const quotPackage = {
            quotationPackageID: this.packageSequenceID--,
            packageID: pkg.packageID,
            package: pkg,
            isFixedPrice: (!!pkg.fixedPrice || pkg.fixedPrice === 0)
        };
        if (quotPackage.isFixedPrice) {
            quotPackage.total = pkg.fixedPrice;
        }
        packages.push(quotPackage);
        await this.props.onChange('packages', packages);

        // Add each service
        for (var i = 0; i < pkg.serviceIDs.length; i++) {
            const serviceID = pkg.serviceIDs[i];
            const service = this.state.allServices.find(s => s.serviceID == serviceID);
            await this.addService(service, serviceCategory, quotPackage);
        }
    }

    async addService(service, serviceCategory, quotationPackage) {
        let stylistUserID = null;
        
        const services = [...this.state.quotation.services || []];

        // Get more service details from service
        service = await ServiceService.get(service.serviceID || service.id);

        // Add service
        const newQuotationService = {
            quotationServiceID: this.serviceSequenceID--,
            quotationID: 0,
            stylistUserID,
            service,
            serviceCategory,
            total: (
                (quotationPackage && quotationPackage.isFixedPrice) ? 0 :
                    (stylistUserID ? this.props.getServicePrice(service.serviceID, stylistUserID) : 0)
            ) || 0,
            quotationPackageID: (quotationPackage ? quotationPackage.quotationPackageID : null)
        };
        
        services.push(newQuotationService);
        this.updateFields({ services });
    }

    async removeService(index) {
        const services = [...this.state.quotation.services]
        services.splice(index, 1);
        this.updateFields({ services });
    }

    async removePackage(index) {
        //let services = this.props.quotation.services;
        //const packages = [...this.props.quotation.packages]
        //const quotationPackage = packages.splice(index, 1)[0];

        //let anyDeleted = false;
        //do {
        //    anyDeleted = false;

        //    for (var i = services.length - 1; i >= 0; i--) {
        //        const quotationService = services[i];
        //        if (quotationService.quotationPackageID == quotationPackage.quotationPackageID) {
        //            services = await this.removeService(i);
        //            anyDeleted = true;
        //            break;
        //        }
        //    }

        //} while (anyDeleted);

        //await this.props.onChange('packages', packages);
    }

    //--------------------------------------------------------------------------------------------------------------------
    // Render
    //--------------------------------------------------------------------------------------------------------------------

    render() {
        const {
            serviceCategory,
            packages,
            quotation,
            services
        } = this.state;

        return (<>

            {/* Service category selector */}
            {!serviceCategory &&
                this.props.serviceCategories &&
                this.props.serviceCategories.length > 0 &&
                <div className="panel">
                    <div className="panel-header">
                        Service Categories
                    </div>
                    <div className="panel-body">

                        {/* Service search */}
                        <div className="service-search search-box-absolute">
                            <Search
                                className="search-box"
                                autoFocus={true}
                                placeholder="Search for a service..."
                                maxResults={50}
                                search={async (query, setResults, maxResults, state) => {
                                    const results = await SearchService.search(query, ['Service'], { maxResults, state });
                                    results.sort((a, b) => {
                                        if (a.type == b.type) {
                                            return b.score - a.score;
                                        } else {
                                            return a.type == 'Package' ? -1 : 1;
                                        }
                                    });
                                    setResults(results);
                                }}
                                renderResult={(result, index, info) =>
                                    <div key={index} className="search-result list-item" onMouseDown={e => {
                                        if (result.type == 'Package') {
                                            const pkg = this.props.packages.find(p => p.packageID == result.id);
                                            this.addPackage(pkg);
                                        } else {
                                            result.serviceID = result.id; // TODO just use 'id' everywhere
                                            this.addService(result);
                                        }
                                        info.clearSearch();
                                        info.focus();
                                    }}>
                                        <div className="list-item-name">
                                            {result.type == 'Package' && <>
                                                <span className="fas fa-cubes"></span>{' '}
                                            </>}
                                            {result.name}
                                        </div>
                                    </div>
                                }
                            />
                        </div>

                        <ul className="list">
                            {this.props.serviceCategories.map((serviceCategory, index) =>
                                <li key={serviceCategory.serviceCategoryID} onClick={e => this.selectServiceCategory(serviceCategory)}>
                                    {serviceCategory.name}
                                </li>
                            )}
                        </ul>

                    </div>
                </div>
            }

            {/* Service / package selector */}
            {serviceCategory &&
                <div className="panel service-selector-panel">
                    <div className="panel-header">
                        {serviceCategory.name}
                    </div>
                    <div className="panel-body service-selector">

                        <button className="button button-secondary button-small" onClick={this.deselectServiceCategory}>
                            <span className="fa fa-arrow-left"></span>{' '}
                            Back
                        </button>

                        {/* PACKAGES */}
                        <ul className="list service-list">
                            {packages.map((pkg, index) =>
                                <li key={pkg.packageID} onClick={e => this.addPackage(pkg, serviceCategory)}>
                                    <span className="service-list-icon">
                                        <span className="fas fa-cubes"></span>
                                    </span>
                                    <span className="service-list-name">{pkg.name}</span>

                                    <span className="service-list-price">
                                        <Money amount={pkg.price} />
                                    </span>

                                </li>
                            )}

                            {/* SERVICES */}

                            {services.map((service, index) =>
                                <li key={service.serviceID} onClick={e => this.addService(service, serviceCategory)}>
                                    <span className="service-list-name">{service.name}</span>

                                    <span className="service-list-price">
                                        {service.minPrice === service.maxPrice &&
                                            <Money amount={service.minPrice} />
                                        }
                                        {service.minPrice !== service.maxPrice && <>
                                            <Money amount={service.minPrice} />
                                            {' - '}
                                            <Money amount={service.maxPrice} />
                                        </>}
                                    </span>

                                </li>
                            )}
                        </ul>

                    </div>
                </div>
            }

            {/* Selected services / Discount */}
            {(
                (quotation.services && quotation.services.length > 0) ||
                (quotation.packages && quotation.packages.length > 0)
            ) && <>
                {this.renderServices()}
                {this.renderDiscount()}
            </>}

            {this.renderTotal()}

            <div className="flex-row">

                <button className="button button-tertiary button-small flex-4" onClick={this.props.onBackClicked}>
                    <i className='fas fa-arrow-left'></i>{' '}
                    Back
                </button>

            </div>

        </>);
    }

    renderServices() {
        const {
            quotation,
        } = this.state;

        const isFixedPricePackage = (quotPackageID) => {
            const quotPackage = quotation.packages.find(ap => ap.quotationPackageID == quotPackageID);
            return quotPackage && quotPackage.isFixedPrice;
        };

        return (
            <div className="panel">
                <div className="panel-header">
                    Selected Services
                </div>
                <div className="panel-body">
                    <ul className="list service-list">

                        {quotation.packages &&
                        quotation.packages.map((quotPackage, index) =>
                            <li
                                key={index}
                                id={'Package-' + quotPackage.quotationPackageID}
                                className="colour-coded-item service-list-package"
                                style={{ borderLeft: '4px solid black' }}
                                onMouseOver={e => this.highlightEvents(quotPackage.quotationPackageID)}
                                onMouseOut={e => this.unhighlightEvents(quotPackage.quotationPackageID)}
                            >

                                <span className="service-list-icon">
                                    <span className="fas fa-cubes"></span>
                                </span>

                                <span className="service-list-name">
                                    {quotPackage.package.name}
                                </span>

                                {quotPackage.isFixedPrice &&
                                    <span className="service-list-price">
                                        <Money amount={quotPackage.total} />
                                    </span>
                                }

                                <span className="floating-controls">

                                    <button className="button customer-summary-change-button" onClick={e => this.removePackage(index)}>
                                        <span className="fa fa-times"></span>
                                    </button>

                                </span>

                            </li>
                        )}

                        {quotation.services &&
                        quotation.services.map((quotService, index) =>
                            <React.Fragment key={index}>
                                <li
                                    id={'Service-' + quotService.quotationServiceID}
                                    className={
                                        (quotService.stylistUserID ? 'colour-coded-item ' : '') +
                                        (quotService.warnings && quotService.warnings.length > 0 ? 'service-list-has-warning' : '')
                                    }
                                    style={
                                        (quotService.stylistUserID && this.props.stylistsLookup[quotService.stylistUserID]) ?
                                            { borderLeft: '4px solid ' + this.props.stylistsLookup[quotService.stylistUserID].diaryColour }
                                            : {}
                                    }
                                >

                                    <span className={'service-list-icon ' + (!!quotService.quotationPackageID && 'service-list-icon-indent')}>
                                        <span className="fas fa-cut"></span>
                                    </span>

                                    <span className="service-list-name">
                                        {quotService.service.name}
                                    </span>

                                    <span className="service-list-price">
                                        {quotService.service.isPriceOnConsultation ?
                                            <abbr title="Price on Consultation">POC</abbr> :
                                            <>
                                                {(!quotService.quotationPackageID || !isFixedPricePackage(quotService.quotationPackageID)) &&
                                                    <input type="number" value={quotService.total} onChange={e => this.updateServiceField(index, 'total', Number(e.target.value) || '')} />
                                                }
                                            </>}
                                    </span>

                                    {!quotService.quotationPackageID &&
                                        <span className="floating-controls">

                                            <button className="button customer-summary-change-button" onClick={e => this.removeService(index)}>
                                                <span className="fa fa-times"></span>
                                            </button>

                                        </span>
                                    }
                                </li>

                                <li className="non-selectable">

                                    <span className="service-list-icon">
                                        <span className="fa fa-user" />
                                    </span>

                                    <span className="service-list-name" style={{ marginRight: 0 }}>
                                        <select
                                            value={quotService.stylistUserID || ''}
                                            style={{ width: '100%' }}
                                            onChange={e => this.updateServiceField(index, 'stylistUserID', parseInt(e.target.value))}
                                        >
                                            <option value="">(Please select)</option>
                                            {this.props.stylists.map(s =>
                                                <option key={s.userID} value={s.userID}>{s.nickname}</option>
                                            )}
                                        </select>
                                    </span>

                                </li>

                            </React.Fragment>
                        )}

                    </ul>

                </div>

            </div>
        );
    }

    renderDiscount() {
        const {
            quotation
        } = this.state;

        return (
            <div className="panel">
                <div className="panel-header">
                    Discount
                </div>
                <div className="panel-body">
                    <ul className="list service-list">

                        <li className="non-selectable">
                            <span className="service-list-name" style={{ flex: 1, marginRight: (quotation.discountType ? 10 : 0) }}>
                                <select
                                    value={quotation.discountType || ''}
                                    style={{ width: '100%' }}
                                    onChange={e => this.updateFields({ discountType: e.target.value })}
                                >
                                    <option value="">No discount</option>
                                    <option value="fixed">Fixed amount</option>
                                    <option value="percentage">Percentage</option>
                                </select>
                            </span>

                            {quotation.discountType &&
                                <span className="service-list-price">
                                    <input
                                        type="number"
                                        autoFocus
                                        value={quotation.discountAmount || ''}
                                        onChange={e => this.updateFields({ discountAmount: e.target.value })}
                                    />
                                </span>
                            }
                        </li>
                    </ul>
                </div>

            </div>
        );
    }

    renderTotal() {
        const {
            quotation
        } = this.state;

        return (
            <div className="panel">
                <div className="panel-header">
                    Total
                </div>
                <div className="panel-body">
                    <ul className="list service-list">
                        <li className="non-selectable total-row">
                        
                            <span className="service-list-icon">
                                <span className="fas fa-money-bill"></span>
                            </span>

                            <span className="service-list-name">
                                Total
                            </span>

                            <span className="service-list-price">
                                <Money amount={quotation.total || 0} />
                            </span>

                        </li>
                    </ul>
                </div>
            </div>
        );
    }
}

export default Quotation;