import BaseService from 'services/BaseService';

//-------------------------------------------------------------------------------------------------------------------

export default class StandardReportService
    extends BaseService {

    static async listTypes() {
        const response = await BaseService.callAPI('standard-report/list-types');
        return response;
    }

    static async getType(id, optionValues) {
        return BaseService.callAPI('standard-report/get-type/' + id, { optionValues });
    }

    static async run(param) {
        const response = await BaseService.callAPI('standard-report/run', param);
        return response;
    }

    static async exportToExcel(param) {
        const response = await BaseService.callAPI('standard-report/export-to-excel',
            { ...param, isDownload: true });
        return response;
    }

}
