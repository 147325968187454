// Libs
import React, { Component } from 'react';
import moment from 'moment';
import Modal from 'react-bootstrap/Modal';

// Services & Helpers
import DiaryService from 'services/DiaryService';

// Components
import Loader from 'components/reusable/Loader';

//--------------------------------------------------------------------------------------

export default class EditRotaDateModal extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true
        };
    }

    show(opts) {
        return new Promise((resolve, reject) => {
            this.resolve = resolve;
            console.log(opts);
            this.setState({
                isLoading: true,
                isOpen: true,
                appointmentID: opts.appointmentID
            }, () => {
                this.load();
            });
        });
    }

    async load() {
        const appointmentLogs = await DiaryService.listAppointmentLogs(this.state.appointmentID);
        this.setState({
            appointmentLogs,
            isLoading: false
        });
    }
    
    //---------------------------------------------------------------------

    render() {
        const {
            isOpen
        } = this.state;

        if (!isOpen) {
            return null;
        }

        return (
            <Modal
                show
                className="appointment-log-modal"
                onHide={e => this.setState({ isOpen: false })}
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        Appointment Activity Log
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    {this.renderInner()}

                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="button button-primary ml-auto" onClick={e => this.setState({ isOpen: false })}>
                        Close
                    </button>
                </Modal.Footer>
            </Modal>
        );
    }

    renderInner() {
        const {
            isLoading,
            appointmentLogs
        } = this.state;

        if (isLoading) {
            return (<Loader />);
        }

        return (<>

            <table className="super-table mb-0">
                <thead>
                    <tr>
                        <th>Date</th>
                        <th>Time</th>
                        <th>User</th>
                        <th>Activity</th>
                    </tr>
                </thead>
                <tbody>
                    {appointmentLogs.map(al =>
                        <tr key={al.id}>
                            <td>{moment(al.dateTime).format('DD/MM/YYYY')}</td>
                            <td>{moment(al.dateTime).format('HH:mm')}</td>
                            <td>{al.userNickname}</td>
                            <td>{al.description}</td>
                        </tr>
                    )}
                </tbody>
            </table>

        </>);
    }
}
