import React, { Component } from 'react';

//-------------------------------------------------------------------------------------------------------------------

class ClientRating extends Component {

    renderHeart(title, isActive, isAtRisk, colour) {
        return (
            <div
                className={'client-rating-icon ' + (isActive ? 'client-rating-icon-' + colour : '') + (isAtRisk ? ' client-rating-at-risk' : '')}
                title={title}
            />
        );
    }

    render() {
        const rating = this.props.rating || {};
        const { className } = this.props;

        return (
            <div className={'client-rating ' + (className || '')}>
                {this.renderHeart('Cut', rating.haircut, rating.isCutAtRisk, 'cut')}
                {this.renderHeart('Colour', rating.colour, rating.isColourAtRisk, 'colour')}
                {this.renderHeart('Treatment', rating.conditioning, rating.isTreatmentAtRisk, 'treatment')}
                {this.renderHeart('Product', rating.retailPurchase, rating.isRetailAtRisk, 'retail')}
                {this.renderHeart('Pre-book', rating.preBookedNextAppointment, rating.isPrebookAtRisk, 'prebook')}
            </div>
        );
    }
};

export default ClientRating;