// Libs
import React from 'react';

// Services & helpers
import FormHelper from 'helpers/FormHelper';
import LoyaltySchemeService from 'services/LoyaltySchemeService';
import SalonService from 'services/SalonService';
import TextHelpers from 'helpers/TextHelpers';
import GlobalStateService from 'services/GlobalStateService';

// Components
import FloomlyComponent from 'components/FloomlyComponent';
import Loader from 'components/reusable/Loader';

//-------------------------------------------------------------------------------------------------------------------

class LoyaltySchemeTab extends FloomlyComponent {

    constructor(props) {
        super(props);

        // Init state
        this.state = {
            isLoading: true,
            loyaltyScheme: null
        };

        this.loginDetails = GlobalStateService.getValue('loginDetails');
        this.canEdit = this.loginDetails.permissions['MarketingEditLoyaltyScheme'];

        this.currency = SalonService.getCurrentCurrency();
        const currency1Unit = TextHelpers.formatCurrencyNew(1, { currency: this.currency, includeSymbol: true });
        const currency0Point1Unit = TextHelpers.formatCurrencyNew(0.1, { currency: this.currency, includeSymbol: true });

        this.updateField = this.updateField.bind(this);

        this.formHelper = new FormHelper({
            fields: {
                isActive: { checkboxLabel: 'Loyalty scheme active', type: 'checkbox', isDisabled: !this.canEdit },
                accrueRateServices: {
                    label: `How many points per ${currency1Unit} would you like your clients to receive for a service?`,
                    type: 'number',
                    isDisabled: !this.canEdit
                },
                accrueRateRetail: {
                    label: `How many points per ${currency1Unit} would you like your clients to receive for a retail purchase?`,
                    type: 'number',
                    isDisabled: !this.canEdit
                },
                numPointsForPreBook: {
                    label: 'How many points would you like to give for pre-booking?',
                    type: 'number',
                    isDisabled: !this.canEdit
                },
                canSpendPoints: {
                    label: 'Allow clients to spend their loyalty points at checkout?',
                    type: 'single-select',
                    isDisabled: !this.canEdit,
                    getOptions: () => [
                        { name: 'No', id: false },
                        { name: 'Yes', id: true }
                    ]
                },
                spendRate: {
                    label: `When a client spends their loyalty points, how much is one point worth (e.g. 0.10 = ${currency0Point1Unit})?`,
                    type: 'currency',
                    isDisabled: !this.canEdit
                }
            },
            getValue: (fieldName, fieldInfo) => this.state.loyaltyScheme[fieldName],
            setValue: this.updateField
        });
    }

    componentDidMount() {
        this.load();
    }

    //--------------------------------------------------------------------------------------------------------------------

    async load() {
        let loyaltyScheme = await LoyaltySchemeService.get();
        if (!loyaltyScheme) {
            loyaltyScheme = {
                isActive: false
            };
        }

        this.setState({
            loyaltyScheme: loyaltyScheme,
            isLoading: false
        });
    }

    updateField(fieldName, value, fieldInfo) {
        const loyaltyScheme = { ...this.state.loyaltyScheme };
        loyaltyScheme[fieldName] = value;
        this.setState({
            loyaltyScheme: loyaltyScheme
        });
    }

    async save() {
        this.setState({ isLoading: true });
        await LoyaltySchemeService.save(this.state.loyaltyScheme);
        this.setState({ isLoading: false });
    }

    //--------------------------------------------------------------------------------------------------------------------
    //  Render
    //--------------------------------------------------------------------------------------------------------------------

    render() {
        const {
            isLoading,
            loyaltyScheme
        } = this.state;

        if (isLoading) {
            return (<Loader />);
        }

        return (
            <div className="loyalty-scheme-tab">

                {this.formHelper.renderFormGroups([
                    'isActive'
                ])}
                
                {loyaltyScheme.isActive && this.formHelper.renderFormGroups([
                    'accrueRateServices',
                    'accrueRateRetail',
                    'numPointsForPreBook',
                    'canSpendPoints',
                    loyaltyScheme.canSpendPoints && 'spendRate'
                ])}

            </div>
        );
    }

};

export default LoyaltySchemeTab;