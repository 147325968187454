import BaseService from 'services/BaseService';

//-------------------------------------------------------------------------------------------------------------------

export default class CustomReportService
    extends BaseService {

    static async listAreas() {
        return BaseService.callAPI('custom-report/list-areas');
    }

    static async listOptions(code, globalOptionValues) {
        return BaseService.callAPI('custom-report/list-options', {
            code, globalOptionValues
        });
    }

    static processOptionValues(optionValues) {
        optionValues = { ...optionValues };
        Object.keys(optionValues).forEach(ov => {
            const optionValue = optionValues[ov];
            if (optionValue !== null) {
                if (Array.isArray(optionValue)) {
                    // Serialise array
                    optionValues[ov] = optionValue.join(',');
                }
                else if (typeof optionValue.toISOString === 'function') {
                    // Serialise date
                    optionValues[ov] = optionValue.toISOString();
                }
                else if (typeof optionValue === 'object') {
                    // Convert object to array then serialise it
                    optionValues[ov] = [];
                    Object.keys(optionValue).forEach(ovk => optionValues[ov].push([ovk]));
                    optionValues[ov] = optionValues[ov].join(',');
                }
            }
        });
        return optionValues;
    }

    static async run(code, optionValues, requestID) {
        optionValues = CustomReportService.processOptionValues(optionValues);
        return await BaseService.callAPI('custom-report/run', {
            code, optionValues, requestID
        });
    }

    static async exportExcel(sectionSources, globalOptionValues) {
        sectionSources = sectionSources.filter(ss => !ss.isNew && ss.code);
        for (let i = 0; i < sectionSources.length; i++) {
            const sectionSource = { ...sectionSources[i] };
            sectionSource.optionValues = {
                ...globalOptionValues,
                ...sectionSource.optionValues
            };
            sectionSource.optionValues = CustomReportService.processOptionValues(sectionSource.optionValues);
            sectionSources[i] = sectionSource;
        }
        return await BaseService.callAPI('custom-report/export-excel', {
            sectionSources,
            isDownload: true
        });
    }


    static async listSaved() {
        return BaseService.callAPI('custom-report/list-saved');
    }

    static async getSaved(id) {
        return BaseService.callAPI('custom-report/get-saved/' + id);
    }

    static async save(customReport) {
        return BaseService.callAPI('custom-report/save', customReport);
    }

    static async delete(id) {
        return BaseService.callAPI('custom-report/delete/' + id);
    }
}
