// Libs
import React from 'react';
import moment from 'moment';
import Modal from 'react-bootstrap/Modal';

// Services & Helpers
import LoyaltySchemeService from 'services/LoyaltySchemeService';
import CustomerService from 'services/CustomerService';
import BootboxHelper from 'helpers/BootboxHelper';

// Components
import Loader from 'components/reusable/Loader';
import DatePicker from 'components/reusable/DatePicker';

//-------------------------------------------------------------------------------------------------------------------

export default class CustomerLoyaltyHistoryModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: true
        };
    }

    show(opts) {
        return new Promise((resolve, reject) => {
            this.resolve = resolve;
            this.onUpdate = opts.onUpdate;
            this.setState({
                isOpen: true,
                isLoading: true,
                customerID: opts.customerID,
                loyaltyTransactions: null
            }, () => {
                this.cancelNewTransaction();
                this.load();
            });
        });
    }

    hide() {
        this.setState({
            isOpen: false
        });
    }

    async load() {
        const { customerID } = this.state;
        const customer = await CustomerService.getSummary(customerID);
        const loyaltyTransactions = await LoyaltySchemeService.listTransactions(customerID);
        this.setState({
            isLoading: false,
            customer,
            loyaltyTransactions
        });
    }

    updateNewTransactionField(fieldName, value) {
        const newTransaction = { ...this.state.newTransaction };
        newTransaction[fieldName] = value;
        this.setState({ newTransaction });
    }

    async saveNewTransaction() {
        this.setState({
            isLoading: true
        });
        await LoyaltySchemeService.addTransaction(this.state.customerID, this.state.newTransaction);
        this.cancelNewTransaction();
        this.load();
        if (this.onUpdate) {
            this.onUpdate();
        }
    }

    cancelNewTransaction() {
        const now = moment();
        const datePart = now.format('YYYY-MM-DD');
        const hoursPart = now.format('HH');
        let minsPart = Math.floor(now.format('mm') / 5) * 5;
        if (minsPart < 10) minsPart = `0${minsPart}`
        const dateTime = moment(`${datePart}T${hoursPart}:${minsPart}`).toDate();

        this.setState({
            newTransaction: {
                customerID: this.state.customerID,
                dateTime,
                otherReason: '',
                isNegative: false,
                numPoints: ''
            },
            isAdding: false
        });
    }

    async confirmDelete(id) {
        const confirm = await BootboxHelper.confirm('Are you sure you want to delete this loyalty transaction? This will affect the customer\'s overall loyalty point balance.');
        if (confirm) {
            this.setState({ isLoading: true });

            // Do the delete and reload
            await LoyaltySchemeService.deleteTransaction(id);
            this.load();
            if (this.onUpdate) {
                this.onUpdate();
            }
        }
    }

    render() {
        const {
            isOpen,
            isLoading
        } = this.state;

        if (!isOpen) {
            return null;
        }

        return (
            <Modal
                className="customer-loyalty-history-modal"
                show={isOpen}
                onHide={e => this.hide()}
            >
                {isLoading ?
                    <Modal.Body><Loader /></Modal.Body> :
                    this.renderInner()
                }
            </Modal>
        );
    }

    renderInner() {
        const {
            customer,
            loyaltyTransactions,
            newTransaction,
            isAdding
        } = this.state;

        return (<>
            <Modal.Header closeButton>
                <Modal.Title>
                    Loyalty History

                    {customer.numLoyaltyPoints != 0 &&
                        <div className="modal-subtitle">
                            Loyalty Points: {customer.numLoyaltyPoints}
                        </div>
                    }
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>

                <ul className={`fv2-thing-list ${isAdding ? '' : 'border-on-bottom'} mb-3`}>

                    {loyaltyTransactions.map(lt =>
                        <li className="thing" key={lt.id}>

                            <div className="thing-side">

                                <div className={`thing-icon bordered ${lt.numPoints > 0 ? 'green' : 'red'} fa-solid ${lt.numPoints > 0 ? 'fa-arrow-right' : 'fa-arrow-left'}`} />

                                <div className="thing-detail">

                                    {lt.numPoints > 0 ? 'In' : 'Out'}

                                </div>

                            </div>

                            <div className="thing-main">

                                {!!lt.dateTime &&
                                    <div className="thing-detail heading">
                                        {moment(lt.dateTime).format('DD/MM/YYYY HH:mm')}
                                    </div>
                                }

                                {(!!lt.reason || !!lt.otherReason) &&
                                    <div className="thing-detail">
                                        {LoyaltySchemeService.formatReason(lt.reason, lt.otherReason)}
                                    </div>
                                }

                                <div className="thing-detail">

                                    {Math.abs(lt.numPoints)} Point{Math.abs(lt.numPoints) != 1 ? 's' : ''}

                                </div>

                            </div>

                            <div className="thing-buttons-icons">

                                <button type="button" className="button button-primary" onClick={() => this.confirmDelete(lt.id)}>
                                    <span className="fa fa-times"></span>
                                </button>

                            </div>

                        </li>
                    )}

                    {isAdding &&
                        <li className="thing">

                            <div className="thing-side">

                                <div className="thing-detail">

                                    <select
                                        value={newTransaction.isNegative ? 'Out' : 'In'}
                                        onChange={e => this.updateNewTransactionField('isNegative', e.target.value == 'Out')}
                                    >
                                        <option value="In">In</option>
                                        <option value="Out">Out</option>
                                    </select>

                                </div>

                                <div className={`thing-icon bordered fa-solid ${newTransaction.isNegative ? 'fa-arrow-left red' : 'fa-arrow-right green'}`} />

                            </div>

                            <div className="thing-main">

                                <div className="mb-1">

                                    <DatePicker
                                        selected={newTransaction.dateTime || ''}
                                        onChange={value => this.updateNewTransactionField('dateTime', value)}
                                        showTimeSelect
                                        timeIntervals={5}
                                        dateFormat="dd/MM/yyyy hh:mm aa"
                                    />

                                </div>

                                <input
                                    className="w-100 mb-1"
                                    type="text"
                                    value={newTransaction.otherReason || ''}
                                    placeholder="Description"
                                    onChange={e => this.updateNewTransactionField('otherReason', e.target.value)}
                                />

                                <div className="mb-1 d-flex align-items-center">

                                    <div className="me-1 mb-1">

                                        <input
                                            style={{ width: 100 }}
                                            type="number"
                                            value={newTransaction.numPoints || ''}
                                            onChange={e => this.updateNewTransactionField('numPoints', e.target.value)}
                                        />

                                    </div>

                                    <div className="ms-1">

                                        Points

                                    </div>

                                </div>

                                <button className="button button-primary button-small" onClick={e => this.saveNewTransaction()}>
                                    Save
                                </button>

                            </div>

                            <div className="thing-buttons-icons">

                                <button type="button" className="button button-primary" onClick={() => this.cancelNewTransaction()}>
                                    <span className="fa fa-times"></span>
                                </button>

                            </div>

                        </li>
                    }

                </ul>

                {!isAdding &&
                    <button type="button" className="button button-primary button-small" onClick={() => this.setState({ isAdding: true })}>
                        Add a new transaction
                    </button>
                }

            </Modal.Body>
            <Modal.Footer>
                <button type="button" className="btn btn-primary" onClick={e => this.hide()}>
                    Close
                </button>
            </Modal.Footer>
        </>);
    }

}
