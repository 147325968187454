// Libs
import React from 'react';
import Modal from 'react-bootstrap/Modal';

// Services & Helpers
import CustomerService from 'services/CustomerService';
import SearchService from 'services/SearchService';
import BootboxHelper from 'helpers/BootboxHelper';
import TextHelpers from 'helpers/TextHelpers';

// Components
import Loader from 'components/reusable/Loader';
import Search from 'components/reusable/Search';

//-------------------------------------------------------------------------------------------------------------------

export default class MergeCustomersModal extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true
        };
    }

    show(opts) {
        return new Promise((resolve, reject) => {
            this.resolve = resolve;
            this.onUpdate = opts.onUpdate;
            this.setState({
                isOpen: true,
                isLoading: false,
                customers: []
            }, () => {
                this.addCustomer(opts.customerID);
            });
        });
    }

    hide() {
        this.setState({
           isOpen: false
        });
    }

    async confirmMerge(id) {
        const { primaryCustomerID, customers } = this.state;
        if (!primaryCustomerID) {
            BootboxHelper.alert('Please select a primary client.');
            return;
        }
        if (customers.length < 2) {
            BootboxHelper.alert('Please add at least one other client to merge together.');
            return;
        }

        const confirm = await BootboxHelper.confirm(`Are you sure you want to merge these ${customers.length} clients together?`);
        if (confirm) {
            this.setState({ isLoading: true });
            const customerIDs = customers.map(c => c.customerID);
            await CustomerService.merge(primaryCustomerID, customerIDs.filter(c => c != primaryCustomerID));
            BootboxHelper.alert('The clients have been merged.');
            this.setState({ isOpen: false });
            this.resolve({
                primaryCustomerID,
                customerIDs
            });
        }
    }

    async addCustomer(customerID) {
        this.setState({ isAddingCustomer: true });
        const customer = await CustomerService.get(customerID);
        customer.numPastAppointments = (await CustomerService.listAppointments(customerID, 'past')).length;
        customer.numFutureAppointments = (await CustomerService.listAppointments(customerID, 'future')).length;
        const customers = [...this.state.customers];
        const newState = {
            customers,
            isAddingCustomer: false
        };
        if (customers.length == 0) {
            newState.primaryCustomerID = customerID;
        }
        customers.push(customer);
        this.setState(newState);
    }

    removeCustomer(customerID) {
        const customers = [...this.state.customers];
        const index = customers.findIndex(c => c.customerID == customerID);
        customers.splice(index, 1);
        const newState = {
            customers
        };
        if (customerID == this.state.primaryCustomerID) {
            newState.primaryCustomerID = null;
        }
        this.setState(newState);
    }

    render() {
        const {
            isOpen,
            isLoading
        } = this.state;

        if (!isOpen) {
            return null;
        }

        return (
            <Modal
                className="merge-customer-modal"
                show={isOpen}
                onHide={e => this.hide()}
            >
                {isLoading ?
                    <Modal.Body><Loader /></Modal.Body> :
                    this.renderInner()
                }
            </Modal>
        );
    }

    renderInner() {
        const {
            isAddingCustomer,
            customers,
            primaryCustomerID
        } = this.state;
        
        return (<>
            <Modal.Header closeButton>
                <Modal.Title>
                    Merge clients
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                
                <div className="search-box-absolute">
                    <Search
                        className="search-box"
                        autoFocus={true}
                        maxResults={50}
                        placeholder="Search for client(s) you'd like to merge with..."
                        search={async (query, setResults, maxResults, state) => {
                            const results = await SearchService.search(query, ['Customer'], { maxResults, state });
                            setResults(results);
                        }}
                        filterResults={(result) => {
                            for (let i = 0; i < customers.length; i++) {
                                if (result.id == customers[i].customerID) {
                                    return false;
                                }
                            }
                            return true;
                        }}
                        renderResult={(customer, index, info) =>
                            <div key={index} className="search-result list-item" onMouseDown={e => {
                                this.addCustomer(customer.id);
                                info.clearSearch();
                                info.focus();
                            }}>
                                <div className="list-item-name">
                                    {customer.name}
                                </div>
                            </div>
                        }
                    />
                </div>

                {isAddingCustomer ?
                    <Loader /> :
                    customers.length > 0 &&
                        <table className="table super-table mt-3">
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Email</th>
                                    <th>Mobile Tel</th>
                                    <th className="past-appts-col">Past appts</th>
                                    <th className="future-appts-col">Future appts</th>
                                    <th className="primary-col">Primary</th>
                                </tr>
                            </thead>
                            <tbody>
                                {customers.map(c =>
                                    <tr
                                        key={c.customerID}
                                        onClick={() => this.setState({ primaryCustomerID: c.customerID })}
                                    >
                                        <td>
                                            {TextHelpers.formatName(c.firstName, c.lastName)}
                                        </td>
                                        <td>
                                            {c.email}
                                        </td>
                                        <td>
                                            {c.mobileTel}
                                        </td>
                                        <td className="past-appts-col">
                                            {c.numPastAppointments}
                                        </td>
                                        <td className="future-appts-col">
                                            {c.numFutureAppointments}
                                        </td>
                                        <td className="primary-col">
                                            <div>

                                                <input
                                                    type="radio"
                                                    checked={primaryCustomerID == c.customerID}
                                                />

                                                <span className="floating-controls">
                                                    <button type="button" className="button delete-button" onClick={() => this.removeCustomer(c.customerID)}>
                                                        <span className="fa fa-times"></span>
                                                    </button>
                                                </span>

                                            </div>
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                }

            </Modal.Body>
            <Modal.Footer>
                <button type="button" className="button button-secondary mr-auto" onClick={e => this.hide()}>
                    Cancel
                </button>
                <button type="button" disabled={customers.length == 0} className="button button-primary ml-auto" onClick={e => this.confirmMerge()}>
                    Merge
                </button>
            </Modal.Footer>
        </>);
    }

}
