import React, { Component } from 'react';
import moment from 'moment';
import Modal from 'react-bootstrap/Modal';

import Loader from 'components/reusable/Loader';
import FormService from '../../../services/FormService';
import FormFillModal from '../../FormFillModal';
import BootboxHelper from '../../../helpers/BootboxHelper';

export default class FormListModal extends Component {
    constructor(props) {
        super(props);

        this.formFillModalRef = React.createRef();

        this.state = {
            isLoading: true
        };

        this.load = this.load.bind(this);
        this.showFormFillModal = this.showFormFillModal.bind(this);
        this.selectFormType = this.selectFormType.bind(this);
        this.sendSMS = this.sendSMS.bind(this);
        this.sendEmail = this.sendEmail.bind(this);
    }

    show(opts) {
        return new Promise((resolve, reject) => {
            this.resolve = resolve;


            function sortAppt(a, b) {
                return a.appointmentServices[0].time - b.appointmentServices[0].time;
            }

            var customerIDs = [];
            var times = [];

            opts.appts.sort(sortAppt);

            for (const appt of opts.appts) {
                if (appt.customer?.customerID > 0 && !customerIDs.find(id => id === appt.customer.customerID)) {
                    customerIDs.push(appt.customer.customerID);
                    times.push(appt.appointmentServices[0].time);
                }
            }

            this.setState({
                isLoading: true,
                isOpen: true,
                customerID: opts.customerID,
                customerIDs: customerIDs,
                times: times,
                appts: opts.appts
            }, () => {
                this.load();
            })
        })
    }


    hide() {
        this.setState({
            isOpen: false
        });
    }

    async load() {
        await this.setState({
            forms: {},
            isLoading: true
        });

        var formTypes = await FormService.listFormTypes();
        var selectedFormTypeID = this.state.selectedFormTypeID;
        if (!selectedFormTypeID) {
            if (formTypes.length) {
                selectedFormTypeID = formTypes[0].formTypeID;
            }
        }

        if (selectedFormTypeID > 0) {
            var forms = [];
            if (this.state.customerID > -1) {
                forms = await FormService.listCustomerForms(this.state.customerID);
            } else {
                forms = await FormService.listDiaryForms(this.state.customerIDs, selectedFormTypeID);
                for (var i = 0; i < forms.length; i++) {
                    forms[i].time = this.state.times[i];
                }
            }
        }

        this.setState({
            forms: forms,
            formTypes: formTypes,
            selectedFormTypeID: selectedFormTypeID,
            isLoading: false
        });
    }

    async selectFormType(selectedFormTypeID) {
        var forms = await FormService.listDiaryForms(this.state.customerIDs, selectedFormTypeID);
        await this.setState({
            forms: forms,
            selectedFormTypeID: selectedFormTypeID
        });

    }

    async sendSMS(form) {
        var formID = await FormService.saveForm(form);
        await FormService.sendFormViaSMS(formID);
        BootboxHelper.alert('Link sent via SMS');
    }

    async sendEmail(form) {
        var formID = await FormService.saveForm(form);
        await FormService.sendFormViaEmail(formID);
        BootboxHelper.alert('Link sent via Email');
    }

    viewCustomer(customerID) {
        this.hide();
        window.appHistory.push('/customer/' + customerID);
    }

    async showFormFillModal(form) {
        this.formFillModalRef.current.show({
            formID: form.formID,
            formTypeID: form.formTypeID,
            customerID: form.customerID
        });
    }

    render() {
        if (!this.state.isOpen) {
            return null;
        }

        if (this.state.isLoading) {
            return (<Loader />)
        }

        return (<>
            <Modal
                show
                className="forms-list-modal"
                onHide={e => this.hide()}
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        {this.state.customerID > -1 &&
                            <>
                            Consultation Forms For {this.state.forms[0].customer.firstName} {this.state.forms[0].customer.lastName}
                            </>
                        }
                        {this.state.customerID == -1 &&
                            <>
                                Consultation Forms For Clients Due Today
                            </>
                        }
                    </Modal.Title>

                    {this.state.customerID == -1 && this.state.forms &&
                        <select
                            className={"ml-auto"}
                            onChange={e => this.selectFormType(e.target.value)}
                            value={this.state.selectedFormTypeID}
                        >
                            {this.state.formTypes.map(ft =>
                                <option key={ft.formTypeID} value={ft.formTypeID}>{ft.name}</option>
                            )}
                        </select>
                    }
                </Modal.Header>
                <Modal.Body>
                    {this.state.customerID > -1 &&
                        this.renderCustomerForms()
                    }
                    {this.state.customerID == -1 &&
                        this.renderDiaryForms()
                    }
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="button button-primary ml-auto" onClick={e => this.hide()}>
                        Close
                    </button>
                </Modal.Footer>
            </Modal>

            <FormFillModal ref={this.formFillModalRef}
                onClose={this.load} />
        </>);
    }

    renderCustomerForms() {
        const {
            forms
        } = this.state;

        if (this.state.isLoading) {
            return (<Loader />)
        }

        if (!forms) {
            return (<div className="empty-text">
                No forms added
            </div>)
        }

        return (<>
            <table className="table form-list-table">
                <thead>
                    <tr>
                        <th>Form Type</th>
                        <th>Form Status</th>
                    </tr>
                </thead>
                <tbody>
                    {forms.map(form =>
                        <React.Fragment key={form.formID}>
                            <tr>
                                <td>
                                    {form.formType.name}
                                </td>
                                <td>
                                    {!form.filledDate && !form.createdDate && <>
                                        No form
                                    </>}
                                    {!form.filledDate && form.createdDate && <>
                                        Created {moment(form.dateCreated).format('DD-MM-YYYY')}
                                    </>}
                                    {form.filledDate && <>
                                        Completed {moment(form.dateCompleted).format('DD-MM-YYYY')}
                                    </>}
                                </td>
                            </tr>
                            <tr className="button-row">
                                <td colspan={2}>
                                    <button className="button button-primary button-small" onClick={e => this.showFormFillModal(form)} >
                                        <span className="fas fa-clipboard"></span>{' '}
                                        {!form.filledDate && <>Fill out now</>}
                                        {form.filledDate && <>View</>}
                                    </button>
                                    {form.customer.mobileTel &&
                                        <button className="button button-secondary button-small" onClick={e => this.sendSMS(form)}>
                                            <span className="fas fa-mobile-alt"></span>{' '}
                                            SMS
                                        </button>
                                    }
                                    {form.customer.email &&
                                        <button className="button button-secondary button-small" onClick={e => this.sendEmail(form)}>
                                            <span className="fas fa-envelope"></span>{' '}
                                            Email
                                        </button>
                                    }
                                </td>
                            </tr>
                        </React.Fragment>
                    )}
                </tbody>
            </table>
        </>);
    }

    renderDiaryForms() {
        const {
            forms
        } = this.state;

        if (this.state.isLoading) {
            return (<Loader />)
        }

        if (!forms) {
            return (<div className="empty-text">
                No forms added
            </div>)
        }

        return (<>
            <table className="table form-list-table">
                <thead>
                    <tr>
                        <th>Client</th>
                        <th>Form Status</th>
                        <th className="actions-column"></th>
                    </tr>
                </thead>
                <tbody>
                    {forms.map(form =>
                        <tr key={form.customerID} className={moment(form.time).isBefore(moment().subtract(30,'minutes'))?'grey-background':''}>
                            <td>
                                <div className="waiting-list-customer">
                                    {form.customer.firstName} {form.customer.lastName}
                                    <button className="button customer-summary-info-button" onClick={e => this.viewCustomer(form.customerID)}>
                                        <span className="fas fa-info"></span>
                                    </button>
                                </div>
                                {form.customer.mobileTel}<br />
                                {form.customer.email}<br />
                            </td>
                            <td>
                                {!form.filledDate && !form.createdDate && <>
                                    No form
                                </>}
                                {!form.filledDate && form.createdDate && <>
                                    Created {moment(form.dateCreated).format('DD-MM-YYYY')}
                                </>}
                                {form.filledDate && <>
                                    Completed {moment(form.dateCompleted).format('DD-MM-YYYY')}
                                </>}
                            </td>
                            <td className="actions-column">
                                <button className="button button-primary button-small" onClick={e => this.showFormFillModal(form)}>
                                    <span className="fas fa-clipboard"></span>{' '}
                                    {!form.filledDate && <>Fill out now</>}
                                    {form.filledDate && <>View</>}
                                </button>
                                {form.customer.mobileTel &&
                                    <button className="button button-secondary button-small" onClick={e => this.sendSMS(form)}>
                                        <span className="fas fa-mobile-alt"></span>{' '}
                                        SMS
                                    </button>
                                }
                                {form.customer.email &&
                                    <button className="button button-secondary button-small" onClick={e => this.sendEmail(form)}>
                                        <span className="fas fa-envelope"></span>{' '}
                                        Email
                                    </button>
                                }
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
        </>);
    }
}