import BaseService from 'services/BaseService';

//-------------------------------------------------------------------------------------------------------------------

export default class EmailService
    extends BaseService {
    
    static async listAccounts() {
        return (await BaseService.callAPI('email/list-accounts'));
    }

    static async getAccount(emailAccountID) {
        return BaseService.callAPI('email/get-account/' + emailAccountID);
    }

    static async saveAccount(emailAccount) {
        return BaseService.callAPI('email/save-account', emailAccount);
    }

    static async deleteAccount(emailAccountID) {
        return BaseService.callAPI('email/delete/' + emailAccountID);
    }

    static async listTemplates(emailTypeCode) {
        return (await BaseService.callAPI('email/list-templates/' + (emailTypeCode || '')));
    }

    static async getTemplate(emailTemplateID) {
        return BaseService.callAPI('email/get-template/' + emailTemplateID);
    }

    static async saveTemplate(emailTemplate) {
        return BaseService.callAPI('email/save-template', emailTemplate);
    }

    static async listTypes() {
        const response = await BaseService.callAPI('email/list-types');
        return response;
    }

    static async getSettings() {
        return BaseService.callAPI('email/get-settings');
    }

    static async saveSettings(emailSettings) {
        return BaseService.callAPI('email/save-settings', emailSettings);
    }

    static async getPreviewMeta(salonAutomationTrigger, emailSettings) {
        return BaseService.callAPI('email/get-preview-meta', {
            salonAutomationTrigger, emailSettings
        });
    }

    static async sendCustomer(email) {
        return BaseService.callAPI('email/send-customer', email);
    }

    static async sendTestEmail(emailAccountID, emailAddress) {
        return BaseService.callAPI('email/send-test/' + emailAccountID, { emailAddress });
    }

}
