// Libs
import React from 'react';
import Modal from 'react-bootstrap/Modal';

// Services & Helpers
import ECommerceService from 'services/ECommerceService';
import SMSService from 'services/SMSService';
import EmailService from 'services/EmailService';
import FormHelper from 'helpers/FormHelper';
import BootboxHelper from 'helpers/BootboxHelper';
import SearchService from 'services/SearchService';
import StockService from 'services/StockService';

// Components
import Loader from 'components/reusable/Loader';
import Search from 'components/reusable/Search';

//--------------------------------------------------------------------------------------

export default class EditProductModal extends React.Component {
    
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true
        };

        this.formHelper = new FormHelper({
            fields: {
                autoCreateVoucher: {
                    checkboxLabel: 'Automatically create a voucher when this product is purchased',
                    type: 'checkbox'
                },
                reduceStockLevel: {
                    checkboxLabel: 'Link to stock item and reduce stock level on purchase',
                    type: 'checkbox'
                },
                emailAccountID: {
                    label: 'Send voucher to the recipient using this email account',
                    type: 'single-select',
                    blankText: '(Please select)',
                    getOptions: () => this.state.emailAccounts.map(ea => ({
                        id: ea.emailAccountID,
                        name: ea.fromEmail
                    }))
                },
                smsNumberID: {
                    label: 'If the customer only provides a mobile number, send voucher by SMS using this number instead',
                    type: 'single-select',
                    blankText: '(Please select)',
                    getOptions: () => this.state.smsNumbers.map(sn => ({
                        id: sn.id,
                        name: sn.numberOrSenderID
                    }))
                }
            },
            getValue: (fieldName, fieldInfo) => this.state.product[fieldName],
            setValue: (fieldName, value, fieldInfo) => this.updateFields({ [fieldName]: value })
        });
    }

    show(opts) {
        return new Promise((resolve, reject) => {
            this.resolve = resolve;
            this.setState({
                isOpen: true,
                isLoading: true,
                id: opts.id,
                externalID: opts.externalID,
                eCommerceConnectionID: opts.eCommerceConnectionID
            }, () => {
                this.load();
            });
        });
    }

    hide() {
        this.setState({ isOpen: false });
    }

    async load() {
        const { id, externalID, eCommerceConnectionID } = this.state;
        const emailAccounts = await EmailService.listAccounts();
        const smsNumbers = await SMSService.listNumbers();

        let product;
        if (id) {
            product = await ECommerceService.getProduct(id);
        } else {
            const shopProduct = await ECommerceService.getShopProduct(eCommerceConnectionID, externalID);
            product = {
                ...shopProduct,
                eCommerceConnectionID,
                externalID
            };
        }
        this.setState({
            product,
            emailAccounts,
            smsNumbers,
            isLoading: false,
            isLoadingStockItem: true
        }, () => {
            if (product.stockItemID) {
                this.loadStockItem();
            }
        });
    }
    
    updateFields(newValues, callback) {
        const product = { ...this.state.product };
        for (let fieldName in newValues) {
            const value = newValues[fieldName];
            product[fieldName] = value;
            if (fieldName == 'stockItemID' && value) {
                this.setState({
                    isLoadingStockItem: true
                }, () => this.loadStockItem());
            }
        }
        this.setState({ product }, callback);
    }

    async saveAndClose() {
        const { product } = this.state;
        this.setState({ isLoading: true });
        await ECommerceService.saveProduct(product);
        this.hide();
        this.resolve();
    }

    async confirmDelete() {
        const confirm = await BootboxHelper.confirm('Are you sure you want to disconnect this product?');
        if (confirm) {
            this.delete();
        }
    }

    async delete() {
        const { product } = this.state;
        this.setState({ isLoading: true });
        await ECommerceService.deleteProduct(product.id);
        this.hide();
        this.resolve();
    }

    async loadStockItem() {
        const { product } = this.state;
        let stockItem = null;
        if (product.stockItemID) {
            stockItem = await StockService.getStockItem(product.stockItemID);
        }
        this.setState({ stockItem, isLoadingStockItem: false });
    }

    //---------------------------------------------------------------------

    render() {
        const {
            isOpen,
            isLoading,
            product
        } = this.state;

        if (!isOpen) {
            return null;
        }

        return (
            <Modal
                show
                className="edit-ecommerce-product-modal"
                onHide={e => this.setState({ isOpen: false })}
            >
                <Modal.Header closeButton>
                    {!isLoading &&
                        <Modal.Title>
                            {product.name}
                        </Modal.Title>
                    }
                </Modal.Header>
                <Modal.Body>

                    {this.renderInner()}

                </Modal.Body>
                <Modal.Footer>
                    {!isLoading && <>
                        <button type="button" className="button button-secondary mr-auto" onClick={e => this.setState({ isOpen: false })}>
                            Cancel
                        </button>
                        {!!product.id &&
                            <button type="button" className="button button-danger ml-auto mr-auto" onClick={e => this.confirmDelete()}>
                                Disconnect
                            </button>
                        }
                        <button type="button" className="button button-primary ml-auto" onClick={e => this.saveAndClose()}>
                            Save
                        </button>
                    </>}
                </Modal.Footer>
            </Modal>
        );
    }

    renderInner() {
        const {
            isLoading,
            product
        } = this.state;

        if (isLoading) {
            return (<Loader />);
        }

        return (<>

            {this.formHelper.renderFormGroups([
                'autoCreateVoucher',
                product.autoCreateVoucher && 'emailAccountID',
                product.autoCreateVoucher && 'smsNumberID',
                'reduceStockLevel'
            ])}

            {product.reduceStockLevel &&
                this.renderStockItemSelector()
            }

        </>);
    }

    renderStockItemSelector() {
        const { product, isLoadingStockItem, stockItem } = this.state;

        if (product.stockItemID) {
            return (
                <button type="button" className="button button-secondary button-small" onClick={() => this.updateFields({ stockItemID: null })}>
                    {isLoadingStockItem ?
                        <Loader isInline /> :
                        <>
                            <span className="fa-solid fa-times" />{' '}
                            {stockItem ? <>{stockItem.product?.name} - {stockItem.size}{stockItem.product?.stockUnit}</> : '[Stock item deleted]'}
                        </>
                    }
                </button>
            );
        } else {
            return (
                <div className="service-search search-box-absolute">
                    <Search
                        ref={this.searchRef}
                        className="search-box"
                        autoFocus={true}
                        placeholder="Search for a stock item..."
                        search={async (query, setResults, maxResults, state) => {
                            let results = await SearchService.search(query, ['StockItem'], { state });
                            setResults(results);
                        }}
                        renderResult={(result, index, info) =>
                            <div key={index} className="search-result list-item" onMouseDown={e => {
                                this.updateFields({ stockItemID: result.id });
                                info.clearSearch();
                                info.focus();
                            }}>
                                <div className="list-item-name">
                                    {result.fullName || result.name}
                                </div>
                            </div>
                        }
                    />
                </div>
            );
        }
    }

}
