import BaseService from 'services/BaseService';

//-------------------------------------------------------------------------------------------------------------------

export default class DashboardService
    extends BaseService {

    static async getOverview(opts) {
        return BaseService.callAPI('dashboard/get-overview', opts);
    }

    static async getClientCount() {
        const response = await BaseService.callAPI('dashboard/get-client-count', {
            date: new Date()
            // TODO
        });
        return response;
    }

    //static async getByStylist() {
    //    const response = await BaseService.callAPI('dashboard/get-by-stylist', {
    //        // TODO
    //    });
    //    return response;
    //}

    //static async updateClientCountByStylist(stylistInfo) {
    //    const response = await BaseService.callAPI('dashboard/update-client-count-by-stylist', stylistInfo);
    //    return response;
    //}
}