// Libs
import React, { Component, useEffect, useState } from 'react';
import moment from 'moment';

// Services & Helpers
import StylistService       from 'services/StylistService';
import GlobalStateService   from 'services/GlobalStateService';
import DateHelpers          from 'helpers/DateHelpers';
import WaitingApptService   from 'services/WaitingApptService';

// Components
import DatePicker           from 'react-date-picker';
import Loader               from 'components/reusable/Loader';

//-------------------------------------------------------------------------------------------------------------------

class DiaryBrowser extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isLoading: true
        };

        this.weeks = [
            { name: 'This Week', delta: 0 },
            { name: '+ 1 Week',  delta: 1 },
            { name: '+ 2 Weeks', delta: 2 },
            { name: '+ 3 Weeks', delta: 3 },
            { name: '+ 4 Weeks', delta: 4 },
            { name: '+ 5 Weeks', delta: 5 },
            { name: '+ 6 Weeks', delta: 6 },
            { name: '+ 7 Weeks', delta: 7 },
            { name: '+ 8 Weeks', delta: 8 }
        ];
    }

    async componentDidMount() {
        if (this.props.waitingApptID > 0) {
            const waitingAppt = await WaitingApptService.get(this.props.waitingApptID);
            await this.setState({ waitingAppt });
        }
        this.setState({ isLoading: false });
    }

    //--------------------------------------------------------------------------------------------------------------------
    
    selectDay(date) {
        this.props.onChange({
            date: date,
            view: 'day',
            stylistID: null
        });
    }

    selectStylistWeek(stylistID) {
        const date = moment(this.props.date);
        this.props.onChange({
            view: 'week',
            stylistID: stylistID
        });
    }

    selectSalonWeek(delta) {
        let date;
        if (delta == 0) {
            date = moment(DateHelpers.stripTime(new Date()));
        } else {
            date = moment(this.props.date);
            date.add(delta, 'weeks');
        }
        this.props.onChange({
            date: date.toDate()
        });
    }

    //--------------------------------------------------------------------------------------------------------------------

    renderWaitingApptInfo() {
        const { waitingAppt } = this.state;
        return (<>
            <div className="panel waiting-appt-panel">
                <div className="panel-header">
                    Waiting Appointment
                </div>
                <div className="panel-body">
                    {waitingAppt && <>
                    {waitingAppt.waitingApptServices && <>
                        <b>Services:</b><br />
                        {waitingAppt.waitingApptServices.map(wApptS =>
                            <>{wApptS.service.name} by
                                {wApptS.stylistUserID && <> {wApptS.stylistUser.nickname}</>}
                                {!wApptS.stylistUserID && <> Any</>}
                                <br /></>
                        )}
                    </>}
                    <div style={{ display: "flex" }}>
                        <div style={{ flex: "50%" }}>
                            {(waitingAppt.timeOfDay) && <>
                                <b>Preferred Time:</b><br />
                            </>}
                            {waitingAppt.timeOfDay && <>
                                {waitingAppt.timeOfDay}<br />
                            </>}
                        </div>
                        <div style={{ flex: "50%" }}>
                            <b>Preferred Dates:</b><br />
                            {moment(waitingAppt.primaryDate).format('DD/MM/YYYY')}<br />
                            {waitingAppt.secondaryDate && <>
                                {moment(waitingAppt.secondaryDate).format('DD/MM/YYYY')}<br />
                            </>}
                            {waitingAppt.tertiaryDate && <>
                                {moment(waitingAppt.teritaryDate).format('DD/MM/YYYY')}<br />
                            </>}
                        </div>
                    </div>
                    {(waitingAppt.timeNotes || waitingAppt.notes) && <>
                        <b>Notes:</b><br />
                        {waitingAppt.timeNotes && <>
                            {waitingAppt.timeNotes}<br />
                        </>}
                        {waitingAppt.notes}
                    </>}
                    </>}
                </div>
            </div>
        </>);
    }

    render() {
        const mode = this.props.mode;
        const loginDetails = GlobalStateService.getValue('loginDetails');
        if (this.state.isLoading) {
            return (<Loader/>);
        }
        return (<>

            {/* New Booking */}
            {mode === 'browse' &&
                <div className="split-button sticky-top">
                    <button className="button button-primary" onClick={this.props.onNewBookingClicked} disabled={!loginDetails.permissions['DiaryCreateAppointments']}>
                        <i className="fas fa-calendar-day"></i>{' '}
                        New Booking
                    </button>
                    {/*disabled={!loginDetails.permissions['DiaryWalkInSales']}*/}
                    <button className="button button-primary retail-area-button" onClick={this.props.onRetailAreaClicked} title="Retail area"
                        disabled={!loginDetails.permissions['DiaryCreateAppointments'] && !loginDetails.permissions['DiaryRetail']}>
                        <i className="fas fa-cash-register"></i>
                    </button>
                </div>
            }

            {/* Pre-book here */}
            {mode == 'pre-book' &&
                <button className="button button-primary sticky-top" onClick={this.props.onNewBookingClicked}>
                    <i className="fas fa-calendar-day"></i>{' '}
                    Pre-book here
                </button>
            }

            {/* Cancel pre-book */}
            {mode == 'pre-book' &&
                <button className="button button-secondary" onClick={this.props.onCancelPreBookClicked}>
                    <i className="fas fa-arrow-left"></i>{' '}
                    Cancel Pre-Book
                </button>
            }

            {/* Cancel Date Change */}
            {mode === 'change-date' && <>
                <button className="button button-secondary" onClick={this.props.onCancelChangeDateClicked}>
                    <i className="fas fa-arrow-left"></i>{' '}
                    Cancel Date Change
                </button>
                {this.props.waitingApptID > 0 &&
                    this.renderWaitingApptInfo()
                }
                </>
            }

            {/* Pick a date */}
            <div className="inline-calendar">
                <DatePicker
                    clearIcon={null}
                    isOpen={true}
                    onChange={date => this.selectDay(date)}
                    value={this.props.diary.date || new Date()}
                />
            </div>

            {/* Stylist's Week */}
            {this.props.stylists.length > 0 &&
                <div className="panel stylists-week-panel">
                    <div className="panel-header">
                        Stylist's Week
                    </div>
                    <div className="panel-body">

                        <ul className="list">
                            {this.props.stylists.map((stylist, index) => {
                                return (
                                    <li key={index} onClick={() => this.selectStylistWeek(stylist.userID)}>
                                        {/*<img className="user-icon list-item-icon" src=userIconImage />*/}
                                        <div className="list-item-name">
                                            {stylist.nickname}
                                        </div>
                                        <div className="list-item-colour-bar" style={{ backgroundColor: stylist.diaryColour }}></div>
                                    </li>
                                );
                            })}
                        </ul>

                    </div>
                </div>
            }

            {/* Salon Weeks */}
            <div className="panel salon-weeks-panel">
                <div className="panel-header">
                    Salon weeks
                </div>
                <div className="panel-body">
                    <ul className="list">
                        {this.weeks.map((week, index) =>
                            <li key={index} onClick={(e) => this.selectSalonWeek(week.delta)}>
                                {week.name}
                            </li>
                        )}
                    </ul>
                </div>
            </div>

        </>);
    }
}

export default DiaryBrowser;