// Libs
import React, { Component } from 'react';
import moment from 'moment';

// Services & Helpers
import GlobalStateService from 'services/GlobalStateService';
import CustomerService  from 'services/CustomerService';
import TextHelpers from 'helpers/TextHelpers';

// Components
import Loader           from 'components/reusable/Loader';
import ClientRating     from 'components/reusable/ClientRating';
import CustomerAccountHistoryModal from 'components/pages/customer/CustomerAccountHistoryModal';
import CustomerLoyaltyHistoryModal from 'components/pages/customer/CustomerLoyaltyHistoryModal';

//-------------------------------------------------------------------------------------------------------------------

export default class CustomerSummary extends Component {

    constructor(props) {
        super(props);

        this.accountHistoryModalRef = React.createRef();
        this.loyaltyHistoryModalRef = React.createRef();

        this.state = {
            customer: null
        };
        this.isUnmounted = false;
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.customerID != prevState.customerID) {
            return { customerID: nextProps.customerID };
        }
        return null;
    }

    componentDidMount() {
        this.load();
    }

    componentDidUpdate(prevProps, prevState) {
        if ((this.state.customerID || 0) != (prevState.customerID || 0)) {
            //alert('Update: ' + this.state.customerID);
            this.load();
        }
    }

    componentWillUnmount() {
        this.isUnmounted = true;
    }

    async load() {
        if (this.state.customerID) {
            const customer = await CustomerService.getSummary(this.state.customerID);
            if (!this.isUnmounted) {
                this.setState({
                    customer: customer
                });
            }
        } else {
            this.setState({
                customer: {
                }
            });
        }
    }

    showAccountHistory() {
        this.accountHistoryModalRef.current.show({
            customerID: this.state.customer.customerID,
            onUpdate: () => this.load()
        });
    }

    showLoyaltyHistory() {
        this.loyaltyHistoryModalRef.current.show({
            customerID: this.state.customer.customerID,
            onUpdate: () => this.load()
        });
    }

    //--------------------------------------------------------------------------------------------------------------------
    // Render
    //--------------------------------------------------------------------------------------------------------------------

    renderVisitFrequency() {
        const visitFrequency = this.state.customer.visitFrequency;

        // Get visit frequency in weeks to nearest 0.5
        const visitFreqWeeks = Math.floor(visitFrequency / 7 * 2) / 2;
        const visitFreqWeeksRounded = Math.floor(visitFreqWeeks);
        const isHalf = (visitFreqWeeks - visitFreqWeeksRounded == 0.5);

        // Get correct message
        if (visitFreqWeeksRounded <= 1 && !isHalf) {
            return (<>Visits <span>weekly</span></>);
        } else if (isHalf) {
            return (<>Visits every <span>{visitFreqWeeksRounded} - {visitFreqWeeksRounded + 1} weeks</span></>);
        } else {
            return (<>Visits every <span>{visitFreqWeeksRounded} weeks</span></>);
        }
    }

    renderInner() {
        const customer = this.state.customer;
        const loginDetails = GlobalStateService.getValue('loginDetails');
        const hideCustomerLastName = loginDetails.permissions['ClientCardHideClientLastNames'];
        if (!customer) {
            return (<Loader />);
        }

        return (<>

            {this.props.onChangeClicked &&
                <button className="button customer-summary-change-button" onClick={this.props.onChangeClicked}>
                    <span className="fa fa-times"></span>
                </button>
            }
            {this.props.onViewInfoClicked &&
                <button className="button customer-summary-info-button" onClick={this.props.onViewInfoClicked}>
                    <span className="fas fa-info"></span>
                </button>
            }

            <div className="customer-summary-top">
                <span>{hideCustomerLastName? customer.firstName : customer.name}</span>
                {customer.pronouns &&
                    <div className="customer-summary-pronouns">
                        <span>({customer.pronouns})</span>    
                    </div>
                }
                {customer.perfectClientRating &&
                    <div>
                        <ClientRating rating={customer.perfectClientRating} />
                    </div>
                }
            </div>

            <div className="customer-summary-bottom">

                {!!customer.visitFrequency &&
                    <div className="customer-summary-visit-frequency">
                        <span className="fa fa-calendar-alt"></span>&ensp;
                        {this.renderVisitFrequency()}
                    </div>
                }

                <div className={"customer-patch-test-date" + (customer.patchTestDue ? " customer-patch-test-due" : "")}>
                    <span className="fas fa-eye-dropper"></span>{' '}
                    <span>Patch Test:</span>{' '}
                    {customer.patchTestDate ?
                        moment(customer.patchTestDate).format('DD/MM/YYYY') : 'No recent history'
                    }
                </div>
                {customer.regularStylistUser &&
                    <div className="customer-stylist-notes">
                        <span className="fa fa-cut"></span>{' '}
                        <span>Regular stylist:</span>{' '}
                        {customer.regularStylistUser.nickname}
                    </div>
                }
                {customer.backupStylistUser &&
                    <div className="customer-stylist-notes">
                        <span className="fa fa-cut"></span>{' '}
                        <span>Backup stylist:</span>{' '}
                        {customer.backupStylistUser.nickname}
                    </div>
                } 
                {customer.customerID && <>
                    <div className={'customer-account-balance customer-account-balance-' + (customer.accountBalance > 0 ? 'positive' : 'negative')}>
                        <span className="fa fa-piggy-bank"></span>{' '}
                        <span>Account balance:</span>{' '}
                        {TextHelpers.formatCurrencyNew(customer.accountBalance, { numDP: 2, includeSymbol: true })}

                        <button className="button button-secondary button-small" title="View account history" onClick={e => this.showAccountHistory()}>
                            <span className="fa fa-eye"></span>
                        </button>
                    </div>

                    <div className="customer-num-loyalty-points">
                        <span className="fa fa-stamp"></span>{' '}
                        <span>Loyalty points:</span>{' '}
                        {customer.numLoyaltyPoints}

                        <button className="button button-secondary button-small" title="View loyalty points history" onClick={e => this.showLoyaltyHistory()} disabled={!loginDetails.permissions['Marketing']}>
                            <span className="fa fa-eye"></span>
                        </button>
                    </div>

                    {customer.voucherBalance != 0 &&
                        <div className="customer-voucher-balance">
                            <span className="fas fa-ticket-alt"></span>{' '}
                            <span>Voucher Balance:</span>{' '}
                            {TextHelpers.formatCurrencyNew(customer.voucherBalance, { numDP: 2, includeSymbol: true })}
                        </div>
                    }
                </>}

                {customer.clubExpiryDate &&
                    <div className="customer-stylist-notes">
                        <span className="fa fa-calendar-day"></span>{' '}
                        <span>Club Expiry:</span>{' '}
                        {moment(customer.clubExpiryDate).format('DD/MM/YYYY')}
                    </div>
                } 
            </div>

        </>);
    }

    render() {
        const {
            customerID
        } = this.state;

        let hasChildren = false;
        for (var child in this.props.children) {
            hasChildren = true;
            break;
        }

        return (<>

            <div className={'panel customer-summary-panel ' + (this.props.className || '')}>

                {this.props.header &&
                    <div className="panel-header">
                        {this.props.header}
                    </div>
                }

                <div className="panel-body">

                    {!!customerID &&
                        <div className="panel-body-inner">
                            {this.renderInner()}
                        </div>
                    }

                    {hasChildren && this.state.customer && 
                        <div className="panel-body-extras">
                            {this.props.children}
                        </div>
                    }

                </div>

            </div>

            <CustomerAccountHistoryModal ref={this.accountHistoryModalRef} />
            <CustomerLoyaltyHistoryModal ref={this.loyaltyHistoryModalRef} />

        </>);
    }

}
