// Libs
import React from 'react';
import $ from 'jquery';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from 'App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import * as bootstrap from 'bootstrap/dist/js/bootstrap.min.js';

// CSS
import 'bootstrap/dist/css/bootstrap.css';
import '@fortawesome/fontawesome-free/css/all.css';
import './scss/main.scss'

// https://stackoverflow.com/questions/28241912/bootstrap-modal-in-react-js
// https://github.com/makeusabrew/bootbox/issues/777
window.jQuery = $;
window.$ = $;
global.jQuery = $;
$.fn.modal = bootstrap.Modal.jQueryInterface
$.fn.modal.Constructor = bootstrap.Modal

//-------------------------------------------------------------------------------------------------------------------

const rootElement = document.getElementById('root');

const baseURL = () => {
    let pathSplit = window.location.pathname.split('/').filter(p => !!p);
    if (pathSplit.length >= 1) {
        if (pathSplit[0] == 'group') {
            pathSplit = pathSplit.slice(0, 3); // /group/[groupcode]/[saloncode]
        } else {
            pathSplit = pathSplit.slice(0, 1); // /[saloncode]
        }
        return pathSplit.join('/');
    }
    return '';
    // TODO MERGE THIS IN 
    //let salonCode = window.location.pathname;
    //if (salonCode.includes('mobile') && salonCode.split('/').length >= 2) {
    //    salonCode = salonCode.split('/')[1] + '/' + salonCode.split('/')[2] + '/';
    //}
    //else if (salonCode.split('/').length >= 1) {
    //    salonCode = salonCode.split('/')[1];
    //} else {
    //    salonCode = '';
    //}
    let salonCode = window.location.pathname;
    if (salonCode.includes('mobile') && salonCode.split('/').length >= 2) {
        salonCode = salonCode.split('/')[1] + '/' + salonCode.split('/')[2] + '/';
    }
    else if (salonCode.split('/').length >= 1) {
        salonCode = salonCode.split('/')[1];
    } else {
        salonCode = '';
    }
    return salonCode;
};

ReactDOM.render(
    <BrowserRouter basename={baseURL()}>
        <App />
    </BrowserRouter>,
  rootElement);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();
