// Libs
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import moment from 'moment';

// Services
import DashboardService     from 'services/DashboardService';
import GlobalStateService from 'services/GlobalStateService';

// Components
import FloomlyComponent         from 'components/FloomlyComponent';
import Loader               from 'components/reusable/Loader';
import Money                from 'components/reusable/Money';
import PerfectClientRatingHearts from 'components/pages/dashboard/PerfectClientRatingHearts';

// Images
import heartGreyImage from 'images/perfect-client/heart-grey-small.png';
//import kmHeartGreyImage from 'images/theme/heart-km-grey.png';

//-------------------------------------------------------------------------------------------------------------------

class DashboardOverview extends FloomlyComponent {

    //--------------------------------------------------------------------------------------------------------------------
    //  Logic
    //--------------------------------------------------------------------------------------------------------------------

    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            dashboard: null
        }
    }

    componentDidMount() {
        this.load();
    }

    static getDerivedStateFromProps(props, state) {
        const month = props.match.params.dashboardType || '';
        if (props.dashboardType != state.dashboardType) {
            return {
                dashboardType: props.dashboardType
            };
        } else {
            return null;
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.dashboardType != prevState.dashboardType || this.props.date != prevProps.date || this.props.stylistUserID != prevProps.stylistUserID) {
            this.load();
        }
    }

    async load() {
        const dashboard = await DashboardService.getOverview({
            stylistUserID: this.props.stylistUserID,
            dashboardType: this.state.dashboardType,
            date: moment(this.props.date).format('YYYY-MM-DD')
        });
        if (dashboard) {
            this.setState({
                isLoading: false,
                dashboard
            }, () => {
                if (this.props.onLoad) {
                    this.props.onLoad(dashboard);
                }
            });
        }
    }

    //--------------------------------------------------------------------------------------------------------------------
    //  Render
    //--------------------------------------------------------------------------------------------------------------------

    renderPerfectClientRow(numHearts) {
        const {
            dashboard
        } = this.state;
        const clientInfo = GlobalStateService.getValue('clientInfo');
        const code = (clientInfo.theme && clientInfo.theme.code) || '';

        const hearts = [];
        for (var i = 0; i < numHearts; i++) {
            hearts.push(i);
        }

        const num = dashboard.perfectClient['num' + numHearts];
        let percentage = '';
        if (dashboard.perfectClient.total > 0) {
            percentage = Math.floor((num / dashboard.perfectClient.total) * 100);
        } else {
            percentage = 0;
        }

        return (
            <div className="perfect-client-dashboard-row">

                <div className="perfect-client-dashboard-row-left">
                    {hearts.map(h =>
                        <React.Fragment key={h}>
                            <img src={heartGreyImage} />
                        </React.Fragment>
                    )}
                </div>
                
                <div className="perfect-client-dashboard-row-right">
                    = {percentage}%
                </div>

            </div>
        );
    }

    renderRow1() {
        const {
            dashboard
        } = this.state;

        return (
            <div className="dashboard-row dashboard-row-1">

                <div className="dashboard-block dashboard-col-2">
                    <span className="dashboard-title">
                        Taken Today:
                    </span>
                    <div className="panel">
                        <div className="panel-body">
                            <span className="dashboard-figure">
                                <Money amount={dashboard.totalSalesToday} decimalPlaces={0} />
                            </span>

                            <div className="dashboard-client-figures">
                                <div>
                                    <div><span className="desktop-only">Client Count</span><span className="mobile-only">CC</span></div>
                                    <div></div>
                                    <div><span className="desktop-only">Average Bill</span><span className="mobile-only">AvB</span></div>
                                </div>
                                <div>
                                    <div className="clickable" onClick={() => this.props.history.push('/dashboard/client-count')}>
                                        {dashboard.clientCountToday || 0}
                                    </div>
                                    <div>@</div>
                                    <div>
                                        <Money amount={dashboard.avgBillToday} decimalPlaces={2} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="dashboard-block dashboard-col-1">
                    <span className="dashboard-title">
                        Taken This Week:
                    </span>
                    <div className="panel">
                        <div className="panel-body">
                            <span className="dashboard-figure">
                                <Money amount={dashboard.totalSalesThisWeek} decimalPlaces={0} />
                            </span>

                            <div className="dashboard-client-figures">
                                <div>
                                    <div><span className="desktop-only">Client Count</span><span className="mobile-only">CC</span></div>
                                    <div></div>
                                    <div><span className="desktop-only">Average Bill</span><span className="mobile-only">AvB</span></div>
                                </div>
                                <div>
                                    <div className="clickable" onClick={() => this.props.history.push('/dashboard/client-count')}>
                                        {dashboard.clientCountThisWeek || 0}
                                    </div>
                                    <div>@</div>
                                    <div>
                                        <Money amount={dashboard.avgBillThisWeek} decimalPlaces={2} />
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                <div className="dashboard-block dashboard-col-3">
                    <span className="dashboard-title">
                        Taken This Month:
                    </span>
                    <div className="panel">
                        <div className="panel-body">
                            <span className="dashboard-figure">
                                <Money amount={dashboard.totalSalesThisMonth} decimalPlaces={0} />
                            </span>

                            <div className="dashboard-client-figures">
                                <div>
                                    <div><span className="desktop-only">Client Count</span><span className="mobile-only">CC</span></div>
                                    <div></div>
                                    <div><span className="desktop-only">Average Bill</span><span className="mobile-only">AvB</span></div>
                                </div>
                                <div>
                                    <div className="clickable" onClick={() => this.props.history.push('/dashboard/client-count')}>
                                        {dashboard.clientCountThisMonth || 0}
                                    </div>
                                    <div>@</div>
                                    <div>
                                        <Money amount={dashboard.avgBillThisMonth} decimalPlaces={2} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        );
    }

    renderRow2() {
        const {
            dashboard
        } = this.state;

        return (
            <div className="dashboard-row dashboard-row-2">

                <div className="dashboard-block dashboard-col-1">
                    <span className="dashboard-title">
                        Projected This Week:
                    </span>
                    <div className="panel">
                        <div className="panel-body">
                            <span className="dashboard-figure">
                                <Money amount={dashboard.projectedSales} decimalPlaces={0} />
                            </span>

                            <div className="dashboard-client-figures">
                                <div>
                                    <div><span className="desktop-only">Client Count</span><span className="mobile-only">CC</span></div>
                                    <div></div>
                                    <div><span className="desktop-only">Average Bill</span><span className="mobile-only">AvB</span></div>
                                </div>
                                <div>
                                    <div className="clickable" onClick={() => this.props.history.push('/dashboard/client-count')}>
                                        {dashboard.projectedClientCount || 0}
                                    </div>
                                    <div>@</div>
                                    <div><Money amount={dashboard.projectedAvgBill} decimalPlaces={2} /></div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                <div className="dashboard-block dashboard-col-3">
                    <span className="dashboard-title">
                        Weekly Target:
                    </span>
                    <div className="panel">
                        <div className="panel-body">
                            <span className="dashboard-figure">
                                <Money amount={dashboard.targetSalesThisWeek} decimalPlaces={0} />
                            </span>

                            <div className="dashboard-client-figures">
                                <div>
                                    <div><span className="desktop-only">Client Count</span><span className="mobile-only">CC</span></div>
                                    <div></div>
                                    <div><span className="desktop-only">Average Bill</span><span className="mobile-only">AvB</span></div>
                                </div>
                                <div>
                                    <div className="clickable" onClick={() => this.props.history.push('/dashboard/client-count')}>
                                        {dashboard.targetClientCount || 0}
                                    </div>
                                    <div>@</div>
                                    <div><Money amount={dashboard.targetAvgBill} decimalPlaces={2} /></div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

            </div>
        );
    }

    renderRow3() {
        const {
            dashboard
        } = this.state;
        
        return (
            <div className="dashboard-row dashboard-row-3">

                <div className="dashboard-block dashboard-col-1">
                    <span className="dashboard-title">
                        Perfect Client:
                    </span>
                    <div className="panel">
                        <div className="panel-body">

                            <div className="dashboard-inner-header">

                                <PerfectClientRatingHearts dashboard={dashboard} />
                                
                            </div>

                            {this.renderPerfectClientRow(1)}
                            {this.renderPerfectClientRow(2)}
                            {this.renderPerfectClientRow(3)}
                            {this.renderPerfectClientRow(4)}
                            {this.renderPerfectClientRow(5)}

                        </div>
                    </div>
                </div>

                <div className="dashboard-block dashboard-col-2">
                    <span className="dashboard-title">
                        Weekly Retail:
                    </span>
                    <div className="panel">
                        <div className="panel-body">
                            <div className="dashboard-inner-header split-header">
                                <div>Retail<br/>Clients:</div>
                                <div>Average<br/>Retail Sale:</div>
                            </div>

                            <div className="dashboard-stats">
                                <div className="dashboard-stats-header">
                                    Current:
                                </div>
                                <div>
                                    <div>{dashboard.weeklyRetailClientCount}</div>
                                    <div>@</div>
                                    <div><Money amount={dashboard.weeklyRetailAvgBill} decimalPlaces={2} /></div>
                                </div>
                            </div>
                            
                            <div className="dashboard-stats">
                                <div className="dashboard-stats-header">
                                    Target:
                                </div>
                                <div>
                                    <div>—</div>
                                    <div>@</div>
                                    <div>—</div>
                                </div>
                            </div>

                            {/*
                            <button className="button" onClick={() => this.props.history.push('/stock/manager')}>
                                Check Stockroom
                            </button>
                            */}

                        </div>
                    </div>
                </div>

                <div className="dashboard-block dashboard-col-3">
                    <span className="dashboard-title">
                        Salon Drivers:
                    </span>
                    <div className="panel">
                        <div className="panel-body">
                            <div className="dashboard-inner-header split-header">
                                <div>Active<br />Clients:</div>
                                <div>Average<br />Bill:</div>
                                <div>Visit<br />Frequency:</div>
                            </div>

                            <div className="dashboard-stats">
                                <div className="dashboard-stats-header">
                                    Current:
                                </div>
                                <div>
                                    <div>{dashboard.numActiveClients}</div>
                                    <div>/</div>
                                    <div><Money amount={dashboard.actualAvgBill} decimalPlaces={2} /></div>
                                    <div>/</div>
                                    <div>{dashboard.actualAvgVisitFreq}</div>
                                </div>
                            </div>

                            <div className="dashboard-stats">
                                <div className="dashboard-stats-header">
                                    Target:
                                </div>
                                <div>
                                    <div>—</div>
                                    <div>/</div>
                                    <div>—</div>
                                    <div>/</div>
                                    <div>—</div>
                                </div>
                            </div>

                            {/*
                            <div className="dashboard-stats">
                                <div>
                                    <div>Target Active Clients</div>
                                    <div></div>
                                    <div>Target Current Clients</div>
                                </div>
                                <div>
                                    <div>{dashboard.targetActiveClientCount || 0}</div>
                                    <div>/</div>
                                    <div>{dashboard.targetClientCount || 0}</div>
                                </div>
                            </div>
                            <div className="dashboard-stats">
                                <div>
                                    <div>Target Avg. Bill</div>
                                    <div></div>
                                    <div>Current Avg. Bill</div>
                                </div>
                                <div>
                                    <div><Money amount={dashboard.targetavgbill} decimalPlaces={2} /></div>
                                    <div>/</div>
                                    <div><Money amount={dashboard.actualavgbill} decimalPlaces={2} /></div>
                                </div>
                            </div>
                            <div className="dashboard-stats">
                                <div>
                                    <div>Target Visit Frequency</div>
                                    <div></div>
                                    <div>Current Visit Frequency</div>
                                </div>
                                <div>
                                    <div>{dashboard.targetAvgVisitFreq || 0}</div>
                                    <div>/</div>
                                    <div>{dashboard.actualAvgVisitFreq || 0}</div>
                                </div>
                            </div>
                            */}
                        </div>
                    </div>
                </div>

            </div>
        );
    }

    render() {
        if (this.state.isLoading) {
            return (<Loader />);
        }

        return (<>
            <div className="dashboard-content-overview">
                {this.renderRow1()}
                {this.renderRow2()}
                {this.renderRow3()}
            </div>

            <ReactTooltip
                html={true}
                place="right"
                type="info"
                effect="float"
                delayShow={100}
            />
        </>);
    }
}

export default withRouter(DashboardOverview);