// Libs
import React from 'react';
import moment from 'moment';
import Modal from 'react-bootstrap/Modal';

// Services & Helpers
import DiaryService from 'services/DiaryService';
import DateHelpers from 'helpers/DateHelpers';

// Components
import Loader from 'components/reusable/Loader';
import WalkInPurchase from '../diary/retail2/WalkInPurchase';

//--------------------------------------------------------------------------------------

export default class WalkInPurchaseModal extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			isLoading: true,
			buttons: [],
		};
	}

	show(opts) {
		return new Promise((resolve, reject) => {
			this.resolve = resolve;
			this.setState({
				isLoading: true,
				isOpen: true,
				retailPurchaseID: opts.retailPurchaseID,
			}, () => {
				this.load();
			});
		});
	}

	async load() {
		this.setState({
			isLoading: false,
		});
	}

	setButtons(buttons) {
		this.setState({ buttons });
	}

	close() {
		this.setState({ isOpen: false }); 
		this.resolve(); 
	}

	getButtonclass(button) {
		let className = '';
		if (button.color) {
			className += `button button-${button.color} mr-auto`;
		} else {
			className += 'button ' + (button.primary ? 'button-primary mr-auto' : 'button button-secondary ml-auto');
		}
		className += ((button.active ? button.active() : true) ? '' : ' disabled');
		return className ;
	}

	//---------------------------------------------------------------------

	render() {
		const {
			isOpen,
			isLoading,
			buttons
		} = this.state;

		if (!isOpen) {
			return null;
		}

		return (
			<Modal
				show
				className="edit-retail-purchase-modal"
				onHide={_ => this.close()}
			>
				<Modal.Header closeButton>
					<Modal.Title>
						Walk-in Purchase
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>

					{this.renderInner()}

				</Modal.Body>
				<Modal.Footer>
					{!isLoading && buttons.map(button =>
						<button type="button"
							className={this.getButtonclass(button)}
							onClick={_ => button.click()}
							key={button.key}
						>
							{button.text}
						</button>
					)}
				</Modal.Footer>
			</Modal>
		);
	}

	renderInner() {
		const {
			isLoading,
			retailPurchaseID,
		} = this.state;

		if (isLoading) {
			return (<Loader />);
		}

		return (<>
			<WalkInPurchase
				isStandalone={true}
				retailPurchaseID={retailPurchaseID}
				onButtons={buttons => this.setButtons(buttons)}
				onClose={_ => this.close()}
			/>
		</>);
	}
}
