// Libs
import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/en-gb';
import * as $ from 'jquery';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';

// Services & Helpers
import DiaryService from 'services/DiaryService';
import YPTService from 'services/YPTService';
import GlobalStateService from 'services/GlobalStateService';
import SalonService from 'services/SalonService';
import BootboxHelper from 'helpers/BootboxHelper';
import TextHelpers from 'helpers/TextHelpers';

// Components                   
import FloomlyComponent             from 'components/FloomlyComponent';
import Loader                   from 'components/reusable/Loader';
import MindsetSettingSlide      from 'components/pages/your-profit-target/MindsetSettingSlide';
import TutorialSlide            from 'components/pages/your-profit-target/TutorialSlide';
import InputProfitSlide         from 'components/pages/your-profit-target/InputProfitSlide';
import InputWageSlide           from 'components/pages/your-profit-target/InputWageSlide';
import InputStockSlide          from 'components/pages/your-profit-target/InputStockSlide';
import InputAnnualExpensesSlide from 'components/pages/your-profit-target/InputAnnualExpensesSlide';
import BusinessCategoriesSlide  from 'components/pages/your-profit-target/BusinessCategoriesSlide';
import AnnualTargetSlide        from 'components/pages/your-profit-target/AnnualTargetSlide';
import AdjustTargetSlide        from 'components/pages/your-profit-target/AdjustTargetSlide';
import SuccessLadderSlide       from 'components/pages/your-profit-target/SuccessLadderSlide';

// Images
import heartImage from 'images/perfect-client/heart.png';

//-------------------------------------------------------------------------------------------------------------------

class YourProfitTargetPage extends FloomlyComponent {

    constructor(props) {
        super(props);

        this.updateField = this.updateField.bind(this);
        this.updateTargetStepField = this.updateTargetStepField.bind(this);
        this.selectTargetStep = this.selectTargetStep.bind(this);
        this.updateBusinessCategoryField = this.updateBusinessCategoryField.bind(this);
        this.save = this.save.bind(this);

        // Init state
        this.state = {
            isLoading: true,
            slideIndex: parseInt(props.match.params.slideIndex) || 0
        };
    }

    componentDidMount() {
        document.body.classList.add('full-screen-page');

        this.load();
    }

    async load() {
        // Load SFC results
        const response = await YPTService.loadLatest();
        let salonFocusResult = response;
        if (!salonFocusResult) {
            salonFocusResult = { }; // Create a blank one
        }

        // Load currencies and select first currency if none selected
        const currencies = await SalonService.listCurrencies();
        const clientInfo = GlobalStateService.getValue('clientInfo');
        if (!clientInfo.currencyID) {
            clientInfo.currencyID = currencies[0].currencyID;
            GlobalStateService.setValue('clientInfo', clientInfo);
            SalonService.setCurrency(clientInfo.currencyID);
        }
        
        // Update UI
        this.setState({
            isLoading: false,
            salonFocusResult: salonFocusResult,
            currencies: currencies,
            clientInfo: clientInfo
        });
    }

    componentWillUnmount() {
        document.body.classList.remove('full-screen-page');
    }

    goToPrevSlide() {
        const newSlideIndex = this.state.slideIndex - 1;
        this.setState({ slideIndex: newSlideIndex });
        this.props.history.push('/your-profit-target/' + newSlideIndex);
    }

    goToNextSlide() {
        const newSlideIndex = this.state.slideIndex + 1;
        this.setState({ slideIndex: newSlideIndex });
        this.props.history.push('/your-profit-target/' + newSlideIndex);
    }

    onChangeSlide(newSlideIndex) {
        this.props.history.push('/your-profit-target/' + newSlideIndex);
        this.setState({ slideIndex: newSlideIndex });

        //// Focus first text box in the new slide
        //clearTimeout(this.focusTimeout);
        //this.focusTimeout = setTimeout(() => {
        //    const inputsJQ = window.$('.slide.selected input');
        //    if (inputsJQ.length > 0) {
        //        window.$(inputsJQ[0]).focus();
        //    }
        //}, 750);
    }

    complete() {
        YPTService.complete();
        this.props.history.push('/dashboard');
    }

    async updateField(field, value) {
        const salonFocusResult = { ...this.state.salonFocusResult }; 

        switch (field) {
            case 'desiredProfit':
            case 'annualExpenses':
                value = Number(('' + (value || '')).replace(/,/, ''));
                break;
            case 'taxRate':
                if (!salonFocusResult.isAdvancedTaxMode) {
                    salonFocusResult.businessCategories = [...salonFocusResult.businessCategories];
                    salonFocusResult.businessCategories.forEach(bc => {
                        bc.taxRate = value;
                    });
                }
                break;
        }
        salonFocusResult[field] = value;
        salonFocusResult.targetSteps = null;

        this.updateTotals(salonFocusResult);
        await this.setState({ salonFocusResult });

        switch (field) {
            case 'isAdvancedTaxMode':
            case 'defaultDashboardType':
                this.save();
                break;
        }
    }

    updateBusinessCategoryField(businessCategoryID, field, value) {
        const salonFocusResult = { ...this.state.salonFocusResult };
        salonFocusResult.businessCategories.forEach(bc => {
            if (bc.businessCategoryID == businessCategoryID) {
                bc[field] = value;
            }
        });
        salonFocusResult.targetSteps = null;
        this.updateTotals(salonFocusResult);
        this.setState({ salonFocusResult: salonFocusResult });
    }

    updateTargetStepField(stepNum, field, value) {
        const salonFocusResult = { ...this.state.salonFocusResult };
        value = Number(value) || '';
        salonFocusResult.targetSteps = [...salonFocusResult.targetSteps];
        salonFocusResult.targetSteps.forEach(targetStep => {
            if (targetStep.stepNum == stepNum) {
                targetStep[field] = value;
                const taxProportion = targetStep.salesGross / targetStep.salesNet;

                if (field == 'clientCount' || field == 'averageBillGross') {
                    targetStep.salesGross = TextHelpers.roundCurrency(targetStep.clientCount * targetStep.averageBillGross, 0) || 0
                    targetStep.salesNet = targetStep.salesGross / taxProportion;
                } else if (field == 'salesGross' && targetStep.averageBillGross > 0) {
                    targetStep.clientCount = Math.round(targetStep.salesGross / targetStep.averageBillGross) || 0;
                    targetStep.averageBillGross = TextHelpers.roundCurrency(targetStep.salesGross / targetStep.clientCount, 2) || 0;
                    targetStep.averageBillNet = TextHelpers.roundCurrency(targetStep.salesNet / targetStep.clientCount, 2) || 0;
                }
            }
        });
        this.setState({ salonFocusResult: salonFocusResult });
    }

    async selectTargetStep(index) {
        const salonFocusResult = { ...this.state.salonFocusResult };
        salonFocusResult.targetSteps = [...salonFocusResult.targetSteps];
        salonFocusResult.targetSteps.forEach(ts => {
            ts.isSelected = false;
        });
        salonFocusResult.targetSteps[index].isSelected = true;
        await this.setStateAsync({ salonFocusResult: salonFocusResult });
        this.save();
    }

    async save() {
        const salonFocusResult = { ...this.state.salonFocusResult };
        const response = await YPTService.save(salonFocusResult);
        const newID = response;
        if (!salonFocusResult.salonFocusResultID) {
            this.updateField('salonFocusResultID', newID);
        }
    }

    updateTotals(salonFocusResult) {
        // Target sales (Slide 8)
        salonFocusResult.targetAnnualSalesNet = (salonFocusResult.desiredProfit + salonFocusResult.annualExpenses) / (1 - (salonFocusResult.wagePercentage + salonFocusResult.stockPercentage))

        // Add tax to get gross amount
        let tax = 0;
        salonFocusResult.targetAnnualSalesGross = 0;
        if (salonFocusResult.isAdvancedTaxMode) {
            salonFocusResult.businessCategories.forEach(businessCategory => {
                tax += salonFocusResult.targetAnnualSalesNet * (businessCategory.sfcRatio || 0) * (businessCategory.sfcTaxRate || 0);
            });
        } else {
            tax = salonFocusResult.targetAnnualSalesNet * (salonFocusResult.taxRate || 0);
        }
        salonFocusResult.targetAnnualSalesGross = salonFocusResult.targetAnnualSalesNet + tax;
        const taxProportion = (salonFocusResult.targetAnnualSalesGross / salonFocusResult.targetAnnualSalesNet);
        salonFocusResult.targetAverageWeeklySalesNet = salonFocusResult.targetAnnualSalesNet / 52;
        salonFocusResult.targetAverageWeeklySalesGross = salonFocusResult.targetAnnualSalesGross / 52;

        // Slide 9
        if (typeof (salonFocusResult.clientCountOrAvgBillBias) == 'undefined') {
            salonFocusResult.clientCountOrAvgBillBias = 0.5;
        }
        salonFocusResult.currentWeeklyAvgSales = (Number(salonFocusResult.currentWeeklyAvgClientCount) || 0) * (Number(salonFocusResult.currentWeeklyAvgBill) || 0);
        const averageWeeklySalesDelta = (salonFocusResult.targetAverageWeeklySalesGross - salonFocusResult.currentWeeklyAvgSales) || 0;
        if (salonFocusResult.currentWeeklyAvgSales > 0) {
            salonFocusResult.increaseNeeded = (averageWeeklySalesDelta / salonFocusResult.currentWeeklyAvgSales) || 0;
        } else {
            salonFocusResult.increaseNeeded = 0;
        }
        salonFocusResult.targetWeeklyAvgClientCount = (Math.round(salonFocusResult.currentWeeklyAvgClientCount * (1 + salonFocusResult.increaseNeeded * (1 - salonFocusResult.clientCountOrAvgBillBias)))) || 0;
        if (salonFocusResult.targetWeeklyAvgClientCount > 0) {
            salonFocusResult.targetWeeklyAvgBillGross = (Math.round((salonFocusResult.targetAverageWeeklySalesGross / salonFocusResult.targetWeeklyAvgClientCount) * 100) / 100) || 0;
        } else {
            salonFocusResult.targetWeeklyAvgBillGross = 0;
        }
        salonFocusResult.targetWeeklyAvgBillNet = (salonFocusResult.targetWeeklyAvgBillGross) || 0; // TODO

        // Slide 10
        if (!salonFocusResult.targetSteps || salonFocusResult.targetSteps.length == 0) {
            const numMidPoints = 3;
            salonFocusResult.targetSteps = [];

            // Add final step (target)
            salonFocusResult.targetSteps.push({
                stepNum: numMidPoints + 1,
                clientCount: (salonFocusResult.targetWeeklyAvgClientCount || 0),
                averageBillGross: (salonFocusResult.targetWeeklyAvgBillGross || 0),
                averageBillNet: (salonFocusResult.targetWeeklyAvgBillNet || 0),
                salesGross: TextHelpers.roundCurrency(salonFocusResult.targetAverageWeeklySalesGross, 2) || 0,
                salesNet: TextHelpers.roundCurrency(salonFocusResult.targetAverageWeeklySalesNet, 2) || 0
            });

            // Add midpoints
            for (var i = numMidPoints - 1; i >= 0; i--) {
                const proportion = (1 / (numMidPoints + 1) * (i + 1));
                const clientCount = salonFocusResult.currentWeeklyAvgClientCount + (salonFocusResult.targetWeeklyAvgClientCount - salonFocusResult.currentWeeklyAvgClientCount) * proportion;
                const avgBill = salonFocusResult.currentWeeklyAvgBill + (salonFocusResult.targetWeeklyAvgBillGross - salonFocusResult.currentWeeklyAvgBill) * proportion;
                const midPoint = {
                    isSelected: (i == 0),
                    stepNum: 1 + i,
                    clientCount: Math.round(clientCount) || 0,
                    averageBillGross: (Math.round(avgBill * 100) / 100) || 0
                };
                midPoint.averageBillNet = midPoint.averageBillGross / taxProportion;
                midPoint.salesGross = TextHelpers.roundCurrency(midPoint.clientCount * midPoint.averageBillGross, 0);
                midPoint.salesNet = midPoint.salesGross / taxProportion;
                salonFocusResult.targetSteps.push(midPoint);
            }

            // Add step 1 (current)
            const currentStep = {
                stepNum: 0,
                clientCount: (salonFocusResult.currentWeeklyAvgClientCount || 0),
                averageBillGross: (salonFocusResult.currentWeeklyAvgBill || 0),
                salesGross: (salonFocusResult.currentWeeklyAvgSales || 0)
            };
            currentStep.averageBillNet = currentStep.averageBillGross / taxProportion;
            currentStep.salesNet = currentStep.salesGross / taxProportion;
            salonFocusResult.targetSteps.push(currentStep);
        }
    }

    async confirmSkip() {
        const skip = await BootboxHelper.confirm('Are you sure you want to Skip \'Your Profit Target\'?');
        if (skip) {
            YPTService.complete();
            this.props.history.push('/');
        }
    }

    setCurrency(currencyID) {
        const clientInfo = { ...this.state.clientInfo };
        clientInfo.currencyID = currencyID;
        GlobalStateService.setValue('clientInfo', clientInfo);
        SalonService.setCurrency(currencyID);
        this.setState({
            clientInfo: clientInfo
        });
    }

    //--------------------------------------------------------------------------------------------------------------------
    //  Render
    //--------------------------------------------------------------------------------------------------------------------

    render() {
        const {
            isLoading,
            slideIndex,
            salonFocusResult,
            currencies,
            clientInfo
        } = this.state;

        if (isLoading) {
            return (<Loader />);
        }

        return (<>
            <div className="page-content your-profit-target-page">
                
                <Carousel
                    selectedItem={slideIndex}
                    onChange={index => this.onChangeSlide(index)}
                    renderIndicator={(clickHandler, isSelected, index, label) =>
                        <li
                            className={(isSelected ? 'selected' : '')}
                            value={index}
                            role="button"
                            tabIndex={index}
                            onClick={clickHandler}
                        >
                            <img src={heartImage} />
                        </li>
                    }
                    showStatus={false}
                >
                    <MindsetSettingSlide
                        salonFocusResult={salonFocusResult}
                        onContinue={e => this.goToNextSlide()}
                        onSkip={e => this.confirmSkip()}
                    />
                    <TutorialSlide
                        salonFocusResult={salonFocusResult}
                        onContinue={e => this.goToNextSlide()}
                        onBack={e => this.goToPrevSlide()}
                        onSkip={e => this.confirmSkip()}
                    />
                    <InputProfitSlide
                        salonFocusResult={salonFocusResult}
                        onContinue={e => this.goToNextSlide()}
                        onBack={e => this.goToPrevSlide()}
                        onSkip={e => this.confirmSkip()}
                        onChange={this.updateField}
                        onSave={this.save}
                    />
                    <InputWageSlide
                        salonFocusResult={salonFocusResult}
                        onContinue={e => this.goToNextSlide()}
                        onBack={e => this.goToPrevSlide()}
                        onSkip={e => this.confirmSkip()}
                        onChange={this.updateField}
                        onSave={this.save}
                    />
                    <InputStockSlide
                        salonFocusResult={salonFocusResult}
                        onContinue={e => this.goToNextSlide()} 
                        onBack={e => this.goToPrevSlide()}
                        onChange={this.updateField}
                        onSave={this.save}
                    />
                    <InputAnnualExpensesSlide
                        salonFocusResult={salonFocusResult}
                        onContinue={e => this.goToNextSlide()} 
                        onBack={e => this.goToPrevSlide()}
                        onSkip={e => this.confirmSkip()}
                        onChange={this.updateField}
                        onSave={this.save}
                    />
                    <BusinessCategoriesSlide
                        salonFocusResult={salonFocusResult}
                        onContinue={e => this.goToNextSlide()} 
                        onBack={e => this.goToPrevSlide()}
                        onSkip={e => this.confirmSkip()}
                        onChange={this.updateField}
                        onBusinessCategoryChange={this.updateBusinessCategoryField}
                        onSave={this.save}
                    />
                    <AnnualTargetSlide
                        salonFocusResult={salonFocusResult}
                        onContinue={e => this.goToNextSlide()} 
                        onBack={e => this.goToPrevSlide()}
                        onSkip={e => this.confirmSkip()}
                        onChange={this.updateField}
                    />
                    <AdjustTargetSlide
                        salonFocusResult={salonFocusResult}
                        onContinue={e => this.goToNextSlide()} 
                        onBack={e => this.goToPrevSlide()}
                        onSkip={e => this.confirmSkip()}
                        onChange={this.updateField}
                        onSave={this.save}
                    />
                    <SuccessLadderSlide
                        salonFocusResult={salonFocusResult}
                        onContinue={e => this.complete()}
                        onBack={e => this.goToPrevSlide()}
                        onSkip={e => this.confirmSkip()}
                        onChange={this.updateTargetStepField}
                        onSelect={this.selectTargetStep}
                        onSave={this.save}
                    />
                </Carousel>

                {/* Currency selector */}

                <div className="currency-selector">

                    <select value={clientInfo.currencyID} onChange={e => this.setCurrency(e.target.value)}>
                        {currencies.map(c =>
                            <option key={c.currencyID} value={c.currencyID}>
                                {c.name}
                            </option>
                        )}
                    </select>

                </div>

            </div>

            {/*<script src="https://player.vimeo.com/api/player.js"></script>*/}
        </>);
    }

};

export default withRouter(YourProfitTargetPage);