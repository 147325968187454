// Libs
import React from 'react';
import { ContextMenu, MenuItem } from 'react-contextmenu';
import { showMenu, hideMenu } from 'react-contextmenu/modules/actions'

// Services & helpers
import TextHelpers from 'helpers/TextHelpers';
import MergeFields from 'services/MergeFields';

// Components
import FloomlyComponent from 'components/FloomlyComponent';
import FormService from '../../services/FormService';

//-------------------------------------------------------------------------------------------------------------------

class InsertDynamicFieldButton extends FloomlyComponent {

    constructor(props) {
        super(props);
        this.state = {
            id: `${Math.random()}`
        };
    }

    async componentDidMount() {
        const forms = await FormService.listFormTypes();
        this.setState({ forms });
    }

    openMenu(e) {
        const buttonPos = e.target.getBoundingClientRect();
        showMenu({
            position: { x: buttonPos.left - 130, y: buttonPos.top + buttonPos.height },
            target: e.target,
            id: `dynamic-field-menu-${this.state.id}`
        });
    }

    onSelect(e, value) {
        e.preventDefault();
        e.stopPropagation();
        const field = this.props.fieldRef.current;
        let inputValue = (field.value || '');
        const selectionPos = field.selectionStart;
        const oldLength = inputValue.length;
        inputValue = TextHelpers.insertText(inputValue, selectionPos, value, true);
        const addedLength = inputValue.length - oldLength;
        field.value = inputValue;
        field.focus();
        field.selectionEnd = selectionPos + addedLength;
        hideMenu();
        if (this.props.onSelect) {
            this.props.onSelect(inputValue);
        }
    }

    render() {
        const {
            id,
            forms
        } = this.state;
        const {
            showAppointmentRelated,
            isSMS,
            showWaitingApptRelated,
            showFormRelated,
        } = this.props;

        const fields = [];
        const mergeTags = MergeFields.mergeTags;
        const apptMergeTags = MergeFields.appointmentMergeTags;

        mergeTags.map(mt => {
            if (!fields.includes(mt.name)) {
                fields.push({ label: mt.name, value: mt.value });
            }
        })

        if (showAppointmentRelated) {
            apptMergeTags.map(mt => {
                if (!fields.some(f => f.label == mt.name)) {
                    if (!mt.name.includes('Waiting') && !mt.name.includes('Summary')) {
                        fields.push({ label: mt.name, value: mt.value });
                    }
                    if (!isSMS) {
                        if (!mt.name.includes('Waiting') && mt.name.includes('Summary'))
                            fields.push({ label: mt.name, value: mt.value });
                    }
                }
            })
        }

        if (showWaitingApptRelated) {
            apptMergeTags.map(mt => {
                if (!fields.some(f => f.label == mt.name)) {
                    if (mt.name.includes('Waiting')) {
                        fields.push({ label: mt.name, value: mt.value });
                    }
                }
            })
        }

        if (showFormRelated) {
            for (var i = 0; i < forms?.length; i++) {
                const formName = TextHelpers.simplifyString(forms[i].name);
                fields.push({ label: 'Form » ' + forms[i].name, value: '{{ Form.FormLinks.' + formName + ' }}' });
            }
        }

        return (<>

            <button className="button button-small button-secondary insert-dynamic-field-button" onClick={e => this.openMenu(e)}>
                Insert...
            </button>

            <ContextMenu id={`dynamic-field-menu-${id}`}>
                {fields.map(f =>                    
                    <MenuItem key={f.value} onClick={e => this.onSelect(e, f.value)}>
                        {f.label}
                    </MenuItem>
                )}
            </ContextMenu>

        </>);
    }
}

export default InsertDynamicFieldButton;