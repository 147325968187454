// Libs
import React from 'react';
import { withRouter } from 'react-router-dom';
import Select from 'react-select';

// Services & Helpers
import PackageService from 'services/PackageService';
import ServiceService from 'services/ServiceService';
import ServiceCategoryService from 'services/ServiceCategoryService';
import TaxRateService from 'services/TaxRateService';
import FormHelper from 'helpers/FormHelper';
import BootboxHelper from 'helpers/BootboxHelper';
import TextHelpers from 'helpers/TextHelpers';

// Components
import FloomlyComponent from 'components/FloomlyComponent';
import InfoBar from 'components/layout/InfoBar'
import Loader from 'components/reusable/Loader';
import SuperTable from 'components/reusable/SuperTable';
import Money from 'components/reusable/Money';

//-------------------------------------------------------------------------------------------------------------------

class SettingsPackageEdit extends FloomlyComponent {

    constructor(props) {
        super(props);

        // Init state
        this.state = {
            isLoading: true,
            servicePackage: null
        };

        this.updateField = this.updateField.bind(this);
        
        this.formHelper = new FormHelper({
            fields: {
                name: {
                    label: 'Name (if left blank, the package name will default to the service name(s) e.g.: Half Head Highlights + Toner + Blow Dry)'
                },
                description: { label: 'Description (this will show on your Online Booking)', type: 'multiline-text' },
                pricingType: {
                    label: 'Pricing type',
                    type: 'single-select',
                    getOptions: () => [
                        { id: 'services', name: 'Service pricing' },
                        { id: 'fixed', name: 'Custom pricing' },
                        { id: 'discountPercentage', name: 'Discount percentage' },
                        { id: 'free', name: 'Free' }
                    ]
                },
                standardPrice: {
                    label: 'Price',
                    isDisabled: true
                },
                fixedPrice: {
                    label: 'Custom price',
                    type: 'currency'
                },
                discountPercentage: {
                    label: 'Discount %',
                    type: 'number'
                },
                taxRateID: {
                    label: 'Tax Rate',
                    type: 'single-select',
                    blankText: '(No Tax Rate)',
                    getOptions: () => this.state.taxRates
                },
                serviceIDs: {
                    label: 'Services',
                    type: 'multi-select',
                    blankText: '(No services)',
                    getOptions: () => this.state.services.map(sc => ({
                        id: sc.serviceID,
                        name: sc.name
                    }))
                },
                serviceCategoryIDs: {
                    label: 'Please select the Service Categories where you would like this Package to show',
                    type: 'multi-select',
                    blankText: '(No categories)',
                    creatable: {
                        formatCreateLabel: (name) => 'Add category: ' + name,
                        onCreateOption: (option) => {
                            return new Promise((resolve) => {
                                const serviceCategories = [...this.state.serviceCategories];
                                const newOption = {
                                    id: `new${serviceCategories.length}-${option.name}`,
                                    name: option.name
                                };
                                serviceCategories.push(newOption);
                                this.setState({
                                    serviceCategories
                                }, () => {
                                    resolve(newOption);
                                });
                            });
                        }
                    },
                    getOptions: () => this.state.serviceCategories.map(sc => ({
                        id: sc.serviceCategoryID || sc.id, // TODO fix this
                        name: sc.name
                    }))
                },
                isAvailableOnlineBooking: {
                    checkboxLabel: 'Available for Online Booking',
                    type: 'checkbox'
                }
            },
            getValue: (fieldName, fieldInfo) => {
                const { servicePackage } = this.state;
                if (fieldName == 'fixedPrice') {
                    return servicePackage.fixedPriceOrDiscountProportion;
                } else if (fieldName == 'discountPercentage') {
                    return (servicePackage.fixedPriceOrDiscountProportion ? servicePackage.fixedPriceOrDiscountProportion * 100 : null);
                } else if (fieldName == 'standardPrice') {
                    const { minPrice, maxPrice } = this.getMinMaxPrice();
                    return (minPrice == maxPrice ? '' : 'From ') +
                        TextHelpers.formatCurrencyNew(minPrice, { includeSymbol: true, numDP: 2, commas: true });
                }
                let value = servicePackage[fieldName];
                return value;
            },
            setValue: this.updateField
        });
    }

    componentDidMount() {
        this.load();
    }

    //--------------------------------------------------------------------------------------------------------------------

    async load() {
        const packageID = Number(this.props.match.params.packageID) || 0;
        const services = await ServiceService.list();
        //const businessCategories = await BusinessCategoryService.list();

        let servicePackage;
        if (packageID) {
            servicePackage = await PackageService.get(packageID);
        } else {
            const taxRates = await TaxRateService.list();
            const defaultTaxRate = taxRates.find(tr => tr.isDefaultServices);
            servicePackage = {
                taxRateID: (defaultTaxRate ? defaultTaxRate.id : null),
                serviceIDs: [],
                serviceCategoryIDs: [],
                pricingType: 'Services'
            };
        }

        // Load related entities
        const serviceCategories = await ServiceCategoryService.listSummary();
        const taxRates = await TaxRateService.list();

        // Update UI
        this.setState({
            isLoading: false,
            servicePackage,
            services,
            serviceCategories,
            taxRates
        });
    }

    goBack() {
        this.props.history.push('/settings/packages');
    }

    async trySave() {
        const { servicePackage } = this.state;

        if (servicePackage.serviceCategoryIDs.length == 0) {
            BootboxHelper.alert('Please select at least one category');
            return;
        }

        const servicesLookup = {};
        this.state.services.forEach(s => servicesLookup[s.serviceID] = s);

        if (servicePackage.isFixedPrice && servicePackage.fixedPrice > 0) {
            let anyZero = false;
            let allZero = true;
            if (servicePackage.serviceIDs) {
                servicePackage.serviceIDs.forEach(id => {
                    const pkgService = servicesLookup[id] || { serviceID: id, name: 'Unknown / deleted service' };
                    if ((pkgService.minPrice || 0) == 0 && (pkgService.maxPrice || 0) == 0) {
                        anyZero = true;
                    } else {
                        allZero = false;
                    }
                });
            }

            if (allZero) {
                BootboxHelper.alert('If the package has a fixed price, at least one of the services needs to have a standard price.');
                return;
            } else if (anyZero) {
                const confirm = await BootboxHelper.confirm(
                    'At least one service has no standard price. This may cause problems with reports later. Continue?'
                );
                if (!confirm) {
                    return;
                }
            }
        }
        this.save();
    }

    async save() {
        this.setState({ isLoading: true });
        const servicePackage = { ...this.state.servicePackage };
        await PackageService.save(servicePackage);
        this.goBack();
    }

    async confirmDelete() {
        const confirm = await BootboxHelper.confirm('Are you sure you want to delete this package?');
        if (confirm) {
            this.delete();
        }
    }

    async delete() {
        try {
            await PackageService.delete(this.state.servicePackage.packageID);
            this.props.history.replace('/settings/packages');
        } catch (e) {
            BootboxHelper.alert(e);
        }
    }

    updateField(fieldName, value, fieldInfo) {
        const servicePackage = { ...this.state.servicePackage };
        if (fieldName == 'discountPercentage') {
            fieldName = 'fixedPriceOrDiscountProportion';
            value = (value || 0) / 100;
        } else if (fieldName == 'fixedPrice') {
            fieldName = 'fixedPriceOrDiscountProportion';
        }
        else if (fieldName == 'pricingType') {
            if (value == 'discountPercentage') {
                servicePackage.fixedPriceOrDiscountProportion = 0;
            } 
            else if (value == 'free') {
                servicePackage.fixedPriceOrDiscountProportion = 1;
            } else if (value == 'fixed') {
                const { minPrice } = this.getMinMaxPrice();
                servicePackage.fixedPriceOrDiscountProportion = minPrice;
            } else {
                servicePackage.fixedPriceOrDiscountProportion = null;
            }
        }
        servicePackage[fieldName] = value;
        this.setState({ servicePackage });
    }

    rearrange(serviceID, newIndex) {
        const serviceIDs = [...this.state.servicePackage.serviceIDs];
        const currentIndex = serviceIDs.findIndex(id => id == serviceID);
        serviceIDs.splice(currentIndex, 1);
        serviceIDs.splice(newIndex, 0, serviceID);
        this.updateField('serviceIDs', serviceIDs);
    }

    addService(service) {
        const serviceIDs = [...this.state.servicePackage.serviceIDs];
        if (service.mustBeFirst) {
            const servicesLookup = {};
            this.state.services.forEach(s => servicesLookup[s.serviceID] = s);

            // Add before first non-"must be first" service
            let index;
            for (index = 0; index < serviceIDs.length; index++) {
                const otherService = servicesLookup[serviceIDs[index]];
                if (otherService && !otherService.mustBeFirst) {
                    break;
                }
            }
            serviceIDs.splice(index, 0, service.serviceID);
        } else {
            serviceIDs.push(service.serviceID);
        }
        this.updateField('serviceIDs', serviceIDs);
    }

    deleteService(serviceID) {
        const serviceIDs = [...this.state.servicePackage.serviceIDs];
        const index = serviceIDs.findIndex(id => id == serviceID);
        serviceIDs.splice(index, 1);
        this.updateField('serviceIDs', serviceIDs);
    }

    getMinMaxPrice() {
        const { servicePackage, services } = this.state;
        const servicesLookup = {};
        services.forEach(s => servicesLookup[s.serviceID] = s);
        let minPrice = 0, maxPrice = 0;
        if (servicePackage.serviceIDs) {
            servicePackage.serviceIDs.forEach(id => {
                const pkgService = servicesLookup[id] || { };
                minPrice += (pkgService.minPrice || 0);
                maxPrice += (pkgService.maxPrice || 0);
            });
        }
        return { minPrice, maxPrice };
    }

    getDiscountedPrice(service) {
        const { servicePackage } = this.state;
        let discountedMinPrice = service.minPrice;
        let discountedMaxPrice = service.maxPrice;
        switch (servicePackage.pricingType) {
            case 'fixed':
                const { minPrice } = this.getMinMaxPrice();
                if (minPrice > 0) {
                    discountedMinPrice = Number(servicePackage.fixedPriceOrDiscountProportion) * Number(service.minPrice || 0) / minPrice;
                    discountedMaxPrice = discountedMinPrice;
                } else {
                    discountedMinPrice = '???';
                    discountedMaxPrice = '???';
                }
                break;
            case 'discountPercentage':
                const proportion = Number(servicePackage.fixedPriceOrDiscountProportion) || 0;
                discountedMinPrice *= (1 - proportion);
                discountedMaxPrice *= (1 - proportion);
                break;
            case 'free':
                discountedMinPrice = 0;
                discountedMaxPrice = 0;
                break;
        }
        return { discountedMinPrice, discountedMaxPrice };
    }
     
    //--------------------------------------------------------------------------------------------------------------------
    //  Render
    //--------------------------------------------------------------------------------------------------------------------

    renderInfoBar() {
        const {
            servicePackage
        } = this.state;

        return (
            <InfoBar className="settings-info-bar" containerClassName="floating-on-mobile" sticky={true}>

                <div className="info-bar-panel-section">

                    <button className="button" onClick={() => this.goBack()}>
                        <span className="fa fa-chevron-left"></span>{' '}
                        Back
                    </button>

                </div>

                <div className="info-bar-panel-section info-bar-panel-section-text desktop-only">
                    {servicePackage.packageID ? servicePackage.name : 'New package'}
                </div>

                <div className="info-bar-panel-section ml-auto" style={{ whiteSpace: 'nowrap' }}>

                    {servicePackage.packageID &&
                        <button className="button" style={{ marginRight: 10 }} onClick={() => this.confirmDelete()}>
                            <span className="fa fa-times"></span>{' '}
                            Delete
                        </button>
                    }

                    <button className="button" onClick={() => this.trySave()}>
                        <span className="fa fa-check"></span>{' '}
                        Save
                    </button>

                </div>

            </InfoBar>
        );
    }

    render() {
        const {
            isLoading,
            servicePackage
        } = this.state;

        if (isLoading) {
            return (<Loader />);
        }

        const { minPrice, maxPrice } = this.getMinMaxPrice();
        const discountAmount = (Number(minPrice) - (Number(servicePackage.fixedPriceOrDiscountProportion) || 0));

        return (<>
            {this.renderInfoBar()}

            <div className="settings-page-main">
                <div className="panel">
                    <div className="panel-body">

                        <h2>Package Details</h2>

                        {this.formHelper.renderFormGroups([
                            'name',
                            'description'
                        ])}
                        {this.renderServicesTable(minPrice, maxPrice)}
                        
                        <h2>Pricing</h2>

                        {this.formHelper.renderFormGroups([
                            'pricingType',
                            (servicePackage.pricingType == 'services' && 'standardPrice'),
                            (servicePackage.pricingType == 'fixed' && 'fixedPrice'),
                        ])}
                        {servicePackage.pricingType == 'fixed' && <>
                            <div className="small-text-under-field">
                                Discount amount:{' '}
                                {minPrice != maxPrice && <>From </>}
                                {TextHelpers.formatCurrencyNew(discountAmount, { includeSymbol: true, numDP: 2, commas: true })}
                            </div>
                        </>}
                        {this.formHelper.renderFormGroups([
                            (servicePackage.pricingType == 'fixed' && 'taxRateID'),
                            (servicePackage.pricingType == 'discountPercentage' && 'discountPercentage')
                        ])}

                        <h2>Visibility</h2>

                        {this.formHelper.renderFormGroups([
                            'serviceCategoryIDs',
                            'isAvailableOnlineBooking'
                        ])}

                    </div>
                </div>

            </div>

        </>);
    }

    renderServicesTable(minPrice, maxPrice) {
        const { servicePackage, services } = this.state;
        const packageServices = [];
        const servicesLookup = {};
        services.forEach(s => servicesLookup[s.serviceID] = s);
        if (servicePackage.serviceIDs) {
            servicePackage.serviceIDs.forEach(id => {
                const pkgService = servicesLookup[id] || { serviceID: id, name: 'Unknown / deleted service' };
                packageServices.push(pkgService);
            });
        }
        packageServices.push({
            isTotal: true,
            name: 'Total',
            minPrice,
            maxPrice
        });
        //if (servicePackage.isFixedPrice) {
        //    packageServices.push({
        //        isTotal: true,
        //        name: 'Fixed package price',
        //        minPrice: (servicePackage.fixedPrice || 0),
        //        maxPrice: (servicePackage.fixedPrice || 0)
        //    });
        //}

        return (<>
            <Select
                className="select-box mb-3"
                classNamePrefix="select-box"
                options={services.filter(s => servicePackage.serviceIDs.indexOf(s.serviceID) == -1)}
                getOptionValue={ov => ov.serviceID}
                getOptionLabel={ov => ov.name}
                value={null}
                placeholder="Add a service to this package..."
                onChange={service => this.addService(service)}
            />

            {packageServices.length > 1 &&
                <SuperTable
                    className="settings-table package-services-table mb-3"
                    rows={packageServices}
                    keyAccessor={service => service.serviceID}
                    rowPropsAccessor={(row, rowIndex) => {
                        if (row.isTotal) return { className: 'total-row' };
                    }}
                    cols={{
                        name: { label: 'Services' },
                        price: {
                            label: 'Price',
                            getValue: (colInfo, service) => {
                                const { discountedMinPrice, discountedMaxPrice } = this.getDiscountedPrice(service);
                                return (<>
                                    {service.minPrice !== service.maxPrice && <>From </>}
                                    <span className={servicePackage.pricingType != 'services' ? 'old-price' : ''}>
                                        <Money amount={service.minPrice} />
                                    </span>
                                    {servicePackage.pricingType != 'services' &&
                                        <span className="ms-3">
                                            <Money amount={discountedMinPrice} />
                                        </span>
                                    }
                                </>);
                            }
                        },
                        actions: {
                            className: 'actions-col',
                            getValue: (colInfo, row) => 
                                !row.isTotal &&
                                    <button type="button" className="button remove-button" onClick={() => this.deleteService(row.serviceID)}>
                                        <span className="fa fa-times" />
                                    </button>
                        }
                    }}
                    reorder={{
                        enabled: true,
                        droppableID: 'services',
                        onDragEnd: (serviceID, destination) => {
                            this.rearrange(serviceID, destination.index);
                        }
                    }}
                />
            }

        </>);
    }
};

export default withRouter(SettingsPackageEdit);