import BaseService from 'services/BaseService';

//-------------------------------------------------------------------------------------------------------------------

export default class AccountService
    extends BaseService {

    static async getAnonSalonInfo(salonCode) {
        return BaseService.callAPI('account/get-anon-salon-info/' + salonCode);
    }

    static async getLoginDetails(loginMethod) {
        return BaseService.callAPI('account/get-login-details', {
            loginMethod,
            ignoreAPIErrorHandler: true
        });
    }

    // details: salonCode, salonPassword, pin
    static async logIn(details) {
        return BaseService.callAPI('account/log-in', { ...details, ignoreAPIErrorHandler: true });
    }

    static async logOutUser(details) {
        return BaseService.callAPI('account/log-out-user');
    }

    static async logOutSalon(details) {
        return BaseService.callAPI('account/log-out-salon');
    }

    static async registerUser(details) {
        return BaseService.callAPI('account/register-user', { ...details });
    }

    static async mobileLogIn(details) {
        return BaseService.callAPI('account/mobile-log-in', { ...details, ignoreAPIErrorHandler: true });
    }
}