import BaseService from 'services/BaseService';

//-------------------------------------------------------------------------------------------------------------------
export default class CommissionService
    extends BaseService {

    static async list() {
        return (await BaseService.callAPI('commission/list'));
    }

    static async getSplitCommissionStatus() {
        return BaseService.callAPI('commission/get-split-commission');
    }

    static async saveSplitCommissionStatus(isEnabled) {
        return BaseService.callAPI('commission/save-split-commission/'+ isEnabled);
    }

    static async get(id) {
        return BaseService.callAPI('commission/get/' + id);
    }

    static async save(commission) {
        return BaseService.callAPI('commission/save', commission);
    }

    static async delete(id) {
        return BaseService.callAPI('commission/delete/' + id);
    }
}