// Libs
import React from 'react';
import { withRouter } from 'react-router-dom';

// Services & Helpers
import ECommerceService from 'services/ECommerceService';
import FormHelper from 'helpers/FormHelper';
import BootboxHelper from 'helpers/BootboxHelper';

// Components
import FloomlyComponent from 'components/FloomlyComponent';
import InfoBar from 'components/layout/InfoBar'
import Loader from 'components/reusable/Loader';
import SuperTable from 'components/reusable/SuperTable';
import ListShopProductsModal from 'components/pages/settings/ecommerce/ListShopProductsModal';
import EditProductModal from 'components/pages/settings/ecommerce/EditProductModal';

//-------------------------------------------------------------------------------------------------------------------

class SettingsECommerceConnectionEdit extends FloomlyComponent {

    constructor(props) {
        super(props);

        this.listShopProductsModalRef = React.createRef();
        this.editProductModalRef = React.createRef();
        this.updateField = this.updateField.bind(this);

        // Init state
        this.state = {
            isLoading: true,
            eCommerceConnection: null,
            isLoadingProducts: true
        };

        // Init form helper
        this.formHelper = new FormHelper({
            fields: {
                name: {
                    label: 'Friendly name',
                    type: 'text'
                },
                isEnabled: {
                    label: 'Enabled',
                    type: 'checkbox'
                },
                shopType: {
                    label: 'Shop type',
                    type: 'single-select',
                    getOptions: () => [
                        { id: 'wooCommerce', name: 'WooCommerce' },
                        { id: 'shopifyPrivate', name: 'Shopify (private app)' }
                    ]
                },
                shopDomain: {
                    label: 'Shop domain',
                    type: 'text'
                },
                shopifyClientID: {
                    label: 'App client ID',
                    type: 'text'
                },
                shopifyClientSecret: {
                    label: 'App client secret',
                    type: 'text'
                },
                wooCommerceConsumerKey: {
                    label: 'Consumer key',
                    type: 'text'
                },
                wooCommerceConsumerSecret: {
                    label: 'Consumer secret',
                    type: 'text'
                }
            },
            getValue: (fieldName, fieldInfo) => this.state.eCommerceConnection[fieldName],
            setValue: this.updateField
        });
    }

    componentDidMount() {
        this.load();
    }

    //--------------------------------------------------------------------------------------------------------------------

    async load() {
        const id = Number(this.props.match.params.id) || 0;

        let eCommerceConnection;
        if (id) {
            eCommerceConnection = await ECommerceService.getConnection(id);
        } else {
            eCommerceConnection = {
                shopType: 'wooCommerce',
                isEnabled: true,
                name: 'My shop'
            }; 
        }

        // Update UI
        this.setState({
            isLoading: false,
            eCommerceConnection
        }, () => {

            // Load products
            if (eCommerceConnection.isConnected) {
                this.loadProducts();
            }

        });
    }

    async loadProducts() {
        this.setState({ isLoadingProducts: true });
        const products = await ECommerceService.listProducts(this.state.eCommerceConnection.id);
        this.setState({
            isLoadingProducts: false,
            products
        });
    }

    goBack() {
        this.props.history.push('/settings/ecommerce-connections');
    }

    async save() {
        this.setState({ isLoading: true });
        const eCommerceConnection = { ...this.state.eCommerceConnection };
        const { id } = await ECommerceService.saveConnection(eCommerceConnection);
        this.setState({ isLoading: false });
        return id;
    }

    async saveAndGoBack() {
        const id = await this.save();
        if (this.state.eCommerceConnection.id) {
            this.goBack();
        } else {
            this.props.history.replace(`/settings/ecommerce-connection/${id}`);
            this.load();
        }
    }

    updateField(fieldName, value, fieldInfo) {
        const eCommerceConnection = { ...this.state.eCommerceConnection };
        eCommerceConnection[fieldName] = value;
        this.setState({
            eCommerceConnection
        });
    }

    async confirmDelete() {
        const confirm = await BootboxHelper.confirm('Are you sure you want to delete this e-commerce connection?');
        if (confirm) {
            this.delete();
        }
    }

    async delete() {
        this.setState({ isLoading: true });
        await ECommerceService.deleteConnection(this.state.eCommerceConnection.id);
        this.props.history.replace('/settings/ecommerce-connections');
    }

    async connect() {
        await this.save();
        const { id } = this.state.eCommerceConnection;
        this.setState({ isLoading: true });
        const authURL = await ECommerceService.getAuthURL(id);
        window.location = authURL;
    }

    async showListShopProductsModal() {
        await this.listShopProductsModalRef.current.show({
            eCommerceConnectionID: this.state.eCommerceConnection.id
        });
        this.loadProducts();
    }

    async editProduct(id) {
        await this.editProductModalRef.current.show({
            eCommerceConnectionID: this.state.eCommerceConnection.id,
            id
        });
        this.loadProducts();
    }

    //--------------------------------------------------------------------------------------------------------------------
    //  Render
    //--------------------------------------------------------------------------------------------------------------------

    renderInfoBar() {
        const {
            eCommerceConnection
        } = this.state;

        return (
            <InfoBar className="settings-info-bar" containerClassName="floating-on-mobile" sticky={true}>

                <div className="info-bar-panel-section">

                    <button className="button" onClick={() => this.goBack()}>
                        <span className="fa fa-chevron-left"></span>{' '}
                        Back
                    </button>

                </div>

                <div className="info-bar-panel-section info-bar-panel-section-text desktop-only">
                    {eCommerceConnection.id ? (eCommerceConnection.name || eCommerceConnection.shopDomain) : 'New e-commerce connection'}
                </div>

                <div className="info-bar-panel-section ml-auto">

                    {!!eCommerceConnection.id &&
                        <button className="button" style={{ marginRight: 10 }} onClick={() => this.confirmDelete()}>
                            <span className="fa fa-times"></span>{' '}
                            Delete
                        </button>
                    }
                    <button className="button" onClick={() => this.saveAndGoBack()}>
                        <span className="fa fa-check"></span>{' '}
                        Save
                    </button>

                </div>
                
            </InfoBar>
        );
    }
    
    render() {
        const {
            isLoading,
            eCommerceConnection
        } = this.state;

        if (isLoading) {
            return (<Loader />);
        }

        return (<>
            {this.renderInfoBar()}

            <div className="settings-page-main">
                <div className="panel">
                    <div className="panel-body">

                        {!!eCommerceConnection.id && <>
                            {eCommerceConnection.isConnected ?
                                <div className="alert alert-success">
                                    Connection status: connected successfully
                                </div>
                                :
                                <div className="alert alert-danger">
                                    Connection status: not connected

                                    {eCommerceConnection.shopType == 'shopifyPrivate' && <>
                                        <br /> <br />
                                        <button type="button" className="button button-secondary" onClick={() => this.connect()}>
                                            Connect
                                        </button>
                                    </>}

                                    {eCommerceConnection.shopType == 'wooCommerce' && <>
                                        <br /> <br />
                                        Make sure that <b>Shop domain</b>, <b>Consumer key</b> and <b>Consumer secret</b> are all specified, then press save.
                                    </>}

                                    {eCommerceConnection.lastError && <>
                                        <br />
                                        <br />
                                        {eCommerceConnection.lastError}
                                    </>}
                                </div>
                            }
                        </>}

                        {this.formHelper.renderFormGroups([
                            'isEnabled',
                            'name',
                            'shopType',
                            'shopDomain',
                            eCommerceConnection.shopType == 'shopifyPrivate' && 'shopifyClientID',
                            eCommerceConnection.shopType == 'shopifyPrivate' && 'shopifyClientSecret',
                            eCommerceConnection.shopType == 'wooCommerce' && 'wooCommerceConsumerKey',
                            eCommerceConnection.shopType == 'wooCommerce' && 'wooCommerceConsumerSecret'
                        ])}
                        
                        {!!eCommerceConnection.isConnected &&
                            <>
                                <h2 className="mt-3">Products</h2>
                                {this.renderProducts()}
                            </>
                        }

                    </div>
                </div>

            </div>

            <ListShopProductsModal ref={this.listShopProductsModalRef} />
            <EditProductModal ref={this.editProductModalRef} />
            
        </>);
    }

    renderProducts() {
        const {
            isLoadingProducts,
            products
        } = this.state;

        if (isLoadingProducts) {
            return (<Loader isInline />);
        }
        
        return (<>

            <button type="button" className="button button-secondary button-small mb-3" onClick={() => this.showListShopProductsModal()}>
                Connect a new product
            </button>

            <SuperTable
                className="ecommerce-products-table"
                rows={products}
                keyAccessor={p => p.id}
                emptyText="No products have been connected yet"
                onClick={cp => this.editProduct(cp.id)}
                cols={{
                    image: {
                        label: 'Image',
                        className: 'image-col',
                        getValue: (colInfo, p) => p.imageURL &&
                            <img src={'/api/ecommerce/get-thumbnail/' + btoa(p.imageURL)} />
                    },
                    name: {
                        label: 'Product',
                        className: 'name-col'
                    },
                    autoCreateVoucher: {
                        label: 'Create voucher',
                        className: 'auto-create-voucher-col',
                        getValue: (colInfo, p) => p.autoCreateVoucher &&
                            <span className="fa fa-check" />
                    },
                    reduceStockLevel: {
                        label: 'Reduce stock level',
                        className: 'reduce-stock-level-col',
                        getValue: (colInfo, p) => p.reduceStockLevel &&
                            <span className="fa fa-check" />
                    }
                }}
            />
        </>);
    }
};

export default withRouter(SettingsECommerceConnectionEdit);