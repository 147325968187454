// Libs
import React from 'react';
import { Route } from 'react-router';
import { Link, withRouter } from 'react-router-dom';

// Services & Helpers
import UserService from 'services/UserService';
import RoleService from 'services/RoleService';
import DiaryService from 'services/DiaryService';
import ServiceBandService from 'services/ServiceBandService';
import CapabilityService from 'services/CapabilityService';
import GlobalStateService from 'services/GlobalStateService';
import FormHelper from 'helpers/FormHelper';
import BootboxHelper from 'helpers/BootboxHelper';
import DateHelpers from 'helpers/DateHelpers';

// Components
import FloomlyComponent from 'components/FloomlyComponent';
import InfoBar from 'components/layout/InfoBar'
import Loader from 'components/reusable/Loader';

//-------------------------------------------------------------------------------------------------------------------

class SettingsUserEdit extends FloomlyComponent {

    constructor(props) {
        super(props);

        // Init state
        this.state = {
            isLoading: true,
            user: null,
            capabilities: null
        };

        this.updateField = this.updateField.bind(this);

        const fields = {
            firstName: { label: 'First Name', validation: { isRequired: true } },
            lastName: { label: 'Last Name' },
            nickname: { label: 'Nickname', validation: { isRequired: false } },
            emailAddress: { label: 'Email address' },
            mobile: { label: 'Mobile number' },
            notifyOnlineBooking: { label: 'Notify about new online bookings (via email)', type: 'checkbox' },
            roleID: {
                label: 'Role',
                type: 'single-select',
                blankText: '(Select)',
                getOptions: () => this.state.roles.map(role => ({
                    id: role.roleID,
                    name: role.name
                }))
            },
            isGroupUser: { label: 'Salon group user', type: 'checkbox' },
            serviceBandID: {
                label: 'Service Band',
                type: 'single-select',
                blankText: '(Select)',
                getOptions: () => this.state.serviceBands.map(serviceBand => ({
                    id: serviceBand.serviceBandID,
                    name: serviceBand.name
                }))
            },

            // Diary
            isOnDiary: { checkboxLabel: 'Show on diary', type: 'checkbox' },
            isAvailableOnlineBooking: { checkboxLabel: 'Show on Online Booking', type: 'checkbox' },
            diaryColourID: {
                label: 'Diary colour',
                type: 'single-select',
                getOptions: () => this.state.diaryColours,
                getOptionStyle: (provided, info) => ({
                    ...provided,
                    borderLeft: '30px solid ' + info.data.colour
                }),
                getSingleValueStyle: (provided, info) => ({
                    ...provided,
                    borderLeft: '30px solid ' + info.data.colour,
                    paddingLeft: 10
                })
            },
            //isJunior: { checkboxLabel: 'Junior (sales made by this stylist are attributed to their senior instead)', type: 'checkbox' },

            // Rota
            numRotas: {
                label: 'Rota Type',
                type: 'single-select',
                getOptions: () => [
                    { id: 0, name: 'Not on rota' },
                    { id: 1, name: 'Weekly' },
                    { id: 2, name: '2-Weekly' },
                    { id: 3, name: '3-Weekly' },
                    { id: 4, name: '4-Weekly' },
                    { id: 5, name: '5-Weekly' },
                    { id: 6, name: '6-Weekly' }
                ]
            },
            rotaOffset: {
                label: 'Start rota on',
                type: 'single-select',
                getOptions: () => {
                    const values = [];
                    if (this.state.user.numRotas >= 2) {
                        for (var i = 0; i < this.state.user.numRotas; i++) {
                            values.push({ id: i, name: this.getWeekName(i) });
                        }
                    }
                    return values;
                }
            },
            startNewRotaPeriod: {
                checkboxLabel: 'Switch to a new schedule automatically',
                type: 'checkbox'
            },
            newRotaStartDate: {
                type: 'date',
                label: 'New schedule takes effect on'
            },
            newRotaOffset: {
                label: 'Start rota on',
                type: 'single-select',
                getOptions: () => {
                    const values = [];
                    if (this.state.user.newNumRotas >= 2) {
                        for (var i = 0; i < this.state.user.newNumRotas; i++) {
                            values.push({ id: i, name: this.getWeekName(i) });
                        }
                    }
                    return values;
                }
            },
            capabilityIDs: {
                label: 'Capabilities',
                type: 'multi-select',
                blankText: '(No capabilities)',
                creatable: {
                    formatCreateLabel: (name) => 'Add capability: ' + name,
                    onCreateOption: (option) => {
                        return new Promise((resolve) => {
                            const capabilities = [...this.state.capabilities];
                            const newOption = {
                                id: `new${capabilities.length}-${option.name}`,
                                name: option.name
                            };
                            capabilities.push(newOption);
                            this.setState({
                                capabilities
                            }, () => {
                                resolve(newOption);
                            });
                        });
                    }
                },
                getOptions: () => this.state.capabilities.map(c => ({
                    id: c.capabilityID,
                    name: c.name
                }))
            },
            loginPIN: {
                label: 'Change login PIN',
                type: 'tel'
            },
            showInFinancialReports: {
                checkboxLabel: 'Show in financial reports (wages, commissions, etc)',
                type: 'checkbox'
            },
            mobilePassword: {
                label: 'Mobile password'
            }
        };
        fields.newNumRotas = { ...fields.numRotas };
        this.formHelper = new FormHelper({
            fields: fields,
            getValue: (fieldName, fieldInfo) => this.state.user[fieldName],
            setValue: this.updateField,
            onValidate: (validation) => this.setState({ validation: validation })
        });
    }

    componentDidMount() {
        this.load();
    }

    //--------------------------------------------------------------------------------------------------------------------

    async load() {
        const userID = Number(this.props.match.params.userID) || 0;

        let user;
        if (userID) {
            const getUserResponse = await UserService.get(userID);
            user = getUserResponse;
        } else {
            // New
            let year = new Date().getFullYear();
            const defaultRole = await RoleService.getDefaultRole();
            user = {
                loginPIN: await UserService.getRandomPIN(),
                showInFinancialReports: true,
                vacationDays: [ { year, days:28 }, { year: year + 1, days:28 }],
                roleID: defaultRole?.roleID
            };
        }

        // Init rotas
        this.initRotas(user);

        // Load other entities
        const roles = await RoleService.list();
        const serviceBands = await ServiceBandService.list();
        const capabilities = await CapabilityService.list(userID);
        const diaryColours = await DiaryService.listColours();

        // Load diary settings
        const getDiarySettingsResponse = await DiaryService.getSettings();
        const settings = getDiarySettingsResponse;
        const currentDiaryInterval = (settings.salonPeriod ? settings.salonPeriod.diaryInterval : 15);

        // Get start/end times for each day of the week
        this.startTimes = {};
        this.endTimes = {};
        for (let i = 0; i < 7; i++) {
            const dow = DateHelpers.daysOfWeek[i];
            let openingTime = null;
            let closingTime = null;
            if (settings.salonPeriod) {
                const sot = settings.salonPeriod.salonOpeningTimes.find(sot => sot.dayOfWeek == dow);
                if (sot) {
                    openingTime = DateHelpers.stripSeconds(sot.openingTime);
                    closingTime = DateHelpers.stripSeconds(sot.closingTime);
                }
            }
            this.startTimes[dow] = [{
                value: '',
                text: 'Open' + (openingTime ? ` (${openingTime})` : '')
            }].concat(DateHelpers.listTimes(currentDiaryInterval));
            this.endTimes[dow] = [{
                value: '',
                text: 'Close' + (closingTime ? ` (${closingTime})` : '')
            }].concat(DateHelpers.listTimes(currentDiaryInterval));
        }

        // Update UI
        this.setState({
            isLoading: false,
            user,
            roles,
            diaryColours,
            serviceBands,
            capabilities
        });
    }

    // TODO clean this up
    initRotas(user) {
        if (!user.rotas) {
            user.rotas = [];
        }
        while (user.rotas.length < user.numRotas) {
            user.rotas.push({
                rotaNumber: user.rotas.length,
                days: {}
            });
        }
        while (user.rotas.length > user.numRotas) {
            user.rotas.pop();
        }
        for (var i = 0; i < user.rotas.length; i++) {
            const rota = user.rotas[i];

            DateHelpers.daysOfWeek.forEach(dow => {
                if (!rota.days[dow]) {
                    rota.days[dow] = {
                        rotaNumber: rota.rotaNumber,
                        dayOfWeek: dow,
                        workingType: 'notWorking',
                        startTime: null,
                        endTime: null
                    };
                }
                const rotaDay = rota.days[dow];
                if (rotaDay.startTime) {
                    rotaDay.startTime = DateHelpers.removeSeconds(rotaDay.startTime);
                }
                if (rotaDay.endTime) {
                    rotaDay.endTime = DateHelpers.removeSeconds(rotaDay.endTime);
                }
            });
        }

        // New rotas
        if (!user.newRotas) {
            user.newRotas = [];
        }
        while (user.newRotas.length < user.newNumRotas) {
            user.newRotas.push({
                rotaNumber: user.newRotas.length,
                days: {}
            });
        }
        while (user.newRotas.length > user.newNumRotas) {
            user.newRotas.pop();
        }
        for (var i = 0; i < user.newRotas.length; i++) {
            const newRota = user.newRotas[i];

            DateHelpers.daysOfWeek.forEach(dow => {
                if (!newRota.days[dow]) {
                    newRota.days[dow] = {
                        rotaNumber: newRota.newRotaNumber,
                        dayOfWeek: dow,
                        workingType: 'notWorking',
                        startTime: null,
                        endTime: null
                    };
                }
                const rotaDay = newRota.days[dow];
                if (rotaDay.startTime) {
                    rotaDay.startTime = DateHelpers.removeSeconds(rotaDay.startTime);
                }
                if (rotaDay.endTime) {
                    rotaDay.endTime = DateHelpers.removeSeconds(rotaDay.endTime);
                }
            });
        }
    }

    updateRotaField(rota, dayOfWeek, field, value, type) {
        const rotaIndex = (type == 'new' ?
            this.state.user.newRotas.findIndex(r => r.rotaNumber == rota.rotaNumber) :
            this.state.user.rotas.findIndex(r => r.rotaNumber == rota.rotaNumber)
        );
        const rotas = (type == 'new' ?
            [...this.state.user.newRotas] :
            [...this.state.user.rotas]
        );
        rota = { ...rotas[rotaIndex] };

        // Update rota field
        if (rota.days[dayOfWeek]) {
            rota.days[dayOfWeek] = { ...rota.days[dayOfWeek] };
        } else {
            rota.days[dayOfWeek] = {};
        }
        rota.days[dayOfWeek][field] = value;

        // Update user
        const user = { ...this.state.user };
        rotas[rotaIndex] = rota;
        if (type == 'new') {
            user.newRotas = rotas;
        } else {
            user.rotas = rotas;
        }
        this.setState({
            user: user
        });
    }

    goBack() {
        this.props.history.push('/settings/users');
    }

    async save(sendingInvite) {
        const {
            validation,
            user
        } = this.state;

        if (validation.isValid) {
            this.setState({ isLoading: true });
            try {
                await UserService.save(user);
                if (!sendingInvite) {
                    this.goBack();
                }
            } catch (e) {
                BootboxHelper.alert(e);
                this.setState({ isLoading: false });
            }
        } else {
            BootboxHelper.alert('Please correct the errors on the form first:<br/>&nbsp;' + this.formHelper.getValidationSummaryHTML());
        }
    }

    updateField(fieldName, value, fieldInfo) {
        const user = { ...this.state.user };
        let oldFirstName = null;
        if (fieldName == 'firstName') {
            oldFirstName = user.firstName;
        }
        user[fieldName] = value;
        if (fieldName == 'firstName' && user.nickname == oldFirstName) {
            user.nickname = value;
        }
        if (fieldName == 'numRotas' || fieldName == 'newNumRotas') {
            this.initRotas(user);
        }
        this.setState({
            user: user
        });
    }

    getIsStylist() {
        const roleID = this.state.user.roleID;
        if (roleID) {
            const role = this.state.roles.find(r => r.roleID == roleID);
            if (role) {
                return role.isStylist;
            }
        }
        return false;
    }

    toggleCapability(capabilityID) {
        const capabilities = [...this.state.capabilities];
        const index = capabilities.findIndex(p => p.capabilityID == capabilityID);
        capabilities[index].isSelected = !capabilities[index].isSelected;
        this.setState({ capabilities: capabilities });
    }

    getWeekName(rotaNumber) {
        return 'Week ' + String.fromCharCode(65 + rotaNumber);
    }

    async generateNewRandomPIN() {
        const pin = await UserService.getRandomPIN();
        this.updateField('loginPIN', pin);
    }

    async sendInviteThroughSMS() {
        try {
            this.setState({ isLoading: true });
            await this.save(true);
            const response = await UserService.sendInviteThroughSms(this.state.user.userID);
            if (response) {
                BootboxHelper.alert('Invitation sent');
                this.goBack();
            }
            this.setState({ false: true });
        }
        catch (e) {
            BootboxHelper.alert(e);
            this.setState({ isLoading: false });
        }
    }

    async sendInviteThroughEmail() {
        try {
            this.setState({ isLoading: true });
            await this.save(true);
            const response = await UserService.sendInviteThroughEmail(this.state.user.userID);
            if (response) {
                BootboxHelper.alert('Invitation sent');
                this.goBack();
            }
            this.setState({ false: true });
        }
        catch (e) {
            BootboxHelper.alert(e);
            this.setState({ isLoading: false });
        }
    }

    async enableUserMobileAccess() {

    }

    async confirmDelete() {
        await BootboxHelper.alert('Please make sure the appointments are moved before deleting the team member!');
        const confirm = await BootboxHelper.confirm('Are you sure you want to delete this team member?');
        if (confirm) {
            this.delete();
        }
    }

    async delete() {
        await UserService.delete(this.state.user.userID);
        this.props.history.replace('/settings/users');
    }

    getVacationDays() {
        return this.state.user.vacationDays;
    }

    setVacationDays(year, value) {
        const user = { ...this.state.user };

        for (var i = 0; i < user.vacationDays.length; i++) {
            if (user.vacationDays[i].year === year) {

                const days = Math.round(Number(value));

                user.vacationDays[i].days = days;
            }
        }

        this.setState({
            user: user
        });
    }

    //--------------------------------------------------------------------------------------------------------------------
    //  Render
    //--------------------------------------------------------------------------------------------------------------------

    renderInfoBar() {
        const {
            user
        } = this.state;

        return (
            <InfoBar className="settings-info-bar" containerClassName="floating-on-mobile" sticky={true}>

                <div className="info-bar-panel-section">

                    <button className="button" onClick={() => this.goBack()}>
                        <span className="fa fa-chevron-left"></span>{' '}
                        Back
                    </button>

                </div>

                <div className="info-bar-panel-section info-bar-panel-section-text desktop-only">
                    {user.userID ? (user.nickname || <>&nbsp;</>) : 'New team member'}
                </div>

                <div className="info-bar-panel-section ml-auto">

                    {user.userID &&
                        <button className="button" style={{ marginRight: 10 }} onClick={() => this.confirmDelete()}>
                            <span className="fa fa-times"></span>{' '}
                            Delete
                        </button>
                    }

                    <button className="button" onClick={() => this.save()}>
                        <span className="fa fa-check"></span>{' '}
                        Save
                    </button>

                </div>

            </InfoBar>
        );
    }

    renderRotas(type) {
        const {
            user
        } = this.state;

        const rotas = (type == 'new' ?
            user.newRotas :
            user.rotas
        );

        return rotas.map(r =>
            <React.Fragment key={r.rotaNumber}>
                {this.renderRota(r, type)}
            </React.Fragment>
        );
    }

    renderRota(rota, type) {
        return (

            <div className="rota-table-container">
                <label>{this.getWeekName(rota.rotaNumber)}</label>

                <table className="super-table settings-table rota-table">
                    <thead>
                        <tr>
                            <th></th>
                            {DateHelpers.daysOfWeek.map(d =>
                                <th key={d}>
                                    {DateHelpers.getShortDayName(d)}
                                </th>
                            )}
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                Working
                            </td>
                            {DateHelpers.daysOfWeek.map(d =>
                                <td key={d} className="is-open-cell">
                                    <input
                                        type="checkbox"
                                        checked={rota.days[d] && rota.days[d].workingType == 'working'}
                                        onChange={e => this.updateRotaField(rota, d, 'workingType', e.target.checked ? 'working' : 'notWorking', type)}
                                    />
                                </td>
                            )}
                        </tr>
                        <tr>
                            <td>
                                Start
                            </td>
                            {DateHelpers.daysOfWeek.map(d =>
                                <td key={d}>
                                    {rota.days[d] && rota.days[d].workingType == 'working' &&
                                        <select
                                            value={rota.days[d].startTime || ''}
                                            onChange={e => this.updateRotaField(rota, d, 'startTime', e.target.value, type)}
                                        >
                                            {this.startTimes[d].map(t =>
                                                <option key={t.value} value={t.value}>{t.text}</option>
                                            )}
                                        </select>
                                    }
                                </td>
                            )}
                        </tr>
                        <tr>
                            <td>
                                Finish
                            </td>
                            {DateHelpers.daysOfWeek.map(d =>
                                <td key={d}>
                                    {rota.days[d] && rota.days[d].workingType == 'working' && <>
                                        {/*
                                        ({rota.days[d].startTime} / {DateHelpers.parseTimeToNumMins(rota.days[d].startTime)})
                                        */}
                                        <select
                                            value={rota.days[d].endTime || ''}
                                            onChange={e => this.updateRotaField(rota, d, 'endTime', e.target.value, type)}
                                        >
                                            {this.endTimes[d]
                                                .filter(st => !st.value || !rota.days[d].endTime || DateHelpers.parseTimeToNumMins(st.value) > DateHelpers.parseTimeToNumMins(rota.days[d].startTime))
                                                .map(t =>
                                                    <option key={t.value} value={t.value}>
                                                        {t.text}
                                                        {/*
                                                    / {DateHelpers.parseTimeToNumMins(t.value)} / {DateHelpers.parseTimeToNumMins(rota.days[d].startTime)}
                                                    */}
                                                    </option>
                                                )}
                                        </select>
                                    </>}
                                </td>
                            )}
                        </tr>
                    </tbody>
                </table>
            </div>

        );
    }

    renderVacationDays() {
        return (

            <div className="vacation-days-container">
                <label>Total holiday days per year</label>

                <table className="super-table settings-table super-table-pivot vacation-days-table">
                    <tbody>
                        {this.getVacationDays().map(dby =>
                            <tr key={dby.year}>
                                <th>
                                    {dby.year}
                                </th>
                                <td>
                                    <input
                                        type="text"
                                        value={dby.days}
                                        onChange={e => this.setVacationDays(dby.year, e.target.value)}
                                    />
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>

            </div>
        );
    }

    render() {
        const {
            isLoading,
            user,
            validation
        } = this.state;

        if (isLoading) {
            return (<Loader />);
        }

        const isStylist = this.getIsStylist();
        const clientInfo = GlobalStateService.getValue('clientInfo');

        return (<>
            {this.renderInfoBar()}

            <div className="settings-page-main">
                <div className="panel">

                    <div className="panel-body">

                        <fieldset>
                            <legend>Basic Details</legend>

                            {this.formHelper.renderFormGroups([
                                'firstName',
                                'lastName',
                                'nickname',
                                'mobile',
                                'emailAddress',
                                'notifyOnlineBooking',
                                'loginPIN'
                            ], validation)}

                            <button className="button button-secondary button-small" style={{ marginBottom: 15 }} onClick={e => this.generateNewRandomPIN()}>
                                Generate new random PIN
                            </button>

                            {this.formHelper.renderFormGroups([
                                'roleID',
                                (user.isOnDiary && 'serviceBandID'),
                                (user.isOnDiary && 'capabilityIDs')
                            ], validation)}

                            {clientInfo.salonGroupID && <>{this.formHelper.renderFormGroup('isGroupUser')} </>}
                        </fieldset>

                        <fieldset>
                            <legend>Diary</legend>

                            {this.formHelper.renderFormGroups([
                                'isOnDiary',
                                (user.isOnDiary && 'diaryColourID'),
                                //(user.isOnDiary && 'isJunior'),
                                (user.isOnDiary && 'isAvailableOnlineBooking')
                            ], validation)}

                        </fieldset>

                        <fieldset>
                            <legend>Rota</legend>
                            {this.formHelper.renderFormGroup('numRotas')}
                            {user.numRotas > 0 && <>
                                {this.renderRotas('')}
                                {user.numRotas >= 2 && this.formHelper.renderFormGroup('rotaOffset')}
                            </>}
                            {this.formHelper.renderFormGroup('startNewRotaPeriod')}
                            {user.startNewRotaPeriod && <>
                                {this.formHelper.renderFormGroup('newRotaStartDate')}
                                {user.newRotaStartDate && <>
                                    {this.formHelper.renderFormGroup('newNumRotas')}
                                    {this.renderRotas('new')}
                                    {user.newNumRotas >= 2 && this.formHelper.renderFormGroup('newRotaOffset')}
                                </>}
                            </>}
                        </fieldset>

                        <fieldset>
                            <legend>Financial</legend>
                            {this.formHelper.renderFormGroups([
                                'showInFinancialReports'
                            ])}
                            {this.renderVacationDays()}
                        </fieldset>

                        {/*
                        <fieldset>
                            <legend>Mobile access</legend>
                            <div className="mb-3">
                                Status: {user.mobileAccessStatus}
                            </div>
                            {this.formHelper.renderFormGroup(
                                (user.dateAccepted && 'mobilePassword')
                            )}
                            {
                                <>
                                    {user.dateInvited && !user.dateAccepted && !user.dateDisabled &&
                                        <>
                                            <button className="button button-secondary button-small me-3" style={{ marginBottom: 15 }} onClick={e => this.sendInviteThroughEmail()}>
                                                Send invitation again (email)
                                            </button>
                                            <button className="button button-secondary button-small" style={{ marginBottom: 15 }} onClick={e => this.sendInviteThroughSMS()}>
                                                Send invitation again (SMS)
                                            </button>
                                        </>
                                    }
                                    {!user.dateInvited && !user.dateAccepted  && !user.dateDisabled &&
                                        <>
                                            <button className="button button-secondary button-small me-3" style={{ marginBottom: 15 }} onClick={e => this.sendInviteThroughEmail()}>
                                                Invite to mobile team app (email)
                                            </button>
                                            <button className="button button-secondary button-small" style={{ marginBottom: 15 }} onClick={e => this.sendInviteThroughSMS()}>
                                                Invite to mobile team app (SMS)
                                            </button>
                                        </>
                                    }                                   
                                    {user.dateDisabled &&
                                        <button className="button button-secondary button-small" style={{ marginBottom: 15 }} onClick={e => this.enableUserMobileAccess()}>
                                            Enable mobile access
                                        </button>
                                    }
                                </>
                            }
                        </fieldset>
                        */}

                    </div>

                </div>

            </div>

        </>);
    }

};

export default withRouter(SettingsUserEdit);