// Libs
import React, { Component } from 'react';
import NumberFormat from 'react-number-format';

// Services
import SalonService from 'services/SalonService';

// Images
import profitImage from 'images/ypt-profit-image.png';

//-------------------------------------------------------------------------------------------------------------------

class InputAnnualExpensessSide extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        const currency = SalonService.getCurrentCurrency();
        let annualExpenses = this.props.salonFocusResult.annualExpenses;
        if (annualExpenses == 0) annualExpenses = '';

        return (
            <div className="ypt-slide ypt-slide-input-profit">

                <div className="ypt-title">
                    Annual Expenditure
                </div>

                <div className="ypt-row">
                    <div>
                        <div className="ypt-field-title">Annual Expenses:</div>

                        <p>Now, this is simply 'everything' else. So if you take out the wages for stylists/therapists/assistants which we've already counted, and then the stock which we've also counted, now we need an amount (not a %) for everything else.</p>
                        <p>Click on the question mark for a full list of everything that should be included here. </p>
 
                        <p>Once you've done this, you're almost done! On the next page you will see your target - excited?! Well get on with it then and get these numbers sorted :) </p>

                        <span className="help-icon"></span>

                        <div className="ypt-field-large">
                            <NumberFormat
                                thousandSeparator={currency.thousandSeparator}
                                decimalSeparator={currency.decimalSeparator}
                                className="has-prefix"
                                value={annualExpenses}
                                onChange={e => {
                                    this.isChanged = true;
                                    this.props.onChange('annualExpenses', e.target.value)
                                }}
                                onBlur={e => {
                                    if (this.isChanged) {
                                        this.isChanged = false;
                                        this.props.onSave(e)
                                    }
                                }}
                            />
                            <span className="ypt-field-prefix">{currency.symbol}</span>
                        </div>

                        <div className="ypt-controls-row">

                            <button className="button button-primary ypt-back-button" onClick={e => this.props.onBack()}>
                                Back
                            </button>

                            <button className="button button-secondary ypt-continue-button" onClick={e => this.props.onContinue()}>
                                Continue
                            </button>

                        </div>

                    </div>
                    <div>
                        <img src={profitImage} />
                    </div>
                </div>
            </div>
        );
    }
}

export default InputAnnualExpensessSide;
