import BaseService from 'services/BaseService';

//-------------------------------------------------------------------------------------------------------------------

export default class AutomationService
    extends BaseService {

    static async listTriggers() {
        const response = await BaseService.callAPI('automation/list-triggers');
        return response;
    }

    static async getTrigger(id) {
        const response = await BaseService.callAPI('automation/get-trigger/' + id);
        return response;
    }

    static async listSalonTriggers() {
        const response = await BaseService.callAPI('automation/list-salon-triggers');
        return response;
    }

    static async getSalonTrigger(id) {
        const response = await BaseService.callAPI('automation/get-salon-trigger/' + id);
        return response;
    }

    static async saveSalonTrigger(salonTrigger) {
        const response = await BaseService.callAPI('automation/save-salon-trigger', salonTrigger);
        return response;
    }

    static async deleteSalonTrigger(id) {
        const response = await BaseService.callAPI('automation/delete-salon-trigger/' + id);
        return response;
    }

    static async sendTestEmail(salonAutomationTrigger, emailAddress) {
        const response = await BaseService.callAPI('automation/send-test-email', {
            salonAutomationTrigger, emailAddress
        });
        return response;
    }
}
