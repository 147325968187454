// Libs
import React from 'react';
import moment from 'moment';
import Modal from 'react-bootstrap/Modal';

// Services & Helpers
import DiaryService from 'services/DiaryService';
import DateHelpers from 'helpers/DateHelpers';

// Components
import Loader from 'components/reusable/Loader';

//--------------------------------------------------------------------------------------

export default class EditRotaDateModal extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true
        };
    }

    show(opts) {
        return new Promise((resolve, reject) => {
            this.resolve = resolve;
            this.setState({
                isLoading: true,
                isOpen: true,
                stylistUserID: opts.stylistUserID,
                date: opts.date,
                rotaDate: {}
            }, () => {
                this.load();
            });
        });
    }

    async load() {
        // Load date info
        const dateInfo = await DiaryService.getDate(this.state.date, this.state.stylistUserID);
        const stylist = dateInfo.stylists[0];
        const dateKey = moment(this.state.date).format('YYYY-MM-DD') + 'T00:00:00';
        const rotaDateItems = stylist.rotaDates[dateKey];
		let rotaDateInfo = {
			date: this.state.date,
			userID: this.state.stylistUserID,
			items: rotaDateItems
		};

        const dayOfWeek = moment(this.state.date).format('dddd').toLowerCase();

        // Load diary settings
        const settings = await DiaryService.getSettings(this.state.date);
        const currentDiaryInterval = (settings.salonPeriod ? settings.salonPeriod.diaryInterval : 15);

        // Get start/end times for each day of the week
        this.startTimes = [];
        this.endTimes = [];
        this.openingTime = null;
        this.closingTime = null;
        if (settings.salonPeriod) {
            const sot = settings.salonPeriod.salonOpeningTimes.find(sot => sot.dayOfWeek === dayOfWeek);
            if (sot && sot.isOpen) {
                this.openingTime = DateHelpers.stripSeconds(sot.openingTime);
                this.closingTime = DateHelpers.stripSeconds(sot.closingTime);
            }
        }
        this.startTimes = DateHelpers.listTimes(currentDiaryInterval);
        if (this.openingTime) {
            this.startTimes = [{
                value: '',
                text: 'Open' + (this.openingTime ? ` (${this.openingTime})` : '')
            }].concat(this.startTimes);
        }
        this.endTimes = DateHelpers.listTimes(currentDiaryInterval);
        if (this.closingTime) {
            this.endTimes = [{
                value: '',
                text: 'Close' + (this.closingTime ? ` (${this.closingTime})` : '')
            }].concat(this.endTimes);
        }

        // Update model
        if (rotaDateInfo.items) {
            rotaDateInfo.overrideStandard = true;
        } else if (stylist.rotaDayMulti && stylist.rotaDayMulti.length) {
            // rotaDayMulti contains the default rota information
            let startTime = stylist.rotaDayMulti[0].startTime;
            let endTime = stylist.rotaDayMulti[0].endTime;
            if (!startTime && !this.openingTime) {
                startTime = this.startTimes[0].value;
            }
            if (!endTime && !this.closingTime) {
                endTime = this.endTimes[this.endTimes.length - 1].value;
            }
			rotaDateInfo.items = [{
				workingType: stylist.rotaDayMulti[0].workingType,
                startTime,
                endTime
            }];
			rotaDateInfo.overrideStandard = false;
        } else {
            rotaDateInfo.items = [{
                workingType: 'notWorking',
                startTime: this.openingTime,
                endTime: this.closingTime
            }];
			rotaDateInfo.overrideStandard = false;
        }

		for (var i = 0; i < rotaDateInfo.items.length; i++) {
			rotaDateInfo.items[i].startTime = DateHelpers.stripSeconds(rotaDateInfo.items[i].startTime);
			rotaDateInfo.items[i].endTime = DateHelpers.stripSeconds(rotaDateInfo.items[i].endTime);
		}

        // Update UI
        this.setState({
            isLoading: false,
			rotaDateInfo: rotaDateInfo,
            dayOfWeek: dayOfWeek,
            stylist: stylist
        });
    }
    
    updateFields(values) {
        const rotaDateInfo = { ...this.state.rotaDateInfo };
        for (var fieldName in values) {
            const value = values[fieldName];
            if (rotaDateInfo[fieldName] !== value) {
                this.setState({
                    haveSettingsChanged: true
                });
            }
            rotaDateInfo[fieldName] = value;
        }   
        this.setState({
            rotaDateInfo
        });
    }

	updateItemFields(index, values) {
		const rotaDateInfo = { ...this.state.rotaDateInfo };
        for (var fieldName in values) {
            const value = values[fieldName];
            if (rotaDateInfo.items[index][fieldName] !== value) {
                this.setState({
                    haveSettingsChanged: true
                });
            }
            rotaDateInfo.items[index][fieldName] = value;
			// there should be no "space" between times
			if (fieldName == "startTime" && index > 0) {
				rotaDateInfo.items[index-1]["endTime"] = value;
			} else if (fieldName == "endTime" && index < rotaDateInfo.items.length-1) {
				rotaDateInfo.items[index+1]["startTime"] = value;
			}
        }
        this.setState({
            rotaDateInfo
        });
	}

	addItem() {
		if (this.state.rotaDateInfo.items.length > 2)
			return;

		const rotaDateInfo = { ...this.state.rotaDateInfo };

		const et = rotaDateInfo.items[rotaDateInfo.items.length-1].endTime || this.closingTime;
		let et_index = this.endTimes.findIndex(t => t.value === et);
		if (et_index < 0) et_index = this.endTimes.length-1;

		const st = rotaDateInfo.items[rotaDateInfo.items.length-1].startTime || this.openingTime;
		let st_index = this.startTimes.findIndex(t => t.value === st);
		if (st_index < 0) st_index = 1;	//skip empty 'opening'

		let mid_index = st_index + Math.round((et_index - st_index)/2);

		//TODO: make sure that st_index < mid_index < et_index

		rotaDateInfo.items[rotaDateInfo.items.length-1].endTime = this.endTimes[mid_index].value;
		const newStartTime = this.startTimes[mid_index].value;

		let new_item = {
			workingType: 'working',
			startTime: newStartTime,
			endTime: null,
		};

		rotaDateInfo.items.push(new_item);
		this.setState({
			rotaDateInfo
		});
	}

	removeItem(index) {
		const rotaDateInfo = { ...this.state.rotaDateInfo };
		if (rotaDateInfo.items.length > 1 && index < rotaDateInfo.items.length && index >= 0) {
			rotaDateInfo.items.splice(index, 1);
		}
		this.setState({
			rotaDateInfo
		});
	}

    async save() {
        this.setState({ isLoading: true });
        await DiaryService.saveRotaDate(this.state.rotaDateInfo);
        this.setState({ isOpen: false });
        this.resolve();
    }

    //---------------------------------------------------------------------

    render() {
        const {
            isOpen,
            isLoading,
            date,
            stylist
        } = this.state;

        if (!isOpen) {
            return null;
        }

        return (
            <Modal
                show
                className="edit-rota-date-modal"
                onHide={e => this.setState({ isOpen: false })}
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        {isLoading ?
                            <>Rota</> :
                            <>Rota for {stylist.nickname} on {moment(date).format('ddd, Do MMM YYYY')}</>
                        }
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    {this.renderInner()}

                </Modal.Body>
                <Modal.Footer>
                    {!isLoading && <>
                        <button type="button" className="button button-secondary mr-auto" onClick={e => this.setState({ isOpen: false })}>
                            Cancel
                        </button>
                        <button type="button" className="button button-primary ml-auto" onClick={e => this.save()}>
                            Save
                        </button>
                    </>}
                </Modal.Footer>
            </Modal>
        );
    }

    renderInner() {
        const {
            isOpen,
            isLoading,
            rotaDateInfo
        } = this.state;

        if (isLoading) {
            return (<Loader />);
        }

        return (<>

            <div className="form-check mb-3" style={{ paddingLeft: 0 }}>
                <label>
                    <input
                        style={{ marginRight: 10 }}
                        type="checkbox"
                        checked={rotaDateInfo.overrideStandard}
                        onChange={e => this.updateFields({ overrideStandard: e.target.checked })}
                    />{' '}
                    Override the standard rota
                </label>
            </div>

			<div className="rota-columns">
            {(rotaDateInfo.overrideStandard) && <>

				{rotaDateInfo.items.map((item, index) => 

					<span key={'item' + index} className="rota-column">

						<div className="form-group">
		                    <label>Status</label>
		                    <select
		                        value={item.workingType}
		                        onChange={e => this.updateItemFields(index, { workingType: e.target.value })}
		                    >
		                        <option value="working">Working</option>
		                        <option value="notWorking">Not working</option>
		                        <option value="college">College</option>
		                        <option value="holidayPaid">Holiday (paid)</option>
		                        <option value="absenceNotPaid">Unpaid leave</option>
		                        <option value="sick">Sick</option>
		                        <option value="maternity">Maternity</option>
		                        <option value="bankHoliday">Bank holiday</option>
                                <option value="dayInLieu">Day in lieu</option>
                                <option value="wedding">Wedding</option>
		                    </select>
		                </div>

		                {(item.workingType === 'working' || rotaDateInfo.items.length > 1) && <>

		                    <div className="form-group">
		                        <label>Start time</label>
		                        <select
		                            value={item.startTime || ''}
		                            onChange={e => this.updateItemFields(index, { startTime: e.target.value })}
		                        >
		                            {this.startTimes.map(t =>
		                                <option key={t.value} value={t.value}>{t.text}</option>
		                            )}
		                        </select>
		                    </div>

		                    <div className="form-group">
		                        <label>End time</label>
		                        <select
		                            value={item.endTime || ''}
		                            onChange={e => this.updateItemFields(index, { endTime: e.target.value })}
		                        >
		                            {this.endTimes.map(t =>
		                                <option key={t.value} value={t.value}>{t.text}</option>
		                            )}
		                        </select>
		                    </div>

							{rotaDateInfo.items.length > 1 &&
								<div className="form-group">
									<button type="button" className="button minus-button" onClick={_ => this.removeItem(index)}>
										<span className="fa fa-minus"></span>
									</button>
								</div>
							}
		                </>}

					</span>

				)}

				{rotaDateInfo.items.length < 3 &&
				<span className="rota-column plus-button">
					<button type="button" className="button plus-button" onClick={_ => this.addItem()}>
						<span className="fa fa-plus"></span>
					</button>
				</span>}

            </>}
			</div>
        </>);
    }
}
