// Libs
import React from 'react';
import Modal from 'react-bootstrap/Modal';

// Services & Helpers
import DiaryService from 'services/DiaryService';
import ThermalPrinterService from 'services/ThermalPrinterService';
import BootboxHelper from 'helpers/BootboxHelper';
import TextHelpers from 'helpers/TextHelpers';

// Components
import Loader from 'components/reusable/Loader';

//--------------------------------------------------------------------------------------

export default class CancelApptModal extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true
        };
    }

    show(opts) {
        return new Promise((resolve, reject) => {
            this.resolve = resolve;
            console.log(opts);
            this.setState({
                isLoading: true,
                isOpen: true,
                appointmentID: opts.appointmentID,
                type: opts.type,
                refundOption: null,
                reason: null
            }, () => {
                this.load();
            });
        });
    }

    async load() {
        const appointment = await DiaryService.getCancellationInfo(this.state.appointmentID);
        if (appointment) {
            this.setState({
                appointment,
                isLoading: false
            });
        } else {
            this.setState({ isOpen: false });
            this.resolve();
        }
    }

    async save() {
        const { appointmentID, appointment, reason, refundOption, type } = this.state;

        // Validate
        if (appointment.amountPaid > 0 && !refundOption) {
            BootboxHelper.alert('Please select a refund option');
            return;
        }
        if (!reason) {
            BootboxHelper.alert('Please specify a reason for ' + (type == 'cancel' ? 'cancellation' : 'deletion'));
            return;
        }

        // Cancel and close
        this.setState({ isLoading: true });
        try {
            const { openCashDrawer } = await DiaryService.cancelOrDeleteAppointment(appointmentID, reason, refundOption, type);
            if (openCashDrawer) {
                ThermalPrinterService.openCashDrawers(false);
            }
            this.setState({ isOpen: false });
            this.resolve();
        } catch (error) {
            BootboxHelper.alert(error);
            this.setState({ isLoading: false });
        }
    }

    //---------------------------------------------------------------------

    render() {
        const {
            isOpen,
            isLoading,
            appointment,
            type
        } = this.state;

        if (!isOpen) {
            return null;
        }

        return (
            <Modal
                show
                className="cancel-appt-modal"
                onHide={e => this.setState({ isOpen: false })}
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        {type == 'cancel' ? 'Cancel' : 'Delete'} {' '}
                        {!isLoading && <> 
                            {appointment.customer ?
                                <>booking for {TextHelpers.formatName(appointment.customer.firstName, appointment.customer.lastName)}</> :
                                <>appointment</>
                            }
                        </>}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    {this.renderInner()}

                </Modal.Body>
                <Modal.Footer>
                    {!isLoading && <>
                        <button type="button" className="button button-secondary mr-auto" onClick={e => this.setState({ isOpen: false })}>
                            Don't {type}
                        </button>
                        <button type="button" className="button button-primary ml-auto" onClick={e => this.save()}>
                            Confirm
                        </button>
                    </>}
                </Modal.Footer>
            </Modal>
        );
    }

    renderInner() {
        const {
            isLoading,
            reason,
            appointment,
            type,
            refundOption
        } = this.state;

        if (isLoading) {
            return (<Loader />);
        }

        return (<>

            {appointment.amountPaid > 0 && <>
                <div className="form-group">
                    <label>
                        The client has paid a <b>{TextHelpers.formatCurrencyNew(appointment.amountPaid, { includeSymbol: true })}</b> deposit towards this appointment. <br />
                        What would you like to do with it?
                    </label>
                </div>
                <div className="checkbox mb-1">
                    <label>
                        <input
                            className="me-2"
                            type="radio"
                            checked={refundOption == 'None'}
                            onChange={e => this.setState({ refundOption: 'None' })}
                        />{' '}
                        Do not Refund
                    </label>
                </div>
                <div className="checkbox mb-1">
                    <label>
                        <input
                            className="me-2"
                            type="radio"
                            checked={refundOption == 'Card'}
                            onChange={e => this.setState({ refundOption: 'Card' })}
                        />{' '}
                        Refund by Card
                    </label>
                </div>
                <div className="checkbox mb-1">
                    <label>
                        <input
                            className="me-2"
                            type="radio"
                            checked={refundOption == 'Cash'}
                            onChange={e => this.setState({ refundOption: 'Cash' })}
                        />{' '}
                        Refund by Cash
                    </label>
                </div>
                <div className="checkbox mb-3">
                    <label>
                        <input
                            className="me-2"
                            type="radio"
                            checked={refundOption == 'Account'}
                            onChange={e => this.setState({ refundOption: 'Account' })}
                        />{' '}
                        Add to Client's Account Balance
                    </label>
                </div>
            </>}

            <div className="form-group">
                <label>Please specify reason for {type == 'cancel' ? 'cancellation' : 'deletion'}</label>
                <textarea
                    autoFocus
                    value={reason || ''}
                    onChange={e => this.setState({ reason: e.target.value })}
                    rows={2} 
                />
            </div>

        </>);
    }
}
