// Libs
import React, { Component } from 'react';
import Modal from 'react-bootstrap/Modal';

// Services & Helpers
import EmailTemplateService from 'services/EmailTemplateService';

// Components
import Loader from 'components/reusable/Loader';
import BootboxHelper from 'helpers/BootboxHelper';


export default class EmailBatchModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            permissionSelected: false,
            purpose: "marketing"
        };
    }

    show(opts) {
        return new Promise((resolve, reject) => {
            this.resolve = resolve;
            this.onAddClientList = opts.onAddClientList;
            this.setState({
                isOpen: true,
                isLoading: true,
                permissionSelected:false,
                emailTemplateID: opts.emailTemplateID
            }, () => {
                this.load();
            });
        });
    }

    hide() {
        this.setState({
            isOpen: false
        });
    }

    async load() {
        const clientLists = await EmailTemplateService.listClientLists();
        this.setState({
            isLoading: false,
            clientLists,
            clientListID: clientLists ? clientLists[0].clientListID : 0
        });
    }

    addNewClientList() {
        this.setState({
            isLoading: true
        });
        this.hide();
        if (this.onAddClientList) {
            this.onAddClientList();
        }
    }

    async send() {
        const { emailTemplateID, clientListID, purpose, clientLists } = this.state;
        const clientsCount = clientLists.find(c => c.clientListID == clientListID).clientCount;

        const confirmSending = await BootboxHelper.confirm(`Your email will be sent to ${clientsCount} client${(clientsCount > 1) ? 's' : ''}, would you like to confirm and send this email?`);

        if (confirmSending) {
            await BootboxHelper.alert(`Great, your email has been sent! 💪`);

            const emailBatch = {
                emailTemplateID,
                clientListID,
                purpose
            };
            EmailTemplateService.sendEmailBatch(emailBatch);
            this.hide();
            this.props.onClose();
        }
    }

    render() {
        const {
            isOpen,
            isLoading
        } = this.state;

        if (!isOpen) {
            return null;
        }

        return (
            <Modal
                className="email-batch-modal"
                show={isOpen}
                onHide={e => this.hide()}
            >
                {isLoading ?
                    <Modal.Body><Loader /></Modal.Body> :
                    this.renderInner()
                }
            </Modal>
        );
    }

    renderInner() {
        const {
            clientLists,
            permissionSelected,
            isLoading,
            clientListID,
            purpose
        } = this.state;
        return (<>
            <Modal.Header closeButton>
            </Modal.Header>
            <Modal.Body>
                <>
                    <div className="row">
                        <div className="col-md-5">
                            <div className="form-group">
                                <label>Client list</label>
                                <select
                                    value={clientListID}
                                    style={{ width: '100%' }}
                                    onChange={e => this.setState({ clientListID: e.target.value })}
                                >
                                    {clientLists.map(s =>
                                        <option key={s.clientListID} value={s.clientListID}>{s.name}</option>
                                    )}
                                </select>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Purpose</label>
                                <select
                                    value={purpose}
                                    style={{ width: '100%' }}
                                    onChange={e => this.setState({ purpose: e.target.value })}
                                >
                                    <option value="marketing">Marketing - To advertise new products/services, offer discounts, etc</option>
                                    {/*<option value="functional">Functional - To deliver non-commercial service information</option>*/}
                                </select>
                            </div>
                        </div>
                    </div>

                    <button className="button button-secondary button-small add-client-list-button" onClick={e => this.addNewClientList()}>
                        Add new client list
                    </button>
                    <div className="permission-div">
                        <input
                            id="permission-check"
                            className="permission-check"
                            type="checkbox"
                            checked={permissionSelected || false}
                            onChange={e => this.setState({ permissionSelected: e.target.checked })}
                        />
                        <span>
                            <label htmlFor="permission-check">
                                I have permission to send emails to all of the clients on this list
                            </label>
                        </span>
                    </div>
                </>
            </Modal.Body>
            <Modal.Footer>
                {!isLoading &&
                    <>
                        <button type="button" className="button button-secondary mr-auto" onClick={e => this.setState({ isOpen: false })}>
                            Cancel
                        </button>
                        <button type="button" className="button button-primary ml-auto" disabled={!permissionSelected} onClick={e => this.send()}>
                            Send
                        </button>
                    </>
                }
            </Modal.Footer>
        </>);
    }
}