// Libs
import React, { Component } from 'react';
import moment from 'moment';
import momentTimezone from 'moment-timezone';
import { Calendar as ReactBigCalendar, Views, momentLocalizer } from 'lib/react-big-calendar';
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop'

// CSS
import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'react-big-calendar/lib/addons/dragAndDrop/styles.scss'

//-------------------------------------------------------------------------------------------------------------------

// Init calendar
moment.locale('en-GB');
const CalendarWithDragAndDrop = withDragAndDrop(ReactBigCalendar);
const calendarLocalizer = momentLocalizer(moment); // or globalizeLocalizer

export default class Calendar extends Component {

    constructor(props) {
        super(props);
    }

    shouldComponentUpdate(nextProps, nextState) {

        //if ((nextProps.events || []).length != (this.props.events || []).length) {
        //    return true;
        //}
        //if ((nextProps.resources || []).length != (this.props.resources || []).length) {
        //    return true;
        //}

        //return false;
        return true;
    }

    render() {
        const props = {
            ...this.props,
            localizer: calendarLocalizer,
            dayLayoutAlgorithm: 'no-overlap'
        };

        if (this.props.enableDragAndDrop) {
            return (
                <CalendarWithDragAndDrop {...props} />
            );
        } else {
            return (
                <ReactBigCalendar {...props} />
            );
        }
    }
}
