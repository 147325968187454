import BaseService from 'services/BaseService';

import GlobalStateService from 'services/GlobalStateService';

//-------------------------------------------------------------------------------------------------------------------

export default class SalonService
    extends BaseService {

    static async getPublicInfo(salonCode) {
        return BaseService.callAPI('salon/get-public-info/' + salonCode);
    }

    static async getTheme(code) {
        return BaseService.callAPI('salon/get-theme/' + code);
    }

    static async getClientInfo() {
        return BaseService.callAPI('salon/get-client-info');
    }

    static async getCurrent() {
        return BaseService.callAPI('salon/get-current');
    }

    static async saveCurrent(salon) {
        return BaseService.callAPI('salon/save-current', salon);
    }

    static async listCurrencies() {
        const response = await BaseService.callAPI('salon/list-currencies');
        return response;
    }

    static async listAllPaymentMethods() {
        const response = await BaseService.callAPI('salon/list-all-payment-methods');
        return response;
    }

    static async setCurrency(currencyID) {
        return BaseService.callAPI('salon/set-currency/' + currencyID);
    }

    static getCurrentCurrency() {
        const clientInfo = GlobalStateService.getValue('clientInfo');
        if (clientInfo && clientInfo.currencies[clientInfo.currencyID]) {
            return clientInfo.currencies[clientInfo.currencyID];
        } else {
            return {
                symbol: '?'
            };
        }
    }

    static async listAllSalons() {
        return BaseService.callAPI('salon/list');
    }
}
