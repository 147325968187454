// Libs
import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import NumberFormat from 'react-number-format';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';

// Services & Helpers
import StylistService from 'services/StylistService';
import TextHelpers from 'helpers/TextHelpers';
import SalonService from 'services/SalonService';

// Components
import FloomlyComponent from 'components/FloomlyComponent';
import InfoBar from 'components/layout/InfoBar'
import Loader from 'components/reusable/Loader';

//-------------------------------------------------------------------------------------------------------------------

class StylistTargetsPage extends FloomlyComponent {

    constructor(props) {
        super(props);

        // Init state
        this.state = {
            isLoading: true,
            editingStylistTarget: null,
            editingStylistTargetIndex: -1
        };
        this.isChanged = false;
        this.stylistsChanged = {};

        this.updateField = this.updateField.bind(this);
        this.updateStylistTargetField = this.updateStylistTargetField.bind(this);
    }

    //--------------------------------------------------------------------------------------------------------------------

    componentDidMount() {
        this.load();
    }

    async load() {
        const targets = await StylistService.getTargets();

        targets.stylists.forEach(stylist => {
            if (typeof (stylist.clientCountOrAvgBillBias) == 'undefined') {
                stylist.clientCountOrAvgBillBias = 0.5;
                this.calculateStylistTotals(stylist);
            }
        });

        this.setState({
            targets: targets,
            isLoading: false
        });
    }

    updateField(field, value) {
        const targets = { ...this.state.targets };
        if (field == 'override' && !value) {
            value = '';
        }
        targets[field] = value;
        this.isChanged = true;
        this.updateTotals(targets);
        this.setState({
            targets: targets
        });
    }

    updateStylistTargetField(index, field, value) {
        const targets = { ...this.state.targets };
        const stylist = { ...targets.stylists[index] };
        stylist[field] = value;
        if (field == 'clientCountOrAvgBillBias' || field == 'sales') {
            this.calculateStylistTotals(stylist);
        }
        if (field == 'newCustomers') {
            stylist.regularCustomers = Math.max(0, stylist.clientCount - parseInt(value || 0));
        } else if (field == 'regularCustomers') {
            stylist.newCustomers = Math.max(0, stylist.clientCount - parseInt(value || 0));
        }

        // Update on UI and update totals
        this.stylistsChanged[targets.stylists[index].stylistUserID] = true;
        targets.stylists[index] = stylist;
        this.updateTotals(targets);
        this.setState({
            targets: targets
        });
    }

    calculateStylistTotals(stylist) {
        // Set target client count / average bill
        const minClients = Math.max(1, stylist.sales / 1000);
        const maxClients = stylist.sales * 0.1;
        const bias = Math.max(0, Math.min(1, Math.log10(stylist.clientCountOrAvgBillBias * 9 + 1)));
        const isNew = (typeof (stylist.clientCount) == 'undefined') || ((stylist.newCustomers || 0) + (stylist.regularCustomers || 0) == 0);
        stylist.clientCount = Math.max(1, Math.floor((minClients + (maxClients - minClients) * (1 - bias))));
        stylist.averageBillGross = stylist.sales / stylist.clientCount;

        // Default split
        if (isNew) {
            stylist.newCustomers = 0;
            stylist.regularCustomers = stylist.clientCount;
            stylist.clientCountOrAvgBillBias = 0.5;
        } else {
            stylist.newCustomers = parseInt(stylist.newCustomers || 0);
            stylist.regularCustomers = parseInt(stylist.regularCustomers || 0);
            const ratio = stylist.newCustomers / (stylist.newCustomers + stylist.regularCustomers);
            stylist.newCustomers = Math.floor(stylist.clientCount * ratio);
            stylist.regularCustomers = stylist.clientCount - stylist.newCustomers;
        }
    }

    saveStylistTarget(index) {
        const stylist = this.state.targets.stylists[index];
        if (this.stylistsChanged[stylist.stylistUserID]) {
            StylistService.saveStylistTarget(stylist);
            delete this.stylistsChanged[stylist.stylistUserID];
        }
    }

    save() {
        if (this.isChanged) {
            StylistService.saveTargets(this.state.targets);
            this.isChanged = false;
        }
    }

    updateTotals(targets) {
        targets.total = 0;
        targets.stylists.forEach(st => {
            targets.total += (Number(st.sales) || 0);
        });
        targets.target = targets.baseTarget * (1 + targets.override);
    }

    scrollToStylist(id) {
        const element = document.querySelector(`[data-id="${id}"]`);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    }

    //--------------------------------------------------------------------------------------------------------------------
    //  Render
    //--------------------------------------------------------------------------------------------------------------------

    renderInfoBar() {
        const {
        } = this.state;

        return (
            <InfoBar className="stylist-targets-info-bar" sticky={false}>

                <div className="info-bar-panel-section">
                    <button className="button" onClick={() => this.props.history.push('/the-office')}>
                        <span className="fa fa-arrow-left"></span>{' '}
                        Back
                    </button>
                </div>

                <div className="info-bar-panel-section info-bar-panel-section-text">
                    Stylist Targets
                </div>

            </InfoBar>
        );
    }

    renderStylists() {
        const {
            targets
        } = this.state;

        return (<>

            <div className="targets-stylist-container">

                <div className="targets-stylist-side-nav">

                    <div className="panel stylist-list-panel">
                        {/*<div className="panel-header">
                            Team Dashboard:
                        </div>*/}
                        <div className="panel-body">

                            <ul className="list">
                                {targets.stylists.map((stylistInfo, index) => {
                                    return (
                                        <li key={index} onClick={e => this.scrollToStylist(stylistInfo.stylistUserID)}>
                                            {/*onClick={() => this.selectStylist(stylist.userID)}>*/}
                                            <div className="list-item-name">
                                                {stylistInfo.stylist.nickname}
                                            </div>
                                            <div className="list-item-colour-bar" style={{ backgroundColor: stylistInfo.stylist.diaryColour }}></div>
                                        </li>
                                    );
                                })}
                            </ul>

                        </div>
                    </div>

                </div>

                <div className="targets-stylist-list">

                    {targets.stylists.map((stylistInfo, index) =>
                        <React.Fragment key={stylistInfo.stylistUserID}>
                            {this.renderStylist(stylistInfo, index)}
                        </React.Fragment>
                    )}

                </div>

            </div>

        </>);
    }

    renderStylist(stylistInfo, index) {
        const currency = SalonService.getCurrentCurrency();

        return (<>
            <div className="stylist-name" data-id={stylistInfo.stylistUserID}>
                {stylistInfo.stylist.nickname}
            </div>
            <div className="stylist panel">
                <div className="panel-body">
                    <NumberFormat
                        thousandSeparator={currency.thousandSeparator}
                        decimalSeparator={currency.decimalSeparator}
                        prefix={currency.symbol}
                        className="stylist-target"
                        placeholder="Target sales"
                        value={stylistInfo.sales || ''}
                        onValueChange={(values) => this.updateStylistTargetField(index, 'sales', values.value)}
                        onBlur={e => this.saveStylistTarget(index)}
                    />
                    <div className="ypt-heart-slider-container">

                        <div className="flex-row">

                            <div>
                                <Slider
                                    min={0}
                                    max={1}
                                    step={0.005}
                                    value={1 - stylistInfo.clientCountOrAvgBillBias}
                                    onChange={value => {
                                        this.updateStylistTargetField(index, 'clientCountOrAvgBillBias', 1 - value);
                                    }}
                                    onBlur={e => {
                                        this.saveStylistTarget(index);
                                    }}
                                />

                                <div className="ypt-heart-slider-result">
                                    Target Client Count
                                    <span>{stylistInfo.sales > 0 ? stylistInfo.clientCount : 'Enter a sales target'}</span>
                                </div>

                                <div className="new-existing-client-breakdown">

                                    <div>
                                        Regular
                                        <input
                                            type="number"
                                            min="0"
                                            max={stylistInfo.clientCount}
                                            value={stylistInfo.regularCustomers}
                                            onChange={e => this.updateStylistTargetField(index, 'regularCustomers', e.target.value)}
                                            onBlur={e => this.saveStylistTarget(index)}
                                        />
                                    </div>

                                    <div>
                                        New
                                        <input
                                            type="number"
                                            min="0"
                                            max={stylistInfo.clientCount}
                                            value={stylistInfo.newCustomers}
                                            onChange={e => this.updateStylistTargetField(index, 'newCustomers', e.target.value)}
                                            onBlur={e => this.saveStylistTarget(index)}
                                        />
                                    </div>

                                </div>
                            </div>

                            <div>

                                <Slider
                                    min={0}
                                    max={1}
                                    step={0.005}
                                    value={stylistInfo.clientCountOrAvgBillBias}
                                    onChange={value => {
                                        this.updateStylistTargetField(index, 'clientCountOrAvgBillBias', value);
                                        //this.isChanged = true;
                                    }}
                                    onBlur={e => {
                                        if (this.isChanged) {
                                            this.isChanged = false;
                                            //this.props.onSave(e)
                                        }
                                    }}
                                />

                                <div className="ypt-heart-slider-result">
                                    Target Average Bill
                                    <span>{stylistInfo.sales > 0 ? TextHelpers.formatCurrencyNew(stylistInfo.averageBillGross, { numDP: 2 }) : 'Enter a sales target'}</span>
                                </div>
                            </div>

                        </div>

                        <div className="floating-at-symbol">
                            @
                        </div>

                    </div>
                </div>
                <div className="stylist-panel-colour" style={{
                    backgroundColor: stylistInfo.stylist.diaryColour
                }} />
            </div>
        </>);
    }

    renderMainContent() {
        const {
            isLoading,
            targets
        } = this.state;

        if (isLoading) {
            return (<Loader />);
        }

        return (<>

            <div className="targets-row sticky-topmost">

                <div className="targets-block">

                    <span className="targets-title">
                        Total
                    </span>
                    <div className="panel targets-total">

                        <div className="panel-body">

                            {TextHelpers.formatCurrencyNew(targets.total, { numDP: 0, includeSymbol: true })}

                            {Math.round(targets.total) != Math.round(targets.target) &&
                                <div className="targets-total-warning">
                                    {targets.total > targets.target ? 'Over ' : 'Under '}
                                    by {TextHelpers.formatCurrencyNew(Math.abs(targets.target - targets.total), { numDP: 0, includeSymbol: true })}
                                </div>
                            }
                            
                        </div>

                    </div>

                </div>

                <div className="targets-block">

                    <span className="targets-title">
                        Target
                    </span>
                    <div className="panel">

                        <div className="panel-body targets-target">

                            {TextHelpers.formatCurrencyNew(targets.target, { numDP: 0, includeSymbol: true })}

                        </div>

                    </div>

                </div>

                <div className="targets-block">

                    <span className="targets-title">
                        Override
                    </span>
                    <div className="panel targets-override">

                        <div className="panel-body">

                            <input
                                type="number"
                                value={targets.override ? Math.floor(targets.override * 100) : ''}
                                onChange={e => this.updateField('override', Number(e.target.value) / 100)}
                                onBlur={e => this.save()}
                            />
                            <span className="suffix">%</span>

                        </div>

                    </div>

                </div>

            </div>

            {this.renderStylists()}

        </>);
    }

    render() {
        return (<>
            <div className="page-content">
                <div className="page-content-left">
                </div>
                <div className="page-content-right">
                    <div className="page-content-right-inner">
                        {this.renderInfoBar()}
                    </div>
                </div>
            </div>

            <div className="stylist-targets-page">

                {this.renderMainContent()}

            </div>
        </>);
    }

};

export default withRouter(StylistTargetsPage);