export default class GlobalStateService {

    static values = {};
    static subscribers = {};
    static eventSubscribers = {};

    static setValue(name, value) {
        this.values[name] = value;
        const subscribers = this.subscribers[name];
        if (subscribers) {
            for (var i = 0; i < subscribers.length; i++) {
                const fn = subscribers[i];
                fn(value);
            }
        }
    }

    static setValues(object) {
        for (var key in object) {
            if (object.hasOwnProperty(key)) {
                GlobalStateService.setValue(key, object[key]);
            }
        }
    }

    static getValue(name) {
        return this.values[name];
    }

    static subscribe(name, fn) {
        if (!this.subscribers[name]) {
            this.subscribers[name] = [];
        }
        const existing = this.subscribers[name].find(s => s === fn);
        if (!existing) {
            this.subscribers[name].push(fn);
        }
    }

    static unsubscribe(name, fn) {
        if (this.subscribers[name]) {
            const index = this.subscribers[name].findIndex(s => s === fn);
            if (index != -1) {
                this.subscribers[name].splice(index, 1);
            }
        }
    }

    static callEvent(name, data) {
        const eventSubscribers = this.eventSubscribers[name];
        if (eventSubscribers) {
            for (var i = 0; i < eventSubscribers.length; i++) {
                const fn = eventSubscribers[i];
                fn(data, name);
            }
        }
    }

    static subscribeEvent(name, fn) {
        if (!this.eventSubscribers[name]) {
            this.eventSubscribers[name] = [];
        }
        const existing = this.eventSubscribers[name].find(s => s === fn);
        if (!existing) {
            this.eventSubscribers[name].push(fn);
        }
    }

    static unsubscribeEvent(name, fn) {
        if (this.eventSubscribers[name]) {
            const index = this.eventSubscribers[name].findIndex(s => s === fn);
            if (index != -1) {
                this.eventSubscribers[name].splice(index, 1);
            }
        }
    }
};

window.GlobalStateService = GlobalStateService;