// Libs
import React from 'react';
import moment from 'moment';
import 'moment/locale/en-gb';

// Services & Helpers
import CustomerService      from 'services/CustomerService';

// Components
import FloomlyComponent     from 'components/FloomlyComponent';
import Loader               from 'components/reusable/Loader';
import EmailPreviewModal    from 'components/pages/customer/EmailPreviewModal';
import SendSMSModal from 'components/SendSMSModal';
import SendEmailModal from 'components/SendEmailModal';

//-------------------------------------------------------------------------------------------------------------------

class CustomerComms extends FloomlyComponent {

    constructor(props) {
        super(props);

        this.emailPreviewModalRef = React.createRef();
        this.sendSMSModalRef = React.createRef();
        this.sendEmailModalRef = React.createRef();

        this.state = {
            isLoading: true
        }
    }

    componentDidMount() {
        this.load();
    }

    async load() {
        this.setState({
            isLoading: true
        });
        const comms = await CustomerService.listComms(this.props.id);
        this.setState({
            comms,
            isLoading: false
        });
    }

    async showSendSMSModal() {
        await this.sendSMSModalRef.current.show({
            customerID: this.props.id
        });
        this.load();
    }

    async showSendEmailModal() {
        await this.sendEmailModalRef.current.show({
            customerID: this.props.id
        });
        this.load();
    }

    //--------------------------------------------------------------------------------------------------------------------
    //  Render
    //--------------------------------------------------------------------------------------------------------------------

    render() {
        return (<>
            <div className="tab-panel customer-comms">
                <div className="panel tab-panel-content">

                    {this.renderInner()}
                    
                </div>
            </div>

            <SendSMSModal ref={this.sendSMSModalRef} />
            <SendEmailModal ref={this.sendEmailModalRef} />

        </>);
    }

    renderInner() {
        const {
            isLoading,
            comms
        } = this.state;

        if (isLoading) {
            return (<Loader />);
        }

        return (<>

            <div className="control-panel">

                <button className="button button-primary button-small" onClick={() => this.showSendSMSModal()}>
                    Send SMS
                </button>

                <button className="button button-primary button-small" onClick={() => this.showSendEmailModal()}>
                    Send Email
                </button>

            </div>

            <hr />

            <ul className="fv2-thing-list">

                {comms.map(cc =>
                    <li className="thing" key={cc.id}>

                        <div className="thing-side">

                            <div className={`thing-icon bordered teal fa-solid ${cc.isIncoming ? 'fa-arrow-right' : 'fa-arrow-left'}`} />

                            <div className="thing-detail">

                                {cc.isIncoming ? 'In' : 'Out'}

                            </div>

                        </div>

                        <div className="thing-main">

                            <div className="thing-detail heading">
                                {moment(cc.date).format('DD/MM/YYYY HH:mm')}
                            </div>

                            <div className="thing-detail">
                                {
                                    cc.method == 'sms' ? 'SMS' :
                                    cc.method == 'email' ? 'Email' :
                                    cc.method == 'link' ? 'Confirm link' :
                                    cc.method
                                }
                            </div>

                            {!!cc.message &&
                                <div className="thing-detail">

                                    {cc.message}

                                </div>
                            }

                            <div className="thing-detail">

                                {cc.method == 'email' &&
                                    <button type="button" className="button button-secondary button-small" onClick={() => {
                                        this.emailPreviewModalRef.current.show({ customerCommID: cc.id });
                                    }}>
                                        View
                                    </button>
                                }

                            </div>

                        </div>

                        <div className="thing-buttons-icons">

                            <span className={`thing-icon teal ${cc.method == 'email' ? 'fa-regular fa-envelope' : 'fa-solid fa-mobile-screen-button'}`} />

                        </div>

                    </li>
                )}
            </ul>

            <EmailPreviewModal ref={this.emailPreviewModalRef} />

        </>);
    }

}

export default CustomerComms;