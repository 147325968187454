// Libs
import React from 'react';

// Services & Helpers
import RetailService from 'services/RetailService';
import DiaryService from 'services/DiaryService';
import ProductService from 'services/ProductService';
import PrintService from 'services/PrintService';
import CustomerService from 'services/CustomerService';
import ThermalPrinterService from 'services/ThermalPrinterService';

// Components
import FloomlyComponent from 'components/FloomlyComponent';
import Loader from 'components/reusable/Loader';

// Steps/Panels
import RetailPurchasePanel from './RetailPurchasePanel';
import PaymentPanel from '../checkout/PaymentPanel';

//-------------------------------------------------------------------------------------------------------------------

class WalkInPurchase extends FloomlyComponent {

	constructor(props) {
		super(props);

		this.retailPurchasePanelRef = React.createRef();
		this.paymentPanelRef = React.createRef();

		this.state = {
			step: 1,
			customer: null,
			isLoading: true,
			retailPurchase: null,
		};
		this.autoGoBackTimeOut = null;
	}

	componentDidMount() {
		this.load();
	}

	componentWillUnmount() {
		if (this.autoGoBackTimeOut) {
			window.clearTimeout(this.autoGoBackTimeOut);
			this.autoGoBackTimeOut = null;
		}
	}

	fix(retailPurchase) {
		retailPurchase.newPayments = retailPurchase.newPayments || [];
		retailPurchase.paymentDate = retailPurchase.paymentDate || retailPurchase.date || new Date();
		retailPurchase.type = 'retail';
	}

	async load() {
		if (this.props.retailPurchaseID) {
			const retailPurchase = await RetailService.get(this.props.retailPurchaseID);
			retailPurchase.date += 'Z'; // Force date as UTC as that's how it comes from the server
			this.fix(retailPurchase);
			DiaryService.updateBalance(retailPurchase, true);
			this.setState({ retailPurchase });

			if (retailPurchase.customerID) {
				const customer = await CustomerService.get(retailPurchase.customerID);
				customer.id = customer.customerID;
				retailPurchase.customer = customer;
				this.setState({
					customer,
					retailPurchase
				});
			}
		}

		const paymentMethods = await DiaryService.loadPaymentMethods();
		this.setState({
			paymentMethods,
			isLoading: false,
		});
		this.onButtons(1);
	}

	goBack() {
		if (this.state.step === 1) {
			this.props.onClose();
		} else {
			this.setState({
				step: this.state.step - 1
			});
			this.onButtons(this.state.step - 1);
		}
	}

	goToPayment(retailPurchase) {
		this.fix(retailPurchase);
		DiaryService.updateBalance(retailPurchase, true);	

		this.setState({ step: 2, retailPurchase });
		this.onButtons(2);
	}

	startOver() {
		if (this.autoGoBackTimeOut) {
			window.clearTimeout(this.autoGoBackTimeOut);
			this.autoGoBackTimeOut = null;
		}
		this.setState({
			step: 1,
			customer: null,
			retailPurchase: null,
		});
		this.onButtons(1);
	}

	close() {
		if (this.autoGoBackTimeOut) {
			window.clearTimeout(this.autoGoBackTimeOut);
			this.autoGoBackTimeOut = null;
		}
		this.props.onClose();
	}

	onButtons(step) {
		if (this.props.onButtons) {
			switch (step) {
				case 1:
					this.props.onButtons([{
						key: "btn1",
						text: (<>Cancel</>),
						primary: false,
						click: () => this.close()
					}, {
						key: "btn2",
						text: (<>Save and continue{' '}<i className="fas fa-arrow-right"></i></>),
						primary: true,
						click: () => this.retailPurchasePanelRef.current.next(),
						active: () => RetailPurchasePanel.canGoNext(this.state.retailPurchase),
					}, {
						key: "btn3",
						text: "Delete",
						color:"danger",
						click: () => this.retailPurchasePanelRef.current.delete(),
						active: () => RetailPurchasePanel.canDelete(this.state.retailPurchase),
					}]);
					break;

				case 2:
					this.props.onButtons([{
						key: "btn1",
						text: (<><i className="fas fa-arrow-left"></i>{' '}Back</>),
						primary: false,
						click: () => this.goBack()
					}, {
						key: "btn2",
						text: "Save and Close",
						primary: true,
						click: async () => {
							await this.saveRP();
							this.close();
						},
						active: () => PaymentPanel.canGoNext(this.state.retailPurchase),
					}]);
					break;
			}
		}
	}

	//--------------------------------------------------------------------------------------------------------------------
	// Retail purchase
	//--------------------------------------------------------------------------------------------------------------------

	async selectRPStockItem(stockItem) {
		// Get more info about stock item
		stockItem = await ProductService.getStockItem(stockItem.id);

		// Add product
		const stockItems = [...this.state.retailPurchase.stockItems];
		stockItems.push({
			...stockItem,
			stockItemID: stockItem.stockItemID,
			total: (stockItem.sellPrice || '')
		});

		this.updateRPFields({
			stockItems
		});
	}

	updateRPTotals(retailPurchase) {
		retailPurchase.subTotal = 0;
		retailPurchase.total = 0;

		// Calculate purchase total
		retailPurchase.stockItems.forEach(si => {
			retailPurchase.subTotal += Number(si.total) || 0;
		});

		// Add discount
		retailPurchase.discountTotal = 0;
		switch (retailPurchase.discountType) {
			case 'amount':
				retailPurchase.discountTotal = -retailPurchase.discountAmount;
				break;
			case 'percentage':
				retailPurchase.discountTotal = -(retailPurchase.subTotal * retailPurchase.discountAmount / 100);
				break;
		}

		retailPurchase.total = retailPurchase.subTotal + retailPurchase.discountTotal;
	}

	async updateStockItemField(index, field, value) {
		const stockItems = [...this.state.retailPurchase.stockItems];
		stockItems[index][field] = value;
		this.updateRPFields({
			stockItems
		});
	}

	updateRPFields(values) {
		const retailPurchase = { ...this.state.retailPurchase };
		for (var field in values) {
			retailPurchase[field] = values[field];
		}
		this.updateRPTotals(retailPurchase);
		this.setState({
			retailPurchase: retailPurchase
		});
	}

	canSave() {
		return PaymentPanel.canGoNext(this.state.retailPurchase);
	}

	async printRPReceipt(id) {
		const { isPrintingReceipt } = this.state;
		if (isPrintingReceipt) return;
		this.setState({ isPrintingReceipt: true });
		await ThermalPrinterService.printRetailPurchaseReceiptWithPDFFallback(id);
		this.setState({ isPrintingReceipt: false });
	}

	async saveRP() {
		if (!this.canSave()) {
			return;
		}

		const {
			retailPurchase
		} = this.state;

		this.setState({ isLoading: true });

		retailPurchase.date = retailPurchase.paymentDate;

		// Save
		const { id, openCashDrawer } = await RetailService.save(retailPurchase);

		// Print
		if (retailPurchase.printReceipt) {
			this.printRPReceipt(id);
		}

		// Open cash drawer
		if (openCashDrawer) {
			ThermalPrinterService.openCashDrawers(false);
		}

		if (this.props.isStandalone) {
			this.close();
		} else {
			// Update UI
			this.setState({
				isLoading: false,
				step: 3
			});
			this.onButtons(3);

			// Automatically close and go back to menu (or whatever else)
			this.autoGoBackTimeOut = window.setTimeout(() => {
				this.close();
			}, 3 * 1000);
		}
	}

	renderPayment() {

		let {
			retailPurchase
		} = this.state;

		return (
			<>
				{!this.props.isStandalone &&
				<button className="button button-secondary button-small" onClick={_ => this.goBack()}>
					<span className="fas fa-arrow-left"></span>{' '}
					Back
				</button>}

				<PaymentPanel
					ref={this.paymentPanelRef}
					checkOut={retailPurchase}
					isRetail={true}
					onCheckOutChanged={checkOut => this.setState({retailPurchase:checkOut})}
					onChange={(name,value) => {}}
				/>

				{!this.props.isStandalone &&
				<div className="button-stack">

					<button className={"button button-primary button-small" + (this.canSave() ? '' : ' disabled')} onClick={_ => this.saveRP()}>
						<i className="fas fa-check"></i>{' '}
						Complete purchase
					</button>

					<button className="button button-tertiary button-small" onClick={_ => this.goBack()}>
						<i className="fas fa-arrow-left"></i>{' '}
						Back
					</button>

				</div>}
			</>
		);
	}

	renderRPComplete() {
		return (<>
			<div className="panel retail-purchase-complete-panel">
				<div className="panel-body">
					Purchase complete!
				</div>
			</div>

			<button className="button button-primary" onClick={_ => this.close()}>
				<i className="fas fa-check"></i>{' '}
				Back to Menu
			</button>
			<button className="button button-secondary" onClick={_ => this.startOver()}>
				<i className="fa fa-cheron-right"></i>{' '}
				Another Purchase
			</button>
		</>);
	}

	render() {
		const {
			isLoading,
			step,
		} = this.state;

		if (isLoading) {
			return (<Loader />);
		}

		if (step === 1) {
			return (<RetailPurchasePanel
				ref={this.retailPurchasePanelRef}

				retailPurchase={this.state.retailPurchase}
				customer={this.state.customer}
				isStandalone={this.props.isStandalone}

				onGoBack={() => this.goBack()}
				onGoFwd={retailPurchase => this.goToPayment(retailPurchase)}
				onCustomerSelected={customer => {
					let retailPurchase = this.state.retailPurchase;
					if (retailPurchase) {
						retailPurchase.customerID = customer ? customer.id : null;
						retailPurchase.customer = customer;
						this.setState({ customer, retailPurchase });
					} else {
						this.setState({ customer });
					}
				}}
			/>);
		} else if (step === 2) {
			return this.renderPayment();
		} else if (step === 3) {
			return this.renderRPComplete();
		}
	}
}

export default WalkInPurchase;