import GlobalStateService from 'services/GlobalStateService';

export default class BaseService {
    
    static async callAPI(url, data) {
        try {
            data = { ...data };

            let stackTrace = null;
            try {
                stackTrace = new Error().stack;
                stackTrace = (new Error().stack || '')
                    .replace(/\r\n|\r|\n/g, '|') // Replace line break with |
                    .replace(/[^\x00-\x7F]/g, ''); // Remove all non-ascii characters
            } catch (e) {
                // Ignore
            }

            const opt = {
                method: (data.method || 'POST'),
                headers: {
                    'Content-Type': 'application/json',
                    'X-Salon-Code': GlobalStateService.getValue('salonCode'),
                    'X-Salon-Group-Code': GlobalStateService.getValue('salonGroupCode'),
                    'X-Stack-Trace': stackTrace
                }
            };
            const ignoreAPIErrorHandler = !!data.ignoreAPIErrorHandler;
            if (data.method) {
                delete data.method;
            }
            if (data.ignoreAPIErrorHandler) {
                delete data.ignoreAPIErrorHandler;
            }
            if ((opt.method || '').toLowerCase() != 'get') {
                opt.body = JSON.stringify(data);
            }
            const response = await fetch('/api/' + url, opt);
            
            if (data && data.isDownload) {

                if (!data.fileName) {
                    const contentDisposition = response.headers.get('content-disposition');
                    if (contentDisposition) {
                        const match = contentDisposition.match(/filename="(.+?)"/);
                        if (match) {
                            data.fileName = match[1];
                        }
                    }
                }

                const blob = await response.blob();

                var a = document.createElement('a');
                a.style = 'display: none';
                document.body.appendChild(a);
                try {
                    a.href = window.URL.createObjectURL(blob);
                    a.download = data.fileName;
                    a.click();
                } finally {
                    setTimeout(() => {
                        window.URL.revokeObjectURL(blob);
                        a.remove();
                    }, 100);
                }

            } else {
                const text = await response.text();
                let responseObject = null;
                if (text.length > 0) {
                    responseObject = JSON.parse(text);
                }
                switch (response.status) {
                    case 200:
                    case 400:
                        if (responseObject) {
                            if (responseObject.success) {
                                return responseObject.content;
                            } else {
                                throw responseObject.content; // Error message
                            }
                        } else {
                            return null;
                        }
                        break;
                    case 401:
                    case 403:
                    case 500:
                        if (!ignoreAPIErrorHandler) {
                            const apiErrorHandler = GlobalStateService.getValue('apiErrorHandler');
                            if (apiErrorHandler) {
                                apiErrorHandler(response, responseObject);
                            }
                        }
                        //if (responseObject) {
                        //    throw responseObject.content || 'Something went wrong on our side. Please try again or contact support.';
                        //}
                        break;
                    //case 500:
                    //    break;
                    //default:
                    //    throw 'API CALL status code ' + response.status + ' (' + url + ')';
                }
            }

        } catch (error) {
            throw error;
        }
    }

    static async postFile(url, file, data) {
        try {

            // Create form data object
            var formData = new FormData();
            formData.append('file', file);
            if (data) {
                for (var key in data) {
                    formData.append(key, data[key])
                }
            }

            // Do the upload
            const response = await fetch('/api/' + url, {
                method: 'POST',
                body: formData,
                headers: {
                    'X-Salon-Code': GlobalStateService.getValue('salonCode')
                }
            });
            const text = await response.text();
            if (text.length > 0) {
                const responseObject = JSON.parse(text);
                return responseObject;
            } else {
                // TODO handle differently?
                return null;
            }
        } catch (error) {
            console.log('API error: ', error);
            return {
                success: false,
                message: error
            };
        }

    }

    static postToIframe(obj, action, iframeName) {
        const form = document.createElement('form');
        form.setAttribute('method', 'post');
        form.setAttribute('action', action);
        form.setAttribute('target', iframeName);
        form.setAttribute('style', 'display:none');
        
        for (let fieldName in obj) {
            if (obj.hasOwnProperty(fieldName)) {
                const value = obj[fieldName];
                const field = document.createElement('textarea');
                field.setAttribute('name', fieldName);
                field.value = value;
                form.appendChild(field);
            }
        }

        document.body.appendChild(form);
        form.submit();
        setTimeout(() => {
            document.body.removeChild(form);
        }, 100);
    }
}