import GlobalStateService from 'services/GlobalStateService';

export default class PrintService {

	static printURL(url) {
		return new Promise(function (resolve, reject) {
			// Add unique number to URL to ensure it always loads fresh
			url += (url.indexOf('?') > -1 ? '&' : '?') + 'random=' + Math.random();

			// Append salon code
			url += '&salon-code=' + GlobalStateService.getValue('salonCode');

			// Create the printing IFrame
			if (!PrintService.printIFrame) {
				var iFrame = document.createElement('IFRAME');
					iFrame.style.position = 'absolute';
					iFrame.style.left = '0px';
					iFrame.style.top = '0px';
					iFrame.style.width = '1px';
					iFrame.style.height = '1px';
					iFrame.style.opacity = '0';
				document.body.appendChild(iFrame);
				PrintService.printIFrame = iFrame;
			}

			// Call print on load
			var fnOnLoad = function () {
				if (PrintService.printIFrame.src) {
					window.setTimeout(function () {
						PrintService.printIFrame.focus();
						PrintService.printIFrame.contentWindow.print();
					}, 250);
					window.setTimeout(function () {
						resolve();
					}, 500);
				}
			};
			PrintService.printIFrame.removeEventListener('load', fnOnLoad);
			PrintService.printIFrame.addEventListener('load', fnOnLoad);

			// Point to the print URL
			PrintService.printIFrame.src = url;
		});
    }

}
