// Libs
import React from 'react';
import { Route } from 'react-router';
import { Link, withRouter } from 'react-router-dom';

// Services & Helpers
import ThermalPrinterService from 'services/ThermalPrinterService';

// Components
import FloomlyComponent from 'components/FloomlyComponent';
import InfoBar      from 'components/layout/InfoBar'
import Loader       from 'components/reusable/Loader';
import SuperTable   from 'components/reusable/SuperTable';

//-------------------------------------------------------------------------------------------------------------------

class SettingsThermalPrinterList extends FloomlyComponent {

    constructor(props) {
        super(props);

        // Init state
        this.state = {
            isLoading: true,
            thermalPrinters: []
        };
    }

    componentDidMount() {
        this.load();
    }

    //--------------------------------------------------------------------------------------------------------------------

    async load() {
        const thermalPrinters = await ThermalPrinterService.list();
        
        this.setState({
            isLoading: false, 
            thermalPrinters
        }, () => {
            this.checkConnections();
        });
    }

    edit(id) {
        this.props.history.push('/settings/thermal-printers/' + id);
    }

    add() {
        this.props.history.push('/settings/thermal-printers/new');
    }

    async checkConnections() {
        const devices = await navigator.usb.getDevices();
        const thermalPrinters = [...this.state.thermalPrinters];
        thermalPrinters.forEach(tp => {
            const device = ThermalPrinterService.findMatchingDevice(devices, tp);
            tp.isConnected = !!device;
            tp.isDisconnected = !device;
        });
        this.setState({ thermalPrinters });
    }

    //--------------------------------------------------------------------------------------------------------------------
    //  Render
    //--------------------------------------------------------------------------------------------------------------------

    renderInfoBar() {
        return (
            <InfoBar className="settings-info-bar" containerClassName="floating-on-mobile" sticky={true}>

                <div className="info-bar-panel-section info-bar-panel-section-text desktop-only">
                    Thermal Printers
                </div>
                <div className="info-bar-panel-section ml-auto">

                    <button className="button" onClick={() => this.add()}>
                        <span className="fa fa-plus"></span>{' '}
                        Add New
                    </button>

                </div>

            </InfoBar>
        );
    }

    render() {
        const {
            isLoading,
            thermalPrinters
        } = this.state;

        if (isLoading) {
            return (<Loader />);
        }

        return (<>
            {this.renderInfoBar()}

            <div className="settings-page-main">
                <div className="panel">
                    <div className="panel-body">

                        <SuperTable
                            className="settings-table"
                            containerClassName="mb-0"
                            rows={thermalPrinters}
                            keyAccessor={thermalPrinter => thermalPrinter.id}
                            cols={{
                                name: { label: 'Name' },
                                status: {
                                    label: 'Status',
                                    getValue: (colInfo, tp) => {
                                        if (tp.isConnected)
                                            return <><span className="fa fa-check" /> Connected ok</>;
                                        else if (tp.isDisconnected)
                                            return <><span className="fa fa-times" /> Disconnected</>;
                                    }
                                }
                            }}
                            onClick={(thermalPrinter, e) => this.edit(thermalPrinter.id)}
                        />
                        
                    </div>
                </div>

            </div>

        </>);
    }

};

export default withRouter(SettingsThermalPrinterList);