import BaseService from 'services/BaseService';

//-------------------------------------------------------------------------------------------------------------------

export default class BudgetService
    extends BaseService {

    static async get(month) {
        const date = month.getFullYear() + '-' + (month.getMonth() + 1) + '-01';
        return BaseService.callAPI('budget/get/' + date);
    }

    static async save(budget) {
        return BaseService.callAPI('budget/save', budget);
    }

    static async saveLine(budgetLine) {
        return BaseService.callAPI('budget/save-line', budgetLine);
    }

    static async deleteLine(budgetLineID) {
        return BaseService.callAPI('budget/delete-line/' + budgetLineID);
    }

}
