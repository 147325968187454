// Libs
import React from 'react';

// Services & Helpers
import SearchService from 'services/SearchService';

// Components
import FloomlyComponent from 'components/FloomlyComponent';
import Search from 'components/reusable/Search';
import CustomerSummary from 'components/reusable/CustomerSummary';

//-------------------------------------------------------------------------------------------------------------------

class CustomerSelector extends FloomlyComponent {

	constructor(props) {
		super(props);

		this.isFor = !!this.props.isFor;
		this.autoFocus = !!this.props.autoFocus;

		this.state = {
			customer: props.customer,
		}
	}

	render() {
		const customer = this.state.customer;

		if (customer) {
			return (
				<CustomerSummary
					header={this.isFor ? 'For customer' : 'Purchased by'}
					customerID={customer.id}
					onChangeClicked={_ => {
						this.setState({ customer: null });
						this.props.onCustomerSelected(null);
					}}
				/>
			);
		}

		return (
			<div className="panel retail-purchase-customer">
				<div className="panel-header">
					{this.isFor ? 'For customer' : 'Purchased by'}
				</div>
				<div className="panel-body">

					<Search
						className="search-box"
						autoFocus={this.autoFocus}
						placeholder="Find customer (optional)..."
						maxResults={50}
						renderWrapper={(input, results, _) => (<>
							{input}
							{results && results.length > 0 &&
								<div className="list search-results">
									{results}
								</div>
							}
						</>)}
						search={async (query, setResults, maxResults, state) => {
							this.customerSearchQuery = query;
							const results = await SearchService.search(query, ['Customer'], { maxResults, state });
							setResults(results);
						}}
						renderResult={(customer, index) =>
							<div key={index} className="search-result list-item" onMouseDown={_ => {
								this.setState({ customer });
								this.props.onCustomerSelected(customer);
							}}>
								{/*<img className="user-icon list-item-icon" src=userIconImage />*/}
								<div className="list-item-name">
									{customer.name}
									<div className="list-subitem-number">
										{customer.customerTel} {customer.customerTel && customer.customerEmail && <>-</>} {customer.customerEmail}
									</div>
								</div>
							</div>
						}
					/>

				</div>

			</div>
		);
	}
}

export default CustomerSelector;
