// Libs
import React, { Component } from 'react';

// Images
import profitImage from 'images/ypt-profit-image.png';

//-------------------------------------------------------------------------------------------------------------------

class InputWageSlide extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        let wagePercentage = Math.round((this.props.salonFocusResult.wagePercentage || 0) * 10000) / 100;
        if (wagePercentage == 0) wagePercentage = '';

        return (
            <div className="ypt-slide ypt-slide-input-profit">

                <div className="ypt-title">
                    Your Wage Percentage
                </div>

                <div className="ypt-row">
                    <div>
                        <div className="ypt-field-title">Wage Percentage:</div>

                        <p>Okay, you have to do a bit of work here now - there are 3 numbers you need to get (if you don't know them, whoever does your accounts should be able to help). 
                        Firstly you need to find out what percentage of your sales over the last 3 months was paid back out as wages to your stylists/therapists/assistants* </p>
                        <p>*Remember to include yourself as Alan was talking about on the last video if you are a stylist or therapist. </p>
 
                        <p>Then on the next two pages of the calculator, you will need to enter what percentage of your sales you are spending on salon stock and then all the rest of your expenses for the year. </p>
 
                        <p>(If you are a new salon and you haven't been weaving your magic on all those lucky people for a full year yet, then click on the question mark for more information on how to get these figures - your Fantastic Coach will be able to help you here too.)
                        
                        <span className="help-icon"></span>
                            </p>


                        <div className="ypt-field-large">
                            <input
                                type="number"
                                step="1"
                                min="0"
                                max="100"
                                className="has-suffix"
                                value={wagePercentage}
                                onChange={e => {
                                    this.isChanged = true;
                                    this.props.onChange('wagePercentage', e.target.value / 100)
                                }}
                                onBlur={e => {
                                    if (this.isChanged) {
                                        this.isChanged = false;
                                        this.props.onSave(e)
                                    }
                                }}
                            />
                            <span className="ypt-field-suffix">%</span>
                        </div>
                        
                        <div className="ypt-controls-row">

                            <button className="button button-primary ypt-back-button" onClick={e => this.props.onBack()}>
                                Back
                            </button>

                            <button className="button button-secondary ypt-continue-button" onClick={e => this.props.onContinue()}>
                                Continue
                            </button>

                        </div>

                    </div>
                    <div>
                        <img src={profitImage} />
                    </div>
                </div>
            </div>
        );
    }
}

export default InputWageSlide;
