/* eslint-disable no-template-curly-in-string */
import React, { Component } from 'react';
import moment from 'moment';

import NotificationService from 'services/NotificationService';
import Loader from 'components/reusable/Loader';
import SendSMSModal from './SendSMSModal';
import BootboxHelper from '../helpers/BootboxHelper';

export default class NotificationArea extends Component {

    constructor(props) {
        super(props);

        this.sendSMSModalRef = React.createRef();

        this.load = this.load.bind(this);

        this.state = {
            cssClass: "",
            isLoading: true,
            isOpen: false,
            page: 1
        };


    }

    show(opts) {
        return new Promise((resolve, reject) => {
            this.resolve = resolve;

            this.checkNotifications = setInterval(() => {
                this.load();
            }, 60 * 1000);

            this.setState({
                cssClass: "show",
                isOpen: true,
                isLoading: true,
                page: 1

            }, () => {
                this.load();
            });
        });
    }

    hide() {
        clearInterval(this.checkNotifications);
        this.setState({
            cssClass: "",
            isOpen: false
        });
    }

    async load() {
        var notifications = await NotificationService.getNotifications(-1);
        this.props.updateNumOfNotifications();
        const maxPages = Math.ceil(notifications.length / 10);
        await this.setState({
            notifications: notifications,
            maxPage: maxPages,
            isLoading: false
        });
    }

    setPage(page) {
        if (page > this.state.maxPage) {
            page = this.state.maxPage;
        }
        if (page < 1) {
            page = 1;
        }
        this.setStaate({
            page: page
        });
    }

    viewCustomer(customerID) {
        this.hide();
        window.appHistory.push('/customer/' + customerID);
    }

    async read(notifID) {
        await NotificationService.readNotification(notifID);
        this.props.updateNumOfNotifications();
        this.load();
    }

    async delete(notifID) {
        const confirm = await BootboxHelper.confirm('Are you sure you want to delete this notification?');
        if (confirm) {
            await NotificationService.deleteNotification(notifID);
            this.props.updateNumOfNotifications();
            this.load();
        }
    }

    async showSendSMSModal(notificationID, toNumber, customerID) {
        await this.sendSMSModalRef.current.show({
            notificationID,
            customerID,
            toNumber
        });
    }

    render() {
        const {
            cssClass,
            isLoading,
            page,
            maxPage,
            notifications
        } = this.state;

        return (<>
            <SendSMSModal
                ref={this.sendSMSModalRef}
                notificationsReload={this.load}
            />

            <div className={`notifications-area ${cssClass}`}>
                <div className={"notifications-header"}>
                    <h4><b>Notifications</b></h4>
                    <button type="button" className="btn-close" onClick={e => this.hide()} aria-label="Close" />
                </div>

                <div className={"notifications-contents"}>
                    {isLoading && <Loader>
                    </Loader>}

                    {notifications?.length == 0 && <>
                        No notifications.
                    </>}

                    {notifications?.length > 0 && <ul className="notifications-list">
                        {notifications.slice(0 + ((page - 1) * 10), 10 + ((page - 1) * 10)).map(notif =>
                            <li className="notification">
                                <div className="notification-header">
                                    <div className={`notification-customer ${notif.isRead ? 'read' : 'unread'}`}>
                                        {moment(notif.dateAdded).format('DD MMM YYYY HH:mm:ss')}
                                        {'   '}
                                        {notif.customer && <>
                                            {notif.customer?.firstName} {notif.customer?.lastName}
                                            {'   '}
                                            <button className="button customer-summary-info-button" onClick={e => this.viewCustomer(notif.customerID)}>
                                                <span className="fas fa-info"></span>
                                            </button>
                                        </>}
                                        {!notif.customer && <>{notif.customerComm?.sender}</>}
                                    </div>
                                    <div className="notification-buttons">
                                        <button type="button" className={`button button-small ${notif.isRead ? 'button-secondary' : 'button-primary'}`} onClick={e => this.read(notif.notificationID)}>
                                            {notif.isRead && <><span className="fa-regular fa-eye"></span>{' '}Mark as Unread</>}
                                            {!notif.isRead && <><span className="fa-regular fa-eye"></span>{' '}Mark as Read</>}
                                        </button>
                                        <button type="button" className={`button button-small ${notif.isReplied ? 'button-secondary' : 'button-primary'}`} onClick={e => this.showSendSMSModal(notif.notificationID, notif.customerComm.sender, notif.customerID)}>
                                            {notif.isReplied && <><span className="fa fa-check"></span>{' '}Replied</>}
                                            {!notif.isReplied && <><span className="fa fa-reply"></span>{' '}Reply</>}
                                        </button>
                                        <button type="button" className="button button-small button-secondary" onClick={e => this.delete(notif.notificationID)}>
                                            <span className="fa fa-trash"></span>{' '}Delete
                                        </button>
                                    </div>
                                </div>
                                <div className="notification-content">
                                    {notif.content}
                                </div>
                            </li>
                        )}
                    </ul>}
                </div>

                {notifications?.length > 0 && <div className={"notifications-footer"}>
                    <button type="button" className="button" disabled={page == 1} onClick={e => this.setState({ page: 1 })}>
                        <span className="fa fa-angles-left"></span>
                    </button>
                    <button type="button" className="button" disabled={page == 1} onClick={e => this.setState({ page: page - 1 })}>
                        <span className="fa fa-angle-left"></span>
                    </button>
                    {/*<input className="page-input" type="number" defaultValue={page} onBlur={e => this.setPage(e.target.value)}></input>*/}
                    {page}{" of "}{maxPage}
                    <button type="button" className="button" disabled={page == maxPage} onClick={e => this.setState({ page: page + 1 })}>
                        <span className="fa fa-angle-right"></span>
                    </button>
                    <button type="button" className="button" disabled={page == maxPage} onClick={e => this.setState({ page: maxPage })}>
                        <span className="fa fa-angles-right"></span>
                    </button>
                </div>}

            </div>
        </>);
    }

}