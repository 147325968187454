import BaseService      from 'services/BaseService';
import SearchService    from 'services/SearchService';

//-------------------------------------------------------------------------------------------------------------------

export default class ProductService
    extends BaseService {
    
    static async list() {
        const response = await BaseService.callAPI('product/list');
        return response;
    }

    static async get(id) {
        const response = await BaseService.callAPI('product/get/' + id);
        return response;
    }

    static async getStockItem(id) {
        const response = await BaseService.callAPI('product/get-stock-item/' + id);
        return response;
    }

    static async save(product) {
        const response = await BaseService.callAPI('product/save', product);
        return response;
    }

}