import BaseService from 'services/BaseService';

//-------------------------------------------------------------------------------------------------------------------

export default class InternalApptTypeService
    extends BaseService {
    
    static async list() {
        return (await BaseService.callAPI('internal-appt-type/list'));
    }

    static async get(id) {
        return BaseService.callAPI('internal-appt-type/get/' + id);
    }

    static async save(internalApptType) {
        return BaseService.callAPI('internal-appt-type/save', internalApptType);
    }

    static async delete(id) {
        return BaseService.callAPI('internal-appt-type/delete/' + id);
    }

}
