import { Component } from 'react';
import PropTypes from 'prop-types';

// Services & Helpers
import TextHelpers from 'helpers/TextHelpers';

//-------------------------------------------------------------------------------------------------------------------

class Money extends Component {

    static propTypes = {
        amount: PropTypes.number,
        currencyOnly: PropTypes.bool,
        blankIfZero: PropTypes.bool,
        plusIfPositive: PropTypes.bool
    };
    
    static defaultProps = {
        amount: 0,
        currencyOnly: false
    };

    render() {
        let decimalPlaces;
        if (typeof (this.props.decimalPlaces) == 'undefined') {
            decimalPlaces = 2;
        } else {
            decimalPlaces = Number(this.props.decimalPlaces);
        }
        const amount = this.props.amount || 0;
        if (this.props.blankIfZero && amount == 0) {
            return '';
        }
        const formatted = TextHelpers.formatCurrencyNew(amount, {
            numDP: decimalPlaces,
            includeSymbol: !this.props.currencyOnly,
            currency: this.props.currency,
            commas: true
        });
        if (this.props.plusIfPositive && amount > 0) {
            return '+' + formatted;
        }
        return formatted;
    }
}
export default Money;