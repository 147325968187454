import BaseService from 'services/BaseService';

import GlobalStateService from 'services/GlobalStateService';

//-------------------------------------------------------------------------------------------------------------------

export default class LoyaltySchemeService
    extends BaseService {
    
    static async get() {
        return BaseService.callAPI('loyalty-scheme/get');
    }

    static async save(loyaltyScheme) {
        return BaseService.callAPI('loyalty-scheme/save', loyaltyScheme);
    }

    static async listTransactions(customerID) {
        return BaseService.callAPI('loyalty-scheme/list-transactions/' + customerID);
    }

    static async addTransaction(customerID, loyaltyTransaction) {
        return BaseService.callAPI('loyalty-scheme/add-transaction/' + customerID, loyaltyTransaction);
    }

    static async deleteTransaction(loyaltyTransactionID) {
        return BaseService.callAPI('loyalty-scheme/delete-transaction/' + loyaltyTransactionID);
    }

    static getPointsEarned(loyaltyScheme, appointmentServices, apptPurchases, retailDiscountTotal) {
        if (!loyaltyScheme.isActive) return 0;
        let numPoints = 0;
        if (appointmentServices) {
            let serviceTotal = 0;
            appointmentServices.forEach(asv => {
                serviceTotal += (Number(asv.total) || 0);
                const service = loyaltyScheme.services[asv.serviceID];
                if (service) {
                    serviceTotal += (service.numLoyaltyPointsAccrued || 0);
                }
            });
            numPoints += Math.floor(serviceTotal * loyaltyScheme.accrueRateServices);
        }
        if (apptPurchases) {
            let retailTotal = 0;
            apptPurchases.forEach(ap => {
                retailTotal += (Number(ap.total) || 0);
                if (ap.stockItem) {
                    const stockItem = loyaltyScheme.products[ap.stockItem.productID];
                    if (stockItem) {
                        numPoints += (stockItem.numLoyaltyPointsAccrued || 0);
                    }
                }
            });
            numPoints += Math.floor(retailTotal + (retailDiscountTotal || 0) * loyaltyScheme.accrueRateRetail);
        }
        return Math.max(0, numPoints);
    }

    static formatReason(reason, otherReason) {
        switch (reason) {
            case 'preBookedAppt':
                return 'Pre-booked appointment';
            case 'spentServices':
                return 'Spent money on services';
            case 'spentRetail':
                return 'Spent money on retail';
            case 'usedService':
                return 'Purchased a service';
            case 'purchasedProduct':
                return 'Purchased a product';
            case 'spent':
                return 'Points redeemed';
            default:
                return otherReason;
        }
    }
}
