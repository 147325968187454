// Libs
import React from 'react';
import moment from 'moment';
import Modal from 'react-bootstrap/Modal';

// Services & Helpers
import CustomReportService from 'services/CustomReportService';
import BootboxHelper from 'helpers/BootboxHelper';
import TextHelpers from 'helpers/TextHelpers';

// Components
import Loader from 'components/reusable/Loader';

//--------------------------------------------------------------------------------------

export default class SaveCustomReportModal extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    show(opts) {
        return new Promise((resolve, reject) => {
            this.resolve = resolve;
            this.setState({
                isOpen: true,
                isLoading: false,
                customReport: opts.customReport
            });
        });
    }

    async save() {
        this.setState({ isLoading: true });
        try {
            const customReport = {...this.state.customReport};
            customReport.json = JSON.stringify({
                sectionSources: customReport.sectionSources
            });
            await CustomReportService.save(customReport);
            this.setState({ isOpen: false });
            this.resolve();
            BootboxHelper.alert('Custom report saved as ' + TextHelpers.escapeHTML(this.state.customReport.name));
        } catch (error) {
            BootboxHelper.alert(error);
            this.setState({ isLoading: false });
        }
    }

    updateField(field, value) {
        const customReport = { ...this.state.customReport };
        customReport[field] = value;
        this.setState({ customReport });
    }

    //---------------------------------------------------------------------

    render() {
        const {
            isOpen
        } = this.state;
        if (!isOpen) {
            return null;
        }

        return (
            <Modal
                show
                className="save-custom-report-modal"
                onHide={e => this.setState({ isOpen: false })}
            >
                {/*
                <Modal.Header closeButton>
                    <Modal.Title>
                        Block booking {customer ? ' for ' + TextHelpers.formatName(customer.firstName, customer.lastName) : ''}
                    </Modal.Title>
                </Modal.Header>
                */}
                <Modal.Body>

                    {this.renderInner()}

                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="button button-secondary mr-auto" onClick={e => this.setState({ isOpen: false })}>
                        Cancel
                    </button>
                    <button type="button" className="button button-primary ml-auto" onClick={e => this.save()}>
                        Save
                    </button>
                </Modal.Footer>
            </Modal>
        );
    }

    renderInner() {
        const {
            isLoading,
            customReport
        } = this.state;

        if (isLoading) {
            return (<Loader />);
        }

        return (<>

            <div className="form-group">
                <label>Name</label>

                <input
                    type="text"
                    value={customReport.name}
                    onChange={e => this.updateField('name', e.target.value)}
                />

            </div>

            <div className="form-check ps-0">
                <label>
                    <input
                        type="checkbox"
                        checked={customReport.isShared}
                        onChange={e => this.updateField('isShared', e.target.checked)}
                    />{' '}
                    Other people in my salon can see and run this report
                </label>
            </div>

        </>);
    }

}
