// Libs
import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/en-gb';

// Services & Helpers
import FormService from 'services/FormService';

// Components
import FloomlyComponent from 'components/FloomlyComponent';
import Loader from 'components/reusable/Loader';
import FormFillModal from '../../FormFillModal';
import BootboxHelper from '../../../helpers/BootboxHelper';

//-------------------------------------------------------------------------------------------------------------------

class CustomerForms extends FloomlyComponent {

    constructor(props) {
        super(props);

        this.formFillModalRef = React.createRef();

        this.state = {
            isLoading: true
        }

        this.showFormFillModal = this.showFormFillModal.bind(this);
        this.sendSMS = this.sendSMS.bind(this);
        this.sendEmail = this.sendEmail.bind(this);
        this.load = this.load.bind(this);
    }

    componentDidMount() {
        this.load();
    }

    componentDidUpdate(prevProps) {
        if (this.props.type != prevProps.type) {
            this.load();
        }
    }

    async load() {
        const { type } = this.props;
        this.setState({
            isLoading: true
        });

        const formTypes = await FormService.listFormTypes();
        var selectedFormTypeID = -1;
        if (formTypes.length) {
            selectedFormTypeID = formTypes[0].formTypeID;
        }
        const forms = await FormService.listAllCustomerForms(this.props.id);

        this.setState({
            forms: forms,
            formTypes: formTypes,
            selectedFormTypeID: selectedFormTypeID,
            isLoading: false
        });
    }

    async sendSMS(form) {
        var formID = await FormService.saveForm(form);
        await FormService.sendFormViaSMS(formID);
        BootboxHelper.alert('Link sent via SMS');
    }

    async sendEmail(form) {
        var formID = await FormService.saveForm(form);
        await FormService.sendFormViaEmail(formID);
        BootboxHelper.alert('Link sent via Email');
    }

    async showFormFillModal(form) {
        this.formFillModalRef.current.show({
            formID: form.formID,
            formTypeID: form.formTypeID,
            customerID: form.customerID
        });
    }

    async selectFormType(selectedFormTypeID) {
        await this.setState({
            selectedFormTypeID: selectedFormTypeID
        });
    }

    async createForm() {
        const { forms } = this.state;

        var form = {
            formTypeID: this.state.selectedFormTypeID,
            customerID: this.props.id
        };
        var confirm = true;

        for (var i = 0; i < forms.length; i++) {
            if (forms[i].formTypeID == form.formTypeID) {
                if (forms[i].filledDate && moment(forms[i].filledDate).isAfter(moment().subtract(6, 'months'))) {
                    confirm = await BootboxHelper.confirm("A form of this type has already been completed. Are you sure you want to create a new form?");
                    break;
                }
                if (!forms[i].filledDate) {
                    confirm = await BootboxHelper.confirm("A form of this type has not been completed yet. Are you sure you want to create a new form?");
                    break;
                }
                break;
            }
        }

        if (confirm) {
            await FormService.saveForm(form);
            await this.load();
        }
    }

    async delete(form) {
        const confirm = await BootboxHelper.confirm("Are you sure you want to delete this form?");
        if (confirm) {
            await FormService.deleteForm(form.formID);
            await this.load();
        }
    }

    //--------------------------------------------------------------------------------------------------------------------
    //  Render
    //--------------------------------------------------------------------------------------------------------------------

    render() {
        const {
            isLoading,
            forms
        } = this.state;

        if (isLoading) {
            return (<Loader />);
        }
        return (<>

            <div className="tab-panel appt-history">
                <div className="panel tab-panel-content">

                    <div className="control-panel">
                        <select
                            className={"me-2"}
                            onChange={e => this.selectFormType(e.target.value)}
                            value={this.state.selectedFormTypeID}
                        >
                            {this.state.formTypes.map(ft =>
                                <option key={ft.formTypeID} value={ft.formTypeID}>{ft.name}</option>
                            )}
                        </select>
                        {this.state.formTypes.length > 0 &&                            
                            <button className="button button-primary button-small" onClick={e => this.createForm()} >
                                <span className="fas fa-clipboard"></span>{' '}
                                Add new
                            </button>
                        }
                    </div>
                    <table className="table waiting-list-table">
                        <thead>
                            <tr>
                                <th>Form Type</th>
                                <th>Form Status</th>
                                <th className="actions-column"></th>
                            </tr>
                        </thead>
                        <tbody>
                            {forms.map(form =>
                                <tr key={form.formID}>
                                    <td>
                                        {form.formType.name}
                                    </td>
                                    <td>
                                        {!form.filledDate && !form.createdDate && <>
                                            No form
                                        </>}
                                        {!form.filledDate && form.createdDate && <>
                                            Created {moment(form.dateCreated).format('DD-MM-YYYY')}
                                        </>}
                                        {form.filledDate && <>
                                            Completed {moment(form.filledDate).format('DD-MM-YYYY')}
                                        </>}
                                    </td>
                                    <td className="actions-column">
                                        <button className="button button-secondary button-small me-2" onClick={e => this.showFormFillModal(form)} >
                                            <span className="fas fa-clipboard"></span>{' '}
                                            {!form.filledDate && <>Fill out now</>}
                                            {form.filledDate && <>View</>}
                                        </button>
                                        <button className="button button-primary button-small me-2" onClick={e => this.delete(form)}>
                                            <span className="fas fa-clipboard"></span>{' '}
                                            Delete
                                        </button>
                                        {form.customer.mobileTel &&
                                            <button className="button button-secondary button-small me-2" onClick={e => this.sendSMS(form)}>
                                                <span className="fas fa-mobile-alt"></span>{' '}
                                                SMS
                                            </button>
                                        }
                                        {form.customer.email &&
                                            <button className="button button-secondary button-small me-2" onClick={e => this.sendEmail(form)}>
                                                <span className="fas fa-envelope"></span>{' '}
                                                Email
                                            </button>
                                        }
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>

                </div>
            </div>

            <FormFillModal ref={this.formFillModalRef}
                onClose={this.load} />
        </>);
    }
}

export default CustomerForms;