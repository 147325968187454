// Libs
import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import moment from 'moment';

//Services
import SalonService from 'services/SalonService';
import StockService from 'services/StockService';


// Components
import FloomlyComponent from 'components/FloomlyComponent';
import Loader from 'components/reusable/Loader';
import SuperTable from 'components/reusable/SuperTable';

class PreviousOrdersListTab extends FloomlyComponent {
    constructor(props) {
        super(props);
        this.NumItemsPerPage = 100;
        this.state = {
            isLoading: true,
            stockOrders:[]
        };
    }

    componentDidMount() {
        this.load();
    }

    async load() {
        const stockOrders = await StockService.getStockOrders();
        this.setState({
            stockOrders,
            pageNum: 0,
            numPages: Math.ceil(stockOrders.length / this.NumItemsPerPage)
        }, () => {
            this.loadPage(0);
        });
    }

    async loadPage(pageNum) {
        this.setState({
            isLoading: true
        });
        while (pageNum * this.NumItemsPerPage > this.state.stockOrders.length) {
            pageNum--;
        }
        const stockOrdersThisPage = this.state.stockOrders.slice(this.NumItemsPerPage * pageNum, this.NumItemsPerPage * pageNum + this.NumItemsPerPage);
        this.setState({
            stockOrdersThisPage,
            pageNum,
            isLoading: false
        });
    }

    renderStockOrders() {
        const {
            numPages,
            pageNum,
            stockOrdersThisPage
        } = this.state;
        const currency = SalonService.getCurrentCurrency();
        const pages = [...new Array(numPages).keys()];
        const pagination = numPages > 1 && (
            <ul className="pagination">
                {pages.map(page =>
                    <li key={page} className={pageNum == page ? 'active' : ''} onClick={e => this.loadPage(page)}>
                        {(page + 1)}
                    </li>
                )}
            </ul>
        );
        return (<>
            {pagination} 
            <div className="stock-order">
                <div className="stock-table-content-left">

                    <div className="">

                        <div className="">
                            <SuperTable
                                className="stock-orders-table"
                                rows={stockOrdersThisPage}
                                keyAccessor={s => s.stockOrderID}
                                emptyText="You have no stock orders"
                                cols={{
                                    reference: {
                                        label: 'Order Reference Id',
                                        className: 'reference-column'
                                    },
                                    dateOrdered: {
                                        label: 'Date of Order',
                                        className: 'date-column',
                                        getValue: (colInfo, stockOrder) => <>
                                            {
                                                <span>{moment(stockOrder.dateOrdered).format('DD/MM/YYYY')}</span>
                                            }
                                        </>
                                    },
                                    status: {
                                        label: 'Status',
                                        className: 'status-column'
                                    },
                                    noOfItemsOrdered: {
                                        label: 'Number of items ordered',
                                        className: 'no-of-items-column'
                                    },
                                    totalCost: {
                                        label: `Total amount ${currency.symbol}`,
                                        className: 'total-amount-column',
                                        getValue: (colInfo, stockOrder) => <>
                                            {
                                                <span>{currency.symbol}{stockOrder.totalCost}</span>
                                            }
                                        </>

                                    },
                                    orderedBy: {
                                        label: 'Ordered by',
                                        className: 'ordered-by-column'
                                    },
                                    viewOrder: {
                                        label: 'View Order',
                                        getValue: (colInfo, stockOrder) =>

                                            <>
                                                {stockOrder.status != 'Draft' ?
                                                    <Link to={`/stock/stock-ordering/existing-order/${stockOrder.stockOrderID}`}>
                                                        View Order
                                                    </Link> :
                                                    <Link to={`/stock/stock-ordering/create-new-stock-order/${stockOrder.stockOrderID}`}>
                                                        View and Complete Order
                                                    </Link>
                                                }

                                            </>
                                    }
                                }}
                            />
                        </div>
                    </div>



                </div>
            </div>
            {pagination} 
        </>);
    }

    render() {
        const {
            isLoading
        } = this.state;

        if (isLoading) {
            return (<Loader />);
        }
        return (<>

            <div className="stock-orders-list">
                {this.renderStockOrders() }
            </div>
        </>);
    }
}

export default withRouter(PreviousOrdersListTab);