// Libs
import React from 'react';
import moment from 'moment';

// Services & Helpers
import DiaryService from 'services/DiaryService';
import TextHelpers from 'helpers/TextHelpers';

// Components
import FloomlyComponent from 'components/FloomlyComponent';

class NextAppoinmentsPanel extends FloomlyComponent {
	constructor(props) {
		super(props);

		this.state = {
			checkOut: props.checkOut,
			isLoading: false,
		}
	}

	componentDidMount() {
		this.load();
	}

	async load() {
		this.setState({ isLoading: true });

		// Load related info
		if (!this.state.nextAppointments) {

			// Load next appointment(s) info
			const nextAppointments = await DiaryService.loadNextApointments(
				this.state.checkOut.customer.customerID,
				this.state.checkOut.date
			);

			this.setState({
				nextAppointments
			});
		}

		this.setState({
			isLoadingStep: false
		});
	}

    renderAppointmentServiceSummary(apptServices) {
        const items = [];
        apptServices.forEach(asv => {
            items.push(`${asv.service.name} with ${asv.stylistName} at ${asv.time ? moment(asv.time).format('HH:mm') : ''}`);
        });
        return items.join(', ');
    }

	render() {
		const {
			nextAppointments,
			checkOut,
		} = this.state;

		return (<>
			<div className="panel">
				<div className="panel-header">
					Next Appointment{nextAppointments && nextAppointments.length > 1 ? 's' : ''}
				</div>
				<div className="panel-body">

					{nextAppointments && nextAppointments.length == 0 &&
						<div className="panel-info-headline">
							None booked
						</div>
					}

					<div className="next-appointment-list">
						{nextAppointments && nextAppointments.map(nextAppointment =>
							<div key={nextAppointment.appointmentID} style={{ marginBottom: 15 }}>
								<div className="panel-info-headline">
									{moment(nextAppointment.date).format('Do MMM YYYY')}{' '}
								</div>
								<div className="panel-info-sub-headline">
									({TextHelpers.formatDateDiff(checkOut.date, nextAppointment.date)})
								</div>
								<div className="panel-info-sub-headline" style={{ marginTop: 10 }}>
									{this.renderAppointmentServiceSummary(nextAppointment.appointmentServices)}
								</div>
							</div>
						)}
					</div>

					<button className="button button-primary" onClick={e => this.props.onPreBookClicked(checkOut)}>
						Book Now
					</button>

				</div>
			</div>
		</>);
	}
}

export default NextAppoinmentsPanel;