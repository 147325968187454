// Libs
import React, { Component } from 'react';
import Modal from 'react-bootstrap/Modal';
import moment from 'moment';

// Services & Helpers
import EmailTemplateService from 'services/EmailTemplateService';

// Components
import Loader from 'components/reusable/Loader';
import SuperTable from 'components/reusable/SuperTable';


export default class EmailHistoryModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true
        };
    }

    show(opts) {
        return new Promise((resolve, reject) => {
            this.resolve = resolve;
            this.setState({
                isOpen: true,
                isLoading: true,
                emailTemplateID: opts.emailTemplateID
            }, () => {
                this.load();
            });
        });
    }

    hide() {
        this.setState({
            isOpen: false
        });
    }

    async load() {
        const { emailTemplateID } = this.state;
        const templateHistoryList = await EmailTemplateService.getHistory(emailTemplateID);
        this.setState({
            isLoading: false,
            templateHistoryList
        });
    }

    renderInner() {
        const { templateHistoryList } = this.state;

        return (
            <>
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <>
                        <div className="">
                            <SuperTable
                                className="email-templates-table"
                                rows={templateHistoryList}
                                cols={{
                                    templateSentDate: {
                                        label: 'Date',
                                        className: 'date-column',
                                        getValue: (colInfo, template) => <>
                                            {
                                                <><span>{moment(template.templateSentDate).format('DD/MM/YYYY')}</span></>
                                            }
                                        </>
                                    },
                                    sentCount: {
                                        label: '# Sent'
                                    },
                                    openCount: {
                                        label: '# Open'
                                    },
                                    clicksCount: {
                                        label: '# Clicks'
                                    },
                                    unsubscribeCount: {
                                        label: '# Unsubscribes'
                                    }
                                }}
                            />
                        </div>
                    </>
                </Modal.Body>
            </>
        );
    }

    render() {
        const {
            isOpen,
            isLoading
        } = this.state;

        if (!isOpen) {
            return null;
        }

        return (
            <Modal
                className="email-template-history-modal"
                show={isOpen}
                onHide={e => this.hide()}
            >
                {isLoading ?
                    <Modal.Body><Loader /></Modal.Body> :
                    this.renderInner()
                }
            </Modal>
        );
    }

}