// Libs
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import Modal from 'react-bootstrap/Modal';
import NumberFormat from 'react-number-format';

// Services & Helpers
import CustomerService from 'services/CustomerService';
import SalonService from 'services/SalonService';
import DiaryService from 'services/DiaryService';
import BootboxHelper from 'helpers/BootboxHelper';

// Components
import Loader from 'components/reusable/Loader';
import Money from 'components/reusable/Money';
import DatePicker from 'components/reusable/DatePicker';

//-------------------------------------------------------------------------------------------------------------------

export default class CustomerAccountHistoryModal extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true
        };
    }

    show(opts) {
        return new Promise((resolve, reject) => {
            this.resolve = resolve;
            this.onUpdate = opts.onUpdate;
            this.opts = opts;
            this.setState({
                isOpen: true,
                isLoading: true,
                customerAcctTrans: null,
                isAdding: false,
                customerID: opts.customerID
            }, () => {
                this.cancelNewTransaction();
                this.load();
            });
        });
    }
    
    hide() {
        this.setState({
           isOpen: false
        });
    }

    async load() {
        const { customerID } = this.state;
        const customer = await CustomerService.getSummary(customerID);
        const customerAcctTrans = await CustomerService.listAccountTransactions(customerID);
        let paymentMethods = await DiaryService.loadPaymentMethods();
        paymentMethods = paymentMethods.filter(pm => {
            switch (pm.code) {
                case 'OnAccount':
                case 'Account':
                case 'Voucher':
                case 'LoyaltyPoints':
                    return false;
            }
            return true;
        });
        this.setState({
            isLoading: false,
            paymentMethods,
            customer,
            customerAcctTrans
        });
    }

    updateNewTransactionField(fieldName, value) {
        const newTransaction = { ...this.state.newTransaction };
        newTransaction[fieldName] = value;
        this.setState({
            newTransaction
        });
    }

    cancelNewTransaction() {
        const now = moment();
        const datePart = now.format('YYYY-MM-DD');
        const hoursPart = now.format('HH');
        let minsPart = Math.floor(now.format('mm') / 5) * 5;
        if (minsPart < 10) minsPart = `0${minsPart}`
        const dateTime = moment(`${datePart}T${hoursPart}:${minsPart}`).toDate();

        this.setState({
            newTransaction: {
                customerID: this.state.customerID,
                date: dateTime,
                description: '',
                isNegative: false,
                amount: ''
            },
            isAdding: false
        });
    }

    async saveNewTransaction() {
        this.setState({ isLoading: true });
        await CustomerService.addAccountTransaction(this.state.newTransaction);
        this.load();
        this.cancelNewTransaction();
        //this.hide();
        if (this.onUpdate) {
            this.onUpdate();
        }
    }

    async confirmDelete(id) {
        const confirm = await BootboxHelper.confirm('Are you sure you want to delete this account transaction? This will affect the customer\'s overall account balance.');
        if (confirm) {
            this.setState({ isLoading: true });

            // Do the delete and reload
            await CustomerService.deleteAccountTransaction(id);
            this.load();
            if (this.onUpdate) {
                this.onUpdate();
            }
        }
    }

    render() {
        const {
            isOpen,
            isLoading
        } = this.state;

        if (!isOpen) {
            return null;
        }

        return (
            <Modal
                className="customer-account-history-modal"
                show={isOpen}
                onHide={e => this.hide()}
            >
                {isLoading ?
                    <Modal.Body><Loader /></Modal.Body> :
                    this.renderInner()
                }
            </Modal>
        );
    }

    renderInner() {
        const {
            customer,
            customerAcctTrans,
            newTransaction,
            paymentMethods,
            isAdding
        } = this.state;
        const currency = SalonService.getCurrentCurrency();

        return (<>
            <Modal.Header closeButton>
                <Modal.Title>
                    Account History

                    {customer.accountBalance != 0 &&
                        <div className="modal-subtitle">
                            Account Balance: <Money amount={customer.accountBalance} />
                        </div>
                    }
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>

                <ul className={`fv2-thing-list ${isAdding ? '' : 'border-on-bottom'} mb-3`}>

                    {customerAcctTrans.map(cat =>
                        <li className="thing" key={cat.id}>

                            <div className="thing-side">

                                <div className={`thing-icon bordered ${cat.amount > 0 ? 'green' : 'red'} fa-solid ${cat.amount > 0 ? 'fa-arrow-right' : 'fa-arrow-left'}`} />

                                <div className="thing-detail">

                                    {cat.amount > 0 ? 'In' : 'Out'}

                                </div>

                            </div>

                            <div className="thing-main">

                                {!!cat.date &&
                                    <div className="thing-detail heading">
                                        {moment(cat.date).format('DD/MM/YYYY HH:mm')}
                                    </div>
                                }

                                {!!cat.description &&
                                    <div className="thing-detail">
                                        {cat.description}
                                    </div>
                                }

                                <div className="thing-detail">

                                    <Money amount={Math.abs(cat.amount)} />
                                    {!!cat.paymentMethodName && <> by </>}
                                    {cat.paymentMethodName}

                                </div>

                                {!!cat.appointmentID &&
                                    <div className="thing-detail">

                                        <Link className="button button-secondary button-small" to={'/diary/' + moment(cat.date).format('YYYY-MM-DD')}>
                                            View in Diary
                                        </Link>

                                    </div>
                                }

                            </div>

                            <div className="thing-buttons-icons">

                                <button type="button" className="button button-primary" onClick={() => this.confirmDelete(cat.id)}>
                                    <span className="fa fa-times"></span>
                                </button>

                            </div>
                            
                        </li>
                    )}

                    {isAdding &&
                        <li className="thing">

                            <div className="thing-side">

                                <div className="thing-detail">
                                    
                                    <select
                                        value={newTransaction.isNegative? 'Out' : 'In'}
                                        onChange={e => this.updateNewTransactionField('isNegative', e.target.value == 'Out')}
                                    >
                                        <option value="In">In</option>
                                        <option value="Out">Out</option>
                                    </select>

                                </div>
                                
                                <div className={`thing-icon bordered fa-solid ${newTransaction.isNegative ? 'fa-arrow-left red' : 'fa-arrow-right green'}`} />

                            </div>

                            <div className="thing-main">

                                <div className="mb-1">

                                    <DatePicker
                                        selected={newTransaction.date || ''}
                                        onChange={value => this.updateNewTransactionField('date', value)}
                                        showTimeSelect
                                        timeIntervals={5}
                                        dateFormat="dd/MM/yyyy hh:mm aa"
                                    />

                                </div>

                                <input
                                    className="w-100 mb-1"
                                    type="text"
                                    value={newTransaction.description}
                                    placeholder="Description"
                                    onChange={e => this.updateNewTransactionField('description', e.target.value)}
                                />

                                <NumberFormat
                                    thousandSeparator={currency.thousandSeparator}
                                    decimalSeparator={currency.decimalSeparator}
                                    prefix={currency.symbol}
                                    className="has-prefix w-100 mb-1"
                                    placeholder="Amount"
                                    value={newTransaction.amount != 0 ? Math.abs(newTransaction.amount) : ''}
                                    onValueChange={(values) => {
                                        const val = Math.abs(Number(values.value));
                                        if (val != Math.abs(newTransaction.amount)) {
                                            this.updateNewTransactionField('amount', val);
                                        }
                                    }}
                                />
                                
                                <select
                                    value={newTransaction.paymentMethodID || ''}
                                    onChange={e => this.updateNewTransactionField('paymentMethodID', e.target.value)}
                                >
                                    <option value="">(Select)</option>
                                    {paymentMethods.map(pm =>
                                        <option key={pm.paymentMethodID} value={pm.paymentMethodID}>
                                            {pm.shortName}
                                        </option>
                                    )}
                                </select>

                                <button className="button button-primary button-small ms-1" onClick={e => this.saveNewTransaction()}>
                                    Save
                                </button>

                            </div>

                            <div className="thing-buttons-icons">

                                <button type="button" className="button button-primary" onClick={() => this.cancelNewTransaction()}>
                                    <span className="fa fa-times"></span>
                                </button>

                            </div>
                            
                        </li>
                    }

                </ul>

                {!isAdding &&
                    <button type="button" className="button button-primary button-small" onClick={() => this.setState({ isAdding: true })}>
                        Add a new transaction
                    </button>
                }

            </Modal.Body>
            <Modal.Footer>
                <button type="button" className="btn btn-primary" onClick={e => this.hide()}>
                    Close
                </button>
            </Modal.Footer>
        </>);
    }

}
