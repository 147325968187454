import PropTypes from 'prop-types'
import clsx from 'clsx'
import scrollbarSize from 'dom-helpers/scrollbarSize'
import React from 'react'

import DateContentRow from './DateContentRow'
import Header from './Header'
import ResourceHeader from './ResourceHeader'
import DateHeader from './DateHeader'
import { notify } from './utils/helpers'

class TimeGridHeader extends React.Component {
    handleHeaderClick = (date, view, e) => {
        e.preventDefault()
        notify(this.props.onDrillDown, [date, view])
    }

    renderHeaderCells(range, dateHeaderComponent, resource) { // KJA
        let {
            localizer,
            getDrilldownView,
            getNow,
            getters: { dayProp },
            components: { header: HeaderComponent = dateHeaderComponent }, // KJA
        } = this.props

        const today = getNow()

        return range.map((date, i) => {
            let drilldownView = getDrilldownView(date)
            let label = localizer.format(date, 'dayFormat')

            const { className, style } = dayProp(date)

            // KJA
            // Don't render this day if not visible for this resource
            if (this.props.getDayVisible && !this.props.getDayVisible(date, resource)) {
                return null;
            }

            let header = (
                <HeaderComponent date={date} label={label} localizer={localizer} resource={resource} />
            )

            return (
                <div
                    key={i}
                    style={style}
                    className={clsx(
                        'rbc-header',
                        className,
                        localizer.isSameDate(date, today) && 'rbc-today'
                    )}
                >
                    {drilldownView ? (
                        <a
                            href="#"
                            onClick={e => this.handleHeaderClick(date, drilldownView, e)}
                        >
                            {header}
                        </a>
                    ) : (
                        <span>{header}</span>
                    )}
                </div>
            )
        })
    }
    renderRow = resource => {
        let {
            events,
            rtl,
            selectable,
            getNow,
            range,
            getters,
            localizer,
            accessors,
            components,
            resizable,
        } = this.props

        const resourceId = accessors.resourceId(resource)
        let eventsToDisplay = resource
            ? events.filter(event => accessors.resource(event) === resourceId)
            : events

        return (
            <DateContentRow
                isAllDay
                rtl={rtl}
                getNow={getNow}
                minRows={2}
                range={range}
                events={eventsToDisplay}
                resourceId={resourceId}
                className="rbc-allday-cell"
                selectable={selectable}
                selected={this.props.selected}
                components={components}
                accessors={accessors}
                getters={getters}
                localizer={localizer}
                onSelect={this.props.onSelectEvent}
                onDoubleClick={this.props.onDoubleClickEvent}
                onKeyPress={this.props.onKeyPressEvent}
                onSelectSlot={this.props.onSelectSlot}
                longPressThreshold={this.props.longPressThreshold}
                resizable={resizable}
            />
        )
    }

    render() {
        let {
            width,
            rtl,
            resources,
            range,
            events,
            getNow,
            accessors,
            selectable,
            components,
            getters,
            scrollRef,
            localizer,
            isOverflowing,
            components: {
                timeGutterHeader: TimeGutterHeader,
                resourceHeader: ResourceHeaderComponent = ResourceHeader,
                dateHeader: DateHeaderComponent = DateHeader // KJA
            },
            resizable,
        } = this.props

        let style = {}
        if (isOverflowing) {
            style[rtl ? 'marginLeft' : 'marginRight'] = `${scrollbarSize()}px`
        }

        const groupedEvents = resources.groupEvents(events)

        return (
            <div
                style={style}
                ref={scrollRef}
                className={clsx('rbc-time-header', isOverflowing && 'rbc-overflowing')}
            >
                <div
                    className="rbc-label rbc-time-header-gutter"
                    style={{ width, minWidth: width, maxWidth: width }}
                >
                    {TimeGutterHeader && <TimeGutterHeader />}
                </div>

                {resources.map(([id, resource], idx) => (
                    <div className="rbc-time-header-content" key={id || idx}>
                        {resource && (
                            <div className="rbc-row rbc-row-resource" key={`resource_${idx}`}>
                                <div className="rbc-header">
                                    <ResourceHeaderComponent
                                        index={idx}
                                        label={accessors.resourceTitle(resource)}
                                        resource={resource}
                                    />
                                </div>
                            </div>
                        )}
                        <div
                            className={`rbc-row rbc-time-header-cell${range.length <= 1 ? ' rbc-time-header-cell-single-day' : ''
                                }`}
                        >
                            {this.renderHeaderCells(range, DateHeaderComponent, resource)} {/* KJA */}
                        </div>
                        <DateContentRow
                            isAllDay
                            rtl={rtl}
                            getNow={getNow}
                            minRows={2}
                            range={range}
                            events={groupedEvents.get(id) || []}
                            resourceId={resource && id}
                            className="rbc-allday-cell"
                            selectable={selectable}
                            selected={this.props.selected}
                            components={components}
                            accessors={accessors}
                            getters={getters}
                            localizer={localizer}
                            onSelect={this.props.onSelectEvent}
                            onDoubleClick={this.props.onDoubleClickEvent}
                            onKeyPress={this.props.onKeyPressEvent}
                            onSelectSlot={this.props.onSelectSlot}
                            longPressThreshold={this.props.longPressThreshold}
                            resizable={resizable}
                        />
                    </div>
                ))}
                {/*<div
                    className="rbc-label rbc-time-header-gutter"
                    style={{ width, minWidth: width, maxWidth: width }}
                >
                    {TimeGutterHeader && <TimeGutterHeader />}
                </div>*/}
            </div>
        )
    }
}

TimeGridHeader.propTypes = {
    range: PropTypes.array.isRequired,
    events: PropTypes.array.isRequired,
    resources: PropTypes.object,
    getNow: PropTypes.func.isRequired,
    isOverflowing: PropTypes.bool,

    rtl: PropTypes.bool,
    resizable: PropTypes.bool,
    width: PropTypes.number,

    localizer: PropTypes.object.isRequired,
    accessors: PropTypes.object.isRequired,
    components: PropTypes.object.isRequired,
    getters: PropTypes.object.isRequired,

    selected: PropTypes.object,
    selectable: PropTypes.oneOf([true, false, 'ignoreEvents']),
    longPressThreshold: PropTypes.number,

    onSelectSlot: PropTypes.func,
    onSelectEvent: PropTypes.func,
    onDoubleClickEvent: PropTypes.func,
    onKeyPressEvent: PropTypes.func,
    onDrillDown: PropTypes.func,
    getDrilldownView: PropTypes.func.isRequired,
    scrollRef: PropTypes.any,
}

export default TimeGridHeader
