// Libs
import React from 'react';
import { Route } from 'react-router';
import { Link, withRouter } from 'react-router-dom';

// Services & Helpers
import CapabilityService from 'services/CapabilityService';
import FormHelper from 'helpers/FormHelper';
import BootboxHelper from 'helpers/BootboxHelper';

// Components
import FloomlyComponent from 'components/FloomlyComponent';
import InfoBar from 'components/layout/InfoBar'
import Loader from 'components/reusable/Loader';

//-------------------------------------------------------------------------------------------------------------------

class SettingsCapabilityEdit extends FloomlyComponent {

    constructor(props) {
        super(props);

        // Init state
        this.state = {
            isLoading: true,
            capability: null
        };

        this.updateField = this.updateField.bind(this);
        
        this.formHelper = new FormHelper({
            fields: {
                name: { label: 'Name' },
            },
            getValue: (fieldName, fieldInfo) => this.state.capability[fieldName],
            setValue: this.updateField
        });
    }

    componentDidMount() {
        this.load();
    }

    //--------------------------------------------------------------------------------------------------------------------

    async load() {
        const capabilityID = Number(this.props.match.params.capabilityID) || 0;

        let capability;
        if (capabilityID) {
            capability = await CapabilityService.get(capabilityID);
        } else {
            capability = {
                // New
            };
        }

        const capabilitys = await CapabilityService.list();

        this.setState({
            isLoading: false,
            capability: capability,
            capabilitys: capabilitys
        });
    }

    goBack() {
        this.props.history.push('/settings/capabilities');
    }

    async save() {
        this.setState({ isLoading: true });
        await CapabilityService.save(this.state.capability);
        this.goBack();
    }

    updateField(fieldName, value, fieldInfo) {
        const capability = { ...this.state.capability };
        capability[fieldName] = value;
        this.setState({
            capability: capability
        });
    }

    async confirmDelete() {
        const confirm = await BootboxHelper.confirm('Are you sure you want to delete this capability?');
        if (confirm) {
            this.delete();
        }
    }

    async delete() {
        await CapabilityService.delete(this.state.capability.capabilityID);
        this.props.history.replace('/settings/capabilities');
    }

    //--------------------------------------------------------------------------------------------------------------------
    //  Render
    //--------------------------------------------------------------------------------------------------------------------

    renderInfoBar() {
        const {
            capability
        } = this.state;

        return (
            <InfoBar className="settings-info-bar" containerClassName="floating-on-mobile" sticky={true}>

                <div className="info-bar-panel-section">

                    <button className="button" onClick={() => this.goBack()}>
                        <span className="fa fa-chevron-left"></span>{' '}
                        Back
                    </button>

                </div>

                <div className="info-bar-panel-section info-bar-panel-section-text desktop-only">
                    {capability.capabilityID ? capability.name : 'New capability'}
                </div>
                
                <div className="info-bar-panel-section ml-auto">

                    {capability.capabilityID &&
                        <button className="button" style={{ marginRight: 10 }} onClick={() => this.confirmDelete()}>
                            <span className="fa fa-times"></span>{' '}
                            Delete
                        </button>
                    }
                    <button className="button" onClick={() => this.save()}>
                        <span className="fa fa-check"></span>{' '}
                        Save
                    </button>
                    
                </div>

            </InfoBar>
        );
    }

    render() {
        const {
            isLoading,
            capability
        } = this.state;

        if (isLoading) {
            return (<Loader />);
        }

        return (<>
            {this.renderInfoBar()}

            <div className="settings-page-main">
                <div className="panel">
                    <div className="panel-body">

                        {this.formHelper.renderFormGroups([
                            'name'
                        ])}

                    </div>
                </div>

            </div>

        </>);
    }

};

export default withRouter(SettingsCapabilityEdit);