import React, { Component } from 'react';
import moment from 'moment';
import Modal from 'react-bootstrap/Modal';

import FormHelper from 'helpers/FormHelper';

import Loader from 'components/reusable/Loader';
import FormService from 'services/FormService';
import BootboxHelper from '../helpers/BootboxHelper';

export default class FormFillModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            form: {},
            responses: {}
        };

        this.updateField = this.updateField.bind(this);
        this.setDateAndSave = this.setDateAndSave.bind(this);
        this.save = this.save.bind(this);
        this.hide = this.hide.bind(this);
        this.print = this.print.bind(this);

        this.formHelper = new FormHelper({
            fields: {
                name: { label: 'Name', validation: { isRequired: true } },
                email: { label: 'Email', validation: { isRequired: true } },
                phone: { label: 'Mobile Phone', validation: { isRequired: true } },
                over16: {
                    type: 'radio-boolean',
                    label: 'Are you over 16?',
                    validation: { isRequired: true }
                },
                hasScalpSensitivity: {
                    type: 'radio-boolean',
                    label: 'Do you have/have you had any skin or scalp sensitivity?',
                    validation: { isRequired: true }
                },
                scalpSensitivity: { label: 'Please explain when and from what ' },
                hasAllergicReaction: {
                    type: 'radio-boolean',
                    label: 'Have you ever had an allergic reaction with a previous patch test, hair colour, tattoo, henna, permanent makeup, skin product, other?',
                    validation: { isRequired: true }
                },
                allergicReaction: {
                    label: 'Please explain'
                },
                hasSkinAllergy: {
                    type: 'radio-boolean',
                    label: 'Do you have/have you had any other skin allergies?',
                    validation: { isRequired: true }
                },
                skinAllergy: {
                    label: 'Please give details'
                },
                hasMedicalCondition: {
                    type: 'radio-boolean',
                    label: 'Do you have/have you had any health conditions or treatments where you have had radiation, chemotherapy, been prescribed steroids or other medications?',
                    validation: { isRequired: true }
                },
                hasMedicalAdvice: {
                    type: 'radio-boolean',
                    label: 'Have you ever been advised by a medical professional not to use hair colour or other chemical treatments in your hair?',
                    validation: { isRequired: true }
                },
                hasHairLoss: {
                    type: 'radio-boolean',
                    label: 'Have you ever experienced excessive hair loss or damage to your hair?',
                    validation: { isRequired: true }
                },
                hairLoss: {
                    label: 'Please give details'
                },
                hasActivities: {
                    type: 'radio-boolean',
                    label: 'Do you carry out any of the following activities regularly? Swimming / Gym / Sauna / Steam Room / Horse Riding / Other...',
                    validation: { isRequired: true }
                },
                activities: {
                    label: 'Please specify'
                },
                products: {
                    label: 'What Shampoo/Conditioner/Styling Products do you use?',
                    validation: { isRequired: true }
                },
                shampooFreq: {
                    label: 'How often do you need to shampoo your hair?',
                    validation: { isRequired: true }
                },
                heatedTools: {
                    label: 'What heated tools (hairdryers/straighteners/curling tools) do you use and how often?',
                    validation: { isRequired: true }
                },
                hairOpinion: {
                    label: 'What are your likes/dislikes about your hair?',
                    validation: { isRequired: true }
                },
                heardOfUs: {
                    label: 'Where did you hear about us?',
                    validation: { isRequired: true }
                }
            },
            getValue: (fieldName, fieldInfo) => this.state.responses[fieldName],
            setValue: this.updateField,
            isDisabled: (disabled) => this.state.form.filledDate,
            onValidate: (validation) => this.setState({ validation: validation })
        });
    }

    async updateField(fieldName, value, fieldInfo) {
        const responses = { ...this.state.responses };
        responses[fieldName] = value;
        const form = this.state.form;
        form.responses = responses;
        await this.setState({
            form: form,
            responses: responses,
            saved: false
        });
        this.save();
    }

    setDateAndSave() {
        if (this.state.validation.isValid) {
            const form = this.state.form;
            form.filledDate = moment().toDate();
            this.setState({
                form: form
            })
            FormService.saveForm(form);
            this.props.onClose();

            this.setState({
                isOpen: false
            });
        } else {
            BootboxHelper.alert('Please fill in all sections of the form:<br/>&nbsp;' + this.formHelper.getValidationSummaryHTML());
        }
    }

    async save() {
        if (!this.state.form.filledDate) {
            var formID = this.state.form.formID;
            await FormService.saveFormResponse(formID, this.state.responses);
        }
    }

    show(opts) {
        return new Promise((resolve, reject) => {
            this.resolve = resolve;
            this.setState({
                formID: opts.formID,
                formTypeID: opts.formTypeID,
                customerID: opts.customerID,
                isLoading: true,
                isOpen: true
            }, () => {
                this.load();
            })
        })
    }

    async hide(message) {
        var confirm = false
        if (!this.state.form.filledDate) {
            confirm = await BootboxHelper.confirm(message);
        } else {
            confirm = true;
        }
        if (confirm) {
            this.props.onClose();

            this.setState({
                isOpen: false
            });
        }
    }

    async load() {
        let form, responses, formID;
        if (this.state.formID) {
            form = await FormService.getForm(this.state.formID);
            formID = this.state.formID;
        } else {
            form = {
                customerID: this.state.customerID,
                formTypeID: this.state.formTypeID
            };
            responses = {};

            formID = await FormService.saveForm(form);
            form = await FormService.getForm(formID);
        }
        responses = await FormService.getFormResponse(formID);
        this.setState({
            form,
            responses,
            isLoading: false
        });
    }

    print() {
        window.print();
    }

    render() {
        if (!this.state.isOpen) {
            return null;
        }

        return (<>
            <Modal
                show
                id="form-fill-modal"
                className="form-fill-modal"
                onHide={e => this.hide()}
            >
                <Modal.Header>
                    <Modal.Title>{this.state.form?.formType?.name}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {this.renderForm()}
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="button button-secondary button-small" onClick={e => this.hide('Are you sure you want to close this form?')}>
                        Close
                    </button>

                    <button type="button" className="button button-secondary button-small" onClick={e => this.print()}>
                        Print
                    </button>

                    <button type="button" className="button button-secondary button-small" disabled={this.state.saved} onClick={e => this.setState({ saved: true })}>
                        {!this.state.saved && <>Save</>}{this.state.saved && <>Saved</>}
                    </button>

                    <button type="button" className="button button-primary button-small" disabled={this.state.form.filledDate} onClick={e => this.setDateAndSave()}>
                        Save and Complete
                    </button>

                </Modal.Footer>
            </Modal>
        </>);
    }

    renderForm() {
        const {
            isLoading,
            responses
        } = this.state;

        if (isLoading) {
            return (<Loader />);
        }
        return (<>
            {this.formHelper.renderFormGroups([
                'name',
                'email',
                'phone',
                'over16',
                'hasScalpSensitivity',
            ])}
            {(responses?.hasScalpSensitivity == true || responses?.hasScalpSensitivity == 'true') && this.formHelper.renderFormGroup('scalpSensitivity')}
            {this.formHelper.renderFormGroup('hasAllergicReaction')}
            {(responses?.hasAllergicReaction == true || responses?.hasAllergicReaction == 'true') && this.formHelper.renderFormGroup('allergicReaction')}
            {this.formHelper.renderFormGroup('hasSkinAllergy')}
            {(responses?.hasSkinAllergy == true || responses?.hasSkinAllergy == 'true') && this.formHelper.renderFormGroup('skinAllergy')}
            {this.formHelper.renderFormGroups([
                'hasMedicalCondition',
                'hasMedicalAdvice',
                'hasHairLoss',
            ])}
            {(responses?.hasHairLoss == true || responses?.hasHairLoss == 'true') && this.formHelper.renderFormGroup('hairLoss')}
            {this.formHelper.renderFormGroup('hasActivities')}
            {(responses?.hasActivities == true || responses?.hasActivities == 'true') && this.formHelper.renderFormGroup('activities')}
            {this.formHelper.renderFormGroups([
                'products',
                'shampooFreq',
                'heatedTools',
                'hairOpinion',
                'heardOfUs'
            ])}
        </>);
    }
}