// Libs
import React from 'react';

// Services
import BaseService from 'services/BaseService';

//-------------------------------------------------------------------------------------------------------------------

class ErrorBoundary extends React.Component {

    constructor(props) {
        super(props);

        this.state = { }
    }
    
    static getDerivedStateFromError(error) {
        return { hasError: true };
    }

    async componentDidCatch(error, errorInfo) {
        await BaseService.callAPI('debug/log-client-error', {
            error: error.toString(),
            stackTrace: errorInfo.componentStack,
            url: window.location.href
        });
    }

    render() {
        if (this.state.hasError) {
            return (
                <div className="panel error-panel">
                    <div className="panel-body">

                        <h1>Sorry, there's been a problem</h1>
                        <h2>Please contact support. </h2>

                        {/*
                        <button className="button button-primary">
                            View error details
                        </button>
                        */}
                        
                    </div>
                </div>
            );
        }

        return this.props.children;
    }

}

export default ErrorBoundary;