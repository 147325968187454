// Libs
import React from 'react';
import { Link, withRouter } from 'react-router-dom';

// Services
import StockService       from 'services/StockService';
import SearchService      from 'services/SearchService';
import BootboxHelper      from 'helpers/BootboxHelper';
import GlobalStateService from 'services/GlobalStateService';

// Components
import FloomlyComponent from 'components/FloomlyComponent';
import InfoBar      from 'components/layout/InfoBar'
import Loader       from 'components/reusable/Loader';
import Search       from 'components/reusable/Search';

//-------------------------------------------------------------------------------------------------------------------

class StockCheckOutPage extends FloomlyComponent {

    constructor(props) {
        super(props);

        this.searchRef = React.createRef();

        // Init state
        this.state = {
            stockItems: []
        };
        this.stockItemIDs = {};
    }

    //--------------------------------------------------------------------------------------------------------------------

    async selectStockItem(stockItem) {
        const stockItems = [...this.state.stockItems];

        // Get more info
        stockItem = await StockService.getStockItem(stockItem.id);
        var index = stockItems.findIndex(e => e.stockItemID === stockItem.stockItemID);
        if (index > -1) {
            stockItems[index].delta += 1;
        } else {
            stockItem.delta = 1;

            // Add to the list
            stockItems.push(stockItem);
            this.stockItemIDs[stockItem.stockItemID] = true;
        }
        this.setState({
            stockItems
        });
    }

    confirmRemoveStockItem(index) {
        this.removeStockItem(index);
    }

    removeStockItem(index) {
        const stockItems = [...this.state.stockItems];
        const stockItem = stockItems.splice(index, 1)[0];
        delete this.stockItemIDs[stockItem.stockItemID];
        this.setState({
            stockItems
        });
    }

    changeStockLevel(index, amount) {
        const stockItems = [...this.state.stockItems];
        const newAmount = stockItems[index].delta + amount;
        if (newAmount >= 0) {
            stockItems[index].delta = newAmount;
        }
        this.setState({
            stockItems
        });
    }

    async confirmCheckOut() {
        const numItems = this.state.stockItems.length;
        const confirm = await BootboxHelper.confirm('Complete checkout (' + numItems + ' item' + (numItems != 1 ? 's' : '') + ')?');
        if (confirm) {
            this.checkOut();
        }
    }

    async checkOut() {
        this.setState({ isLoading: true });
        await StockService.checkOut(this.state.stockItems.map(si => ({
            stockItemID: si.stockItemID,
            delta: si.delta
        })));
        this.stockItemIDs = {};
        this.setState({
            isLoading: false,
            stockItems: []
        });
    }

    //--------------------------------------------------------------------------------------------------------------------
    //  Render
    //--------------------------------------------------------------------------------------------------------------------

    renderMainContent() {
        const {
            isLoading,
            stockItems
        } = this.state;

        if (isLoading) {
            return (<Loader />);
        }

        return (<>

            <div className="stock-checkout">

                <div className="stock-panel">
                    <div className="panel-header">
                        Stockroom Check-Out
                    </div>
                    <div className="panel-body">

                        <div className="service-search search-box-absolute">
                            <Search
                                ref={this.searchRef}
                                className="search-box"
                                autoFocus={true}
                                placeholder="Search for a stock item..."
                                search={async (query, setResults, maxResults, state) => {
                                    let results = await SearchService.search(query, ['StockItem'], { state });
                                    //results = results.filter(r => !this.stockItemIDs[r.id]);
                                    setResults(results);
                                    if (results.length === 1 && results[0].stockBarcodes === query) {
                                        this.selectStockItem(results[0]);
                                        this.searchRef.current.clearSearch();
                                        this.searchRef.current.focus();
                                    }
                                }}
                                renderResult={(result, index, info) =>
                                    <div key={index} className="search-result list-item" onMouseDown={e => {
                                        this.selectStockItem(result);
                                        info.clearSearch();
                                        info.focus();
                                    }}>
                                        <div className="list-item-name">
                                            {!!result.stockManufacturerName &&
                                                <div style={{ fontWeight: 'bold' }}>
                                                    {result.stockManufacturerName} &raquo; {result.stockProductRangeName}
                                                </div>
                                            }
                                            {result.fullName || result.name}
                                        </div>
                                    </div>
                                }
                            />
                        </div>
                        
                        {stockItems.length > 0 && <>
                            <table className="stock-checkout-selected-items">
                                <thead>
                                    <tr>
                                        <th>Product</th>
                                        <th>Size</th>
                                        <th style={{ width: '100px', textAlign: 'center' }}>Current</th>
                                        <th style={{ width: '100px', textAlign: 'center' }}>Amount</th>
                                        <th style={{ width: '100px', textAlign: 'center' }}>New</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {stockItems.map((stockItem, index) =>
                                        <tr key={stockItem.stockItemID}>
                                            <td>
                                                {stockItem.product.fullName || stockItem.product.name}

                                                <button className="button remove-button" onClick={e => this.confirmRemoveStockItem(index)}>
                                                    <span className="fa fa-times"></span>
                                                </button>
                                            </td>
                                            <td style={{ textAlign: 'center' }}>{stockItem.size}{stockItem.product.stockUnit}</td>
                                            <td style={{ textAlign: 'center' }}>{stockItem.currentStock}</td>
                                            <td className="stock-checkout-controls-cell">
                                                <div style={{ textAlign: 'center', marginBottom: 10, display: 'block' }}>
                                                    {stockItem.delta}
                                                </div>

                                                <div>

                                                    <button className="button button-secondary remove-stock-button" onClick={e => this.changeStockLevel(index, (e.shiftKey ? -5 : -1))}>
                                                        <span className="fa fa-chevron-left"></span>
                                                    </button>

                                                    <button className="button button-secondary add-stock-button" onClick={e => this.changeStockLevel(index, (e.shiftKey ? 5 : 1))}>
                                                        <span className="fa fa-chevron-right"></span>
                                                    </button>

                                                </div>
                                            </td>
                                            <td style={{ textAlign: 'center' }}>
                                                {stockItem.currentStock - stockItem.delta}
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>

                            <div className="check-out-button-container">

                                <button className="button button-primary" onClick={e => this.confirmCheckOut()}>
                                    Complete Check-Out
                                </button>

                            </div>
                        </>}

                    </div>

                </div>

            </div>

        </>);
    }

    renderInfoBar() {
        const loginDetails = GlobalStateService.getValue('loginDetails');

        return (
            <InfoBar className="stock-info-bar" sticky={true}>

                <div className="info-bar-panel-section info-bar-panel-section-text">

                    Stockroom

                </div>

                <div className="info-bar-panel-section ml-auto">

                    {loginDetails.permissions['StockroomViewStockManager'] &&
                        <Link className="button" to="/stock/manager">
                            <span className="fa fa-boxes"></span>{' '}
                            Stock Manager
                        </Link>
                    }

                </div>

            </InfoBar>
        );
    }

    render() {
        return (<>
            <div className="page-content">
                <div className="page-content-left">
                    {/*{this.renderSideContent()}*/}
                </div>
                <div className="page-content-right">
                    <div className="page-content-right-inner">
                        {this.renderInfoBar()}
                    </div>
                </div>
            </div>

            {this.renderMainContent()}
        </>);
    }

};

export default withRouter(StockCheckOutPage);