// Libs
import React, { Component } from 'react';
import { Link } from 'react-router-dom';

//-------------------------------------------------------------------------------------------------------------------

export default class TabPanel extends Component {

    constructor(props) {
        super(props);
        this.state = {

        };
    }

    static defaultProps = {
    };

    onClick(c, element) {
        const tabPanelTabs = element.closest('.tab-panel-tabs');
        const isMobile = window.matchMedia('(pointer: coarse)').matches;
        if (isMobile && !tabPanelTabs.classList.contains('open')) {
            tabPanelTabs.classList.add('open');
        } else {
            (c.props.onClick ?? this.props.onChangeTab)(c.props.id);
            tabPanelTabs.classList.remove('open');
        }
    }

    //--------------------------------------------------------------------------------------------------------------------
    //  Render
    //--------------------------------------------------------------------------------------------------------------------

    render() {
        let currentTab;

        const children = React.Children.toArray(this.props.children);
        if (this.props.tab) {
            currentTab = children.find(c => c.props.id == this.props.tab);
        } else {
            currentTab = children[0];
        }

        let currentTabContent = '';
        if (currentTab) {
            if (currentTab.props.render) {
                currentTabContent = currentTab.props.render(currentTab);
            } else {
                currentTabContent = (
                    <div className="tab-panel-content">
                        {currentTab}
                    </div>
                );
            }
        }

        return (
            <div className={'tab-panel ' + (this.props.className || '')}>
                <ul className="tab-panel-tabs">
                    {React.Children.map(this.props.children, (c, index) => <React.Fragment key={c ? c.props.id : 'tab-' + index}>
                        {!!c && (
                            c.props.href ?
                                <li
                                    className={(c.props.id == this.props.tab ? 'active ' : '') + (c.props.tabClassName || '')}
                                >
                                    <Link to={c.props.href}>
                                        <span>{c.props.title}</span>
                                    </Link>
                                </li>
                                :
                                <li
                                    onClick={e => this.onClick(c, e.target)}
                                    className={(c.props.id == this.props.tab ? 'active ' : '') + (c.props.tabClassName || '')}
                                >
                                    <span>{c.props.title}</span>
                                    <button className="tab-panel-menu-button">
                                        Menu <span className="fa-solid fa-chevron-down" />
                                    </button>
                                </li>
                        )}
                    </React.Fragment>)}
                    {this.props.inlineText &&
                        <li className="inline-text">
                            {this.props.inlineText}
                        </li>
                    }
                </ul>
                {currentTabContent}
            </div>
        );
    }

}

export class Tab extends Component {

    render() {
        return (<>
            {this.props.children}
        </>);
    }

}
