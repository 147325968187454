// Libs
import React, { Component } from 'react';
import moment from 'moment';

// Services & Helpers
import DiaryService         from 'services/DiaryService';
import TextHelpers          from 'helpers/TextHelpers';

// Components
import FloomlyComponent     from 'components/FloomlyComponent';
import Loader               from 'components/reusable/Loader';
import AppointmentSummary   from 'components/reusable/AppointmentSummary';

//-------------------------------------------------------------------------------------------------------------------

class PreviousAppointments extends FloomlyComponent {

    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            currentIndex: 0,
            previousAppointments: []
        };
    }

    componentDidMount() {
        this.load();
    }

    async load() {
        // Load
        const appointment = this.props.appointment;
        const previousAppointments = await DiaryService.loadPreviousApointments(appointment.customer.customerID, appointment.date, null);

        // Update UI
        this.setState({
            isLoading: false,
            previousAppointments
        });

        if (previousAppointments.length > 0) {
            this.setIndex(0);
        }
    }

    move(delta) {
        const newIndex = this.state.currentIndex + delta;
        if (newIndex >= 0 && newIndex <= this.state.previousAppointments.length - 1) {
            this.setIndex(newIndex);
        }
    }

    setIndex(index) {
        this.setState({
            currentIndex: index
        });
        this.props.onAppointmentSelected(this.state.previousAppointments[index]);
    }

    //--------------------------------------------------------------------------------------------------------------------
    // Render
    //--------------------------------------------------------------------------------------------------------------------

    render() {
        const {
            isLoading,
            previousAppointments,
            currentIndex
        } = this.state;
        const today = new Date();
        
        if (isLoading) {
            return (<Loader />);
        }

        const previousAppointment = previousAppointments[currentIndex];

        return (<>

            <div className="panel previous-appointments-panel">
                <div className="panel-header">Previous Appointments</div>
                <div className="panel-body">
                    {/*
                    onClick={e => this.loadClientRecord(appointment.appointmentID)}
                    */}
                    <ul className="list service-list appt-list">
                        {previousAppointments.map((appointment, index) =>
                            <li
                                key={appointment.appointmentID}
                                onClick={e => this.setIndex(index)}
                                className={previousAppointment && appointment.appointmentID == previousAppointment.appointmentID ? 'highlighted' : ''}
                            >
                                <div className="appt-list-icon">
                                    <span className="fa fa-calendar-alt"></span>
                                </div>
                                <div>
                                    <div className="appt-list-date">
                                        {moment(appointment.date).format('Do MMM YYYY')}
                                    </div>
                                    <div className="appt-list-stylists">
                                        {appointment.stylistNames.join(', ')}
                                    </div>
                                </div>
                                <div className="appt-list-time-ago">
                                    {TextHelpers.formatDateDiff(today, appointment.date)}
                                </div>
                            </li>
                        )}
                    </ul>

                    <div className="flex-row previous-appointments-nav">
                        
                        <button className={'button button-small ' + (currentIndex > 0 ? 'button-primary' : 'button-disabled')} onClick={e => this.move(-1)}>
                            <span className="fa fa-arrow-left"></span>{' '}
                            Newer
                        </button>

                        <button className={'button button-small ' + (currentIndex < previousAppointments.length - 1 ? 'button-primary' : 'button-disabled')} onClick={e => this.move(+1)}>
                            Older{' '}
                            <span className="fa fa-arrow-right"></span>
                        </button>

                    </div>

                    <hr />

                    <AppointmentSummary
                        appointment={previousAppointment}
                        hideProducts={this.props.hideProducts}
                        hideApptNotes={this.props.hideApptNotes}
                        hideColourNotes={this.props.hideColourNotes}
                    />

                </div>
            </div>

            {/* Back */}
            <button className="button button-secondary button-small" onClick={this.props.onBackClicked}>
                <i className="fas fa-arrow-left"></i>{' '}
                Back
            </button>

        </>);
    }

}

export default PreviousAppointments;