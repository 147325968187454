// Libs
import React from 'react';
import Modal from 'react-bootstrap/Modal';

// Services & Helpers
import BaseService from 'services/BaseService';
import CustomerService from 'services/CustomerService';
import GlobalStateService from 'services/GlobalStateService';
import EmailService from 'services/EmailService';
import FormHelper from 'helpers/FormHelper';
import BootboxHelper from 'helpers/BootboxHelper';

// Components
import Loader from 'components/reusable/Loader';
import InsertDynamicFieldButton from 'components/reusable/InsertDynamicFieldButton';

//--------------------------------------------------------------------------------------

export default class SendEmailModal extends React.Component {
    
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true
        };

        this.formHelper = new FormHelper({
            fields: {
                subject: {
                    label: 'Subject',
                    type: 'text',
                    getLabelExtras: (fieldInfo, fieldRef) =>
                        <InsertDynamicFieldButton
                            showAppointmentRelated={!!this.state.email.customerID}
                            showWaitingApptRelated={this.state.waitingApptID}
                            showFormRelated
                            fieldRef={fieldRef}
                            onSelect={(value) => {
                                this.updateField('subject', value, () => {
                                    this.updatePreview();
                                });
                            }}
                        />

                },
                body: {
                    label: 'Message',
                    type: 'multiline-text',
                    rows: 8,
                    getLabelExtras: (fieldInfo, fieldRef) =>
                        <InsertDynamicFieldButton
                            showAppointmentRelated={!!this.state.email.customerID}
                            showFormRelated
                            fieldRef={fieldRef}
                            onSelect={(value) => {
                                this.updateField('body', value, () => {
                                    this.updatePreview();
                                });
                            }}
                        />
                }
            },
            getValue: (fieldName, fieldInfo) => this.state.email[fieldName],
            setValue: (fieldName, value, fieldInfo) => this.updateField(fieldName, value),
            onBlur: (fieldName) => this.updatePreview()
        });
    }

    show(opts) {
        return new Promise((resolve, reject) => {
            this.resolve = resolve;
            this.setState({
                isOpen: true,
                isLoading: true,
                error: null,
                preview: null,
                email: {
                    customerID: opts.customerID,
                    appointmentID: opts.appointmentID,
                    waitingApptID: opts.waitingApptID
                }
            }, () => {
                this.load();
            });
        });
    }

    hide() {
        this.setState({
            isOpen: false
        });
    }

    async load() {
        const {
            email
        } = this.state;
        const newState = {
            isLoading: false,
            email: email || {}
        };

        // Load recipient
        if (email.customerID) {
            newState.customer = await CustomerService.get(email.customerID);
            newState.sendTo = 'customer';
        }

        //// Load accounts
        //newState.emailAccounts = await SMSService.listNumbers();
        //if (newState.emailAccounts.length > 0) {
        //    newState.email.emailAccountID = newState.emailAccounts[0].id;
        //}
        
        this.setState(newState);
    }
    
    updateField(fieldName, value, callback) {
        const email = { ...this.state.email };
        email[fieldName] = value;
        this.setState({ email }, () => {
            //this.debounceUpdatePreview();
            if (callback) callback();
        });
    }
    
    async send() {
        this.setState({ isLoading: true });
        var result = await EmailService.sendCustomer(this.state.email);
        if (result.success) {
            BootboxHelper.alert('Email sent');
        } else {
            BootboxHelper.alert(result.error || 'Unknown error');
        }
        this.hide();
        this.resolve();
    }

    debounceUpdatePreview() {
        clearTimeout(this.updatePreviewTimeout);
        this.updatePreviewTimeout = setTimeout(() => {
            this.updatePreview();
            this.updatePreviewTimeout = null;
        }, 500);
    }

    async updatePreview() {
        const salonCode = GlobalStateService.getValue('salonCode');
        const url = `/api/email/get-preview?salon-code=${salonCode}`;
        BaseService.postToIframe({
            emailSubjectTemplate: (this.state.email.subject || ''),
            emailBodyTemplate: (this.state.email.body || ''),
            customerID: this.state.email.customerID,
            noSampleText: true
        }, url, 'preview-iframe');
    }

    //---------------------------------------------------------------------

    render() {
        const {
            isOpen,
            isLoading,
            previewURL,
            email
        } = this.state;

        if (!isOpen) {
            return null;
        }

        return (
            <Modal
                show
                className="send-email-modal"
                onHide={e => this.setState({ isOpen: false })}
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        Send Email
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    {this.renderInner()}

                </Modal.Body>
                <Modal.Footer>
                    {!isLoading && <>
                        <button type="button" className="button button-secondary mr-auto" onClick={e => this.setState({ isOpen: false })}>
                            Cancel
                        </button>
                        <button type="button" className="button button-primary ml-auto" onClick={e => this.send()}>
                            Send
                        </button>
                    </>}
                </Modal.Footer>
            </Modal>
        );
    }

    renderInner() {
        const {
            isLoading
        } = this.state;

        if (isLoading) {
            return (<Loader />);
        }

        return (
            <div className="row">

                <div className="col-md-6">

                    {this.renderLeftCol()}

                </div>

                <div className="col-md-6">

                    {this.renderRightCol()}

                </div>

            </div>
        );
    }

    renderLeftCol() {
        const {
            sendTo,
            customer
        } = this.state;

        return (<>

            {/*
            {email.clientListID &&
                this.formHelper.renderFormGroups([
                    'communicationType'
                ])
            }*/}

            <div className="row">

                <div className="col-7">

                    <div className="form-group">
                        <label>Send to</label>
                        <p className="form-control-plaintext">
                            {sendTo == 'customer' && <>
                                {customer.firstName}{' '}
                                {customer.lastName}{' '}
                                ({customer.email})
                            </>}
                            {/*
                            {sendTo == 'clientList' && <>
                                {clientList.name}{' '}
                                {email.communicationType == 'functional' &&
                                    <>({clientList.numClientsForSMS} {clientList.numClientsForSMS == 1 ? 'client' : 'clients'} with a mobile number)</>
                                }
                                {email.communicationType == 'marketing' &&
                                    <>({clientList.numClientsOptInSMS} {clientList.numClientsOptInSMS == 1 ? 'client' : 'clients'} opted-in)</>
                                }
                            </>}
                            */}
                        </p>
                    </div>

                </div>

                <div className="col-5">

                    {/*
                    <div className="form-group">
                        <label>Send from</label>
                        <select
                            value={email.emailAccountID}
                            onChange={e => this.updateField('emailAccountID', e.target.value)}
                        >
                            {emailAccounts.map(sn =>
                                <option key={sn.id} value={sn.id}>
                                    {sn.number}
                                </option>
                            )}
                        </select>
                    </div>
                    */}

                </div>

            </div>

            {this.formHelper.renderFormGroups([
                'subject',
                'body'
            ])}

        </>);
    }

    renderRightCol() {
        return (<>

            <iframe name="preview-iframe" src="about:blank" className="preview-iframe" />
            
        </>);
    }

}
