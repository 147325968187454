// Libs
import React, { Component } from 'react';

//-------------------------------------------------------------------------------------------------------------------

class MindsetSettingSlide extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="ypt-slide ypt-slide-mindset-setting">

                <div className="ypt-title">
                    Welcome to Floomly
                </div>
                <div className="ypt-subtitle">
                    Time to Focus on Profit
                </div>

                <div className="ypt-video-container" style={{ float: 'left', width: '415px', height: '276px', marginRight: '20px' }}>
                    <iframe src="https://player.vimeo.com/video/547428571?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen 
                    title="Software video one.mov">
                    </iframe>
                </div>
                <p style={{ textAlign: 'left', fontSize: '20px' }}>Welcome to Floomly! Thank you so much for joining us here, we're so excited to have you!</p>
                <p style={{ textAlign: 'left', fontSize: '20px' }}>Before we begin, there's a few steps we need to go through to make sure this really works for you and you get the absolute most from it! This part of your system setup is really really important as it's what makes Floomly different.
                    As Alan says in the video - "<em>information is only valuable when it's relevant</em>" - and as Floomly has been created by a business coaching company, <strong>everything</strong> is based on helping you to grow your business, get the profits you want and have a laser-like focus on what you need to achieve.</p>
                <p style={{ textAlign: 'left', fontSize: '20px' }}>So grab a cuppa and watch this short video from our founder, Alan Austin-Smith - we're on the way to making you more profitable! Yay!</p>

                {/*<span className="help-icon"></span>*/}

                <div className="ypt-row ypt-controls-row">
                    <div>
                        <button className="ypt-skip-setup-button" onClick={e => this.props.onSkip()}>
                            Skip 'Your Profit Target'
                        </button>
                    </div>
                    <div>

                        <button className="button button-secondary ypt-continue-button" onClick={e => this.props.onContinue()}>
                            Continue
                        </button>

                    </div>
                </div>
            </div>
        );
    }
}

export default MindsetSettingSlide;
