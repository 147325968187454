// Libs
import React from 'react';
import { withRouter } from 'react-router-dom';
import EmailEditor from 'react-email-editor';


// Services & helpers
import EmailTemplateService from 'services/EmailTemplateService';
import FormHelper from 'helpers/FormHelper';
import BootboxHelper from 'helpers/BootboxHelper';
import MergeFields from 'services/MergeFields';

// Components
import FloomlyComponent from 'components/FloomlyComponent';
import SuperTable from 'components/reusable/SuperTable';
import EmailBatchModal from 'components/pages/marketing/EmailBatchModal';
import EmailHistoryModal from 'components/pages/marketing/EmailHistoryModal';
import InsertDynamicFieldButton from 'components/reusable/InsertDynamicFieldButton';
import Loader from 'components/reusable/Loader';
import TemplateFile from 'components/pages/marketing/TemplateFile.json';

class EmailTemplatesTab extends FloomlyComponent {
    constructor(props) {
        super(props);
        this.emailEditorRef = React.createRef();
        this.emailBatchModalRef = React.createRef();
        this.emailHistoryModalRef = React.createRef();

        // Init state
        this.state = {
            isLoadingList: true,
            isLoadingTemplate: false,
            emailTemplates: [],
            preDefinedEmailTemplates:[],
            emailTemplateID: null,
            emailTemplate: null,
            sampleTemplate: TemplateFile,
            mergeFields: [],
            canSendEmail: true
        };

        this.updateField = this.updateField.bind(this);
        this.load = this.load.bind(this);
        this.onSendEmail = this.onSendEmail.bind(this);
        this.setSendEmail = this.setSendEmail.bind(this);
        this.formHelper = new FormHelper({
            fields: {
                name: {
                    label: 'Name',
                    type: 'text'
                },
                subjectTemplate: {
                    label: 'Subject',
                    type: 'text',
                    getLabelExtras: (fieldInfo, fieldRef) =>
                        <InsertDynamicFieldButton
                            showAppointmentRelated={false }
                            showWaitingApptRelated={false}
                            showFormRelated={false }
                            fieldRef={fieldRef}
                            onSelect={(value) => {
                                this.updateField('subjectTemplate', value);
                            }}
                        />
                },
                bodyTemplate: {
                    label: 'Body',
                    type: 'multiline-text',
                    rows: 8,
                    getLabelExtras: (fieldInfo, fieldRef) =>
                        <InsertDynamicFieldButton
                            showAppointmentRelated
                            showWaitingApptRelated
                            fieldRef={fieldRef}
                            onSelect={(value) => {
                                this.updateField('bodyTemplate', value);
                            }}
                        />
                }
            },
            getValue: (fieldName, fieldInfo) => this.state.emailTemplate[fieldName],
            setValue: this.updateField,
        });
    }

    async componentDidMount() {
        if (this.props.match.params.subTab) {
            await this.loadEmailTemplate(Number(this.props.match.params.subTab));
            await this.showEmailBatchModal();
        }
        else {
            this.load();
        }
        this.state.mergeFields.push(MergeFields.mergeTags);
    }   

    async load() {
        this.setState({
            isLoading: true
        });

        const emailTemplates = await EmailTemplateService.list();
        const preDefinedEmailTemplates = await EmailTemplateService.preDefinedTemplatesList();
        this.setState({
            emailTemplates,
            preDefinedEmailTemplates,
            isLoadingList: false,
            isLoading: false,
            emailTemplate: null
        });
    }

    async loadEmailTemplate(id) {
        const emailTemplate = await EmailTemplateService.get(id);
        if (!emailTemplate.salonID) {
            emailTemplate.emailTemplateID = null;
            id = null;
        }
        const emailBody = emailTemplate.bodyTemplate ? emailTemplate.bodyTemplate.body : '';
        this.state.sampleTemplate.body = emailBody;

        this.setState({
            emailTemplate,
            emailTemplateID: id,
            isLoadingTemplate: false,
            isLoadingList: false
        });
    }

    onLoadEmailBody = () => {
        const { emailTemplate, sampleTemplate } = this.state;

        this.emailEditorRef.current.editor.registerCallback('image', this.handleImageUpload);
        if (emailTemplate && emailTemplate.bodyTemplate)
            this.emailEditorRef.current.editor.loadDesign(sampleTemplate);
        else
            this.emailEditorRef.current.editor.loadDesign();
    };

    handleImageUpload = async (file, done) => {
        try {
            const response = await EmailTemplateService.uploadImage(file.attachments[0]);
            const url = response.content;
            done({ progress: 100, url });
        } catch (error) {
            console.log('Error in upload', error);
            done({ progress: 0 });
        }
    }

    updateField(fieldName, value, fieldInfo) {
        const emailTemplate = { ...this.state.emailTemplate };
        emailTemplate[fieldName] = value;
        this.setState({ emailTemplate });
    }

    addNew() {
        const emailTemplate = {};
        this.setState({
            emailTemplate
        });
    }

    goBack() {
        this.setState({
            emailTemplate: null
        });
        this.props.history.replace(`/marketing/email-template`);
        this.load();
    }

    async onSave(emailEditorRef, loadTemplate) {
        const { emailTemplate } = this.state;
        let emailTemplateID;

        if (!emailTemplate.name) {
            BootboxHelper.alert('Please provide a template name.');
            return;
        }

        this.emailEditorRef.current.editor.saveDesign(async (design) => {
            emailTemplate.bodyTemplate = JSON.stringify(design, null, -1);
            this.emailEditorRef.current.editor.exportHtml(async (data) => {
                emailTemplate.htmlBodyTemplate = data.html;
                emailTemplateID = await EmailTemplateService.save(emailTemplate);
                if (loadTemplate) {
                    BootboxHelper.alert('Template saved successfully!');
                    this.setState({
                        isLoadingTemplate: true,
                        emailTemplateID
                    }, () => {
                        this.loadEmailTemplate(emailTemplateID);
                    });
                }
            },
                {
                    cleanup: true
                });
        });
    }

    async sendEmailBatch() {
        setTimeout(() => {
            this.setSendEmail(true);
        }, 100000);
        if (this.state.canSendEmail) {
            await this.onSave(this.emailEditorRef, false);
            this.showEmailBatchModal();
        }
        else {
            BootboxHelper.alert('Another batch of emails is already being sent. Please wait for it to finish first.');
        }
    }

    async setSendEmail(value) {
        this.setState({
            canSendEmail: value
        })
    }

    //block the user to send another batch at the same time
    async onSendEmail() {
        setTimeout(() => {
            this.setSendEmail(false)
        }, 100);
    }

    async confirmDelete() {
        const confirm = await BootboxHelper.confirm('Are you sure you want to delete this template?');
        if (confirm) {
            this.delete();
        }
    }

    async delete() {
        this.setState({
            isLoading: true
        });
        await EmailTemplateService.delete(this.state.emailTemplate.emailTemplateID);
        this.load();
    }

    showEmailBatchModal() {
        this.emailBatchModalRef.current.show({
            emailTemplateID: this.state.emailTemplateID,
            onAddClientList: () => this.props.history.replace(`/marketing/client-lists/?emailTemplateID=` + this.state.emailTemplateID)
        });
    }

    showEmailHistoryModal() {
        this.emailHistoryModalRef.current.show({
            emailTemplateID: this.state.emailTemplateID
        });
    }

    renderEmailTemplate() {
        const { emailTemplate, mergeFields, emailTemplateID } = this.state;
        
        return (<>
            <div className="row">
                <div className="">
                    {this.formHelper.renderFormGroups([
                        'name',
                        'subjectTemplate'
                    ])}
                </div>
                <div>
                    <label>Body</label>
                    <EmailEditor ref={this.emailEditorRef}
                        onLoad={this.onLoadEmailBody}
                        minHeight="70vh"
                        options={{
                            appearance: {
                                theme: "modern_light", panels: { tools: { dock: "left" } }
                            },
                            mergeTags: mergeFields[0],
                            safeHTML: true,
                            linkTypes: [
                                {
                                    name: 'email',
                                    enabled: false,
                                },
                                {
                                    name: 'sms',
                                    enabled: false
                                },
                                {
                                    name: 'phone',
                                    enabled: false
                                },
                            ],
                            tools: {
                                heading: {
                                    enabled: true
                                },
                                html: {
                                    enabled: true
                                },
                            },
                            displayMode: 'email',
                            //devices: "Desktop",
                            features: {
                                textEditor: {
                                    fontSizes: ["18px", "20px", "30px"]
                                }
                            },

                        }}
                    />
                </div>
            </div>
            <div className="email-buttons">
                <button className="button button-secondary button-small me-3" onClick={e => this.goBack()}>
                    <span className="fa fa-chevron-left" />{' '}
                    Go back
                </button>
                <button
                    className="button button-secondary button-small me-3"
                    onClick={e => this.onSave(null, true)}>
                    Save
                </button>
                <button
                    className="button button-secondary button-small me-3" disabled={emailTemplateID ? false : true}
                    onClick={e => this.sendEmailBatch()}>
                    Send
                </button>
                {
                    emailTemplate && emailTemplate.sentCount > 0 &&
                    <button
                            className="button button-secondary button-small me-3"
                            onClick={e => this.showEmailHistoryModal()}>
                        History
                    </button>
                }
                {emailTemplateID &&
                    <button
                        className="button button-secondary button-small me-3"
                        onClick={e => this.confirmDelete()}>
                        Delete
                    </button>
                }
            </div>
        </>);
    }

    renderEmailTemplates() {
        const { emailTemplates } = this.state;
        return (<>
            <div className="">
                <SuperTable
                    className="email-templates-table"
                    rows={emailTemplates}
                    onClick={(emailTemplate, e) => this.loadEmailTemplate(emailTemplate.emailTemplateID)}
                    keyAccessor={s => s.emailTemplateID}
                    emptyText="You have no email templates"
                    cols={{
                        name: {
                            label: 'Name',
                            className: 'name-column',
                        },
                        subjectTemplate: {
                            label: 'Subject Template',
                            className: 'subject-template-column',
                        },
                        sentCount: {
                            label: '# Sent'
                        },
                        openCount: {
                            label: '# Open'
                        },
                        clicksCount: {
                            label: '# Clicks'
                        },
                        unsubscribeCount: {
                            label: '# Unsubscribes'
                        }
                    }}
                />
            </div>
        </>)
    }

    renderExtras() {
        return (
            <>
                <EmailBatchModal ref={this.emailBatchModalRef} onClose={this.onSendEmail} />
                <EmailHistoryModal ref={this.emailHistoryModalRef}/>
            </>
        );
    }

    render() {
        const {
            emailTemplate,
            isLoadingList,
            isLoadingTemplate,
            preDefinedEmailTemplates
        } = this.state;

        if (isLoadingList || isLoadingTemplate) {
            return (<Loader />);
        }



        return (
            <>
                {this.renderExtras()}
                <div className="email-templates">
                    {emailTemplate ? this.renderEmailTemplate() :
                        <>
                            <>
                                <div className="new-template-button">
                                    <select
                                        style={{ width: '100%' }}
                                        onChange={e => this.loadEmailTemplate(e.target.value)}
                                    >
                                        <option value="">(Select Predefined template)</option>
                                        {preDefinedEmailTemplates.map(s =>
                                            <option key={s.emailTemplateID} value={s.emailTemplateID}>{s.name}</option>
                                        )}
                                    </select>
                                </div>
                                <div className="new-template-button">
                                    <button
                                        className="button button-secondary button-small me-3"
                                        onClick={e => this.addNew()}>
                                        New Template
                                    </button>
                                </div>
                            </>
                            <div className="email-templates-list">
                                {this.renderEmailTemplates()}
                            </div>
                        </>
                    }
                </div>

            </>
        );
    }
}

export default withRouter(EmailTemplatesTab);