import BaseService from 'services/BaseService';
import moment from 'moment';

//-------------------------------------------------------------------------------------------------------------------

export default class OfficeService
    extends BaseService {

    static async getEndOfDayBanking(date) {
        const response = await BaseService.callAPI('office/get-end-of-day-banking', {
            date
        });
        return response;
    }

    static async getTransactionSummary(date) {
        const response = await BaseService.callAPI('office/get-transaction-summary', {
            date
        });
        return response;
    }

    //static async saveEndOfDaySummary(endOfDaySummary) {
    //    const response = await BaseService.callAPI('office/save-end-of-day-summary', endOfDaySummary);
    //    return response;
    //}

    static async saveEndOfDayBankingAccount(account, date) {
        return BaseService.callAPI('office/save-end-of-day-banking-account', {
            account, date
        });   
    }

    static async saveActualTakings(date, paymentMethodID, amount) {
        return BaseService.callAPI('office/save-actual-takings', {
            date, paymentMethodID, amount
        });
    }

    static async saveEODNotes(date, notes) {
        return BaseService.callAPI('office/save-eod-notes', {
            date, notes
        });
    }
}

