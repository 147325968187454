// Libs
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

// Services & Helpers
import DiaryService from 'services/DiaryService';
import ThermalPrinterService from 'services/ThermalPrinterService';
import TextHelpers from 'helpers/TextHelpers';
import BootboxHelper from 'helpers/BootboxHelper';

// Components
import FloomlyComponent from 'components/FloomlyComponent';
import Loader from 'components/reusable/Loader';
import Money from 'components/reusable/Money';
import CustomerSummary from 'components/reusable/CustomerSummary';

//-------------------------------------------------------------------------------------------------------------------

class Refund extends FloomlyComponent {

    constructor(props) {
        super(props);

        this.state = {
            isLoading: true
        };

        this.viewCustomer = this.viewCustomer.bind(this);
    }

    componentDidMount() {
        this.load();
    }

    async load() {
        // Load checkout info
        const refund = await DiaryService.loadCheckOut(this.props.appointment.appointmentID);
        // As we're now doing a refund, store the original payment method and don't set a refund payment method yet
        refund.originalPaymentMethodID = refund.paymentMethodID;
        refund.paymentMethodID = null;

        // Load payment methods
        let paymentMethods = await DiaryService.loadPaymentMethods();
        paymentMethods = paymentMethods.filter(pm => pm.useForRefunds);

        // Display
        this.setState({
            refund: refund,
            paymentMethods: paymentMethods,
            isLoading: false
        });
    }

    async updateFields(values) {
        const refund = { ...this.state.refund };
        for (var field in values) {
            let value = values[field];
            refund[field] = value;
        }
        this.setState({
            refund: refund
        });
    }

    async confirm() {
        const {
            refund,
            paymentMethods
        } = this.state;

        // Validate
        refund.amount = (refund.amount || 0);
        if (refund.amount <= 0) {
            BootboxHelper.alert('Please specify amount to refund');
            return;
        }

        // Build confirm message
        const amount = TextHelpers.formatCurrencyNew(refund.amount, { includeSymbol: true });
        const paymentMethod = paymentMethods.find(pm => pm.paymentMethodID == refund.paymentMethodID);
        let message = `Issue refund for <b>${amount}</b>${paymentMethod ? ' by ' + paymentMethod.shortName : ''}?`;
        
        // Ensure they don't accidentally refund more than the value of the appointment
        let linkedAppointmentsTotal = 0;
        if (refund.linkedAppointments) {
            refund.linkedAppointments.forEach(la => {
                linkedAppointmentsTotal += la.total;
            });
        }
        const max = refund.total + linkedAppointmentsTotal - refund.amountRefunded;
        if (refund.amount > max) {
            message += '<br/><br/>(<b>Note that this is more than the total owing of ' + TextHelpers.formatCurrencyNew(max, { includeSymbol: true }) + '</b>)';
        }

        // Check and save if confirmed
        const confirm = await BootboxHelper.confirm(message);
        if (confirm) {
            this.save();
        }
    }

    async save() {
        this.setState({ isLoading: true });
        const { openCashDrawer } = await DiaryService.issueRefund(this.state.refund);
        if (openCashDrawer) {
            ThermalPrinterService.openCashDrawers(false);
        }
        this.props.onCompleted();
    }

    viewCustomer() {
        this.props.history.push('/customer/' + this.props.appointment.customer.customerID);
    }

    //--------------------------------------------------------------------------------------------------------------------
    // Render
    //--------------------------------------------------------------------------------------------------------------------

    render() {
        const {
            isLoading,
            refund,
            paymentMethods
        } = this.state;
        const {
            appointment
        } = this.props;

        if (isLoading) {
            return (<Loader />);
        }

        let linkedAppointmentsTotal = 0;
        if (refund.linkedAppointments) {
            refund.linkedAppointments.forEach(la => {
                linkedAppointmentsTotal += la.total;
            });
        }

        const originalPaymentMethod = paymentMethods.find(pm => pm.paymentMethodID == refund.originalPaymentMethodID);
        
        return (<>

            <CustomerSummary
                customerID={appointment.customer.customerID}
                onViewInfoClicked={this.viewCustomer}
            />

            <div className="panel refund-panel">
                <div className="panel-header">Refund</div>
                <div className="panel-body">

                    {this.renderServices()}

                    {this.renderPurchases()}

                    <div className="panel-info-header">
                        Totals
                    </div>

                    <ul className="list service-list">

                        {/* Total Services */}
                        <li className="non-selectable">

                            <span className="service-list-icon">
                                <span className="fas fa-cut"></span>
                            </span>

                            <span className="service-list-name">
                                Total Services
                            </span>

                            <span className="service-list-price">
                                <Money amount={refund.serviceTotal} />
                            </span>

                        </li>

                        {/* Total Retail */}
                        <li className="non-selectable">

                            <span className="service-list-icon">
                                <span className="fas fa-cash-register"></span>
                            </span>

                            <span className="service-list-name">
                                Total Retail
                            </span>

                            <span className="service-list-price">
                                <Money amount={refund.retailTotal} />
                            </span>

                        </li>

                        {/* Discount */}
                        <li className="non-selectable">

                            <span className="service-list-icon">
                                <span className="fas fa-arrow-down"></span>
                            </span>

                            <span className="service-list-name">
                                Discount
                            </span>

                            <span className="service-list-price">
                                <Money amount={refund.discountTotal} />
                            </span>

                        </li>

                        {/* Tips */}
                        <li className="non-selectable">

                            <span className="service-list-icon">
                                <span className="far fa-smile"></span>
                            </span>

                            <span className="service-list-name">
                                Tips
                            </span>

                            <span className="service-list-price">
                                <Money amount={refund.tipTotal} />
                            </span>

                        </li>

                        {/* Other appointments */}
                        {linkedAppointmentsTotal > 0 &&
                            <li className="non-selectable">

                                <span className="service-list-icon">
                                    <span className="fas fa-calendar"></span>
                                </span>

                                <span className="service-list-name">
                                    Other appointments
                                </span>

                                <span className="service-list-price">
                                    <Money amount={linkedAppointmentsTotal} />
                                </span>

                            </li>
                        }

                        {/* Amount already refunded */}
                        {refund.amountRefunded > 0 &&
                            <li className="non-selectable highlight-text">

                                <span className="service-list-icon">
                                    <span className="fas fa-sad-tear"></span>
                                </span>

                                <span className="service-list-name">
                                    Already refunded
                                </span>

                                <span className="service-list-price">
                                    <Money amount={-refund.amountRefunded} />
                                </span>

                            </li>
                        }

                        {/* Total */}
                        <li className="non-selectable total-row line-above">

                            <span className="service-list-icon">
                                <span className="fas fa-money-bill"></span>
                            </span>

                            <span className="service-list-name">
                                Total
                            </span>

                            <span className="service-list-price">
                                <Money amount={refund.total + linkedAppointmentsTotal - refund.amountRefunded} />
                            </span>

                        </li>

                        {/* Method */}
                        {originalPaymentMethod &&
                            <li className="non-selectable">

                                <span className="service-list-name">
                                    Originally paid by
                                </span>

                                <span className="service-list-price">
                                    {originalPaymentMethod.shortName}
                                </span>

                            </li>
                        }

                        {/* Refund Amount */}
                        <li className="non-selectable amount-to-refund line-above">

                            <span className="service-list-icon">
                                <span className="fas fa-sad-tear"></span>
                            </span>

                            <span className="service-list-name">
                                Refund amount
                            </span>

                            <span className="service-list-price">

                                <input
                                    min="0"
                                    type="number"
                                    value={refund.amount || ''}
                                    onChange={e => this.updateFields({ amount: Number(e.target.value) })}
                                />

                            </span>

                        </li>

                        <li className="non-selectable">

                            <textarea
                                rows="2"
                                value={refund.reason || ''}
                                onChange={e => this.updateFields({ reason: e.target.value })}
                                placeholder="Reason for refund..."
                            />

                        </li>

                    </ul>

                </div>
                
            </div>

            {/* Payment method */}
            <div className="panel payment-methods-panel">
                <div className="panel-header">Payment Method</div>
                <div className="panel-body">
                    <div className="button-stack">
                        {paymentMethods.map(paymentMethod => {
                            const isSelected = (paymentMethod.paymentMethodID == refund.paymentMethodID);
                            return (
                                <button
                                    key={paymentMethod.paymentMethodID}
                                    className={'button button-small button-selectable'}
                                    onClick={e => this.updateFields({ paymentMethodID: Number(paymentMethod.paymentMethodID) })}
                                >
                                    {isSelected && <>
                                        <i className='fas fa-check'></i>{' '}
                                    </>}
                                    {paymentMethod.name}
                                    {paymentMethod.code == 'Account' && <>
                                        <div className="button-subtitle">
                                            (Current: <Money amount={refund.customer.accountBalance} />)
                                        </div>
                                    </>}
                                </button>
                            );
                        })}
                    </div>

                </div>

            </div>

            {/* Issue refund */}
            <button className="button button-primary button-small" onClick={e => this.confirm()}>
                {/*<i className="fas fa-sad-tear"></i>{' '}*/}
                Issue refund
            </button>

            {/* Back */}
            <button className="button button-secondary button-small" onClick={this.props.onBackClicked}>
                <i className="fas fa-arrow-left"></i>{' '}
                Back
            </button>

        </>);
    }

    renderServices() {
        const {
            isLoading,
            refund
        } = this.state;

        if (refund.appointmentPackages.length == 0 && refund.appointmentServices.length == 0 ) {
            return null;
        }

        const isFixedPricePackage = (apptPackageID) => {
            const apptPackage = refund.appointmentPackages.find(ap => ap.appointmentPackageID == apptPackageID);
            return apptPackage && apptPackage.isFixedPrice;
        };

        return (<>
            <div className="panel-info-header">
                Services
            </div>

            <ul className="list service-list">

                {refund.appointmentPackages && refund.appointmentPackages.map((apptPackage, index) =>
                    <li key={index} className="non-selectable">
                        
                        <span className="service-list-icon">
                            <span className="fas fa-cubes"></span>
                        </span>

                        <span className="service-list-name">
                            {apptPackage.package.name}
                        </span>

                        {apptPackage.isFixedPrice &&
                            <span className="service-list-price">

                                <Money amount={apptPackage.total} />

                            </span>
                        }

                    </li>
                )}

                {refund.appointmentServices && refund.appointmentServices.map((apptService, index) =>
                    <li key={index} className="non-selectable">

                        <span className={'service-list-icon ' + (!!apptService.appointmentPackageID && 'service-list-icon-indent')}>
                            <span className="fas fa-cut"></span>
                        </span>

                        <span className="service-list-name">
                            {apptService.service.name}
                        </span>

                        <span className="service-list-price">

                            <Money amount={apptService.total} />

                        </span>

                    </li>
                )}

            </ul>

        </>);
    }

    renderPurchases() {
        const {
            isLoading,
            refund
        } = this.state;

        if (refund.appointmentPurchases.length == 0) {
            return null;
        }

        return (<>
            
            <div className="panel-info-header">
                Retail
            </div>

            <ul className="list service-list">
                {refund.appointmentPurchases && refund.appointmentPurchases.map((apptPurchase, index) =>
                    <li key={index} className="non-selectable">

                        <span className="service-list-icon">
                            <span className="fas fa-cash-register"></span>
                        </span>

                        <span className="service-list-name">
                            {apptPurchase.stockItem.product.name}
                        </span>

                        <span className="service-list-price">

                            <Money amount={apptPurchase.total} />

                        </span>

                    </li>
                )}
            </ul>

        </>);
    }

}

export default withRouter(Refund);