// Libs
import React from 'react';
import Modal from 'react-bootstrap/Modal';

//--------------------------------------------------------------------------------------

class ConfirmDeleteModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = { };
    }

    show(opts) {
        return new Promise((resolve) => {
            this.resolve = resolve;
            this.setState({
                isOpen: true,
                isValid: false,
                confirmText: '',
                message: opts.message
            });
        });
    }

    tryConfirm() {
        if (this.state.isValid) {
            this.confirm();
        } else {
            const element = document.querySelector('.confirm-delete-modal');
            element.classList.add('error-shake');
            setTimeout(() => {
                element.classList.remove('error-shake');
            }, 250);
        }
    }

    confirm() {
        this.setState({ isOpen: false });
        this.resolve(true);
    }

    //---------------------------------------------------------------------

    render() {
        const {
            isOpen
        } = this.state;

        if (!isOpen) {
            return null;
        }

        return (
            <Modal
                show
                className="confirm-delete-modal"
                onHide={e => this.setState({ isOpen: false })}
            >
                <form onSubmit={e => {
                    e.preventDefault();
                    this.tryConfirm();
                }}>
                    <Modal.Body>
                    
                        {this.renderInner()}

                    </Modal.Body>
                    <Modal.Footer>
                        <button type="button" className="button button-secondary mr-auto" onClick={e => this.setState({ isOpen: false })}>
                            Cancel
                        </button>
                        <button type="submit" className="button button-primary ml-auto">
                            Confirm
                        </button>
                    </Modal.Footer>
                </form>
            </Modal>
        );
    }

    renderInner() {
        const { message, confirmText, isValid } = this.state;
        
        return (<>

            {message}
            
            <input
                type="text"
                autoFocus
                className={isValid ? '' : 'error'}
                value={confirmText || ''}
                onChange={e => this.setState({
                    confirmText: e.target.value,
                    isValid: ((e.target.value || '').toLowerCase().trim() == 'delete')
                })}
            />
            
        </>);
    }
}

export default ConfirmDeleteModal;