// Libs
import React from 'react';
import { ContextMenu } from 'react-contextmenu';
import { showMenu } from 'react-contextmenu/modules/actions'

// Services
import GlobalStateService from 'services/GlobalStateService';

//-------------------------------------------------------------------------------------------------------------------

class InfoBar extends React.Component {

    logOut() {
        GlobalStateService.getValue('logOut')();
    };

    openMenu(e) {
        const buttonPos = e.target.getBoundingClientRect();
        showMenu({
            position: { x: buttonPos.left - 130, y: buttonPos.top + buttonPos.height },
            target: e.target,
            id: 'infobar-menu'
        });
    }

    render() {
        const {
            sticky,
            getContextMenu,
            getContextMenuButtonBadge,
            containerClassName,
            className,
            children
        } = this.props;

        let contextMenuButtonBadge = '';
        if (getContextMenuButtonBadge) {
            contextMenuButtonBadge = getContextMenuButtonBadge();
        }

        return (<>

            <div className={'info-bar-container ' + (sticky ? 'sticky-top' : '') + ' ' + (containerClassName || '')}>
                {sticky &&
                    <div className="info-bar-fade"></div>
                }

                <div className={'info-bar ' + (getContextMenu ? 'has-menu-button' : '')}>

                    <div className={'panel info-bar-panel ' + (className || '')}>

                        <div className="panel-body">

                            {children}

                        </div>

                    </div>

                    {getContextMenu &&
                        <button className="button info-bar-menu-button" onClick={e => this.openMenu(e)}>
                            {contextMenuButtonBadge && 
                                <div className="button-badge slow-blink">
                                    {contextMenuButtonBadge}
                                </div>
                            }
                            <span className="fa fa-bars"></span>
                        </button>
                    }

                    <button className="button log-out-button" onClick={this.logOut}>
                        Log out
                    </button>

                </div>

            </div>

            {getContextMenu &&
                <ContextMenu id="infobar-menu">
                    {getContextMenu()}
                </ContextMenu>
            }
        </>);
    }
}

export default InfoBar;