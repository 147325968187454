// Libs
import React from 'react';
import Modal from 'react-bootstrap/Modal';

// Services & Helpers
import ECommerceService from 'services/ECommerceService';
import BootboxHelper from 'helpers/BootboxHelper';

// Components
import Loader from 'components/reusable/Loader';
import SuperTable from 'components/reusable/SuperTable';
import EditProductModal from 'components/pages/settings/ecommerce/EditProductModal';

//--------------------------------------------------------------------------------------

export default class ListShopProductsModal extends React.Component {
    
    constructor(props) {
        super(props);

        this.editProductModalRef = React.createRef();

        this.state = {
            isLoading: true
        };
    }

    show(opts) {
        return new Promise((resolve, reject) => {
            this.resolve = resolve;
            this.setState({
                isOpen: true,
                isLoading: true,
                eCommerceConnectionID: opts.eCommerceConnectionID
            }, () => {
                this.load();
            });
        });
    }

    hide() {
        this.setState({ isOpen: false });
    }

    async load() {
        const { eCommerceConnectionID } = this.state;
        this.setState({ isLoading: true });
        try {
            const shopProducts = await ECommerceService.listShopProducts(eCommerceConnectionID);
            this.setState({
                shopProducts,
                isLoading: false
            });
        } catch (error) {
            BootboxHelper.alert(error);
            this.hide();
        }
    }
    
    updateFields(newValues, callback) {
        const product = { ...this.state.product };
        for (let fieldName in newValues) {
            const value = newValues[fieldName];
            product[fieldName] = value;
        }
        this.setState({ product }, callback);
    }

    async add(externalID) {
        await this.editProductModalRef.current.show({
            externalID,
            eCommerceConnectionID: this.state.eCommerceConnectionID
        });
        this.hide();
        this.resolve();
    }

    //---------------------------------------------------------------------

    render() {
        const {
            isOpen,
            isLoading,
            product
        } = this.state;

        if (!isOpen) {
            return null;
        }

        return (<>
            <Modal
                show
                className="list-ecommerce-shop-products-modal"
                onHide={e => this.setState({ isOpen: false })}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Connect a new shop product</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    {this.renderInner()}

                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="button button-secondary mr-auto" onClick={e => this.setState({ isOpen: false })}>
                        Cancel
                    </button>
                </Modal.Footer>
            </Modal>
            
            <EditProductModal ref={this.editProductModalRef} />

        </>);
    }

    renderInner() {
        const {
            isLoading,
            shopProducts
        } = this.state;

        if (isLoading) {
            return (<Loader />);
        }

        return (
            <SuperTable
                className="shop-products-table"
                rows={shopProducts}
                keyAccessor={p => p.externalID}
                emptyText="We couldn't find any products in your shop"
                onClick={p => this.add(p.externalID)}
                cols={{
                    image: {
                        label: 'Image',
                        className: 'image-col',
                        getValue: (colInfo, p) => p.imageURL &&
                            <img src={'/api/ecommerce/get-thumbnail/' + btoa(p.imageURL)} />
                    },
                    name: {
                        label: 'Product',
                        className: 'name-col'
                    },
                    variants: {
                        label: 'Variants / prices',
                        className: 'variants-col',
                        getValue: (colInfo, p) => p.variants.map((v, index) =>
                            <div key={index}>
                                {v.name} ({v.price})
                            </div>
                        )
                    }
                }}
            />
        );
    }
}
