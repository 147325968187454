// Libs
import React from 'react';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import DatePicker from 'components/reusable/DatePicker';
import NumberFormat from 'react-number-format';

// Services & Helpers
import OfficeService from 'services/OfficeService';
import GlobalStateService from 'services/GlobalStateService';
import SalonService from 'services/SalonService';
import ThermalPrinterService from 'services/ThermalPrinterService';

// Components
import FloomlyComponent from 'components/FloomlyComponent';
import Loader from 'components/reusable/Loader';
import Money from 'components/reusable/Money';

//-------------------------------------------------------------------------------------------------------------------

class EndOfDayBanking extends FloomlyComponent {

    constructor(props) {
        super(props);

        this.accountsChanged = {};

        // Init state
        this.state = {
            isLoading: true,
            paymentMethods: null,
            date: moment().startOf('day').toDate()
        };
        this.load();
    }

    async load() {
        const endOfDayBanking = await OfficeService.getEndOfDayBanking(this.state.date, this.state.date);
        const hasThermalPrinter = ThermalPrinterService.getHasThermalPrinter();
        this.setState({
            endOfDayBanking,
            hasThermalPrinter,
            isLoading: false
        });
    }

    setDate(date) {
        this.setState({
            date
        }, () => {
            this.load();
        });
    }

    updateActualTakings(paymentMethodID, amount) {
        const endOfDayBanking = { ...this.state.endOfDayBanking };
        endOfDayBanking.actualTakings[paymentMethodID] = Number(amount) || ''; 
        this.setState({
            endOfDayBanking
        });
    }

    saveActualTakings(paymentMethodID) {
        const amount = Number(this.state.endOfDayBanking.actualTakings[paymentMethodID]) || 0;
        OfficeService.saveActualTakings(this.state.date, paymentMethodID, amount);
    }

    updateField(field, value) {
        const endOfDayBanking = { ...this.state.endOfDayBanking };
        if (value != endOfDayBanking[field]) {
            endOfDayBanking[field] = value;
            this.isChanged = true;
        }
        this.setState({
            endOfDayBanking
        }, () => {
            if (field == 'notes') {
                this.saveNotes();
            }
        });
    }

    async saveNotes() {
        const { endOfDayBanking } = this.state;
        OfficeService.saveEODNotes(endOfDayBanking.date, endOfDayBanking.notes);
    }

    updateAccountField(accountCode, field, value) {
        const endOfDayBanking = { ...this.state.endOfDayBanking };
        const index = endOfDayBanking.accounts.findIndex(a => a.accountCode == accountCode);
        const account = { ...endOfDayBanking.accounts[index] };
        account[field] = value;
        this.accountsChanged[accountCode] = true;
        endOfDayBanking.accounts[index] = account;
        this.setState({ endOfDayBanking });
    }

    async saveAccount(account) {
        if (this.accountsChanged[account.accountCode]) {
            delete this.accountsChanged[account.accountCode];
            await OfficeService.saveEndOfDayBankingAccount(account, this.state.endOfDayBanking.date);
        }
    }

    //--------------------------------------------------------------------------------------------------------------------
    //  Render
    //--------------------------------------------------------------------------------------------------------------------

    render() {
        const {
            isLoading,
            endOfDayBanking,
            date,
            hasThermalPrinter
        } = this.state;

        if (isLoading) {
            return (<Loader />);
        }
        const loginDetails = GlobalStateService.getValue('loginDetails');
        const canEdit = loginDetails.permissions['OfficeEditEndOfDayBanking'];
        const tillMoniesLocked = moment().startOf('day').isAfter(moment(date));
        const currency = SalonService.getCurrentCurrency();

        // Calculate totals
        let totalExpected = 0, totalActual = 0;
        endOfDayBanking.expectedTakings.forEach(et => {
            if (et.paymentMethodName != 'Total') {
                totalExpected += (et.amount || 0);
            }
        });
        Object.values(endOfDayBanking.actualTakings).forEach(et => totalActual += (et || 0));
        const totalDelta = totalActual - totalExpected;

        return (<>

            <div className="control-panel">
                <DatePicker
                    onChange={date => this.setDate(date)}
                    value={date}
                />

            </div>

            <hr />
            
            <h2>Daily Totals</h2>

            <div className="table-container">
                <table className="eod-summary-table super-table">
                    <thead>
                        <tr>
                            <th>Type</th>
                            <th>Quantity</th>
                            <th>Amount (Net)</th>
                            <th>Tax Amount</th>
                            <th>Amount (Gross)</th>
                        </tr>
                    </thead>
                    <tbody>
                        {endOfDayBanking.dailyTotals.map((r, index) =>
                            <tr key={index} className={r.type == 'Total' ? 'total-row' : ''} style={{ fontWeight: (r._bold ? 'bold' : 'normal') }}>
                                {/*
                                colSpan={!!r.subType ? (r.type == 'Total' ? 2 : 1) : 5}*/}
                                <td colSpan={r.type == '-' ? 5 : (r.type == 'Total' ? 2 : 1)}>
                                    {r.subType || <>&nbsp;</>}
                                </td>
                                {r.subType && r.type != '-' && <>
                                    {r.type != 'Total' &&
                                        <td>
                                            {r.quantity != 0 ? r.quantity : ''}
                                        </td>
                                    }
                                    <td>
                                        {!!r.net &&
                                            <Money amount={r.net} />
                                        }
                                    </td>
                                    <td>
                                        {!!r.tax &&
                                            <Money amount={r.tax} />
                                        }
                                    </td>
                                    <td>
                                        {!!r.gross &&
                                            <Money amount={r.gross} />
                                        }
                                    </td>
                                </>}
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>

            <h2>Payment Methods</h2>
            <div className="table-container">
                <table className="eod-takings-table super-table">
                    <thead>
                        <tr>
                            <th className="payment-method-column">Type</th>
                            <th className="expected-column">Expected</th>
                            <th className="actual-column">Actual</th>
                            <th className="delta-column">+ / -</th>
                        </tr>
                    </thead>
                    <tbody>
                        {endOfDayBanking.expectedTakings.map(et => {
                            if (et.paymentMethodName == 'Total') {
                                return <React.Fragment key="total" />
                            }
                            const actual = endOfDayBanking.actualTakings[et.paymentMethodID];
                            const delta = (endOfDayBanking.actualTakings[et.paymentMethodID] || 0) - (et.amount || 0);

                            return (
                                <tr key={et.paymentMethodID}>
                                    <td>{et.paymentMethodName}</td>
                                    <td>
                                        <Money amount={et.amount} />
                                    </td>
                                    <td>
                                        <NumberFormat
                                            thousandSeparator={currency.thousandSeparator}
                                            decimalSeparator={currency.decimalSeparator}
                                            prefix={currency.symbol}
                                            className="has-prefix"
                                            value={actual || ''}
                                            onValueChange={(values) => this.updateActualTakings(et.paymentMethodID, values.value)}
                                            onBlur={e => this.saveActualTakings(et.paymentMethodID)}
                                            disabled={!canEdit}
                                        />
                                    </td>
                                    <td>
                                        {this.renderDelta(actual, delta)}
                                    </td>
                                </tr>
                            );
                        })}
                    
                        <tr className="total-row">
                            <td>Total</td>
                            <td>
                                <Money amount={totalExpected} />
                            </td>
                            <td>
                                <Money amount={totalActual} />
                            </td>
                            <td>
                                {this.renderDelta(totalActual, totalDelta)}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <h2>Till Monies</h2>
            <div className="table-container">
                <table className="eod-till-funds-table super-table">
                    <thead>
                        <tr>
                            <th>Type</th>
                            <th className="amount-column">Opening Balance</th>
                            <th className="amount-column">In</th>
                            <th className="amount-column">Out</th>
                            <th className="amount-column">Closing Balance</th>
                        </tr>
                    </thead>
                    <tbody>
                        {endOfDayBanking.accounts.map(account =>
                            <tr key={account.accountCode}>
                                <td>
                                    {account.accountName}
                                </td>
                                <td>
                                
                                    {tillMoniesLocked ?
                                        <Money amount={account.openingBalance || 0} /> :
                                        <NumberFormat
                                            thousandSeparator={currency.thousandSeparator}
                                            decimalSeparator={currency.decimalSeparator}
                                            prefix={currency.symbol}
                                            className="has-prefix"
                                            value={account.openingBalance || ''}
                                            onValueChange={(values) => this.updateAccountField(account.accountCode, 'openingBalance', Number(values.value) || '')}
                                            onBlur={e => this.saveAccount(account)}
                                            disabled={!canEdit || tillMoniesLocked}
                                        />
                                    }

                                </td>
                                <td>
                                    <Money amount={account.amountIn} />
                                </td>
                                <td>
                                    <Money amount={-account.amountOut} />
                                </td>
                                <td>
                                    <Money amount={Number(account.closingBalance) || 0} />
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>

            <div className="row">

                <div className="col-md-6">

                    <h2>Banked</h2>

                    <div className="table-container">
                        <table className="eod-banked-table super-table">
                            <thead>
                                <tr>
                                    <th>Type</th>
                                    <th className="amount-column">Total</th>
                                </tr>
                            </thead>
                            <tbody>
                                {endOfDayBanking.banked.map(b =>
                                    <tr key={b.paymentMethodName}>
                                        <td>{b.paymentMethodName}</td>
                                        <td>
                                            <Money amount={b.amount} />
                                        </td>
                                    </tr>
                                )}
                                {endOfDayBanking.banked.length == 0 && 
                                    <tr>
                                        <td colSpan={2} className="empty-text">
                                            Nothing banked
                                        </td>
                                    </tr>
                                }
                            </tbody>
                        </table>
                    </div>

                </div>

                <div className="col-md-6">

                    <h2>Notes for the day</h2>

                    <textarea
                        key={endOfDayBanking.notes}
                        defaultValue={endOfDayBanking.notes}
                        className="eod-notes"
                        rows={3}
                        onBlur={e => this.updateField('notes', e.target.value)}
                        disabled={!canEdit}
                    />

                </div>

            </div>

            <div style={{ display: 'flex', marginTop: 20 }}>

                <button className="ms-auto me-3 button button-tertiary" onClick={e => this.props.history.push('/the-office/transaction-summary')}>
                    Transaction Summary
                </button>

                <button className="me-3 button button-tertiary" onClick={e => window.print()}>
                    Print
                </button>

                {hasThermalPrinter &&
                    <button type="button" className="me-3 button button-tertiary" onClick={() => ThermalPrinterService.printEODBanking(endOfDayBanking)}>
                        Print (receipt printer)
                    </button>
                }

                {/* Placebo save button */}
                <button className="button button-primary" disabled={!canEdit}>
                    Save
                </button>

            </div>
        </>);
    }

    renderDelta(actual, delta) {
        if ((actual || 0) != 0) {
            delta = Math.round((delta || 0) * 100) / 100;
            if (delta == 0) return <>Spot on!</>;
            else if (delta > 0) return <>Over by <Money amount={delta} /></>
            else return <>Under by <Money amount={-delta} /></>
        }
        return null;
    }
};

export default withRouter(EndOfDayBanking);