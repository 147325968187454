import BaseService from 'services/BaseService';

//-------------------------------------------------------------------------------------------------------------------
export default class PromoCodeService
    extends BaseService {

    static async list() {
        return BaseService.callAPI('promo-code/list');
    }

    static async get(promoCodeID) {
        return BaseService.callAPI('promo-code/get/' + promoCodeID);
    }

    static async getPromoCodes(appointmentID) {
        return BaseService.callAPI('promo-code/get-promo-codes/' + appointmentID);
    }

    static async save(promoCode) {
        return BaseService.callAPI('promo-code/save', promoCode);
    }

    static async delete(id) {
        return BaseService.callAPI('promo-code/delete/' + id);
    }
}
