// Libs
import React, { Component } from 'react';
import moment from 'moment';
import 'moment/locale/en-gb';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

// Helpers
import TextHelpers from 'helpers/TextHelpers';
import DateHelpers from 'helpers/DateHelpers';

//-------------------------------------------------------------------------------------------------------------------

class SuperTable extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false
        };
    }

    componentDidMount() {
        this.isMobile = window.matchMedia('(pointer: coarse)').matches;
        this.buildGroupings(this.props.rows);
    }

    componentDidUpdate(oldProps) {
        let buildGroupings = false;
        if (this.props.rows.length != oldProps.rows.length) {
            buildGroupings = true;
        } else {
            for (var i = 0; i < oldProps.rows.length; i++) {
                const oldRow = oldProps.rows[i];
                const newRow = this.props.rows[i];
                if (oldRow._grouping != newRow._grouping) {
                    buildGroupings = true;
                    break;
                }
            }
        }
        if (buildGroupings) {
            this.buildGroupings(this.props.rows);
        }
    }

    buildGroupings(rows) {
        const groupings = {};
        rows.forEach(row => {
            if (row._grouping) {
                if (!groupings[row._grouping]) {
                    groupings[row._grouping] = {
                        isOpen: this.props.groupingsOpenByDefault || false,
                        rows: []
                    };
                }
            }
        });
        this.setState({
            groupings
        });
    }

    formatValue(value, type, col) {
        if (value === '' || value === null) {
            return '';
        }
        switch ((type || '').toLowerCase()) {
            case 'currency':
                return TextHelpers.formatCurrencyNew(value, { 
                    numDP: 2,
                    includeSymbol: true,
                    commas: true,
                    blankIfZero: (col && col.blankIfZero)
                });
            case 'date':
                if (value) {
                    return moment(value).format('DD/MM/YYYY');
                } else {
                    return '';
                }
            case 'datetime':
                if (value) {
                    return moment(value).format('DD/MM/YYYY \\a\\t HH:mm');
                } else {
                    return '';
                }
            case 'time':
                if (value) {
                    //return moment(value).format('HH:mm');
                    return DateHelpers.stripSeconds(value);
                } else {
                    return '';
                }
            case 'percentage':
                if (value) {
                    return Math.round(Number(value) * 10000) / 100 + '%';
                } else if (col && col.blankIfZero) {
                    return '';
                } else {
                    return '0%';
                }
                break;
            case 'number':
                if (value) {
                    return TextHelpers.formatNumber(value);
                } else if (col && col.blankIfZero) {
                    return '';
                } else {
                    return '0';
                }
                break;
            case 'html':
                if (value) {
                    return (
                        <div dangerouslySetInnerHTML={{ __html: value }}></div>
                    );
                }
                return null;
            default:
                return value;
        }
    }

    endDrag(e) {
        if (e.destination) {
            const {
                onDragEnd
            } = this.props.reorder;
            if (onDragEnd) {
                onDragEnd(e.draggableId, e.destination);
            }
        }
        this.setState({ isDragging: false });
    }

    toggleGrouping(key) {
        const groupings = { ...this.state.groupings };
        const grouping = groupings[key];
        if (grouping) {
            grouping.isOpen = !grouping.isOpen;
            this.setState({
                groupings
            });
        }
    }

    render() {
        let {
            className,
            containerClassName,
            rows,
            cols,
            reorder,
            cellPropsAccessor,
            emptyText
        } = this.props;

        if (!rows) {
            rows = [];
            console.warn('No rows specified for <SuperTable>');
        }
        if (!cols) {
            cols = {};
            console.warn('No cols specified for <SuperTable>');
        }

        // Convert cols to an array
        const colArray = [];
        let index = 0;
        for (var name in cols) {
            if (cols.hasOwnProperty(name)) {
                const col = cols[name];
                if (!col.isHidden) {
                    colArray.push({
                        id: col.id || name,
                        name: name,
                        className: col.className,
                        sortOrder: col.sortOrder || index,
                        col: col,
                        index: index++
                    });
                }
            }
        }
        colArray.sort((a, b) => a.sortOrder - b.sortOrder);

        if (rows.length == 0 && emptyText) {
            return (
                <div className="empty-text">
                    {emptyText}
                </div>
            );
        }

        return (<>
            <div className={`table-container ${containerClassName || ''}`}>
                <table className={'super-table ' + (className || '')} style={{ tableLayout: (!this.isMobile && reorder && (this.state.isDragging || true) ? 'fixed' : 'auto') }}>
                    <thead>
                        <tr>
                            {colArray.map(colInfo =>
                                <React.Fragment key={colInfo.name}>
                                    {(colInfo.col.renderHeaderCell || this.props.renderHeaderCell || ((colInfo) => <th
                                        {...(cellPropsAccessor ? cellPropsAccessor(colInfo, null, 0) : null)} className={colInfo.className || ''}>{colInfo.col.label}</th>))(colInfo)}
                                </React.Fragment>
                            )}
                        </tr>
                    </thead>
                    {reorder ?
                        <DragDropContext
                            onBeforeDragStart={e => this.setState({ isDragging: true })}
                            onDragStart={e => { }}
                            onDragEnd={e => this.endDrag(e)}
                        >
                            <Droppable droppableId={reorder.droppableID} isDragDisabled={!reorder.enabled}>
                                {(provided, snapshot) =>
                                    <tbody
                                        ref={provided.innerRef}
                                        {...provided.droppableProps}
                                    >
                                        {this.renderRows(colArray)}
                                        {provided.placeholder}
                                    </tbody>
                                }
                            </Droppable>
                        </DragDropContext> :
                        <tbody>
                            {this.renderRows(colArray)}
                        </tbody>
                    }
                </table>
            </div>
        </>);
    }

    renderRows(colArray) {
        let {
            keyAccessor,
            sorter,
            onClick,
            rowPropsAccessor,
            cellPropsAccessor,
            reorder
        } = this.props;
        const {
            groupings
        } = this.state;
        let rows = [...this.props.rows];

        if (sorter) {
            rows.sort(sorter);
        }

        const getContent = (colInfo, row, index, colIndex) =>
            this.formatValue(
                colInfo.col.getValue ?
                    colInfo.col.getValue(colInfo, row) :
                    row[colInfo.id],
                row[`_type_${colIndex}`] || row._type || colInfo.col.type,
                colInfo.col
            );

        const results = [];

        // If groupings not build yet, skip render - groupings will be built and then render will be called again
        if (!groupings) {
            return null;
        }

        // Create elements for each row
        const groupingsRendered = {};
        rows.forEach((row, rowIndex) => {
            const id = String((keyAccessor ? keyAccessor(row, rowIndex) : null) || rowIndex);
            const rowProps = {
                onClick: e => onClick ? onClick(row, e) : null,
                ...(rowPropsAccessor ? rowPropsAccessor(row, rowIndex) : null)
            };

            // Render grouping header
            if (row._grouping && groupings[row._grouping]) {
                if (!groupingsRendered[row._grouping]) {
                    groupingsRendered[row._grouping] = true;
                    
                    results.push(
                        <tr
                            key={'grouping-' + row._grouping}
                            className="grouping-row"
                            onClick={e => this.toggleGrouping(row._grouping)}
                        >
                            <td colSpan={colArray.length}>
                                {groupings[row._grouping].isOpen ?
                                    <span className="fa fa-minus-circle" /> :
                                    <span className="fa fa-plus-circle" />
                                }
                                {row._grouping}
                            </td>
                        </tr>
                    );
                }
                groupings[row._grouping].rows.push(row);
            }

            // Don't show any more if grouping is closed
            if (row._grouping && groupings[row._grouping] && !groupings[row._grouping].isOpen) {
                return;
            }

            if (reorder) {
                results.push(
                    <Draggable
                        key={id}
                        draggableId={id}
                        index={rowIndex}
                    >
                        {(provided, snapshot) =>
                            <tr
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                {...rowProps}
                                className={(rowProps.className || '') + (snapshot.isDragging ? ' dragging' : '')}
                            >
                                {colArray.map((colInfo, colIndex) =>
                                    <React.Fragment key={colInfo.name}>
                                        {(colInfo.col.renderCell || ((colInfo, row, colIndex) =>
                                            <TableCell
                                                isDragging={snapshot.isDragging}
                                                className={colInfo.className || ''}
                                                {...(cellPropsAccessor ? cellPropsAccessor(colInfo, row, rowIndex, colIndex) : null)}
                                            >
                                                {snapshot.width}
                                                {getContent(colInfo, row, rowIndex, colIndex)}
                                            </TableCell>
                                        ))(colInfo, row, colIndex)}
                                    </React.Fragment>
                                )}
                            </tr>
                        }
                    </Draggable>
                );
            } else {
                results.push(
                    <tr
                        key={id}
                        {...rowProps}
                        className={(rowProps.className || '')}
                    >
                        {colArray.map((colInfo, colIndex) =>
                            <React.Fragment key={colInfo.name}>
                                {(colInfo.col.renderCell || ((colInfo, row, colIndex) =>
                                    <TableCell
                                        key={colInfo.name}
                                        className={colInfo.className || ''}
                                        {...(cellPropsAccessor ? cellPropsAccessor(colInfo, row, rowIndex, colIndex) : null)}
                                    >
                                        {getContent(colInfo, row, rowIndex, colIndex)}
                                    </TableCell>
                                ))(colInfo, row, colIndex)}
                            </React.Fragment>
                        )}
                    </tr>
                );
            }

        });

        return results
    }
}

class TableCell extends React.Component {

    constructor(props) {
        super(props);
    }

    getSnapshotBeforeUpdate(prevProps) {
        if (!this.ref) {
            return null;
        }
        const isDragStarting = this.props.isDragging && !prevProps.isDragging;
        if (!isDragStarting) {
            return null;
        }
        const { width, height } = this.ref.getBoundingClientRect();
        const snapshot = {
            width,
            height,
        };
        return snapshot;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const ref = this.ref;
        if (!ref) {
            return;
        }

        if (snapshot) {
            if (ref.style.width === snapshot.width) {
                return;
            }
            ref.style.width = `${snapshot.width}px`;
            ref.style.height = `${snapshot.height}px`;
            return;
        }

        if (this.props.isDragging) {
            return;
        }

        // inline styles not applied
        if (ref.style.width == null) {
            return;
        }

        // no snapshot and drag is finished - clear the inline styles
        ref.style.removeProperty('height');
        ref.style.removeProperty('width');
    }

    setRef = (ref) => {
        // Needs to be written like this otherwise 'this' is null
        this.ref = ref;
    };

    render() {
        const props = { ...this.props, className: this.props.className };
        //props.className = (props.className || '') + (props.isDragging ? ' dragging' : '');
        delete props.isDragging;

        return <td ref={this.setRef} {...props}>
            {this.props.children}
        </td>;
    }

}

export default SuperTable;