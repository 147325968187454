// Libs
import React, { Component } from 'react';

// Images
import placeholderImage from 'images/ypt-tutorial-placeholder.jpg';

//-------------------------------------------------------------------------------------------------------------------

class TutorialSlide extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="ypt-slide ypt-slide-tutorial">

                <div className="ypt-title">
                    {/*Let Me Show You */}
                    How to set your Profit Target
                </div>

                <img src={placeholderImage} style={{ float: 'left', width: '415px', height: '276px', marginRight: '30px', marginBottom: '30px' }} />

                <p style={{ textAlign: 'left', fontSize: '20px' }}>Okay, this is a short video on how to use the ‘Profit Target Calculator' to set your targets - we'll help you each step of the way. And what's even better... When you start out, you will have a one hour session with one of our Licensed Fantastic Hairdresser Coaches to help make sure that your targets are right on the button! Again, this is our commitment to make sure you get the best out of Floomly. </p>
                <p style={{ textAlign: 'left', fontSize: '20px' }}>Here's Alan again - please grab a notepad for this and he will show you how the calculator works and what information you need to complete it. It's very simple but you may need to gather some little bits of information before you begin, to make sure it's accurate. And the great news is that you can't really go wrong here because you can change it whenever you need to! </p>
                <p style={{ textAlign: 'left', fontSize: '20px' }}>PS. If you want to wait for your coach to help you work through the calculator you can skip it for now - you will still be able to use Floomly :)</p>

                <div className="ypt-row ypt-controls-row">
                    <div>
                        <button className="ypt-skip-setup-button" onClick={e => this.props.onSkip()}>
                            Skip 'Your Profit Target'
                        </button>
                        <button className="button button-primary ypt-back-button" onClick={e => this.props.onBack()}>
                            Back
                        </button>
                    </div>
                    <div>

                        <button className="button button-secondary ypt-continue-button" onClick={e => this.props.onContinue()}>
                            Continue
                        </button>

                    </div>
                </div>
            </div>
        );
    }
}

export default TutorialSlide;
