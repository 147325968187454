// Libs
import React from 'react';
import { withRouter } from 'react-router-dom';

// Services & Helpers
import EmailService from 'services/EmailService';
import FormHelper from 'helpers/FormHelper';
import BootboxHelper from 'helpers/BootboxHelper';

// Components
import FloomlyComponent from 'components/FloomlyComponent';
import InfoBar from 'components/layout/InfoBar'
import Loader from 'components/reusable/Loader';

//-------------------------------------------------------------------------------------------------------------------

class SettingsEmailAccountEdit extends FloomlyComponent {

    constructor(props) {
        super(props);

        // Init state
        this.state = {
            isLoading: true,
            isTesting: false,
            emailAccount: null
        };

        this.updateField = this.updateField.bind(this);
        
        this.formHelper = new FormHelper({
            fields: {
                username: { label: 'Username (usually your email address)' },
                fromEmail: { label: 'From Email (if different to username)' },
                fromName: { label: 'From Name (the name that appears in the \'from\' field for your recipients)' },
                host: { label: 'Host' },
                port: { label: 'Port (usually 25, 587 or 465)', type: 'number' },
                password: { label: 'Password', type: 'password' },
                sslType: {
                    label: 'SSL/TLS type',
                    type: 'single-select',
                    getOptions: () => [
                        { id: 'none', name: 'None' },
                        { id: 'auto', name: 'Auto' },
                        { id: 'sslOnConnect', name: 'SSL on Connect' },
                        { id: 'startTls', name: 'StartTLS' },
                        { id: 'startTlsWhenAvailable', name: 'StartTLS when Available' }
                    ]
                },
            },
            getValue: (fieldName, fieldInfo) => this.state.emailAccount[fieldName],
            setValue: this.updateField
        });
    }

    componentDidMount() {
        this.load();
    }

    //--------------------------------------------------------------------------------------------------------------------

    async load() {
        const emailAccountID = Number(this.props.match.params.emailAccountID) || 0;

        let emailAccount;
        if (emailAccountID) {
            emailAccount = await EmailService.getAccount(emailAccountID);
        } else {
            emailAccount = {
                accountType: 'smtp',
                sslType: 'auto'
            };
        }

        // Update UI
        this.setState({
            isLoading: false,
            emailAccount
        });
    }

    goBack() {
        this.props.history.push('/settings/email-accounts');
    }

    async save() {
        this.setState({ isLoading: true });
        const emailAccount = { ...this.state.emailAccount };
        const { id } = await EmailService.saveAccount(emailAccount);
        this.setState({ isLoading: false });
        return id;
    }

    async saveAndGoBack() {
        const id = await this.save();
        if (this.state.emailAccount.emailAccountID) {
            this.goBack();
        } else {
            this.props.history.replace(`/settings/email-accounts/${id}`);
            this.load();
        }
    }

    updateField(fieldName, value, fieldInfo) {
        const emailAccount = { ...this.state.emailAccount };
        emailAccount[fieldName] = value;
        this.setState({
            emailAccount: emailAccount
        });
    }

    async confirmSendTestEmail() {
        const emailAddress = await BootboxHelper.prompt('Please enter email address to send a test email to');
        if (emailAddress && emailAddress.indexOf('@') != -1) {
            this.sendTestEmail(emailAddress);
        }
    }

    async sendTestEmail(emailAddress) {
        await this.save();
        this.setState({ isTesting: true });
        const { success, error } = await EmailService.sendTestEmail(this.state.emailAccount.emailAccountID, emailAddress);
        if (success) {
            BootboxHelper.alert('The test email was sent. Please check your inbox in the next few minutes to ensure it arrives.');
        } else {
            BootboxHelper.alert('Error sending test email: ' + error);
        }
        this.setState({ isTesting: false });
    }

    async confirmDelete() {
        const confirm = await BootboxHelper.confirm('Are you sure you want to delete this email account?');
        if (confirm) {
            this.delete();
        }
    }

    async delete() {
        await EmailService.deleteAccount(this.state.emailAccount.emailAccountID);
        this.props.history.replace('/settings/email-accounts');
    }

    //--------------------------------------------------------------------------------------------------------------------
    //  Render
    //--------------------------------------------------------------------------------------------------------------------

    renderInfoBar() {
        const {
            emailAccount,
            isTesting
        } = this.state;

        return (
            <InfoBar className="settings-info-bar" containerClassName="floating-on-mobile" sticky={true}>

                <div className="info-bar-panel-section">

                    <button className="button" onClick={() => this.goBack()}>
                        <span className="fa fa-chevron-left"></span>{' '}
                        Back
                    </button>

                </div>

                <div className="info-bar-panel-section info-bar-panel-section-text desktop-only">
                    {emailAccount.emailAccountID ? emailAccount.name : 'New SMTP account'}
                </div>

                {!isTesting &&
                    <div className="info-bar-panel-section ml-auto">

                        {!!emailAccount.emailAccountID &&
                            <button className="button" style={{ marginRight: 10 }} onClick={() => this.confirmSendTestEmail()}>
                                Test
                            </button>
                        }
                        {!!emailAccount.emailAccountID &&
                            <button className="button" style={{ marginRight: 10 }} onClick={() => this.confirmDelete()}>
                                <span className="fa fa-times"></span>
                                <span className="desktop-only">
                                    {' '}
                                    Delete
                                </span>
                            </button>
                        }
                        <button className="button" onClick={() => this.saveAndGoBack()}>
                            <span className="fa fa-check"></span>{' '}
                            Save
                        </button>

                    </div>
                }
                
            </InfoBar>
        );
    }
    
    render() {
        const {
            isLoading,
            isTesting,
            emailAccount
        } = this.state;

        if (isLoading) {
            return (<Loader />);
        }

        if (isTesting) {
            return (<>

                {this.renderInfoBar()}

                <div className="settings-page-main">
                    <div className="panel">
                        <div className="panel-body empty-text">
                            <Loader /><br />
                            Sending test email...
                        </div>
                    </div>
                </div>
            </>);
        }

        return (<>
            {this.renderInfoBar()}

            <div className="settings-page-main">
                <div className="panel">
                    <div className="panel-body">

                        {emailAccount.accountType == 'smtp' && this.renderSMTP()}
                        {emailAccount.accountType == 'mS365' && this.renderMS365()}
                        {emailAccount.accountType == 'gmail' && this.renderGmail()}

                    </div>
                </div>

            </div>

        </>);
    }

    renderSMTP() {
        return (<>

            {this.formHelper.renderFormGroups([
                'username',
                'fromEmail',
                'fromName',
                'host',
                'port',
                'password',
                'sslType'
            ])}

        </>);
    }

    renderMS365() {
        return (<>

            {this.formHelper.renderFormGroups([
                'fromName'
            ])}

        </>);
    }

    renderGmail() {
        return (<>

            {this.formHelper.renderFormGroups([
                'fromName'
            ])}

        </>);
    }
};

export default withRouter(SettingsEmailAccountEdit);