// Libs
import React from 'react';
import { Route } from 'react-router';
import { Link, withRouter } from 'react-router-dom';

// Services & Helpers
import UserService from 'services/UserService';

// Components
import FloomlyComponent from 'components/FloomlyComponent';
import InfoBar      from 'components/layout/InfoBar'
import Loader       from 'components/reusable/Loader';
import SuperTable   from 'components/reusable/SuperTable';

//-------------------------------------------------------------------------------------------------------------------

class SettingsUserList extends FloomlyComponent {

    constructor(props) {
        super(props);

        // Init state
        this.state = {
            isLoading: true,
            users: []
        };
    }

    componentDidMount() {
        this.load();
    }

    //--------------------------------------------------------------------------------------------------------------------

    async load() {
        const users = await UserService.list();
        
        this.setState({
            isLoading: false, 
            users: users
        });
    }

    edit(id) {
        this.props.history.push('/settings/users/' + id);
    }

    add() {
        this.props.history.push('/settings/users/new');
    }

    rearrangeUser(userID, newIndex) {
        // Rearrange
        const users = [...this.state.users];
        const currentIndex = users.findIndex(u => u.userID == userID);
        const user = users.splice(currentIndex, 1)[0];
        users.splice(newIndex, 0, user);

        // Update UI
        this.setState({
            users
        });

        // Send to server
        const userIDs = users.map(u => u.userID);
        UserService.rearrangeDiary(userIDs);
    }

	holidayPlanner() {
		this.props.history.push('/settings/holidays');
	}

    //--------------------------------------------------------------------------------------------------------------------
    //  Render
    //--------------------------------------------------------------------------------------------------------------------

    renderInfoBar() {
        return (
            <InfoBar className="settings-info-bar" containerClassName="floating-on-mobile" sticky={true}>

                <div className="info-bar-panel-section info-bar-panel-section-text settings-team desktop-only">
                    Team
                </div>
				<div className="info-bar-panel-section">
					<button className="button wide" onClick={() => this.holidayPlanner()}>
						<span className="fa fa-calendar-alt"></span>{' '}
						Diary Manager
					</button>
	            </div>    
				<div className="info-bar-panel-section">
					<button className="button" onClick={() => this.add()}>
	                    <span className="fa fa-plus"></span>{' '}
	                    Add New
	                </button>
                </div>

            </InfoBar>
        );
    }

    render() {
        const {
            isLoading,
            users
        } = this.state;

        if (isLoading) {
            return (<Loader />);
        }

        return (<>
            {this.renderInfoBar()}

            <div className="settings-page-main">
                <div className="panel">
                    <div className="panel-body">

                        <SuperTable
                            className="settings-table"
                            rows={users}
                            keyAccessor={user => user.userID}
                            cellPropsAccessor={(colInfo, user, index) => {
                                if (colInfo.id == 'diaryColour') {
                                    return {
                                        style: {
                                            width: 30,
                                            backgroundColor: (user ? user.diaryColour : undefined)
                                        }
                                    };
                                }
                            }}
                            cols={{
                                diaryColour: {
                                    getValue: () => null
                                },
                                firstName: { label: 'First Name' },
                                lastName: { label: 'Last Name' },
                                nickname: { label: 'Nickname' },
                                roleName: {
                                    label: 'Role',
                                    getValue: (colInfo, user) => (user.roleName || '') + (user.serviceBandName ? (user.roleName ? ' - ' : '') + user.serviceBandName : '')
                                },
                                mobileAccessStatus: {
                                    lable : 'Mobile access'
                                }
                            }}
                            onClick={(user, e) => this.edit(user.userID)}
                            reorder={{
                                enabled: true,
                                droppableID: 'users',
                                onDragEnd: (userID, destination) => {
                                    this.rearrangeUser(userID, destination.index);
                                }
                            }}
                        />
                        
                    </div>
                </div>

            </div>

        </>);
    }

};

export default withRouter(SettingsUserList);