// Libs
import React from 'react';
import moment from 'moment';
import 'moment/locale/en-gb';

// Services & Helpers
import DiaryService         from 'services/DiaryService';
import TextHelpers          from 'helpers/TextHelpers';
import BootboxHelper        from 'helpers/BootboxHelper';

// Components
import FloomlyComponent         from 'components/FloomlyComponent';
import Loader               from 'components/reusable/Loader';
import ApptProductsAndNotesEditor from 'components/reusable/ApptProductsAndNotesEditor';

//-------------------------------------------------------------------------------------------------------------------

class CustomerApptNotes extends FloomlyComponent {

    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            isLoadingList: true,
            appointments: null,
            appt: null,
            isClientRecordUpdated: false
        }

        this.updateField = this.updateField.bind(this);
    }

    componentDidMount() {
        this.load();
    }

    async load() {
        const appointments = await DiaryService.loadPreviousApointments(this.props.id, new Date(+new Date() + 86400000), 100);

        this.setState({
            appointments,
            isLoadingList: false
        });
        if (appointments.length > 0) {
            this.loadAppt(appointments[0].appointmentID);
        } else {
            this.setState({ isLoading: false });
        }
    }

    async loadAppt(id) {
        if (this.state.isClientRecordUpdated) {
            const confirm = await BootboxHelper.confirm(`Do you want to save notes before you exit?`);
            if (confirm) {
                await DiaryService.saveClientRecord(this.state.clientRecord);
            }
        }
        this.setState({
            isLoading: true
        });
        const appt = await DiaryService.loadClientRecord(id);
        this.setState({
            isLoading: false,
            appt,
            isClientRecordUpdated: false
        });
    }

    navigate(delta) {
        const { appt, appointments } = this.state;
        let apptIndex = appt ? appointments.findIndex(a => a.appointmentID == appt.appointmentID) : 0;
        apptIndex += delta;
        this.loadAppt(appointments[apptIndex].appointmentID);
    }

    async save() {
        this.setState({
            isLoading: true
        });
        await DiaryService.saveClientRecord(this.state.appt);
        this.setState({
            isLoading: false,
            isClientRecordUpdated: false
        });
        if (this.props.onSave) {
            this.props.onSave();
        }
    }

    updateField(field, value) {
        const appt = { ...this.state.appt };
        appt[field] = value;
        this.setState({ appt });
    }

    async setRecordUpdate(value) {
        this.setState({
            isClientRecordUpdated: value
        })
        this.props.isRecordUpdated(value);
    }

    //--------------------------------------------------------------------------------------------------------------------
    //  Render
    //--------------------------------------------------------------------------------------------------------------------

    render() {
        return (
            <div className="tab-panel-content panel">
                {this.renderInner()}
            </div>
        );
    }

    renderInner() {
        const {
            isLoading,
            appt,
            appointments
        } = this.state;

        if (isLoading) {
            return (
                <Loader />
            );
        }
        const apptIndex = appt ? appointments.findIndex(a => a.appointmentID == appt.appointmentID) : 0;

        return (<>

            <div className="panel-info-header">
                Appointment
            </div>

            {appointments.length > 0 ?
                <div className="mb-3 d-flex">

                    <button type="button" className="button button-small button-primary me-2" disabled={apptIndex == appointments.length - 1} onClick={e => this.navigate(1)}>
                        <span className="fa fa-chevron-left" />{' '}
                        <span className="desktop-only">Previous</span>
                    </button>

                    <select
                        value={appt?.appointmentID}
                        onChange={e => this.loadAppt(e.target.value)}
                    >
                        {appointments.map(a =>
                            <option key={a.appointmentID} value={a.appointmentID}>
                                {moment(a.date).format('Do MMM YYYY')}
                            </option>
                        )}
                    </select>

                    <button type="button" className="button button-small button-primary ms-2" disabled={apptIndex == 0} onClick={e => this.navigate(-1)}>
                        <span className="desktop-only">Next{' '}</span>
                        <span className="fa fa-chevron-right" />
                    </button>

                </div> :
                <p className="empty-text">This client has no appointments</p>
            }

            {appt &&
                <ApptProductsAndNotesEditor
                    appt={appt}
                    onBlur={(fieldName, value) => this.updateField(fieldName, value)}
                    onUpdate={appt => {
                        this.setState({ appt });
                        this.setRecordUpdate(true);
                    }}
                />
            }

        </>);
    }

}

export default CustomerApptNotes;