import BaseService from 'services/BaseService';

//-------------------------------------------------------------------------------------------------------------------

export default class RoleService
    extends BaseService {
    
    static async list() {
        return (await BaseService.callAPI('role/list'));
    }

    static async get(roleID) {
        return BaseService.callAPI('role/get/' + roleID);
    }

    static async getDefaultRole() {
        return BaseService.callAPI('role/get-default-role');
    }

    static async save(role) {
        return BaseService.callAPI('role/save', role);
    }

    static async delete(id) {
        return BaseService.callAPI('role/delete/' + id);
    }

}
