// Libs
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

// Services
import GlobalStateService from 'services/GlobalStateService';

// Components
import FloomlyComponent         from 'components/FloomlyComponent';

// Images
import heartCutImage from 'images/perfect-client/heart-blue-small.png';
import heartColourImage from 'images/perfect-client/heart-pink-small.png';
import heartTreatmentImage from 'images/perfect-client/heart-yellow-small.png';
import heartRetailImage from 'images/perfect-client/heart-green-small.png';
import heartPreBookImage from 'images/perfect-client/heart-purple-small.png';

import kmHeartCutImage from 'images/theme/kevin-murphy/perfect-client/heart-km-cut.png';
import kmHeartColourImage from 'images/theme/kevin-murphy/perfect-client/heart-km-colour.png';
import kmHeartTreatmentImage from 'images/theme/kevin-murphy/perfect-client/heart-km-treatment.png';
import kmHeartRetailImage from 'images/theme/kevin-murphy/perfect-client/heart-km-retail.png';
import kmHeartPreBookImage from 'images/theme/kevin-murphy/perfect-client/heart-km-prebook.png';

//-------------------------------------------------------------------------------------------------------------------

class PerfectClientRatingHearts extends FloomlyComponent {

    //--------------------------------------------------------------------------------------------------------------------
    //  Logic
    //--------------------------------------------------------------------------------------------------------------------

    constructor(props) {
        super(props);

        this.state = {
        }
    }
    
    //--------------------------------------------------------------------------------------------------------------------
    //  Render
    //--------------------------------------------------------------------------------------------------------------------

    renderHeart(field, suffix, image) {
        const dashboard = this.props.dashboard;
        let title = dashboard.perfectClient[field] + ' / ' + dashboard.perfectClient.numAppointments;
        if (dashboard.perfectClient.numAppointments) {
            const percentage = (Math.floor(dashboard.perfectClient[field] / dashboard.perfectClient.numAppointments * 100));
            title += ' (' + percentage + '%)';
        }
        title += ' ' + suffix;
        return (
            <img src={image} data-tip={title} />
        );
    }

    render() {
        //GlobalStateService.setValues({ clientInfo });
        const clientInfo = GlobalStateService.getValue('clientInfo');
        const code = (clientInfo.theme && clientInfo.theme.code) || '';

        const heartImages = {
            cut: heartCutImage,
            colour: heartColourImage,
            treatment: heartTreatmentImage,
            retail: heartRetailImage,
            preBook: heartPreBookImage
        };
        
        if (code == 'kevin-murphy') {
            heartImages.cut = kmHeartCutImage;
            heartImages.colour = kmHeartColourImage;
            heartImages.treatment = kmHeartTreatmentImage;
            heartImages.retail = kmHeartRetailImage;
            heartImages.preBook = kmHeartPreBookImage;
        }

        return (<>
            {this.renderHeart('numHadHaircut', 'had a haircut', heartImages.cut)}
            {this.renderHeart('numHadColour', 'had colour', heartImages.colour)}
            {this.renderHeart('numHadConditioning', 'had conditioning', heartImages.treatment)}
            {this.renderHeart('numHadRetailPurchase', 'made a retail purchase', heartImages.retail)}
            {this.renderHeart('numHadPreBook', 'pre-booked their next appointment', heartImages.preBook)}
        </>);
    }
}

export default withRouter(PerfectClientRatingHearts);