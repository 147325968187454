import BaseService from 'services/BaseService';

//-------------------------------------------------------------------------------------------------------------------

export default class ServiceCategoryService
    extends BaseService {

    static async list() {
        return (await BaseService.callAPI('service-category/list'));
    }

    static async listSummary() {
        return (await BaseService.callAPI('service-category/list-summary'));
    }

    static async get(serviceCategoryID) {
        return BaseService.callAPI('service-category/get/' + serviceCategoryID);
    }

    static async save(serviceCategory) {
        return BaseService.callAPI('service-category/save', serviceCategory);
    }

    static async delete(id) {
        return BaseService.callAPI('service-category/delete/' + id);
    }

    static async rearrange(serviceCategoryIDs) {
        return BaseService.callAPI('service-category/rearrange', {
            serviceCategoryIDs
        });
    }
}