export default class MergeFields {

    static mergeTags =
        [
            { name: 'Client » First Name', value: '{{ Customer.FirstName }}' },
            { name: 'Client » Last Name', value: '{{ Customer.LastName }}' },
            { name: 'Salon » Name', value: '{{ Salon.Name }}' },
            { name: 'Salon » Phone Number', value: '{{ Salon.Phone }}' },
            { name: 'Salon » Email Address', value: '{{ Salon.Email }}' },
        ];

    static appointmentMergeTags = 
        [
            { name: 'Appointment » Date', value: '{{ Appointment.Date }}' },
            { name: 'Appointment » Arrival Time', value: '{{ Appointment.StartTime }}' },
            { name: 'Appointment » Quoted Price', value: '{{  Appointment.QuotedPrice }}' },
            { name: 'Appointment » Deposit', value: '{{ Appointment.DepositAmount }}' },
            { name: 'Appointment » Balance', value: '{{  Appointment.Balance }}' },
            { name: 'Appointment » Confirm link', value: '{{ Appointment.ConfirmURL }}' },
            { name: 'Appointment » Service Summary', value: '{{ Appointment.Summary }}' },
            { name: 'Waiting Appointment » Preferred date', value: '{{ WaitingAppointment.PrimaryDate }}' },
            { name: 'Waiting Appointment » Second preferred date', value: '{{ WaitingAppointment.SecondaryDate }}' },
            { name: 'Waiting Appointment » Third preferred date', value: '{{ WaitingAppointment.TertiaryDate }}' },
            { name: 'Waiting Appointment » Service Summary', value: '{{ WaitingAppointment.Summary }}' },
            { name: 'Waiting Appointment » Time of day', value: '{{ WaitingAppointment.TimeOfDay }}' },
            { name: 'Waiting Appointment » Notes', value: '{{ WaitingAppointment.TimeNotes }}' },
            { name: 'Waiting Appointment » Additional Notes', value: '{{ WaitingAppointment.Notes }}' },
        ];



}