// Libs
import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import moment from 'moment';

//Helpers & Services
import StockService from 'services/StockService';
import SalonService from 'services/SalonService';
import BootboxHelper from 'helpers/BootboxHelper';
import ThermalPrinterService from 'services/ThermalPrinterService';

// Components
import FloomlyComponent from 'components/FloomlyComponent';
import SuperTable from 'components/reusable/SuperTable';
import Loader from 'components/reusable/Loader';

class ExistingOrderTab extends FloomlyComponent {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            createDraftOrder: false,
            salonCurrency :''
        };
    }

    componentDidMount() {
        this.load();
    }

    async load() {
        const stockOrderId = this.props.match.params.subTab;
        const salonCurrency = SalonService.getCurrentCurrency();
        let stockOrder = await StockService.getStockOrder(stockOrderId);
        this.setState({
            stockOrder,
            salonCurrency
        });
        if (this.props.match.params.subTabParam && this.props.match.params.subTabParam == 'checkin')
            this.renderCheckInTab();
    }

    updateStockItem(stockOrderItemID,  fieldName, value) {
        const { stockOrder } = this.state;
        const stockOrderItem = stockOrder.stockOrderItems.find(so => so.stockOrderItemID == stockOrderItemID);
        if (fieldName == 'quantityYetToArrive')
            stockOrderItem['quantityDelivered'] = value;
       
        stockOrderItem[fieldName] = value;

        this.setState({
            stockOrder
        });
    }

    onCheckIn() {
        this.props.history.push('/stock/stock-ordering/existing-order/' + this.props.match.params.subTab + '/' + 'checkin');
    }

    renderCheckInTab() {
        const { stockOrder } = this.state;
        const partialDelivery = stockOrder && stockOrder.stockOrderItems.some(so => so.quantityArrived < so.quantityYetToArrive);
        return (<>
            <div>
                {stockOrder && this.renderCheckInItemsTable(stockOrder)}
               
            </div>
             <div className="save-buttons">
                {partialDelivery && <button
                    className="button button-secondary button-small save-button me-3"
                    onClick={e => this.savePartialDelivery()}
                >
                    Save partial delivery
                </button>}
                    <button
                    className="button button-secondary button-small save-button me-3"
                    onClick={e => this.saveAndCompleteOrder()}
                    >
                        Save and Complete
                </button>
                <button
                    className="button button-secondary button-small save-button"
                    onClick={e => this.markAllArrived()}
                >
                    Mark all as arrived
                </button>
            </div>
        </>
        );
    }

    onCreateDraftOrder() {
        this.props.history.push('/stock/stock-ordering/create-new-stock-order/' + this.state.stockOrder.stockOrderID + '/' + 'draftOrder');
    }

   async savePartialDelivery() {
       const { stockOrder } = this.state;
       let confirm = true;
       const isStockArrived = stockOrder.stockOrderItems.some(s => s.quantityArrived > 0);

       if (!isStockArrived) {
           confirm = await BootboxHelper.confirm('Please note quantity arrived is 0. Do you still want to save?');
       }
       if (confirm) {
           stockOrder.status = 'Pending';
           let result = await StockService.saveStockOrderDelivery(stockOrder);
           if (result.stockOrderCheckInId > 0) {
               await BootboxHelper.alert('Order saved successfully!');
               this.props.history.push('/stock/stock-ordering/previous-orders-list/');
           }
       }

    }

    async saveAndCompleteOrder() {
        let stock = this.state.stockOrder;
        stock.status = 'Completed';
        let result = await StockService.saveStockOrderDelivery(stock);
        if (result.stockOrderCheckInId > 0) {
            await BootboxHelper.alert('Order saved successfully!');
            this.props.history.push('/stock/stock-ordering/previous-orders-list/');
        }
    }

    markAllArrived() {
        const { stockOrder } = this.state;
        stockOrder.stockOrderItems.map(so => {
            this.updateStockItem(so.stockOrderItemID, "quantityArrived", so.quantityYetToArrive);
        });
    }

    async printOrderPdf() {
        const { isPrinting, stockOrder } = this.state;
        if (isPrinting) {
            return;
        }

        this.setState({ isPrinting: true });       
        await ThermalPrinterService.printStockOrderWithPDFFallback(stockOrder.stockOrderID);
        this.setState({ isPrinting: false });
    }

    renderCheckInItemsTable(stockOrder) {
        return (
            <><SuperTable
                className="stock-categorise-table"
                rows={stockOrder.stockOrderItems}
                keyAccessor={s => s.stockOrderItemID}
                emptyText="You have no Stock Order Items"
                cols={{
                    name: {
                        label: 'Name',
                        className: 'name-column',
                        getValue: (colInfo, stockOrderItem) => <>
                            {
                                <><span>{stockOrderItem.stockItem.product.name}</span></>
                            }
                        </>
                    },
                    size: {
                        label: 'Size',
                        className: 'size-column',
                        getValue: (colInfo, stockOrderItem) => <>
                            {
                                <><span>{stockOrderItem.stockItem.size}</span>  {stockOrderItem.stockItem.product.stockUnit}

                                </>
                            }
                        </>
                    },
                    packSize: {
                        label: 'Pack Size',
                        className: 'pack-size-column',
                        getValue: (colInfo, stockOrderItem) =>

                            <span>{stockOrderItem.stockItem.packSize}</span>
                    },
                    costPrice: {
                        label: `Cost ${this.state.salonCurrency.symbol}`,
                        className: 'pack-size-column',
                        getValue: (colInfo, stockOrderItem) =>
                            <span>{this.state.salonCurrency.symbol}{stockOrderItem.stockItem.costPrice}</span>
                    },
                    expected: {
                        label: 'Expected',
                        className: 'expected-column',
                        getValue: (colInfo, stockOrderItem) =>
                            <span>{stockOrderItem.quantityOrdered}</span>
                    },
                    quantityYetToArrive: {
                        label: 'Remaining to arrive',
                        className: 'remaining-to-arrive-column',
                        getValue: (colInfo, stockOrderItem) =>
                            <span>{stockOrderItem.quantityYetToArrive}</span>
                    },
                    quantityDelivered: {
                        label: 'Arrived',
                        className: 'arrived-column',
                        getValue: (colInfo, stockOrderItem) =>
                             <input
                                type="number"
                                value={stockOrderItem.quantityArrived || ''}
                                onChange={e => this.updateStockItem(stockOrderItem.stockOrderItemID, 'quantityArrived', Number(e.target.value))}
                            />
                    },
                }}
            />
        </>);
    }

    renderOrderItemsTable(stockOrder) {
        let stockOrderItems = [];
        if (stockOrder.status == 'Completed') {
            stockOrderItems = stockOrder.stockOrderItems.filter(so => so.quantityYetToArrive > 0);
            if (stockOrderItems.length > 0) {
                stockOrder.stockOrderItems = stockOrderItems;
            }
        }
        return (<><SuperTable
            className="stock-categorise-table"
            rows={stockOrder.stockOrderItems}
            keyAccessor={s => s.stockOrderItemID}
            emptyText="You have no Stock Order Items"
            cols={{
                name: {
                    label: 'Name',
                    className: 'name-column',
                    getValue: (colInfo, stockOrderItem) => <>
                        {
                            <><span>{stockOrderItem.stockItem.product.name}</span></>
                        }
                    </>
                },
                size: {
                    label: 'Size',
                    className: 'size-column',
                    getValue: (colInfo, stockOrderItem) => <>
                        {
                            <><span>{stockOrderItem.stockItem.size}</span>  {stockOrderItem.stockItem.product.stockUnit}

                            </>
                        }
                    </>
                },
                packSize: {
                    label: 'Pack Size',
                    className: 'pack-size-column',
                    getValue: (colInfo, stockOrderItem) =>

                        <span>{stockOrderItem.stockItem.packSize}</span>
                },
                costPrice: {
                    label: `Cost ${this.state.salonCurrency.symbol}`,
                    className: 'cost-price-column',
                    getValue: (colInfo, stockOrderItem) =>
                        <span>{this.state.salonCurrency.symbol}{stockOrderItem.stockItem.costPrice}</span>
                },
                expected: {
                    label: 'Expected',
                    className: 'expected-column',
                    getValue: (colInfo, stockOrderItem) =>
                        <span>{stockOrderItem.quantityOrdered}</span>
                },
                quantityDelivered: {
                    label: 'Arrived',
                    className: 'arrived-column',
                    getValue: (colInfo, stockOrderItem) =>
                        <span>{stockOrderItem.quantityDelivered}</span>
                },
                quantityYetToArrive: {
                    label: 'Remaining to arrive',
                    className: 'remaining-to-arrive-column',
                    getValue: (colInfo, stockOrderItem) =>
                        <span>{stockOrderItem.quantityYetToArrive}</span>
                }
            }}
        />
            {stockOrderItems.length > 0 && !stockOrder.hasChildStockOrder && <div className="create-draft-button">
                <button
                    className="button button-secondary button-small draft-button"
                    onClick={e => this.onCreateDraftOrder()}
                >
                    Create draft order
                </button>
            </div>}
        </>);
    }

    render() {
        const {
            stockOrder,
            isPrinting
        } = this.state;
      
        return (<>
            {this.props.match.params.subTabParam != 'checkin' && stockOrder  && <>
                <div className="check-in-button">
                    <button className="button button-secondary button-small checkin-button me-3" onClick={e => this.printOrderPdf()}>
                        {!isPrinting && <>
                            <span className="fa fa-print"></span>{' '}
                            Print order
                        </>}
                        {isPrinting && <>
                            <Loader isInline={true} />{' '}
                            Printing...
                        </>}
                    </button>
                </div>
                {stockOrder.status == 'Pending' && <div className="check-in-button">
                    <button
                        className="button button-secondary button-small checkin-button me-3"
                        onClick={e => this.onCheckIn()}
                    >
                        Check in
                    </button>

                </div>}
                <div>
                <div className="stock-order-table">
                   
                <div className="stock-order-table-header">
                        <span style={{ fontWeight: 'bold' }}>Order Details:</span>
                    </div>
                   
                    <div className="stock-order-table-content">
                        <table className="super-table block-booking-table mb-0 mt-3">
                            <thead>
                                    <tr>
                                <th>Order Reference Id</th>
                                        <th>Date of Order</th>
                                        {stockOrder.dateFulfilled && <th>Order completed on</th>}
                                    <th className="stylist-col">Ordered by</th>
                                    <th className="time-col">Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                    <tr>
                                        <td>{stockOrder.reference}</td>
                                        <td>{moment(stockOrder.dateOrdered).format('DD/MM/YYYY')}</td>
                                        {stockOrder.dateFulfilled && <td>{moment(stockOrder.dateFulfilled).format('DD/MM/YYYY')}</td> }
                                    <td>{stockOrder.orderedBy}</td>
                                    <td>{stockOrder.status}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="stock-order-items-table">
                    <div className="stock-order-table-header bottom-margin">
                        <span style={{ fontWeight: 'bold' }}>Ordered Items:</span>
                    </div>
                    
                    <div className="stock-order-items-content">
                       {this.renderOrderItemsTable(stockOrder)}
                    </div>
                </div>
                </div>
              
            </>
            }
            {this.props.match.params.subTabParam == 'checkin' && this.renderCheckInTab() }
        </>);
    }
}

export default withRouter(ExistingOrderTab);