import BaseService from 'services/BaseService';

//-------------------------------------------------------------------------------------------------------------------

export default class ECommerceService
    extends BaseService {

    static async connect({ shop, code }) {
        return BaseService.callAPI('ecommerce/connect', { shop, code });
    }

    static async listConnections() {
        return BaseService.callAPI('ecommerce/list-connections');
    }

    static async getConnection(id) {
        return BaseService.callAPI('ecommerce/get-connection/' + id);
    }

    static async saveConnection(eCommerceConnection) {
        return BaseService.callAPI('ecommerce/save-connection', eCommerceConnection);
    }

    static async deleteConnection(id) {
        return BaseService.callAPI('ecommerce/delete-connection/' + id);
    }

    static async getAuthURL(id) {
        return BaseService.callAPI('ecommerce/get-auth-url/' + id);
    }

    static async listProducts(id) {
        return BaseService.callAPI('ecommerce/list-products/' + id);
    }

    static async getProduct(id) {
        return BaseService.callAPI('ecommerce/get-product/' + id);
    }

    static async saveProduct(product) {
        return BaseService.callAPI('ecommerce/save-product', product);
    }

    static async deleteProduct(id) {
        return BaseService.callAPI('ecommerce/delete-product/' + id);
    }
    
    static async listShopProducts(id) {
        return BaseService.callAPI('ecommerce/list-shop-products/' + id);
    }

    static async getShopProduct(id, externalID) {
        return BaseService.callAPI('ecommerce/get-shop-product/' + id + '/' + externalID);
    }

    static getShopTypeFriendly(shopType) {
        switch (shopType) {
            case 'wooCommerce': return 'WooCommerce';
            case 'shopifyPrivate': return 'Shopify (private app)';
        }
        return shopType;
    }

    static getConnectionStatus(eCommerceConnection) {
        if (!eCommerceConnection.dateConnected) return 'Not connected';
        else if (!eCommerceConnection.isEnabled) return 'Disabled';
        else return 'Connected';
    }
}
