// Libs
import React, { Component } from 'react';
import { withRouter } from 'react-router';

// Services
import SalonService from 'services/SalonService';
import UserService from 'services/UserService';
import AccountService from 'services/AccountService';

// Components
import Alert from 'components/reusable/Alert';
import Loader from 'components/reusable/Loader';

// Images
import logoImage from 'images/logo-pink.png';
import logoKevinMurphyImage from 'images/theme/kevin-murphy/logo-kevin-murphy.png';

class Register extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            user: ''
        };

        this.onSubmit = this.onSubmit.bind(this);
    }

    componentDidMount() {
        this.load();
    }

    async load() {
        const {
            mobileSalonCode,
            userInvitationCode
        } = this.props;

        let error = null, user = null;
        if (!userInvitationCode) {
            error = 'Invitation code not specified.';
        }
        const theme = await SalonService.getTheme(mobileSalonCode);

        if (userInvitationCode) {
            user = await UserService.getUserDetails(userInvitationCode);
            if (!user)
                error = 'Please specify correct invitation code.';
            else if (user.dateAccepted && !user.dateDisabled) {
                this.props.history.push('/?email=' + user.emailAddress);
            }
        }

        this.setState({ theme, user, error });
        window.applyTheme(theme);

        setTimeout(() => {
            const loginPanelEl = document.querySelector('.mobile-panel');
            if (loginPanelEl) {
                loginPanelEl.classList.add('show');
            }
        }, 500);
    }

    renderLogo(code) {
        if (code == 'kevin-murphy')
            return <img src={logoKevinMurphyImage} />;
        else
            return <img src={logoImage} />;
    }
    async onSubmit(e) {
        this.setState({ isLoading: true });
        e.preventDefault();
        await this.registerUser();
    }

    async registerUser() {
        try {
            await AccountService.registerUser({
                invitationCode: this.props.userInvitationCode,
                salonMobileCode: this.props.mobileSalonCode,
                mobilePassword: this.state.mobilePassword,
                userEmail: this.state.user.emailAddress
            });
            await this.props.onLogIn();
            this.setState({
                error: null,
                isLoading: false
            });
        } catch (error) {
            this.setState({
                isLoading: false,
                error
            });
        }
    }

    renderInner() {
        const {
            isLoading,
            user,
            mobilePassword,
            error,
            theme
        } = this.state;

        if (isLoading) {
            return (<Loader />);
        }

        const code = (theme && theme.code) || '';
        let email = user ? user.emailAddress : null;

        return (<>

            {this.renderLogo(code)}

            {error &&
                <Alert type="error">
                    {error}
                </Alert>
            }

            <form onSubmit={this.onSubmit}>
                <div className="form-group">
                    <label className="form-label">Your email address</label>
                    <input type="text" value={email || ''} disabled />
                </div>
                <div className="form-group">
                    <label className="form-label">Choose a password</label>
                    <input
                        type="password"
                        autoFocus
                        value={mobilePassword || ''}
                        onChange={e => this.setState({ mobilePassword: e.target.value })}
                    />
                </div>
                <div className="register-button">
                    <button type="submit" className="button button-secondary">
                        Register
                    </button>
                </div>
            </form>
        </>
        )
    }

    render() {
        return (
            <div className="mobile-panel">
                {this.renderInner()}
            </div>
        );
    }
}

export default withRouter(Register);