// Libs
import React from 'react';
import { withRouter } from 'react-router-dom';

// Services & Helpers
import GlobalStateService from 'services/GlobalStateService';
import BaseService from 'services/BaseService';
import BootboxHelper from 'helpers/BootboxHelper';

// Components
import FloomlyComponent from 'components/FloomlyComponent';
import Loader from 'components/reusable/Loader';

//-------------------------------------------------------------------------------------------------------------------

class DebugPage extends FloomlyComponent {

    constructor(props) {
        super(props);

        // Init state
        this.state = {
            isLoading: true
        };
    }

    componentDidMount() {
        this.load();
    }

    async load() {
        this.setState({
            isLoading: false
        });
    }

    async syncSearchIndex() {
        this.setState({ isLoading: true });
        await BaseService.callAPI('debug/sync-search-index');
        this.setState({ isLoading: false });
        BootboxHelper.alert('Done');
    }

    async rebuildSearchIndex() {
        this.setState({ isLoading: true });
        await BaseService.callAPI('debug/rebuild-search-index');
        this.setState({ isLoading: false });
        BootboxHelper.alert('Done');
    }

    async updateFutureApptPrices() {
        this.setState({ isLoading: true });
        await BaseService.callAPI('debug/update-future-appt-prices');
        this.setState({ isLoading: false });
        BootboxHelper.alert('Done');
    }

    async sendTestOnlineBookingNotification() {
        this.setState({ isLoading: true });
        await BaseService.callAPI('debug/send-test-online-booking-notification');
        this.setState({ isLoading: false });
        BootboxHelper.alert('Done');
    }

    async testErrorHandler() {
        this.setState({ isLoading: true });
        await BaseService.callAPI('debug/throw-error');
        this.setState({ isLoading: false });
    }
    
    async syncTransactionLogs(allSalons, deleteFirst) {
        this.setState({ isLoading: true });
        await BaseService.callAPI('debug/sync-transaction-logs', {
            allSalons,
            deleteFirst
        });
        this.setState({ isLoading: false });
    }

    async sendVoucherTest() {
        this.setState({ isLoading: true });
        await BaseService.callAPI('debug/send-voucher-test');
        this.setState({ isLoading: false });
    }

    async freeProcCache() {
        this.setState({ isLoading: true });
        await BaseService.callAPI('debug/free-proc-cache');
        this.setState({ isLoading: false });
    }

    async updateDBStatsSearch() {
        this.setState({ isLoading: true });
        await BaseService.callAPI('debug/update-stats-search');
        this.setState({ isLoading: false });
    }

    async updateDBStatsAll() {
        this.setState({ isLoading: true });
        await BaseService.callAPI('debug/update-stats-all');
        this.setState({ isLoading: false });
    }

    async replayLastShopifyWebhookCall() {
        this.setState({ isLoading: true });
        await BaseService.callAPI('debug/replay-last-shopify-webhook-call');
        this.setState({ isLoading: false });
    }

    async updateUpcomingApptTooltips() {
        this.setState({ isLoading: true });
        await BaseService.callAPI('debug/update-upcoming-appt-tooltips');
        this.setState({ isLoading: false });
    }

    async updateApptNotificationCache() {
        this.setState({ isLoading: true });
        await BaseService.callAPI('debug/update-appt-notification-cache');
        this.setState({ isLoading: false });
    }

    async syncPerfectClient() {
        this.setState({ isLoading: true });
        await BaseService.callAPI('debug/sync-perfect-client');
        this.setState({ isLoading: false });
    }

    async syncPerfectClientAll() {
        this.setState({ isLoading: true });
        await BaseService.callAPI('debug/sync-perfect-client-all');
        this.setState({ isLoading: false });
    }

    async clearCache() {
        this.setState({ isLoading: true });
        await BaseService.callAPI('debug/clear-cache');
        this.setState({ isLoading: false });
    }

    async triggerApptNotifications() {
        this.setState({ isLoading: true });
        await BaseService.callAPI('debug/trigger-appt-notifications');
        this.setState({ isLoading: false });
    }

    //--------------------------------------------------------------------------------------------------------------------
    //  Render
    //--------------------------------------------------------------------------------------------------------------------

    render() {
        return (<>

            <div className="debug-page">

                {this.renderMainContent()}

            </div>
        </>);
    }

    renderMainContent() {
        const {
            isLoading
        } = this.state;

        if (isLoading) {
            return (<Loader />);
        }
        const loginDetails = GlobalStateService.getValue('loginDetails');

        return (<>

            <button className="button button-primary" onClick={e => this.syncSearchIndex()}>
                Sync search index
            </button>
            <br/>
            <br />
            <button className="button button-primary" onClick={e => this.updateFutureApptPrices()}>
                Update future appointment prices
            </button>
            <br />
            <br/>
            <button className="button button-primary" onClick={e => this.sendTestOnlineBookingNotification()}>
                Send test Online Booking notification
            </button>
            <br />
            <br/>
            <button className="button button-primary" onClick={e => this.testErrorHandler()}>
                Test error handler
            </button>
            <br />
            <br />
            <button className="button button-primary" onClick={e => this.syncTransactionLogs(false, false)}>
                Sync transaction logs
            </button>
            <br />
            <br />
            <button className="button button-primary" onClick={e => this.syncPerfectClient()}>
                Sync Perfect Client
            </button>
            <br />
            <br />
            <button className="button button-primary" onClick={e => this.syncPerfectClientAll()}>
                Sync Perfect Client All
            </button>
            <br />
            <br />
            {/*
            <button className="button button-primary" onClick={e => this.rebuildSearchIndex()}>
                Rebuild entire search index
            </button>
            <br />
            <br />
            <button className="button button-primary" onClick={e => this.syncTransactionLogs(false, true)}>
                Sync transaction logs (delete first)
            </button>
            <br />
            <br />
            <button className="button button-primary" onClick={e => this.sendVoucherTest()}>
               Test shopify voucher
            </button>
            <br />
            <br />
            <button className="button button-primary" onClick={e => this.replayLastShopifyWebhookCall()}>
                Replay last Shopify webhook call
            </button>
            <br />
            <br />
            <button className="button button-primary" onClick={e => this.freeProcCache()}>
                Free procedure cache
            </button>
            <br />
            <br />
            <button className="button button-primary" onClick={e => this.updateDBStatsSearch()}>
                Update DB stats (Search only)
            </button>
            <br />
            <br />
            <button className="button button-primary" onClick={e => this.updateDBStatsAll()}>
                Update DB stats (All)
            </button>
            <br />
            <br />
            */}
            <button className="button button-primary" onClick={e => this.updateUpcomingApptTooltips()}>
                Update upcoming appt tooltips
            </button>
            <br />
            <br />
            <button className="button button-primary" onClick={e => this.updateApptNotificationCache()}>
                Update appt notification cache
            </button>
            <br />
            <br />
            <button className="button button-primary" onClick={e => this.clearCache()}>
                Clear cache
            </button>
            <br />
            <br />
            <button className="button button-primary" onClick={e => this.triggerApptNotifications()}>
                Trigger Appt Notifications
            </button>
        </>);
    }

};

export default withRouter(DebugPage);