// Libs
import React from 'react';

// Services & helpers
import BootboxHelper from 'helpers/BootboxHelper';
import StandardReportService from 'services/StandardReportService';
import FormHelper from 'helpers/FormHelper';

// Components
import FloomlyComponent from 'components/FloomlyComponent';
import Loader from 'components/reusable/Loader';
import SuperTable from 'components/reusable/SuperTable';

//-------------------------------------------------------------------------------------------------------------------

class StandardReports extends FloomlyComponent {

    constructor(props) {
        super(props);

        // Init state
        this.state = {
            isLoading: true,
            reportTypes: null,
            reportTypeID: null,
            optionValues: {}
        };
    }

    componentDidMount() {
        const id = this.props.id;
        if (id) {
            this.loadReport(id, false);
        } else {
            this.load();
        }
    }

    async load() {
        const reportTypes = await StandardReportService.listTypes();
        this.setState({
            isLoading: false,
            reportTypes
        });
    }

    async loadReport(id, silent) {
        if (!silent) {
            this.setState({ isLoading: true });
        }
        const reportType = await StandardReportService.getType(id, this.state.optionValues);

        // Set up options
        const optionValues = { ...this.state.optionValues };
        const formHelperOptions = {
            fields: {},
            getValue: (fieldName, fieldInfo) => this.state.optionValues[fieldName],
            setValue: (fieldName, value, fieldInfo) => this.updateOptionValue(fieldName, value)
        };
        this.formHelper = new FormHelper(formHelperOptions);
        reportType.options.forEach(o => {
            let type = '';
            switch (o.dataType) {
                case 'singleSelect': type = 'single-select'; break;
                case 'buttonGroup': type = 'button-group'; break;
                case 'bool': type = 'checkbox'; break;
                default: type = o.dataType; break;
            }
            if (!optionValues[o.id]) {
                optionValues[o.id] = o.defaultValue;
            }
            formHelperOptions.fields[o.id] = {
                type: type,
                label: o.label,
                getOptions: () => o.optionValues,
                getOptionValue: (ov) => ov.value,
                getOptionLabel: (ov) => ov.text
            };
        });

        // Update UI
        this.setState({
            reportTypeID: id,
            reportType,
            optionValues,
            isLoading: false
        });
    }

    async run() {
        this.setState({
            isLoading: true
        });
        try {
            const results = await StandardReportService.run({
                reportTypeID: this.state.reportTypeID,
                optionValues: this.state.optionValues
            });
            this.setState({
                isLoading: false,
                results
            });
        } catch (error) {
            this.setState({
                isLoading: false,
                results: null,
                error
            });
        }
    }

    updateOptionValue(name, value) {
        const { reportType } = this.state;
        const optionValues = { ...this.state.optionValues };
        optionValues[name] = value;
        const option = reportType.options.find(o => o.id == name);
        this.setState({
            optionValues
        }, () => {
            if (option && option.reloadOptions) {
                this.loadReport(reportType.id, true);
            }
        });
    }

    async exportExcel() {
        this.setState({ isExporting: true });
        await StandardReportService.exportToExcel({
            reportTypeID: this.state.reportTypeID,
            optionValues: this.state.optionValues
        });
        this.setState({ isExporting: false });
    }

    //--------------------------------------------------------------------------------------------------------------------
    //  Render
    //--------------------------------------------------------------------------------------------------------------------

    render() {
        const {
            isLoading,
            reportTypes,
            reportTypeID,
            error
        } = this.state;

        if (isLoading) {
            return (<Loader />);
        }

        return (<>

            {error &&
                <div className="alert alert-danger">
                    {error}
                </div>
            }

            {reportTypeID ?
                this.renderReportType() :
                reportTypes.map(rt =>
                    <button
                        key={rt.id}
                        className="button button-primary"
                        onClick={e => this.loadReport(rt.id)}
                    >
                        {rt.name}
                    </button>
                )
            }

        </>);
    }

    renderOptions() {
        const {
            reportType
        } = this.state;
        const fieldIDs = reportType.options.map((o, index) => o.id);
        return this.formHelper.renderFormGroups(fieldIDs);
    }

    renderReportType() {
        const {
            results,
            isExporting
        } = this.state;

        return (
            <div className="standard-report">

                {!this.props.id && <>
                    <button
                        className="button button-secondary button-small"
                        onClick={e => this.setState({ reportTypeID: null })}
                    >
                        <span className="fas fa-arrow-left"></span>{' '}
                        Go Back
                    </button>

                    <hr />
                </>}

                {this.renderOptions()}

                <div className="report-button-panel">
                    <button className="button button-primary" onClick={e => this.run()}>
                        Run{' '}
                        <span className="fa fa-chevron-right"></span>
                    </button>
                    {results && <>
                        <div className="ms-auto">
                            <button className="button button-tertiary" onClick={() => this.exportExcel()} disabled={isExporting}>
                                {isExporting ? <Loader isInline /> : <>Export</>}
                            </button>
                            <button className="button button-tertiary ms-2" onClick={e => window.print()}>
                                Print
                            </button>
                        </div>
                    </>}
                </div>

                {results && this.renderResults()}
            </div>
        );
    }

    renderResults() {
        const {
            reportTypeID,
            reportType,
            reportTypes,
            results
        } = this.state;

        return results.sections.map((s, index) =>
            <React.Fragment key={index}>
                {this.renderSection(s)}
            </React.Fragment>
        );
    }

    renderSection(section) {
        return (<>
            {section.title &&
                <h2>{section.title}</h2>
            }

            <section className={section.className || ''}>
                <SuperTable
                    keyAccessor={section => section.id}
                    className="standard-report-table "
                    rows={section.rows}
                    cols={section.colsDict}

                    rowPropsAccessor={(row, index) => {
                        let props = {
                            style: {}
                        };
                        if (row._className) {
                            props.className = row._className;
                        }
                        if (row._bold) {
                            props.style.fontWeight = 'bold';
                        }
                        return props;
                    }}
                    cellPropsAccessor={(colInfo, row, index) => {
                        let props = {
                            style: {}
                        };
                        if (row) {
                            if (row._indent > 0 && colInfo.name == 'type') {
                                props.style.paddingLeft = row._indent;
                            }
                            else if (colInfo.col.indent > 0) {
                                props.style.paddingLeft = colInfo.col.indent;
                            }
                        }
                        if (colInfo.col.width) {
                            props.style.width = colInfo.col.width;
                        }
                        return props;
                    }}
                    groupingAccessor={(colInfo, row, index) => row._grouping}
                    emptyText="No results"
                />
            </section>
        </>);
    }
};

export default StandardReports;