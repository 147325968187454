import BaseService from 'services/BaseService';

export default class NotificationService extends BaseService {

    static async getNotificationsEnabled() {
        return BaseService.callAPI('notification/get-notifications-enabled');
    }

    static async getNumUnreadNotifications() {
        return BaseService.callAPI('notification/get-num-unread-notifications');
    }

    static async getNotifications(notifType) {
        return BaseService.callAPI('notification/get-notifications/' + notifType);
    }

    static async readNotification(notifID) {
        return BaseService.callAPI('notification/read-notification/' + notifID);
    }

    static async repliedNotification(notifID) {
        return BaseService.callAPI('notification/replied-notification/' + notifID);
    }

    static async deleteNotification(notifID) {
        return BaseService.callAPI('notification/delete-notification/' + notifID);
    }

}