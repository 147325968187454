import BaseService from 'services/BaseService';
import GlobalStateService from 'services/GlobalStateService';
import PrintService from 'services/PrintService';
import moment from 'moment';
import BootboxHelper from 'helpers/BootboxHelper';
import TextHelpers from '../helpers/TextHelpers';

//-------------------------------------------------------------------------------------------------------------------

export default class ThermalPrinterService
    extends BaseService {

    static async list() {
        return BaseService.callAPI('thermal-printer/list');
    }

    static async get(id) {
        return BaseService.callAPI('thermal-printer/get/' + id);
    }

    static async save(thermalPrinter) {
        return BaseService.callAPI('thermal-printer/save', thermalPrinter);
    }

    static async delete(id) {
        return BaseService.callAPI('thermal-printer/delete/' + id);
    }

    static getHasThermalPrinter() {
        const clientInfo = GlobalStateService.getValue('clientInfo');
        return !!clientInfo && clientInfo.thermalPrinters.length > 0;
        //let hasThermalPrinter = false;
        //await ThermalPrinterService.forEachPrinter(async (thermalPrinter, device) => {
        //    //if (thermalPrinter.enablePrinting) {
        //        hasThermalPrinter = true;
        //    //}
        //});
        //return hasThermalPrinter;
    }

    static findMatchingDevice(devices, thermalPrinter) {
        const device = devices.find(device =>
            device.productId == thermalPrinter.usbProductID &&
            device.vendorId == thermalPrinter.usbVendorID &&
            device.serialNumber == thermalPrinter.serialNumber
        );
        return device;
    }

    static async execute(device, fn) {
        try {
            if (!device.opened) {
                await device.open();
            }
            var config = device.configurations[0];
            await device.selectConfiguration(config.configurationValue);
            await device.claimInterface(config.interfaces[0].interfaceNumber);
            await device.selectAlternateInterface(config.interfaces[0].interfaceNumber, {});
            await fn(device);
        } catch (e) {
            console.log('Thermal printer error (exeute)', e);
        } finally {
            if (device.opened) {
                await device.close();
            }
        }
    }

    static async forEachPrinter(fn) {
        const clientInfo = GlobalStateService.getValue('clientInfo');
        if (clientInfo.thermalPrinters.length > 0) {
            const devices = await navigator.usb.getDevices();
            for (let i = 0; i < clientInfo.thermalPrinters.length; i++) {
                const thermalPrinter = clientInfo.thermalPrinters[i];
                const device = ThermalPrinterService.findMatchingDevice(devices, thermalPrinter);
                if (device) {
                    await fn(thermalPrinter, device);
                }
            }
        }
    }

    static async testPrintText() {
        await ThermalPrinterService.forEachPrinter(async (thermalPrinter, device) => {
            await ThermalPrinterService.execute(device, async () => {
                const enc = new TextEncoder(); // always utf-8
                const buffer = enc.encode("This is a test\n");
                await device.transferOut(1, buffer);
            });
        });
    }

    static async openCashDrawers(isManual) {
        try {
            await ThermalPrinterService.forEachPrinter(async (thermalPrinter, device) => {
                if (thermalPrinter.cashDrawer1Type && (isManual || thermalPrinter.openCashDrawer1OnCheckout)) {
                    await ThermalPrinterService.openCashDrawer(device, 1, thermalPrinter.cashDrawer1Type);
                }
                if (thermalPrinter.cashDrawer2Type && (isManual || thermalPrinter.openCashDrawer2OnCheckout)) {
                    await ThermalPrinterService.openCashDrawer(device, 2, thermalPrinter.cashDrawer2Type);
                }
            });
        } catch (e) {
            console.log('Thermal printer error (openCashDrawers)', e);
        }
    }

    static async openCashDrawer(device, num, type) {
        return ThermalPrinterService.execute(device, async () => {
            // Not sure why, but we need to send the command twice.
            // TODO figure this out at some point
            for (let i = 0; i < 2; i++) {
                let buffer = null;
                switch (type) {
                    case 'epson':
                        buffer = Uint8Array.from([0x1B, 0x70, (num == 1 ? 0x00 : 0x01)/*, 0x37, 0x79 */]);
                        break;
                    case 'm80':
                        buffer = Uint8Array.from([0x1B, 0x78, (num == 1 ? 0x00 : 0x01)/*, 0x37, 0x79 */]);
                        break;
                }
                if (buffer) {
                    await device.transferOut(1, buffer);
                }
            }
        });
    }

    static async printApptReceiptWithPDFFallback(appointmentID) {
        let isPrinted = false;
        let receipt = null;
        try {
            await ThermalPrinterService.forEachPrinter(async (thermalPrinter, device) => {
                if (thermalPrinter.enablePrinting) {
                    if (!receipt) {
                        receipt = await BaseService.callAPI('appointment/get-receipt/' + appointmentID);
                    }
                    await ThermalPrinterService._printReceipt(device, thermalPrinter, receipt);
                    isPrinted = true;
                }
            });
        } catch (e) {
            console.log('Thermal printer error (printApptReceiptWithPDFFallback)', e);
            isPrinted = false;
        }
        if (!isPrinted) {
            await PrintService.printURL('/api/appointment/get-receipt-pdf/' + appointmentID);
        }
    }

    static async printRetailPurchaseReceiptWithPDFFallback(retailPurchaseID) {
        let isPrinted = false;
        let receipt = null;
        try {
            await ThermalPrinterService.forEachPrinter(async (thermalPrinter, device) => {
                if (thermalPrinter.enablePrinting) {
                    if (!receipt) {
                        receipt = await BaseService.callAPI('retail/get-receipt/' + retailPurchaseID);
                    }
                    await ThermalPrinterService._printReceipt(device, thermalPrinter, receipt);
                    isPrinted = true;
                }
            });
        } catch (e) {
            console.log('Thermal printer error (printRetailPurchaseReceiptWithPDFFallback)', e);
            isPrinted = false;
        }
        if (!isPrinted) {
            await PrintService.printURL('/api/retail/get-receipt-pdf/' + retailPurchaseID);
        }
    }

    static async printApptWithPDFFallback(apptID) {
        let isPrinted = false;
        let appt = null;
        try {
            await ThermalPrinterService.forEachPrinter(async (thermalPrinter, device) => {
                if (thermalPrinter.enablePrinting) {
                    if (!appt) {
                        appt = await BaseService.callAPI('appointment/get-appt/' + apptID);
                    }
                    await ThermalPrinterService._printAppt(device, thermalPrinter, appt);
                    isPrinted = true;
                }
            });
        } catch (e) {
            console.log('Thermal printer error (printApptWithPDFFallback)', e);
            isPrinted = false;
        }
        if (!isPrinted) {
            await PrintService.printURL('/api/appointment/get-appt-pdf?ID=' + (apptID));
        }
    }

    static async printEODBanking(endOfDayBanking) {
        let isPrinted = false;
        try {
            await ThermalPrinterService.forEachPrinter(async (thermalPrinter, device) => {
                if (thermalPrinter.enablePrinting) {
                    await ThermalPrinterService._printEODBanking(device, thermalPrinter, endOfDayBanking);
                    isPrinted = true;
                }
            });
        } catch (e) {
            console.log('Thermal printer error (printEODBanking)', e);
            isPrinted = false;
        }
        if (!isPrinted) {
            BootboxHelper.alert('There was a problem printing to the thermal printer. Please check that the printer is working or do a regular print instead.');
        }
    }

    static async printStylistApptsWithPDFFallback(stylistUserID, date) {
        let isPrinted = false;
        let stylistAppts = null;
        try {
            await ThermalPrinterService.forEachPrinter(async (thermalPrinter, device) => {
                if (thermalPrinter.enablePrinting) {
                    if (!stylistAppts) {
                        stylistAppts = await BaseService.callAPI('appointment/get-stylist-appts', { stylistUserID, date });
                    }
                    await ThermalPrinterService._printStylistAppts(device, thermalPrinter, stylistAppts);
                    isPrinted = true;
                }
            });
        } catch (e) {
            console.log('Thermal printer error (printStylistApptsWithPDFFallback)', e);
            isPrinted = false;
        }
        if (!isPrinted) {
            await PrintService.printURL('/api/appointment/get-stylist-appts-pdf?stylistUserID=' + (stylistUserID || '') + '&date=' + date);
        }
    }

    static async printStockOrderWithPDFFallback(stockOrderID) {
        await PrintService.printURL('/api/stock/get-stock-order-pdf/' + stockOrderID);
    }

    static async _printReceipt(device, thermalPrinter, receipt) {
        const initCmd = '\x1B\x74' + String.fromCharCode(thermalPrinter.printCodePage);
        const leftTextCmd = '\x1Ba0';
        const centreTextCmd = '\x1Ba1';
        const cutPaperCmd = '\x1B' + '\x69';
        //const enableBoldCmd = '\x1BE' + String.fromCharCode(1);
        //const disableBoldCmd = '\x1BE' + String.fromCharCode(0);
        const separator = '-'.repeat(thermalPrinter.printWidth);
        const indentLevel = 2;
        const lines = [];
        let numSpaces;

        if (receipt.topText) {
            lines.push(centreTextCmd + receipt.topText);
        }
        lines.push(leftTextCmd + separator);

        // Services
        if (receipt.services && receipt.services.length > 0) {
            lines.push('SERVICES');
            for (let i = 0; i < receipt.services.length; i++) {
                const service = receipt.services[i];
                const total = ThermalPrinterService.processCurrency(service.totalFormatted, thermalPrinter);
                numSpaces = Math.max(1, thermalPrinter.printWidth - (indentLevel + service.name.length + total.length + 1));
                lines.push(' '.repeat(indentLevel) + service.name.toUpperCase() + ' '.repeat(numSpaces) + total);
            }
        }

        // Products
        if (receipt.purchases && receipt.purchases.length > 0) {
            lines.push('PRODUCTS');
            for (let i = 0; i < receipt.purchases.length; i++) {
                const purchase = receipt.purchases[i];
                const total = ThermalPrinterService.processCurrency(purchase.totalFormatted, thermalPrinter);
                numSpaces = Math.max(1, thermalPrinter.printWidth - (indentLevel + purchase.name.length + total.length + 1));
                lines.push(' '.repeat(indentLevel) + purchase.name.toUpperCase() + ' '.repeat(numSpaces) + total);
            }
        }

        // VAT, total etc
        lines.push(separator);
        if (receipt.taxSummary) {
            for (var i = 0; i < receipt.taxSummary.length; i++) {
                const taxSummaryLine = receipt.taxSummary[i];
                const total = ThermalPrinterService.processCurrency(taxSummaryLine.totalFormatted, thermalPrinter);
                numSpaces = Math.max(1, thermalPrinter.printWidth - (taxSummaryLine.name.length + total.length + 1));
                lines.push(taxSummaryLine.name.toUpperCase() + ' '.repeat(numSpaces) + total);
            }
            if (receipt.taxSummary.length > 0) {
                const total = ThermalPrinterService.processCurrency(receipt.taxFormatted, thermalPrinter);
                numSpaces = Math.max(1, thermalPrinter.printWidth - ('TOTAL TAX'.length + total.length + 1));
                lines.push('TOTAL TAX' + ' '.repeat(numSpaces) + total);
            }
        }

        const total = ThermalPrinterService.processCurrency(receipt.totalFormatted, thermalPrinter);
        numSpaces = Math.max(1, thermalPrinter.printWidth - ('TOTAL'.length + total.length + 1));
        lines.push('TOTAL' + ' '.repeat(numSpaces) + total);
        lines.push(separator);
        if (receipt.bottomText) {
            lines.push(centreTextCmd + receipt.bottomText);
        }
        if (receipt.nextAppt) {
            lines.push(centreTextCmd + 'Your next appointment');
            lines.push('is on ' + moment(receipt.nextAppt.date).format('DD/MM/YYYY'));
            for (let i = 0; i < receipt.nextAppt.services.length; i++) {
                const service = receipt.nextAppt.services[i];
                lines.push(`${service.name} with ${service.stylistName} at ` + moment(service.time).format('HH:mm'));
            }
        }
        lines.push(leftTextCmd + separator);
        lines.push(centreTextCmd + receipt.dateFormatted);
        if (receipt.isVATReg) {
            lines.push('VAT Reg No.: ' + receipt.vatRegNo);
        }

        lines.push(' ');
        lines.push(separator);

        return ThermalPrinterService.execute(device, async () => {
            const outputText = initCmd + lines.join('\n') + '\n\n\n' + cutPaperCmd;
            const buffer = this.encode(outputText);
            await device.transferOut(1, buffer);
        });
    }

    static async _printAppt(device, thermalPrinter, appt) {
        const initCmd = '\x1B\x74' + String.fromCharCode(thermalPrinter.printCodePage);
        const leftTextCmd = '\x1Ba0';
        const centreTextCmd = '\x1Ba1';
        const cutPaperCmd = '\x1B' + '\x69';
        const enableBoldCmd = '\x1BE' + String.fromCharCode(1);
        const disableBoldCmd = '\x1BE' + String.fromCharCode(0);
        const separator = '-'.repeat(thermalPrinter.printWidth);
        const lines = [];

        lines.push(centreTextCmd + 'Appointment');
        lines.push(appt.dateFormatted);
        lines.push(' ');

        lines.push(leftTextCmd + separator);
        lines.push(centreTextCmd + enableBoldCmd + appt.customerName + disableBoldCmd);
        lines.push(leftTextCmd + separator);

        appt.serviceInfos.forEach(si => {
            lines.push(' ');
            lines.push(si.stylistNickname);
            lines.push(si.timeFormatted);
            lines.push(si.serviceName);
        })

        if (appt.colourNotes) {
            appt.colourNotes.forEach(cnh => {
                lines.push(' ');
                lines.push('Notes ' + moment(cnh.date).format('DD/MM/YYYY'));
                const notes = (cnh.notes || '').replace(/\r\n|\r/g, '\n');
                lines.push(notes);
            })
        }

        lines.push(' ');
        lines.push(separator);
        lines.push('Notes');
        lines.push(' ');
        lines.push(' ');
        lines.push(' ');
        lines.push(' ');
        lines.push(' ');
        lines.push(' ');
        lines.push(' ');
        lines.push(' ');
        lines.push(separator);

        return ThermalPrinterService.execute(device, async () => {
            const outputText = initCmd + lines.join('\n') + '\n\n\n' + cutPaperCmd;
            const buffer = this.encode(outputText);
            await device.transferOut(1, buffer);
        });
    }

    static async _printStylistAppts(device, thermalPrinter, stylistAppts) {
        const initCmd = '\x1B\x74' + String.fromCharCode(thermalPrinter.printCodePage);
        const leftTextCmd = '\x1Ba0';
        const centreTextCmd = '\x1Ba1';
        const cutPaperCmd = '\x1B' + '\x69';
        const enableBoldCmd = '\x1BE' + String.fromCharCode(1);
        const disableBoldCmd = '\x1BE' + String.fromCharCode(0);
        const separator = '-'.repeat(thermalPrinter.printWidth);
        const lines = [];

        lines.push(centreTextCmd + 'Appointment schedule');
        lines.push(stylistAppts.dateFormatted);
        lines.push(' ');

        for (let i = 0; i < stylistAppts.stylists.length; i++) {
            const stylist = stylistAppts.stylists[i];

            if (i > 0) {
                lines.push(leftTextCmd + separator);
            }
            lines.push(centreTextCmd + enableBoldCmd + stylist.nickname + disableBoldCmd);
            lines.push(leftTextCmd + separator);

            for (let j = 0; j < stylist.appointments.length; j++) {
                const appt = stylist.appointments[j];
                if (j > 0) {
                    lines.push(separator);
                }
                lines.push(appt.timeFormatted);
                lines.push(appt.info1);
                lines.push(appt.info2);

                // Colour notes history
                if (appt.colourNotesHistory) {
                    appt.colourNotesHistory.forEach(cnh => {
                        lines.push('Notes ' + moment(cnh.date).format('DD/MM/YYYY'));
                        const notes = (cnh.notes || '').replace(/\r\n|\r/g, '\n');
                        lines.push(notes);
                    });
                }
            }
        }

        lines.push(separator);
        lines.push('Notes');
        lines.push(' ');
        lines.push(' ');
        lines.push(' ');
        lines.push(' ');
        lines.push(' ');
        lines.push(' ');
        lines.push(' ');
        lines.push(' ');
        lines.push(separator);

        return ThermalPrinterService.execute(device, async () => {
            let outputText = initCmd + lines.join('\n') + '\n\n\n' + cutPaperCmd;
            const buffer = this.encode(outputText);
            await device.transferOut(1, buffer);
        });
    }

    static async _printEODBanking(device, thermalPrinter, eodBanking) {
        const initCmd = '\x1B\x74' + String.fromCharCode(thermalPrinter.printCodePage);
        const leftTextCmd = '\x1Ba0';
        const centreTextCmd = '\x1Ba1';
        const cutPaperCmd = '\x1B' + '\x69';
        const enableBoldCmd = '\x1BE' + String.fromCharCode(1);
        const disableBoldCmd = '\x1BE' + String.fromCharCode(0);
        const separator = '-'.repeat(thermalPrinter.printWidth);
        const lines = [];

        // Header
        lines.push(centreTextCmd + 'EOD Banking: ' + moment(eodBanking.date).format('DD/MM/YYYY'));
        lines.push(' ');

        // Daily totals
        lines.push(leftTextCmd + separator);
        lines.push(centreTextCmd + enableBoldCmd + 'Daily Totals' + disableBoldCmd);
        lines.push(leftTextCmd + separator);

        // Daily totals sections
        for (let i = 0; i < eodBanking.dailyTotals.length; i++) {
            const row = eodBanking.dailyTotals[i];

            if (row.type == '-' && !row.subType) {
                lines.push(separator);
            } else if (row.type == '-') {
                lines.push(enableBoldCmd + row.subType + disableBoldCmd);
            } else {
                if (row.gross != 0 || row.type == 'Total') {
                    lines.push(row.subType);
                    const netFormatted = ThermalPrinterService.formatCurrency(row.net, thermalPrinter);
                    const taxFormatted = ThermalPrinterService.formatCurrency(row.tax, thermalPrinter);
                    const grossFormatted = ThermalPrinterService.formatCurrency(row.gross, thermalPrinter);
                    if (row.type != 'Total') {
                        lines.push('    Qty: ' + row.quantity);
                    }
                    if (row.subType != 'Overall total') {
                        lines.push('    Net: ' + netFormatted);
                        lines.push('    Tax: ' + taxFormatted);
                    }
                    lines.push('    Gross: ' + grossFormatted);
                }
            }
        }

        // Payments methods (expected takings)
        lines.push(leftTextCmd + separator);
        lines.push(centreTextCmd + enableBoldCmd + 'Payment Methods' + disableBoldCmd);
        lines.push(leftTextCmd + separator);

        // Payment methods sections
        for (let i = 0; i < eodBanking.expectedTakings.length; i++) {
            const row = eodBanking.expectedTakings[i];
            if (row.amount != 0 || row.paymentMethodName == 'Total') {
                const expected = row.amount;
                const expectedFormatted = ThermalPrinterService.formatCurrency(expected, thermalPrinter);
                const actual = eodBanking.actualTakings[row.paymentMethodID] || 0;
                const actualFormatted = ThermalPrinterService.formatCurrency(actual, thermalPrinter);
                const delta = (actual || 0) - (expected || 0);
                const deltaFormatted = ThermalPrinterService.formatCurrency(delta, thermalPrinter);
                lines.push(row.paymentMethodName);
                lines.push('    Expected: ' + expectedFormatted);
                lines.push('    Actual: ' + actualFormatted);
                lines.push('    + / -: ' + deltaFormatted);
            }
        }

        // Till monies (accounts)
        lines.push(leftTextCmd + separator);
        lines.push(centreTextCmd + enableBoldCmd + 'Till Monies' + disableBoldCmd);
        lines.push(leftTextCmd + separator);

        // Till monies sections
        for (let i = 0; i < eodBanking.accounts.length; i++) {
            const row = eodBanking.accounts[i];
            const openingBalanceFormatted = ThermalPrinterService.formatCurrency(row.openingBalance, thermalPrinter);
            const amountInFormatted = ThermalPrinterService.formatCurrency(row.amountIn, thermalPrinter);
            const amountOutFormatted = ThermalPrinterService.formatCurrency(row.amountOut, thermalPrinter);
            const closingBalanceFormatted = ThermalPrinterService.formatCurrency(row.closingBalance, thermalPrinter);
            lines.push(row.accountName);
            lines.push('    Opening: ' + openingBalanceFormatted);
            lines.push('    In: ' + amountInFormatted);
            lines.push('    Out: ' + amountOutFormatted);
            lines.push('    Closing: ' + closingBalanceFormatted);
        }

        // Banked
        lines.push(leftTextCmd + separator);
        lines.push(centreTextCmd + enableBoldCmd + 'Banked' + disableBoldCmd);
        lines.push(leftTextCmd + separator);

        // Banked sections
        for (let i = 0; i < eodBanking.banked.length; i++) {
            const row = eodBanking.banked[i];
            if (row.amount != 0) {
                const amountFormatted = ThermalPrinterService.formatCurrency(row.amount, thermalPrinter);
                lines.push(row.paymentMethodName);
                lines.push('    ' + amountFormatted);
            }
        }

        // Notes for the day
        lines.push(leftTextCmd + separator);
        lines.push(centreTextCmd + enableBoldCmd + 'Notes for the day' + disableBoldCmd);
        lines.push(leftTextCmd + separator);
        lines.push(eodBanking.notes || '(None)');
        lines.push(' ');
        lines.push(' ');
        lines.push(' ');
        lines.push(' ');
        lines.push(' ');
        lines.push(' ');
        lines.push(' ');
        lines.push(' ');
        lines.push(separator);

        // Print
        return ThermalPrinterService.execute(device, async () => {
            const outputText = initCmd + lines.join('\n') + '\n\n\n' + cutPaperCmd;
            const buffer = this.encode(outputText);
            await device.transferOut(1, buffer);
        });
    }

    static encode(outputText) {
        const buffer = new ArrayBuffer(outputText.length);
        const view = new Int8Array(buffer);
        for (var i = 0; i < outputText.length; i++) {
            const charCode = outputText.charCodeAt(i);
            view[i] = (charCode & 0xFF);
        }
        return buffer;
    }

    static formatCurrency(amount, thermalPrinter) {
        const formatted = TextHelpers.formatCurrencyNew(amount, { includeSymbol: true });
        const processed = ThermalPrinterService.processCurrency(formatted, thermalPrinter);
        return processed;
    }

    static processCurrency(str, thermalPrinter) {
        str = str || '';
        if (!thermalPrinter.printCurrencySymbol) {
            str = str.replace(/[^0-9\.,]/g, '');
        }
        str = str.replace(/£/g, '\xA3');
        str = str.replace(/€/g, '\x80');
        return str;
    }
}

// TEMP
window.ThermalPrinterService = ThermalPrinterService;