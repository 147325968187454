// Libs
import React from 'react';
import { Route } from 'react-router';
import { Link, withRouter } from 'react-router-dom';

// Services & Helpers
import ServiceBandService from 'services/ServiceBandService';
import FormHelper from 'helpers/FormHelper';
import BootboxHelper from 'helpers/BootboxHelper';

// Components
import FloomlyComponent from 'components/FloomlyComponent';
import InfoBar from 'components/layout/InfoBar'
import Loader from 'components/reusable/Loader';

//-------------------------------------------------------------------------------------------------------------------

class SettingsServiceBandEdit extends FloomlyComponent {

    constructor(props) {
        super(props);

        // Init state
        this.state = {
            isLoading: true,
            serviceBand: null
        };

        this.updateField = this.updateField.bind(this);
        
        this.formHelper = new FormHelper({
            fields: {
                name: { label: 'Name' },
            },
            getValue: (fieldName, fieldInfo) => this.state.serviceBand[fieldName],
            setValue: this.updateField
        });
    }

    componentDidMount() {
        this.load();
    }

    //--------------------------------------------------------------------------------------------------------------------

    async load() {
        const serviceBandID = Number(this.props.match.params.serviceBandID) || 0;

        let serviceBand;
        if (serviceBandID) {
            serviceBand = await ServiceBandService.get(serviceBandID);
        } else {
            serviceBand = {
                // New
            };
        }

        const serviceBands = await ServiceBandService.list();

        this.setState({
            isLoading: false,
            serviceBand: serviceBand,
            serviceBands: serviceBands
        });
    }

    goBack() {
        this.props.history.push('/settings/service-bands');
    }

    async save() {
        this.setState({ isLoading: true });
        await ServiceBandService.save(this.state.serviceBand);
        this.goBack();
    }

    updateField(fieldName, value, fieldInfo) {
        const serviceBand = { ...this.state.serviceBand };
        serviceBand[fieldName] = value;
        this.setState({
            serviceBand: serviceBand
        });
    }

    async confirmDelete() {
        const confirm = await BootboxHelper.confirm('Are you sure you want to delete this level?');
        if (confirm) {
            this.delete();
        }
    }

    async delete() {
        await ServiceBandService.delete(this.state.serviceBand.serviceBandID);
        this.props.history.replace('/settings/service-bands');
    }

    //--------------------------------------------------------------------------------------------------------------------
    //  Render
    //--------------------------------------------------------------------------------------------------------------------

    renderInfoBar() {
        const {
            serviceBand
        } = this.state;

        return (
            <InfoBar className="settings-info-bar" containerClassName="floating-on-mobile" sticky={true}>

                <div className="info-bar-panel-section">

                    <button className="button" onClick={() => this.goBack()}>
                        <span className="fa fa-chevron-left"></span>{' '}
                        Back
                    </button>

                </div>

                <div className="info-bar-panel-section info-bar-panel-section-text desktop-only">
                    {serviceBand.serviceBandID ? serviceBand.name : 'New Stylist Level'}
                </div>
                
                <div className="info-bar-panel-section info-bar-panel-buttons ml-auto">

                    {serviceBand.serviceBandID &&
                        <button className="button" style={{ marginRight: 10 }} onClick={() => this.confirmDelete()}>
                            <span className="fa fa-times"></span>{' '}
                            Delete
                        </button>
                    }

                    <button className="button" onClick={() => this.save()}>
                        <span className="fa fa-check"></span>{' '}
                        Save
                    </button>
                    
                </div>

            </InfoBar>
        );
    }

    render() {
        const {
            isLoading,
            serviceBand
        } = this.state;

        if (isLoading) {
            return (<Loader />);
        }

        return (<>
            {this.renderInfoBar()}

            <div className="settings-page-main">
                <div className="panel">
                    <div className="panel-body">

                        {this.formHelper.renderFormGroups([
                            'name'
                        ])}

                    </div>
                </div>

            </div>

        </>);
    }

};

export default withRouter(SettingsServiceBandEdit);