import BaseService from 'services/BaseService';

//-------------------------------------------------------------------------------------------------------------------

export default class SalonSpyService
    extends BaseService {
    
    static async getSettings() {
        return BaseService.callAPI('salon-spy/get-settings');
    }

    static async saveSettings(salon) {
        return BaseService.callAPI('salon-spy/save-settings', salon);
    }
    
}
