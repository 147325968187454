import BaseService from 'services/BaseService';

//-------------------------------------------------------------------------------------------------------------------

export default class SearchService
    extends BaseService {

    static search(query, types, params) {
        return new Promise(async (resolve) => {
            let nonce;
            if (params && params.state) {
                nonce = `${Math.random()}`.replace('0.', '');
                params.state.nonce = nonce;
                delete params.state;
            }
            const response = await BaseService.callAPI('search', {
                query,
                types,
                nonce,
                ...params
            });
            if (params && params.state && response && response.nonce && response.nonce != params.state.nonce) {
                return;
            }
            resolve(response ? response.results : null);
        });
    }

    // details: customerID, query, searchType
    static async addSearchLog(details) {
        await BaseService.callAPI('search/add-search-log', details);
    }

    static async listRecentSearchLogs(searchType) {
        const response = await BaseService.callAPI('search/list-recent/' + searchType);
        return response;
    }


}