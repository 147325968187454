// Libs
import React from 'react';
import { withRouter } from 'react-router-dom';

// Services & Helpers
import CustomReportService from 'services/CustomReportService';
import BootboxHelper from 'helpers/BootboxHelper';

// Components
import FloomlyComponent from 'components/FloomlyComponent';
import Loader from 'components/reusable/Loader';
import SuperTable from 'components/reusable/SuperTable';

//-------------------------------------------------------------------------------------------------------------------

class SavedCustomReports extends FloomlyComponent {

    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            canClick: true
        }
    }

    componentDidMount() {
        this.load();
    }

    async load() {
        const savedCustomReports = await CustomReportService.listSaved();
        this.setState({
            savedCustomReports,
            isLoading: false
        });
    }

    loadSavedReport(report) {
        if (this.state.canClick)
            this.props.history.push('/the-office/reports/custom-report/' + report.id);
    }

    async deleteReport(reportId) {
        this.state = {
            canClick: false
        }
        const confirm = await BootboxHelper.confirm('Are you sure you want to delete this Report?');
        if (confirm) {
            await CustomReportService.delete(reportId);
            this.load();
        }
        else {
            this.setState({
                canClick: true
            });
        }
    }


    //--------------------------------------------------------------------------------------------------------------------
    //  Render
    //--------------------------------------------------------------------------------------------------------------------

    render() {
        const {
            isLoading,
            savedCustomReports,
            canClick = true
        } = this.state;

        if (isLoading) {
            return (<Loader />);
        }

        return (
            <div className="saved-custom-reports">

                <SuperTable
                    keyAccessor={scr => scr.id}
                    rows={savedCustomReports}
                    cols={{
                        name: {
                            label: 'Name'
                        },
                        actions: {
                            className: 'actions-col',
                            getValue: (colInfo, row) =>
                                <button type="button" className="button delete-button" onClick={() => this.deleteReport(row.id)}>
                                    Delete
                                </button>
                        }
                    }}
                    onClick={canClick && (report => this.loadSavedReport(report))}
                    emptyText="You have no saved reports"
                />

            </div>
        );
    }

}

export default withRouter(SavedCustomReports);