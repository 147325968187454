import BaseService from 'services/BaseService';
import SearchService from 'services/SearchService';

//-------------------------------------------------------------------------------------------------------------------

export default class ServiceBandService
    extends BaseService {

    static async list() {
        const response = await BaseService.callAPI('service-band/list');
        return response;
    }

    static async get(id) {
        const response = await BaseService.callAPI('service-band/get/' + id);
        return response;
    }

    static async save(serviceBand) {
        const response = await BaseService.callAPI('service-band/save', serviceBand);
        return response;
    }

    static async delete(id) {
        return BaseService.callAPI('service-band/delete/' + id);
    }

    static async rearrange(serviceBandIDs) {
        return BaseService.callAPI('service-band/rearrange', {
            serviceBandIDs
        });
    }
}
