// Libs
import React from 'react';
import { withRouter } from 'react-router-dom';
import DatePicker from 'components/reusable/DatePicker';

// Services & Helpers
import CustomReportService from 'services/CustomReportService';
import BootboxHelper from 'helpers/BootboxHelper';

// Components
import FloomlyComponent from 'components/FloomlyComponent';
import Loader from 'components/reusable/Loader';
import Select from 'react-select';
import MultiSelect from 'components/reusable/MultiSelect';
import SuperTable from 'components/reusable/SuperTable';
import SaveCustomReportModal from 'components/pages/the-office/SaveCustomReportModal';

//-------------------------------------------------------------------------------------------------------------------

class CustomReports extends FloomlyComponent {

    constructor(props) {
        super(props);

        this.saveCustomReportModalRef = React.createRef();
        this.requestIDs = {};

        this.state = {
            isLoading: true,
            optionsLoading: {},
            running: {},
            sectionSourceOptions: {},
            sections: {}
        }
    }

    componentDidMount() {
        this.load();
    }

    async load() {
        const areas = await CustomReportService.listAreas();

        // Load saved report
        let report = {};
        if (this.props.id) {
            report = await CustomReportService.getSaved(this.props.id);
            const jsonParsed = JSON.parse(report.json);
            if (jsonParsed) {
                report.sectionSources = jsonParsed.sectionSources;
                report.sectionSources.forEach(ss => {
                    delete ss.sections;
                });
            }
        }
        if (!report.globalOptionValues) {
            report.globalOptionValues = {
                ['date-from']: new Date(),
                ['date-to']: new Date()
            };
        }
        if (!report.sectionSources) {
            report.sectionSources = [];
        }
        if (!report.sectionSources.find(ss => ss.isNew)) {
            report.sectionSources.push({ isNew: true });
        }

        this.setState({
            areas,
            report,
            isLoading: !!this.props.id
        }, async () => {
            if (this.props.id) {
                this.loadAllOptions();
            }
        });
    }

    async loadAllOptions() {
        const report = this.state.report;

        // TODO do this a LOT BETTER!!! THIS IS TERRIBLE
        for (let i = 0; i < report.sectionSources.length - 1; i++) {
            setTimeout(() => {
                this.loadOptions(i);
            }, i);
        }
        setTimeout(() => {
            this.setState({
                isLoading: false
            });
        }, 100);
    }

    async run(index) {
        return new Promise(async (resolve, reject) => {
            // Get section source; don't run if no code specified
            const sectionSource = this.state.report.sectionSources[index];
            if (!sectionSource.code) {
                return;
            }
            this.requestIDs[index] = `${Math.random()}`;

            // Don't run if all global options not provided
            const globalOptionValues = this.state.report.globalOptionValues;
            if (!globalOptionValues['date-from'] ||
                !globalOptionValues['date-to']) {
                return;
            }

            // Set loading
            let running = { ...this.state.running };
            running[index] = true;
            this.setState({ running });

            // Run
            const optionValues = {
                ...this.state.report.globalOptionValues,
                ...sectionSource.optionValues
            };

            const { sections, requestID } = await CustomReportService.run(sectionSource.code, optionValues, this.requestIDs[index]);
            if (requestID == this.requestIDs[index]) {
                // Update UI with new results
                this.updateSectionSourceField(index, 'sections', sections);

                // Unset loading
                running = { ...this.state.running };
                delete running[index];
                this.setState({ running }, () => {
                    resolve();
                });
            }
        });
    }

    async runAll() {
        for (let i = 0; i < this.state.report.sectionSources.length; i++) {
            const sectionSource = this.state.report.sectionSources;
            if (!sectionSource.isNew) {
                await this.run(i);
            }
        }
    }

    updateSectionSourceField(index, field, value) {
        const report = { ...this.state.report };
        const sectionSource = { ...report.sectionSources[index] };
        sectionSource[field] = value;
        delete sectionSource.isNew;
        report.sectionSources[index] = sectionSource;

        // Ensure there's always a new item at the end
        if (!report.sectionSources[report.sectionSources.length - 1].isNew) {
            report.sectionSources.push({
                isNew: true
            });
        }

        this.setState({
            report
        }, async () => {
            // Load options
            if (field == 'code' && value) {
                await this.loadOptions(index);
                this.run(index);
            }
        });
    }

    loadOptions(index) {
        return new Promise(async (resolve) => {
            // Set loading
            let optionsLoading = { ...this.state.optionsLoading };
            optionsLoading[index] = true;
            this.setState({ optionsLoading });

            // Load and store
            const sectionSource = this.state.report.sectionSources[index];
            const options = await CustomReportService.listOptions(sectionSource.code, this.state.report.globalOptionValues);
            const sectionSourceOptions = { ...this.state.sectionSourceOptions };
            sectionSourceOptions[sectionSource.code] = options;
            this.setState({ sectionSourceOptions });

            // Set default values
            const optionValues = {};
            options.forEach(o => {

                // Don't load if already loaded
                if (sectionSource.optionValues && sectionSource.optionValues[o.id]) {
                    return;
                }

                switch (o.dataType) {
                    case 'singleSelect':
                    case 'multiSelect':
                        const selectedIDs = {};
                        o.optionValues.forEach(ov => {
                            if (ov.isSelected) {
                                selectedIDs[ov.value] = true;
                            }
                        });
                        optionValues[o.id] = selectedIDs;
                        break;
                }
            });
            this.updateOptionValues(index, optionValues);

            // Unset loading
            optionsLoading = { ...this.state.optionsLoading };
            delete optionsLoading[index];
            this.setState({ optionsLoading }, () => {
                resolve();
            });
        });
    }

    updateOptionValues(index, values) {
        const sectionSource = this.state.report.sectionSources[index];
        const optionValues = { ...sectionSource.optionValues };
        for (let optionID in values) {
            const value = values[optionID];
            optionValues[optionID] = value;
        }
        this.updateSectionSourceField(index, 'optionValues', optionValues);
    }

    updateGlobalOptionValue(name, value) {
        const report = { ...this.state.report };
        const globalOptionValues = { ...report.globalOptionValues };
        globalOptionValues[name] = value;
        report.globalOptionValues = globalOptionValues;
        this.setState({
            report
        }, async () => {
            await this.loadAllOptions();
            this.runAll();
        });
    }

    async save() {
        const customReport = { ...this.state.report };
        customReport.sectionSources.forEach(ss => {
            delete ss.sections;
        });
        this.saveCustomReportModalRef.current.show({
            customReport
        });
    }

    async delete() {
        const confirm = await BootboxHelper.confirm('Are you sure you want to delete this Report?');
        if (confirm) {
            await CustomReportService.delete(this.props.id);
            this.props.history.replace("/the-office/reports/my-reports");
        }
    }

    async exportExcel() {
        const { sectionSources, globalOptionValues } = this.state.report;
        this.setState({ isExporting: true });
        await CustomReportService.exportExcel(sectionSources, globalOptionValues);
        this.setState({ isExporting: false });
    }

    //--------------------------------------------------------------------------------------------------------------------
    //  Render
    //--------------------------------------------------------------------------------------------------------------------

    render() {
        const {
            isLoading,
            report,
            isExporting
        } = this.state;

        if (isLoading) {
            return (<Loader />);
        }
        const hasSectionSources = report.sectionSources.filter(ss => !ss.isNew && ss.code).length > 0;

        return (
            <div className="custom-report">

                <div className="top-control-panel">

                    <div className="form-group">
                        <label>Date From</label>
                        <DatePicker
                            selected={report.globalOptionValues['date-from']}
                            onChange={value => this.updateGlobalOptionValue('date-from', value)}
                        />
                    </div>

                    <div className="form-group">
                        <label>Date To</label>
                        <DatePicker
                            selected={report.globalOptionValues['date-to']}
                            onChange={value => this.updateGlobalOptionValue('date-to', value)}
                        />
                    </div>

                    <div className="ms-auto">

                        {hasSectionSources && <>
                            <button className="button button-tertiary" onClick={() => this.exportExcel()} disabled={isExporting}>
                                {isExporting ? <Loader isInline /> : <>Export</>}
                            </button>
                            <button className="button button-tertiary ms-2" onClick={() => window.print()}>
                                Print
                            </button>
                        </>}

                        {this.props.id &&
                            <button className="button button-secondary ms-2" onClick={() => this.delete()}>
                                Delete
                            </button>
                        }

                        {hasSectionSources &&
                            <button className="button button-primary ms-2" onClick={() => this.save()}>
                                Save
                            </button>
                        }

                    </div>

                </div>

                {report.sectionSources.map((rss, index) =>
                    <React.Fragment key={index}>
                        {this.renderReportSectionSource(rss, index)}
                    </React.Fragment>
                )}

                <SaveCustomReportModal ref={this.saveCustomReportModalRef} />
            </div>
        );
    }

    renderReportSectionSource(reportSectionSource, index) {
        const {
            areas
        } = this.state;

        let area;
        if (reportSectionSource.areaCode) {
            area = areas.find(a => a.code == reportSectionSource.areaCode);
        }

        return (<>

            <div className="section-source">

                <div className="control-panel">

                    <div className="control-panel-section section-source-selector">
                        <label>Please select your categories to build your report</label>

                        <select
                            value={reportSectionSource.areaCode}
                            onChange={e => this.updateSectionSourceField(index, 'areaCode', e.target.value)}
                        >
                            <option value="">(Select)</option>
                            {areas.map(a =>
                                <option key={a.code} value={a.code}>{a.name}</option>
                            )}
                        </select>

                        {area &&
                            <select
                                value={reportSectionSource.code}
                                onChange={e => this.updateSectionSourceField(index, 'code', e.target.value)}
                            >
                                <option value="">(Select)</option>
                                {area.sectionSources.map(ss =>
                                    <option key={ss.code} value={ss.code}>{ss.name}</option>
                                )}
                            </select>
                        }

                    </div>

                    {reportSectionSource.code &&
                        this.renderSectionSourceOptions(index, reportSectionSource)
                    }

                </div>

                {reportSectionSource.sections && reportSectionSource.sections.map((section, sectionIndex) =>
                    <section key={sectionIndex} className={section.className || ''}>
                        {section.title &&
                            <h2>{section.title}</h2>
                        }
                        {section.description &&
                            <div className="alert alert-warning">
                                {section.description}
                            </div>
                        }

                        <SuperTable
                            keyAccessor={section => section.id}
                            className="custom-report-table"
                            rows={section.rows}
                            cols={section.colsDict}

                            rowPropsAccessor={(row, index) => {
                                let props = {
                                    style: {}
                                };
                                if (row._className) {
                                    props.className = row._className;
                                }
                                if (row._bold) {
                                    props.style.fontWeight = 'bold';
                                }
                                return props;
                            }}
                            cellPropsAccessor={(colInfo, row, index) => {
                                let props = {
                                    style: {}
                                };
                                if (row) {
                                    if (row._indent > 0 && colInfo.name == 'type') {
                                        props.style.paddingLeft = row._indent;
                                    }
                                    else if (colInfo.col.indent > 0) {
                                        props.style.paddingLeft = colInfo.col.indent;
                                    }
                                }
                                if (colInfo.col.width) {
                                    props.style.width = colInfo.col.width;
                                }
                                return props;
                            }}
                            groupingAccessor={(colInfo, row, index) => row._grouping}
                            emptyText="No results"
                        />
                    </section>
                )}

            </div>

        </>);
    }

    renderSectionSourceOptions(index, reportSectionSource) {
        const {
            optionsLoading
        } = this.state;

        if (optionsLoading[index]) {
            return (<Loader isInline />);
        }

        const options = this.state.sectionSourceOptions[reportSectionSource.code] || [];
        return options.map((o, oIndex) =>
            <div className="control-panel-section" key={oIndex}>
                <label>{o.label}</label>
                {this.renderSectionSourceOption(o, index, reportSectionSource)}
            </div>
        );
    }

    renderSectionSourceOption(option, index, reportSectionSource) {
        const optionValues = reportSectionSource.optionValues || {};
        const optionValue = optionValues[option.id] || {};

        //const CustomOption = ({ innerRef, innerProps }) => (
        //    <div ref={innerRef} {...innerProps}>TEST</div>
        //);
        const selected = option.optionValues.filter(ov => !!optionValue[ov.value]);

        switch (option.dataType) {
            case 'singleSelect':
                return (
                    <Select
                        className="react-select-container multi-select"
                        classNamePrefix="react-select"
                        options={option.optionValues}
                        value={selected}
                        getOptionValue={optionValues => optionValues.value}
                        getOptionLabel={optionValues => optionValues.text}
                        placeholder={selected.text + ' selected'}
                        onChange={selected => {
                            const selectedIDs = {};
                            selectedIDs[selected.value] = true;
                            this.updateOptionValues(index, { [option.id]: selectedIDs });
                            this.run(index);
                        }}
                        onBlur={() => this.run(index)}
                    />
                )

            case 'multiSelect':
                return (
                    <MultiSelect
                        className="react-select-container multi-select"
                        classNamePrefix="react-select"
                        components={{
                            //Option: CustomOption
                        }}
                        options={option.optionValues}
                        value={selected}
                        getOptionValue={ov => ov.value}
                        getOptionLabel={ov => ov.text}
                        placeholder={selected.length + ' selected'}
                        onChange={selected => {
                            const selectedIDs = {};
                            selected.forEach(ov => selectedIDs[ov.value] = true);
                            this.updateOptionValues(index, { [option.id]: selectedIDs });
                        }}
                        onBlur={() => this.run(index)}
                    />
                );
            //placeholder={'Courses (' + Object.keys(filters.courseIDs).length + ')'}
        }
    }
}

export default withRouter(CustomReports);