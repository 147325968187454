// Libs
import React from 'react';
import { Route } from 'react-router';
import { Link, withRouter } from 'react-router-dom';

// Services & Helpers
import TaxRateService from 'services/TaxRateService';

// Components
import FloomlyComponent from 'components/FloomlyComponent';
import InfoBar      from 'components/layout/InfoBar'
import Loader       from 'components/reusable/Loader';
import SuperTable   from 'components/reusable/SuperTable';

//-------------------------------------------------------------------------------------------------------------------

class SettingsTaxRateList extends FloomlyComponent {

    constructor(props) {
        super(props);

        // Init state
        this.state = {
            isLoading: true,
            taxRates: []
        };
    }

    componentDidMount() {
        this.load();
    }

    //--------------------------------------------------------------------------------------------------------------------

    async load() {
        const taxRates = await TaxRateService.list();
        
        this.setState({
            isLoading: false, 
            taxRates: taxRates
        });
    }

    edit(id) {
        this.props.history.push('/settings/tax-rates/' + id);
    }

    add() {
        this.props.history.push('/settings/tax-rates/new');
    }

    //--------------------------------------------------------------------------------------------------------------------
    //  Render
    //--------------------------------------------------------------------------------------------------------------------

    renderInfoBar() {
        return (
            <InfoBar className="settings-info-bar" containerClassName="floating-on-mobile" sticky={true}>

                <div className="info-bar-panel-section info-bar-panel-section-text desktop-only">
                    Tax Rates
                </div>
                <div className="info-bar-panel-section ml-auto">

                    <button className="button" onClick={() => this.add()}>
                        <span className="fa fa-plus"></span>{' '}
                        Add New
                    </button>

                </div>

            </InfoBar>
        );
    }

    render() {
        const {
            isLoading,
            taxRates
        } = this.state;

        if (isLoading) {
            return (<Loader />);
        }

        return (<>
            {this.renderInfoBar()}

            <div className="settings-page-main">
                <div className="panel">
                    <div className="panel-body">

                        <SuperTable
                            className="settings-table"
                            containerClassName="mb-0"
                            rows={taxRates}
                            keyAccessor={taxRate => taxRate.id}
                            cols={{
                                name: { label: 'Name' },
                                rate: {
                                    label: 'Rate',
                                    getValue: (colInfo, taxRate) => Math.round(taxRate.rate * 10000)/100 + '%'
                                },
                                defaultFor: {
                                    label: 'Default rate for',
                                    getValue: (colInfo, taxRate) => {
                                        const items = [];
                                        if (taxRate.isDefaultServices) items.push('Services');
                                        if (taxRate.isDefaultRetail) items.push('Retail');
                                        //if (taxRate.isDefaultProfessional) items.push('Professional');
                                        return items.join(', ');
                                    }
                                }
                            }}
                            onClick={(taxRate, e) => this.edit(taxRate.id)}
                        />
                        
                    </div>
                </div>

            </div>

        </>);
    }

};

export default withRouter(SettingsTaxRateList);