import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';

// Services
import GlobalStateService   from 'services/GlobalStateService';

// Images
import logoImage from 'images/logo.png';
import logoSmallImage from 'images/perfect-client/heart.png';
import logoKevinMurphyImage from 'images/theme/kevin-murphy/logo-kevin-murphy-white.png';
import logoSmallKevinMurphyImage from 'images/theme/kevin-murphy/logo-small-kevin-murphy.png';

//-------------------------------------------------------------------------------------------------------------------

class SideNav extends Component {

    getLinkClass = (path = '', path2 = '', dontMatchPath = '') => {
        if (this.props.location.pathname.indexOf(path) == 0 || (path2 && this.props.location.pathname.indexOf(path2) == 0)) {
            if (!dontMatchPath || this.props.location.pathname.indexOf(dontMatchPath) == -1) {
                return 'active';
            } else {
                return '';
            }
        } else {
            return '';
        }
    }

    nav(url) {
        const path = this.props.history.location.pathname;
        if (path == url) {
            GlobalStateService.callEvent('replayRoute', path);
        }
        GlobalStateService.getValue('toggleMobileNav')();
    }

    logOut() {
        GlobalStateService.getValue('logOut')();
    }

    handleMouseMove(e) {
        if (this.sideNavScroller) {
            if (document.body.classList.contains('minimal-mode')) {
                if (e.target.className == 'side-nav-scroller' || e.target.className == 'side-nav-scroller-ul') {
                    this.sideNavScroller.style.width = '60px';
                } else {
                    this.sideNavScroller.style.width = '';
                }
            } else {
                this.sideNavScroller.style.width = '';
            }
        }
    }

    render() {
        const loginDetails = GlobalStateService.getValue('loginDetails');
        const clientInfo = GlobalStateService.getValue('clientInfo');
        const code = (clientInfo.theme && clientInfo.theme.code) || '';
        const permissions = loginDetails.permissions || {};

        setTimeout(() => {
            if (!this.sideNavScroller) {
                this.sideNavScroller = document.querySelector('.side-nav-scroller');
            }
        });

        const stockroomLink = (permissions['StockroomViewStockManager'] ? '/stock/manager' : '/stock/check-out');

        return (
            <div className="side-nav">

                {this.renderLogo(code)}

                <div className="side-nav-scroller" onMouseMove={e => this.handleMouseMove(e)}>
                    <ul className="side-nav-scroller-ul"> {/* Class needed for JS hide code */}
                        <li className={this.getLinkClass('/diary')}>
                            <Link to="/diary" onClick={e => this.nav('/diary')}>
                                <span className="side-nav-icon far fa-calendar-alt"></span>
                                <span>Diary</span>
                            </Link>
                        </li>
                        {permissions['Dashboard'] &&
                            <li className={this.getLinkClass('/dashboard', null, '/dashboard/by-stylist')}>
                                <Link to="/dashboard" onClick={e => this.nav('/dashboard')}>
                                    <span className="side-nav-icon fas fa-tachometer-alt"></span>
                                    <span >Dashboard</span>
                                </Link>
                            </li>
                        }
                        {permissions['TeamHub'] &&
                            <li className={this.getLinkClass('/dashboard/by-stylist')}>
                                <Link to="/dashboard/by-stylist" onClick={e => this.nav('/dashboard/by-stylist')}>
                                    <span className="side-nav-icon fas fa-cut"></span>
                                    <span>Team Hub</span>
                                </Link>
                            </li>
                        }
                        {permissions['Marketing'] &&
                            <li className={this.getLinkClass('/marketing')}>
                                <Link to="/marketing" onClick={e => this.nav('/marketing')}>
                                    <span className="side-nav-icon fas fa-bullhorn"></span>
                                    <span>Marketing</span>
                                </Link>
                            </li>
                        }
                        {permissions['Stockroom'] &&
                            <li className={this.getLinkClass(stockroomLink, '/stock/categorise')}>
                                <Link to={stockroomLink} onClick={e => this.nav(stockroomLink)}>
                                    <span className="side-nav-icon fas fa-box-open"></span>
                                    <span>Stockroom</span>
                                </Link>
                            </li>
                        }
                        {permissions['Office'] &&
                            <li className={this.getLinkClass('/the-office', '/stylist-targets')}>
                                <Link to="/the-office" onClick={e => this.nav('/the-office')}>
                                    <span className="side-nav-icon fas fa-laptop-house"></span>
                                    <span>The Office</span>
                                </Link>
                            </li>
                        }
                        {permissions['Education'] &&
                            <li className={this.getLinkClass('/coaching') + ' coming-soon'}>
                                <Link to="/coaching" onClick={e => { }}>
                                    <span className="side-nav-icon fas fa-question-circle"></span>
                                    <span>Education</span>
                                </Link>
                            </li>
                        }
                        {permissions['Settings'] &&
                            <li className={this.getLinkClass('/settings')}>
                                <Link to="/settings" onClick={e => this.nav('/settings')}>
                                    <span className="side-nav-icon fas fa-cog"></span>
                                    <span>Settings</span>
                                </Link>
                            </li>
                        }

                        <li className="side-nav-log-out"> 
                            <Link to="" onClick={e => this.logOut()}>
                                <span className="side-nav-icon fas fa-door-open"></span>
                                <span>Log out</span>
                            </Link>
                        </li>

                        {/* Version */}
                        {clientInfo &&
                            <li className="side-nav-version">
                                {clientInfo.name}<br />
                                {clientInfo.version}
                            </li>
                        }

                    </ul>
                </div>
            </div>
        );
    }

    renderLogo(code) {
        if (code == 'kevin-murphy') {
            return (<>
                <img alt="Floomly" src={logoKevinMurphyImage} />
                <img className="small-version" alt="Floomly" src={logoSmallKevinMurphyImage} />
            </>);
        } else {
            return (<>
                <img alt="Floomly" src={logoImage} />
                <img className="small-version" alt="Floomly" src={logoSmallImage} />
            </>);
        }
    }
}

export default withRouter(SideNav);
