//Libs
import React from 'react';
import moment from 'moment';
import { Link, withRouter, Prompt } from 'react-router-dom';

//Services & Helpers
import GlobalStateService   from 'services/GlobalStateService';
import SearchService        from 'services/SearchService';
import ServiceService       from 'services/ServiceService';
import CustomerService      from 'services/CustomerService';
import WaitingApptService   from 'services/WaitingApptService';
import TextHelpers          from 'helpers/TextHelpers'

// Components
import FloomlyComponent     from 'components/FloomlyComponent';
import Loader               from 'components/reusable/Loader';
import Search               from 'components/reusable/Search';
import CustomerSummary      from 'components/reusable/CustomerSummary';
import NewCustomerPanel     from 'components/pages/diary/NewCustomerPanel'
import Money                from 'components/reusable/Money';
import DatePicker           from 'components/reusable/DatePicker';

class WaitingApptEditor extends FloomlyComponent {

    constructor(props) {
        super(props);

        this.state = {
            step: 1,
            customer: null,

            waitingAppt: {
                waitingApptID: 0,
                primaryDate: null,
                secondaryDate: null,
                tertiaryDate: null,
                timeOfDay: "",
                timeNotes: "",
                additionalNotes: "",
                waitingApptServices: [],
                waitingApptPackages: [],
            },

            serviceCategory: null,
            services: [],
            packages: []
        };

        this.serviceSequenceID = -1;
        this.packageSequenceID = -1;

        this.clientInfo = GlobalStateService.getValue('clientInfo');

        this.deselectServiceCategory = this.deselectServiceCategory.bind(this);
    }

    async load() {
        this.setState({
            isLoading: true
        });
        if (this.props.waitingApptID > 0) {
            var waitingAppt = await WaitingApptService.get(this.props.waitingApptID);
            this.setState({ waitingAppt });
            this.updateAppt({ customer: waitingAppt.customer });
            //for (var i = 0; i < waitingAppt.waitingApptServices.length; i++) {
            //    this.addService(waitingAppt.waitingApptServices[i], null, null);
            //}
        } else {
            this.setState({
                waitingAppt: {
                    waitingApptID: 0,
                    customer: null,
                    customerID: null,
                    primaryDate: null,
                    secondaryDate: null,
                    tertiaryDate: null,
                    timeOfDay: "",
                    timeNotes: "",
                    additionalNotes: "",
                    services: [],
                    packages: [],
                },
            });
        }

        this.setState({
            isLoading: false
        });
    }

    componentDidMount() {
        this.load();
    }

    componentWillUnmount() {
        clearTimeout(this.confirmTimeout);
    }

    setStep(newStep) {
        this.setState({
            step: newStep
        });
    }

    selectCustomer(customer) {
        if (!customer.customerID) {
            customer.customerID = customer.id;
        }
        this.updateAppt({
            customer: customer
        })
    }

    removeCustomer() {
        this.updateAppt({
            customer: null
        });
    }

    addNewCustomer(query) {
        const names = (query ? TextHelpers.splitName(query, true) : "");
        this.selectCustomer({
            customerID: 0,
            firstName: names.firstName,
            lastName: names.lastName,
            mobileTel: '',
            searchQuery: query
        });
    }

    selectServiceCategory(serviceCategory) {
        const services = this.props.services.filter(s => s.serviceCategoryIDs.findIndex(i => String(i) == String(serviceCategory.serviceCategoryID)) != -1 && !s.isPackageOnly);
        this.setState({
            serviceCategory: serviceCategory,
            services,
            packages: this.props.packages.filter(p => p.serviceCategoryID == serviceCategory.serviceCategoryID)
        }, () => {
            document.querySelector('.main-content-inner').scrollTop = 130;
        });
    }

    deselectServiceCategory() {
        this.setState({
            serviceCategory: null,
            services: [],
            packages: []
        });
    }

    async addPackage(pkg, serviceCategory) {
        const packages = this.state.waitingAppt.packages || [];

        const newApptPackage = {
            apptPackageID: this.packageSequenceID--,
            packageIDL: pkg.packageID,
            package: pkg,
            isFixedPrice: (!!pkg.fixedPrice || pkg.fixedPrice === 0)
        };
        if (newApptPackage.isFixedPrice) {
            newApptPackage.total = pkg.fixedPrice;
        }
        packages.push(newApptPackage);
        await this.props.onChange('packages', packages);

        for (var i = 0; i < pkg.serviceIDs.length; i++) {
            const serviceID = pkg.serviceIDs[i];
            const service = this.props.services.find(s => s.serviceID == serviceID);
            await this.addService(service, serviceCategory, newApptPackage);
        }
    }

    async addService(service, serviceCategory, apptPackage) {
        let stylistUserID = service.stylistUserID;

        const waitingApptServices = this.state.waitingAppt.waitingApptServices || [];

        service = await ServiceService.get(service.serviceID || service.id);

        const newApptService = {
            apptServiceID: this.serviceSequenceID--,
            waitingApptID: this.state.waitingAppt.waitingApptID,
            waitingApptServiceID: service.WaitingApptServiceID ? service.WaitingApptServiceID : 0,
            stylistUserID,
            service,
            serviceID: service.serviceID,
            serviceCategory,
            total: (
                (apptPackage && apptPackage.isFixedPrice) ? 0 :
                    this.props.getServicePrice(service.serviceID, stylistUserID)),
            apptPackageID: (apptPackage ? apptPackage.apptPackageID : null)
        }

        waitingApptServices.push(newApptService);
        this.updateAppt({ waitingApptServices });

    }

    removeService(index) {
        const services = this.state.waitingAppt.waitingApptServices;
        services.splice(index, 1);
        this.updateAppt(services);
    }

    updateStylist(index, field, value) {
        const waitingApptServices = this.state.waitingAppt.waitingApptServices;
        const service = waitingApptServices[index];
        service[field] = value;

        if (field == 'stylistUserID') {
            const price = this.props.getServicePrice(parseInt(service.service.serviceID), value);
            service.total = price;
        }

        waitingApptServices[index] = service;

        this.updateAppt({
            waitingApptServices
        });

    }

    updateAppt(values) {
        const waitingAppt = this.state.waitingAppt;

        for (let field in values) {
            const value = values[field];
            waitingAppt[field] = value;

            if (field == 'waitingApptServices' || field == 'discountType' || field == 'discountAmount') {
                waitingAppt.total = 0;
                waitingAppt.waitingApptServices.forEach(s => {
                    waitingAppt.total += (Number(s.total) || 0);
                });

                switch (waitingAppt.discountType) {
                    case 'fixed':
                        waitingAppt.total -= (Number(waitingAppt.discountAmount) || 0);
                        break;
                    case 'percentage':
                        waitingAppt.total -= (Number(waitingAppt.discountAmount / 100) || 0) * waitingAppt.total;
                        break;
                }
            }

            if (field == 'customer') {
                this.updateAppt({ customerID: value?.customerID ? value.customerID : null });
            }
        }

        this.setState({ waitingAppt });
    }

    async saveWaitingAppt() {
        await WaitingApptService.save(this.state.waitingAppt);
        this.setStep(3);
        this.confirmTimeout = setTimeout(() => {
            this.props.onBackClicked();
        }, 3 * 1000);
    }

    renderCustomerFinder() {
        const customer = this.state.waitingAppt.customer;

        if (customer) {
            const customerID = customer.customerID ? customer.customerID : 0;
            if (customerID !== 0) {
                return (
                    <CustomerSummary
                        customerID={customerID}
                        onChangeClicked={e => this.removeCustomer()}
                    />
                )
            } else {
                return (
                    <NewCustomerPanel
                        customer={customer}
                        onChange={e => this.selectCustomer(e)}
                        onChangeClicked={e => this.removeCustomer()}
                    />)
            }
        }

        return (
            <div className="panel">
                <div className="panel-body">

                    <Search
                        className="search-box"
                        autoFocus={true}
                        placeholder="Type customer's name..."
                        defaultQuery={this.customerSearchQuery}
                        renderWrapper={(input, results, info) => (
                            <>
                                <div className="input-group">
                                    {input}
                                    <button
                                        className="button add-customer-button"
                                        onClick={e => this.addNewCustomer(info.query)}
                                        title="Add customer with this name"
                                    >
                                        <i className="fas fa-plus" />
                                    </button>
                                </div>
                                {results && results.length > 0 &&
                                    <div className="list search-results">
                                        {results}
                                    </div>
                                }
                            </>
                        )}
                        search={async (query, setResults, maxResults, state) => {
                            this.customerSearchQuery = query;
                            const results = await SearchService.search(query, ['Customer'], { state })
                            setResults(results);
                        }}
                        renderResult={(customer, index) =>
                            <div key={index} className="search-result list-item" onMouseDown={e => this.selectCustomer(customer)}>
                                {/*<img className="user-icon list-item-icon" src=userIconImage />*/}
                                <div className="list-item-name">
                                    {customer.name}
                                    <div className="list-subitem-number">
                                        {customer.customerTel} {customer.customerTel && customer.customerEmail && <>-</>} {customer.customerEmail}
                                    </div>
                                </div>
                            </div>
                        }
                    />

                </div>

            </div>
        );
    }

    renderServiceSelector() {
        return (<>
            {/* Service category selector */}
            {!this.state.serviceCategory &&
                this.props.serviceCategories &&
                this.props.serviceCategories.length > 0 &&
                <div className="panel">
                    <div className="panel-header">
                        Service Categories
                    </div>
                    <div className="panel-body">

                        {/* Service search */}
                        <div className="service-search search-box-absolute">
                            <Search
                                className="search-box"
                                autoFocus={true}
                                placeholder="Search for a service..."
                                search={async (query, setResults, maxResults, state) => {
                                    const results = await SearchService.search(query, ['Service'], { state });
                                    results.sort((a, b) => {
                                        if (a.type == b.type) {
                                            return b.score - a.score;
                                        } else {
                                            return a.type == 'package' ? -1 : 1;
                                        }
                                    });
                                    setResults(results);
                                }}
                                renderResult={(result, index, info) =>
                                    <div key={index} className="search-result list-item" onMouseDown={e => {
                                        if (result.type == 'package') {
                                            const pkg = this.props.packages.find(p => p.packageID == result.id);
                                            this.addPackage(pkg);
                                        } else {
                                            result.serviceID = result.id; // TODO just use 'id' everywhere
                                            this.addService(result);
                                        }
                                        info.clearSearch();
                                        info.focus();
                                    }}>
                                        <div className="list-item-name">
                                            {result.type == 'package' && <>
                                                <span className="fas fa-cubes"></span>{' '}
                                            </>}
                                            {result.name}
                                        </div>
                                    </div>
                                }
                            />
                        </div>

                        <ul className="list">
                            {this.props.serviceCategories.map((serviceCategory, index) =>
                                <li key={serviceCategory.serviceCategoryID} onClick={e => this.selectServiceCategory(serviceCategory)}>
                                    {serviceCategory.name}
                                </li>
                            )}
                        </ul>

                    </div>
                </div>
            }

            {/* Service / package selector */}
            {this.state.serviceCategory &&
                <div className="panel service-selector-panel">
                    <div className="panel-header">
                        {this.state.serviceCategory.name}
                    </div>
                    <div className="panel-body service-selector">

                        <button className="button button-secondary button-small" onClick={this.deselectServiceCategory}>
                            <span className="fa fa-arrow-left"></span>{' '}
                            Back
                        </button>

                        {/* PACKAGES */}
                        <ul className="list service-list">
                            {this.state.packages.map((pkg, index) =>
                                <li key={pkg.packageID} onClick={e => this.addPackage(pkg, this.state.serviceCategory)}>
                                    <span className="service-list-icon">
                                        <span className="fas fa-cubes"></span>
                                    </span>
                                    <span className="service-list-name">{pkg.name}</span>

                                    <span className="service-list-price">
                                        <Money amount={pkg.price} />
                                    </span>

                                </li>
                            )}

                            {/* SERVICES */}

                            {this.state.services.map((service, index) =>
                                <li key={service.serviceID} onClick={e => this.addService(service, this.state.serviceCategory)}>
                                    <span className="service-list-name">{service.name}</span>

                                    <span className="service-list-price">
                                        {service.minPrice === service.maxPrice &&
                                            <Money amount={service.minPrice} />
                                        }
                                        {service.minPrice !== service.maxPrice &&
                                            <>
                                                <Money amount={service.minPrice} />
                                                {' - '}
                                                <Money amount={service.maxPrice} />
                                            </>
                                        }
                                    </span>

                                </li>
                            )}
                        </ul>

                    </div>
                </div>
            }
        </>)
    }

    renderSelectedServices() {
        const isFixedPricePackage = (packageID) => {
            const apptPackage = this.state.waitingAppt.packages.find(ap => ap.packageID == packageID);
            return apptPackage && apptPackage.isFixedPrice;
        };

        return (
            <div className="panel">
                <div className="panel-header">
                    Selected Services
                </div>
                <div className="panel-body">
                    <ul className="list service-list">

                        {this.state.waitingAppt.packages &&
                            this.state.waitingAppt.packages.map((apptPackage, index) =>
                                <li
                                    key={index}
                                    id={'Package-' + apptPackage.apptPackageID}
                                    className="colour-coded-item service-list-package"
                                    style={{ borderLeft: '4px solid black' }}
                                >

                                    <span className="service-list-icon">
                                        <span className="fas fa-cubes"></span>
                                    </span>

                                    <span className="service-list-name">
                                        {apptPackage.package.name}
                                    </span>

                                    {apptPackage.isFixedPrice &&
                                        <span className="service-list-price">
                                            <Money amount={apptPackage.total} />
                                        </span>
                                    }

                                    <span className="floating-controls">

                                        <button className="button customer-summary-change-button" onClick={e => this.removePackage(index)}>
                                            <span className="fa fa-times"></span>
                                        </button>

                                    </span>

                                </li>
                            )}

                        {this.state.waitingAppt.waitingApptServices &&
                            this.state.waitingAppt.waitingApptServices.map((apptService, index) =>
                                <React.Fragment key={index}>
                                    <li
                                        id={'Service-' + apptService.apptServiceID}
                                        className={
                                            (apptService.stylistUserID ? 'colour-coded-item ' : '') +
                                            (apptService.warnings && apptService.warnings.length > 0 ? 'service-list-has-warning' : '')
                                        }
                                        style={
                                            (apptService.stylistUserID && this.props.stylistsLookup[apptService.stylistUserID]) ?
                                                { borderLeft: '4px solid ' + this.props.stylistsLookup[apptService.stylistUserID].diaryColour }
                                                : {}
                                        }
                                    >

                                        <span className={'service-list-icon ' + (!!apptService.apptPackageID && 'service-list-icon-indent')}>
                                            <span className="fas fa-cut"></span>
                                        </span>

                                        <span className="service-list-name">
                                            {apptService.service.name}
                                        </span>

                                        <span className="service-list-price">
                                            {apptService.service.isPriceOnConsultation ?
                                                <abbr title="Price on Consultation">POC</abbr> :
                                                <>
                                                    {(!apptService.apptPackageID || !isFixedPricePackage(apptService.apptPackageID)) && <>
                                                        { apptService.total ? apptService.total : this.props.getServicePrice(apptService.serviceID, apptService.stylistUserID) }
                                                    </>}
                                                </>}
                                        </span>

                                        {!apptService.apptPackageID &&
                                            <span className="floating-controls">

                                                <button className="button customer-summary-change-button" onClick={e => this.removeService(index)}>
                                                    <span className="fa fa-times"></span>
                                                </button>

                                            </span>
                                        }
                                    </li>

                                    <li className="non-selectable">

                                        <span className="service-list-icon">
                                            <span className="fa fa-user" />
                                        </span>

                                        <span className="service-list-name" style={{ marginRight: 0 }}>
                                            <select
                                                value={apptService.stylistUserID || ''}
                                                style={{ width: '100%' }}
                                                onChange={e => this.updateStylist(index, 'stylistUserID', parseInt(e.target.value))}
                                            >
                                                <option value="">Any</option>
                                                {this.props.stylists.map(s =>
                                                    <option key={s.userID} value={s.userID}>{s.nickname}</option>
                                                )}
                                            </select>
                                        </span>

                                    </li>

                                </React.Fragment>
                            )}

                    </ul>

                </div>

            </div>
        );
    }

    renderStep1() {
        return (<>

            {this.state.waitingAppt.customer && <>
                {this.renderServiceSelector()}
                {this.renderSelectedServices()}
            </>}

            <div className="button-stack">

                <button className="button button-primary button-small" onClick={e => this.setStep(2)} disabled={!this.state.waitingAppt.customer}>
                    Continue
                    {' '}<i className='fas fa-arrow-right'></i>
                </button>

                <button className="button button-tertiary button-small" onClick={this.props.onBackClicked}>
                    <i className='fas fa-arrow-left'></i>{' '}
                    Back
                </button>

            </div>
        </>);
    }

    renderStep2() {

        return (<>
            <div className="button-stack">

                <div className="panel">
                    <div className="panel-header">
                        Date
                    </div>

                    <div className="panel-body">
                        <div className="form-group">
                            <label>Preferred date</label>
                            <DatePicker
                                value={this.state.waitingAppt.primaryDate ? moment(this.state.waitingAppt.primaryDate).toDate() : null}
                                onChange={date => this.updateAppt({ primaryDate: moment(date).format('YYYY-MM-DD') })}
                                minDate={moment().toDate()}
                            />
                        </div>


                        {this.state.waitingAppt.primaryDate &&
                            <div className="form-group">
                                <label>Second preferred date</label>
                                <DatePicker
                                    value={this.state.waitingAppt.secondaryDate ? moment(this.state.waitingAppt.secondaryDate).toDate() : null}
                                    onChange={date => this.updateAppt({ secondaryDate: moment(date).format('YYYY-MM-DD') })}
                                    minDate={moment().toDate()}
                                />
                            </div>
                        }

                        {this.state.waitingAppt.secondaryDate &&
                            <div className="form-group">
                                <label>Third preferred date</label>
                                <DatePicker
                                    value={this.state.waitingAppt.tertiaryDate ? moment(this.state.waitingAppt.tertiaryDate).toDate() : null}
                                    onChange={date => this.updateAppt({ tertiaryDate: moment(date).format('YYYY-MM-DD') })}
                                    minDate={moment().toDate()}
                                />
                            </div>
                        }
                    </div>
                </div>

                <div className="panel">
                    <div className="panel-header">
                        Time
                    </div>

                    <div className="panel-body">
                        <label>Time of day</label>
                        <select
                            style={{ width: '100%' }}
                            defaultValue={this.state.waitingAppt.timeOfDay}
                            onChange={e => this.updateAppt({ timeOfDay: e.target.value })}
                        >
                            <option value="">Any</option>
                            <option value="Morning">Morning</option>
                            <option value="Afternoon">Afternoon</option>
                            <option value="Evening">Evening</option>
                        </select>

                        <label>Notes</label>
                        <textarea
                            style={{ width: '100%' }}
                            rows="3" value={this.state.waitingAppt.timeNotes || ''}
                            onChange={e => this.updateAppt({ timeNotes: e.target.value })}
                        />
                    </div>
                </div>

                <div className="panel">
                    <div className="panel-header">
                        Additional Notes
                    </div>

                    <div className="panel-body">
                        <textarea
                            style={{ width: '100%' }}
                            rows="3" value={this.state.waitingAppt.notes || ''}
                            onChange={e => this.updateAppt({ notes: e.target.value })}
                        />
                    </div>
                </div>

                <button className="button button-primary button-small" onClick={e => this.saveWaitingAppt()} disabled={!this.state.waitingAppt.customer || !this.state.waitingAppt.primaryDate}>
                    <i className='fas fa-check'></i>{' '}
                    Add to Waiting List
                </button>

                <button className="button button-tertiary button-small" onClick={e => this.setStep(1)}>
                    <i className='fas fa-arrow-left'></i>{' '}
                    Back
                </button>

            </div>
        </>);
    }

    renderStep3() {
        return (<>
            <div className="panel retail-purchase-complete-panel">
                <div className="panel-body">
                    Added to Waiting List!
                </div>
            </div>
        </>);
    }

    render() {
        const {
            step,
            isLoading
        } = this.state;
        if (isLoading) {
            return (
                <Loader />
            );
        }
        return (<>

            {step != 3 && this.renderCustomerFinder()}

            {step == 1 && this.renderStep1()}
            {step == 2 && this.renderStep2()}
            {step == 3 && this.renderStep3()}
        </>);
    }
}

export default WaitingApptEditor;