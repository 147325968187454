// Libs
import React from 'react';
import { withRouter } from 'react-router-dom';

// Services & Helpers
import GlobalStateService from 'services/GlobalStateService';

// Components
import FloomlyComponent from 'components/FloomlyComponent';
import InfoBar from 'components/layout/InfoBar'
import Loader from 'components/reusable/Loader';
import TabPanel, { Tab } from 'components/reusable/TabPanel';
import CampaignsTab from 'components/pages/marketing/CampaignsTab';
import LoyaltySchemeTab from 'components/pages/marketing/LoyaltySchemeTab';
import AutomationsTab from 'components/pages/marketing/AutomationsTab';
import ClientListsTab from 'components/pages/marketing/ClientListsTab';
import SalonSpyTab from 'components/pages/marketing/SalonSpyTab';
import EmailTemplatesTab from 'components/pages/marketing/EmailTemplatesTab';

//-------------------------------------------------------------------------------------------------------------------

class MarketingPage extends FloomlyComponent {

    constructor(props) {
        super(props);

        // Refs
        this.loyaltySchemeTabRef = React.createRef();
        this.salonSpyTabRef = React.createRef();

        // Init state
        this.state = {
            isLoading: true
        };
    }

    componentDidMount() {
        this.load();
    }

    async load() {
        this.setState({
            isLoading: false
        });
    }
    
    getCurrentTab() {
        let tab = this.props.match.params.tab;
        const loginDetails = GlobalStateService.getValue('loginDetails');
        if (!tab) {
            if (loginDetails.permissions['MarketingViewCampaigns']) {
                tab = 'client-lists';
            } else if (loginDetails.permissions['MarketingViewAutomatedMessages']) {
                tab = 'automated-messages';
            } else if (loginDetails.permissions['MarketingViewLoyaltyScheme']) {
                tab = 'loyalty-scheme';
            } else if (loginDetails.permissions['MarketingViewVoucherCodes']) {
                tab = 'voucher-codes';
            } else if (loginDetails.permissions['MarketingViewSalonSpy']) {
                tab = 'salon-spy';
            }
        }
        return tab;
    }

    save() {
        const tab = this.getCurrentTab();
        switch (tab) {
            case 'loyalty-scheme':
                this.loyaltySchemeTabRef.current.save();
                break;
            case 'salon-spy':
                this.salonSpyTabRef.current.save();
                break;
        }
    }

    //--------------------------------------------------------------------------------------------------------------------
    //  Render
    //--------------------------------------------------------------------------------------------------------------------

    renderInfoBar() {
        const tab = this.getCurrentTab();
        const showSaveButton = (tab == 'loyalty-scheme' || tab == 'salon-spy');

        return (
            <InfoBar containerClassName="floating-on-mobile" className={'marketing-info-bar ' + (showSaveButton ? '' : 'desktop-only')} sticky={true}>

                <div className="info-bar-panel-section info-bar-panel-section-text desktop-only">
                    Marketing
                </div>

                <div className="info-bar-panel-section">
                    {showSaveButton &&
                        <button className="button" onClick={() => this.save()}>
                            <span className="fa fa-check"></span>{' '}
                            Save
                        </button>
                    }
                </div>

            </InfoBar>
        );
    }

    render() {
        return (<>
            <div className="page-content">
                <div className="page-content-left">
                </div>
                <div className="page-content-right">
                    <div className="page-content-right-inner">
                        {this.renderInfoBar()}
                    </div>
                </div>
            </div>

            <div className="marketing-page">

                {this.renderMainContent()}

            </div>
        </>);
    }

    renderMainContent() {
        const {
            isLoading
        } = this.state;

        if (isLoading) {
            return (<Loader />);
        }
        const loginDetails = GlobalStateService.getValue('loginDetails');
        const tab = this.getCurrentTab();

        return (<>

            <TabPanel tab={tab} onChangeTab={tab => {
                this.props.history.push('/marketing/' + tab);
            }}>

                {loginDetails.permissions['MarketingViewCampaigns'] &&
                    <Tab id="client-lists" title="Client lists" tabClassName="tab-pink">

                        <ClientListsTab />

                    </Tab>
                }

                {loginDetails.permissions['MarketingViewCampaigns'] &&
                    <Tab id="campaigns" title="Campaigns" tabClassName="tab-teal desktop-only">

                        <CampaignsTab />

                    </Tab>
                }

                {loginDetails.permissions['MarketingViewAutomatedMessages'] &&
                    <Tab id="automated-messages" title="Automated Messages" tabClassName="tab-yellow">

                        <AutomationsTab />

                    </Tab>
                }

                {loginDetails.permissions['MarketingViewLoyaltyScheme'] &&
                    <Tab id="loyalty-scheme" title="Loyalty Scheme" tabClassName="tab-green">

                        <LoyaltySchemeTab ref={this.loyaltySchemeTabRef} />

                    </Tab>
                }

                {/*
                {loginDetails.permissions['MarketingViewVoucherCodes'] &&
                    <Tab id="voucher-codes" title="Voucher Codes" tabClassName="tab-pink">

                    </Tab>
                }
                */}

                {loginDetails.permissions['MarketingViewSalonSpy'] &&
                    <Tab id="salon-spy" title="SalonSpy" tabClassName="tab-pink">

                        <SalonSpyTab ref={this.salonSpyTabRef} />

                    </Tab>
                }

                {loginDetails.permissions['MarketingCreateEmailTemplates'] &&
                    <Tab id="email-template" title="Email Templates" tabClassName="tab-teal">
                        <EmailTemplatesTab />
                    </Tab>
                }
            </TabPanel>

        </>);
    }

};

export default withRouter(MarketingPage);