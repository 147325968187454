// Libs
import React, { Component } from 'react';

// Services & Helpers
import DiaryService         from 'services/DiaryService';

// Components
import FloomlyComponent         from 'components/FloomlyComponent';
import Loader               from 'components/reusable/Loader';
import ClientRecordProducts from 'components/reusable/OLD_ClientRecordProducts';
import ClientRecordNotes    from 'components/reusable/OLD_ClientRecordNotes';

//-------------------------------------------------------------------------------------------------------------------

class ClientRecord extends FloomlyComponent {

    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            clientRecord: null
        };

        this.save = this.save.bind(this);
    }

    componentDidMount() {
        this.load();
    }

    async load() {
        // Load info
        const clientRecord = await DiaryService.loadClientRecord(this.props.appointmentID);

        // Update UI
        await this.setStateAsync({
            isLoading: false,
            clientRecord: clientRecord
        });
    }

    async save() {
        this.setState({ isLoading: true });
        await DiaryService.saveClientRecord(this.state.clientRecord);
        this.setState({ isLoading: false });
        this.props.onBackClicked();
    }

    //--------------------------------------------------------------------------------------------------------------------
    // Render
    //--------------------------------------------------------------------------------------------------------------------

    render() {
        const {
            isLoading,
            clientRecord
        } = this.state;

        if (isLoading) {
            return (<Loader />);
        }

        return (<>
            <div className="panel">
                <div className="panel-header">Products Used</div>
                <div className="panel-body">

                    <ClientRecordProducts
                        clientRecord={clientRecord}
                        onUpdate={clientRecord => {
                            this.setState({ clientRecord: clientRecord });
                        }}
                        onApptUpdated={value => {
                            this.props.onUpdateClientRecord(value);
                        }}
                    />

                </div>
            </div>

            <div className="panel client-record-notes-panel">
                <div className="panel-header">Notes</div>
                <div className="panel-body">

                    <ClientRecordNotes
                        clientRecord={clientRecord}
                        onUpdate={clientRecord => {
                            this.setState({ clientRecord: clientRecord});
                        }}
                        onApptUpdated={value => {
                            this.props.onUpdateClientRecord(value);
                        }}
                    />

                </div>
            </div>

            <div className="button-stack">

                {/* Save */}
                <button className="button button-primary button-small" onClick={this.save}>
                    <i className="fas fa-check"></i>{' '}
                    Save
                </button>

                {/* Back */}
                <button className="button button-secondary button-small" onClick={this.props.onBackClicked}>
                    <i className="fas fa-arrow-left"></i>{' '}
                    Back
                </button>

            </div>

        </>);
    }

}

export default ClientRecord;