import BaseService from 'services/BaseService';
import SearchService from 'services/SearchService';

//-------------------------------------------------------------------------------------------------------------------

export default class CapabilityService
    extends BaseService {

    static async list(userID) {
        const response = await BaseService.callAPI('capability/list', {
            userID: userID
        });
        return response;
    }

    static async get(id) {
        const response = await BaseService.callAPI('capability/get/' + id);
        return response;
    }

    static async save(capability) {
        const response = await BaseService.callAPI('capability/save', capability);
        return response;
    }

    static async delete(id) {
        return BaseService.callAPI('capability/delete/' + id);
    }
}
