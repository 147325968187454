// Libs
import React from 'react';
import moment from 'moment';

// Services & Helpers
import TextHelpers  from 'helpers/TextHelpers';

// Components
import AppointmentLogModal from 'components/pages/diary/AppointmentLogModal';

//-------------------------------------------------------------------------------------------------------------------

class AppointmentSummary extends React.Component {

    constructor(props) {
        super(props);

        this.appointmentLogModalRef = React.createRef();

        this.state = {
        };
    }

    render() {
        return (this.props.isMini ? this.renderMini() : this.renderMaxi());
    }

    showAppointmentLogModal() {
        const { appointment } = this.props;
        this.appointmentLogModalRef.current.show({
            appointmentID: appointment.appointmentID
        });
    }

    renderMini() {
        const appt = this.props.appointment;
        return (<>
            <div style={{ position: 'relative' }}>

                <button className="button button-secondary button-small view-activity-log-button" title="View activity log" onClick={e => this.showAppointmentLogModal()}>
                    <span className="fa fa-search"></span>
                </button>

                {appt.addedBy && <>
                    <strong>Booked by: </strong>
                    {appt.addedBy.nickname}
                    <br />
                </>}
                {appt.isOnline && <>
                    <strong>Online booking</strong><br />
                </>}
                {appt.dateAdded && <>
                    <strong>Booked on:</strong>
                    {' ' + moment(appt.dateAdded).format('DD MMM YYYY HH:mm')}
                </>}

                {appt.lastEditedBy &&
                    <div>
                        <strong>Last edited by: </strong>
                        {appt.lastEditedBy.nickname}
                        {appt.dateLastEdited && <>
                            <br />
                            <strong>Edited on:</strong>
                            {' ' + moment(appt.dateLastEdited).format('DD MMM YYYY HH:mm')}
                        </>}
                    </div>
                }
                {appt.confirmedBy &&
                    <div>
                        <strong>Confirmed by: </strong>
                        {appt.confirmedBy.nickname}
                        {appt.dateConfirmed && <>
                            <br />
                            <strong>Confirmed on:</strong>
                            {' ' + moment(appt.dateConfirmed).format('DD MMM YYYY HH:mm')}
                        </>}
                    </div>
                }
            </div>

            <AppointmentLogModal 
                ref={this.appointmentLogModalRef}
            />
        </>);
    }

    renderMaxi() {
        const appt = this.props.appointment;
        if (!appt) {
            return (<></>);
        }

        return (<>

            <div className="panel-info-headline">
                {moment(appt.date).format('Do MMM YYYY')}{' '}
                ({TextHelpers.formatDateDiff(new Date(), appt.date)})
            </div>

            {(!this.props.hideColourNotes || !this.props.hideApptNotes) &&
                <hr />
            }

            {!this.props.hideColourNotes &&
                <>
                    <div className="panel-info-header">
                        Colour Notes
                    </div>
                    <div className="panel-info-body">
                        {appt.colourNotes ? this.renderMultiLineNotes(appt.colourNotes) : '(None)'}
                    </div>
                </>
            }

            {!this.props.hideApptNotes &&
                <>
                    <div className="panel-info-header">
                        Appointment Notes
                    </div>
                    <div className="panel-info-body">
                        {appt.notes ? this.renderMultiLineNotes(appt.notes) : '(None)'}
                    </div>
                </>
            }

            {!this.props.hideProducts &&
                <>
                    <div className="panel-info-header">
                        Products Used
                    </div>
                    <div className="panel-info-body">
                        {appt.appointmentProducts.map(ap =>
                            <div key={ap.appointmentProductID}>
                                {ap.name} - {ap.amountUsed}{ap.stockUnit}
                            </div>
                        )}
                        {appt.appointmentProducts.length == 0 && '(None)'}
                    </div>
                </>
            }
        </>);
    }

    renderMultiLineNotes(notes) {
        const lines = (notes || '').split(/\r\n|\r|\n/g);
        return lines.map((l, index) =>
            <React.Fragment key={index}>
                {l}{index < lines.length - 1 && <br />}
            </React.Fragment>
        );
    }
};

export default AppointmentSummary;