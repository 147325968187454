// Libs
import React from 'react';
import { Route } from 'react-router';
import { Link, withRouter } from 'react-router-dom';

// Services & Helpers
import FormService from 'services/FormService';

// Components
import FloomlyComponent from 'components/FloomlyComponent';
import InfoBar from 'components/layout/InfoBar'
import Loader from 'components/reusable/Loader';
import SuperTable from 'components/reusable/SuperTable';

//-------------------------------------------------------------------------------------------------------------------

class SettingsFormList extends FloomlyComponent {

    constructor(props) {
        super(props);

        // Init state
        this.state = {
            isLoading: true,
            formTypes: []
        };
    }

    componentDidMount() {
        this.load();
    }

    //--------------------------------------------------------------------------------------------------------------------

    async load() {
        const formTypes = await FormService.listFormTypes();

        this.setState({
            isLoading: false,
            formTypes: formTypes
        });
    }

    edit(id) {
        this.props.history.push('/settings/forms/' + id);
    }

    add() {
        this.props.history.push('/settings/forms/new');
    }

    //--------------------------------------------------------------------------------------------------------------------
    //  Render
    //--------------------------------------------------------------------------------------------------------------------

    renderInfoBar() {
        const {
            formTypes
        } = this.state;
        return (
            <InfoBar className="settings-info-bar" containerClassName="floating-on-mobile" sticky={true}>

                <div className="info-bar-panel-section info-bar-panel-section-text desktop-only">
                    Forms
                </div>
                <div className="info-bar-panel-section ml-auto">

                    <button className="button" onClick={() => this.add()}>
                        <span className="fa fa-plus"></span>{' '}
                        Add New
                    </button>

                </div>

            </InfoBar>
        );
    }

    render() {
        const {
            isLoading,
            formTypes
        } = this.state;

        if (isLoading) {
            return (<Loader />);
        }

        return (<>
            {this.renderInfoBar()}

            <div className="settings-page-main">
                <div className="panel">
                    <div className="panel-body">

                        <SuperTable
                            className="settings-table"
                            containerClassName="mb-0"
                            rows={formTypes}
                            keyAccessor={form => form.formTypeID}
                            cols={{
                                name: { label: 'Form' },
                                type: { label: 'Type' },
                                numberOfResponses: {label: '# Responses'}
                            }}
                            onClick={(form, e) => this.edit(form.formTypeID)}
                            emptyText="No forms added"
                        />

                    </div>
                </div>

            </div>

        </>);
    }

};

export default withRouter(SettingsFormList);