// Libs
import React from 'react';
import { Link, withRouter } from 'react-router-dom';

// Services & Helpers
import RoleService from 'services/RoleService';
import FormHelper from 'helpers/FormHelper';
import GlobalStateService from 'services/GlobalStateService';
import BootboxHelper from 'helpers/BootboxHelper';

// Components
import FloomlyComponent from 'components/FloomlyComponent';
import InfoBar from 'components/layout/InfoBar'
import Loader from 'components/reusable/Loader';
import SuperTable from 'components/reusable/SuperTable';

//-------------------------------------------------------------------------------------------------------------------

class SettingsRoleEdit extends FloomlyComponent {

    constructor(props) {
        super(props);

        // Init state
        this.state = {
            isLoading: true,
            role: null
        };

        this.state.permissions = [
            // Diary
            {
                _grouping: 'Diary',
                name: 'Select all',
                isSelectAll: true
            },
            {
                _grouping: 'Diary',
                name: 'Create appointments',
                code: 'DiaryCreateAppointments',
                isDesktop: false,
                isMobile: false
            },
            {
                _grouping: 'Diary',
                name: 'Edit appointments',
                code: 'DiaryEditAppointments',
                isDesktop: false,
                isMobile: false
            },
            {
                _grouping: 'Diary',
                name: 'Check out appointments',
                code: 'DiaryCheckOutAppointments',
                isDesktop: false,
                isMobile: false
            },
            {
                _grouping: 'Diary',
                name: 'Create internal appointments',
                code: 'DiaryCreateInternalAppointments',
                isDesktop: false,
                isMobile: false
            },
            {
                _grouping: 'Diary',
                name: 'Cancel appointments',
                code: 'DiaryCancelAppointments',
                isDesktop: false,
                isMobile: false
            },
            {
                _grouping: 'Diary',
                name: 'Delete appointments',
                code: 'DiaryDeleteAppointments',
                isDesktop: false,
                isMobile: false
            },
            {
                _grouping: 'Diary',
                name: 'Delete internal appointments',
                code: 'DiaryDeleteInternalAppointments',
                isDesktop: false,
                isMobile: false
            },
            {
                _grouping: 'Diary',
                name: 'Confirm online bookings',
                code: 'DiaryConfirmOnlineBookings',
                isDesktop: false,
                isMobile: false
            },
            {
                _grouping: 'Diary',
                name: 'View/update client record',
                code: 'DiaryUpdateClientRecord',
                isDesktop: false,
                isMobile: false
            },
            {
                _grouping: 'Diary',
                name: 'Retail - Cash In',
                code: 'DiaryRetailCashIn',
                isDesktop: false,
                isMobile: false
            },
            {
                _grouping: 'Diary',
                name: 'Retail - Cash Out',
                code: 'DiaryRetailCashOut',
                isDesktop: false,
                isMobile: false
            },
            {
                _grouping: 'Diary',
                name: 'Retail - Walk In',
                code: 'DiaryRetailWalkIn',
                isDesktop: false,
                isMobile: false
            },
            {
                _grouping: 'Diary',
                name: 'Retail - Vouchers',
                code: 'DiaryRetailVouchers',
                isDesktop: false,
                isMobile: false
            },
            {
                _grouping: 'Diary',
                name: 'Retail - Open cash drawer manually',
                code: 'DiaryRetailOpenCashDrawer',
                isDesktop: false,
                isMobile: false
            },
            {
                _grouping: 'Diary',
                name: 'Refunds - Walk In Retail',
                code: 'DiaryRefundWalkInRetail',
                isDesktop: false,
                isMobile: false
            },
            {
                _grouping: 'Diary',
                name: 'Diary - View contact details on appt hover-over',
                code: 'DiaryContactDetailsOnHoverOver',
                isDesktop: false,
                isMobile: false
            },
            {
                _grouping: 'Diary',
                name: 'Diary - Edit Rotas',
                code: 'DiaryEditRotas',
                isDesktop: false,
                isMobile: false
            },
            {
                _grouping: 'Diary',
                name: 'Show Diary',
                code: 'ShowDiaryIndividual',
                isDesktop: false,
                isMobile: false,
                modes: [{
                    name: 'See individual',
                    isDefault: true,
                }, {
                    name: 'See all',
                    code: 'ShowDiaryAll',
                    isDesktop: false,
                    isMobile: false,
                }]
            },
            {
                _grouping: 'Diary',
                name: 'Create promo codes',
                code: 'DiaryCreatePromoCodes',
            },
            {
                _grouping: 'Diary',
                name: 'Edit promo codes',
                code: 'DiaryEditPromoCodes'
            },

            // Client card
            {
                _grouping: 'Client card',
                name: 'Select all',
                isSelectAll: true
            },
            {
                _grouping: 'Client card',
                name: 'Contact details',
                code: 'ClientCardViewContactDetails',
                isDesktop: false,
                isMobile: false,
                modes: [{
                    name: 'View only',
                    isDefault: true
                }, {
                    name: 'Edit',
                    code: 'ClientCardEditContactDetails'
                }]
            },
            {
                _grouping: 'Client card',
                name: 'Appointment notes',
                code: 'ClientCardViewAppointmentNotes',
                isDesktop: false,
                isMobile: false,
                modes: [{
                    name: 'View only',
                    isDefault: true
                }, {
                    name: 'Edit',
                    code: 'ClientCardEditAppointmentNotes',
                    isDesktop: false,
                    isMobile: false,
                }]
            },
            {
                _grouping: 'Client card',
                name: 'Alert notes',
                code: 'ClientCardViewAlertNotes',
                isDesktop: false,
                isMobile: false,
                modes: [{
                    name: 'View only',
                    isDefault: true,
                    isDesktop: false,
                    isMobile: false,
                }, {
                    name: 'Edit',
                    code: 'ClientCardEditAlertNotes',
                    isDesktop: false,
                    isMobile: false,
                }]
            },
            {
                _grouping: 'Client card',
                name: 'Hide client last names',
                code: 'ClientCardHideClientLastNames',
                isDesktop: false,
                isMobile: false
            },
            {
                _grouping: 'Client card',
                name: 'Hide client contact details',
                code: 'ClientCardHideClientContactDetails',
                isDesktop: false,
                isMobile: false
            },
            {
                _grouping: 'Client card',
                name: 'Send individual SMS to a client via Floomly',
                code: 'ClientCardSendIndividualSMSToClient',
                isDesktop: false,
                isMobile: false
            },
            {
                _grouping: 'Client card',
                name: 'Send individual email to a client via Floomly',
                code: 'ClientCardSendIndividualEmailToClient',
                isDesktop: false,
                isMobile: false
            },

            // Dashboard
            {
                _grouping: 'Dashboard',
                name: 'View the dashboard',
                code: 'Dashboard',
                isDesktop: false,
                isMobile: false
            },

            // Team Hub
            {
                _grouping: 'Team Hub',
                name: 'Select all',
                isSelectAll: true
            },
            {
                _grouping: 'Team Hub',
                name: 'View team hub',
                code: 'TeamHubIndividual',
                isDesktop: false,
                isMobile: false,
                modes: [{
                    name: 'See individual',
                    isDefault: true,
                }, {
                    name: 'See all',
                    code: 'TeamHubAll',
                    isDesktop: false,
                    isMobile: false,
                }]
            },

            // Marketing
            {
                _grouping: 'Marketing',
                name: 'Select all',
                isSelectAll: true
            },
            {
                _grouping: 'Marketing',
                name: 'Campaigns',
                code: 'MarketingViewCampaigns',
                isDesktop: false,
                isMobile: false,
                modes: [{
                    name: 'View only',
                    isDefault: true
                }, {
                    name: 'Edit',
                    code: 'MarketingEditCampaigns',
                    isDesktop: false,
                    isMobile: false,
                }]
            },
            {
                _grouping: 'Marketing',
                name: 'Automated messages',
                code: 'MarketingViewAutomatedMessages',
                isDesktop: false,
                isMobile: false,
                modes: [{
                    name: 'View only',
                    isDefault: true
                }, {
                    name: 'Edit',
                    code: 'MarketingEditAutomatedMessages',
                    isDesktop: false,
                    isMobile: false,
                }]
            },
            {
                _grouping: 'Marketing',
                name: 'Loyalty scheme',
                code: 'MarketingViewLoyaltyScheme',
                isDesktop: false,
                isMobile: false,
                modes: [{
                    name: 'View only',
                    isDefault: true
                }, {
                    name: 'Edit',
                    code: 'MarketingEditLoyaltyScheme',
                    isDesktop: false,
                    isMobile: false,
                }]
            },
            {
                _grouping: 'Marketing',
                name: 'Voucher codes',
                code: 'MarketingViewVoucherCodes',
                isDesktop: false,
                isMobile: false,
                modes: [{
                    name: 'View only',
                    isDefault: true
                }, {
                    name: 'Edit',
                    code: 'MarketingEditVoucherCodes',
                    isDesktop: false,
                    isMobile: false,
                }]
            },
            {
                _grouping: 'Marketing',
                name: 'SalonSpy',
                code: 'MarketingViewSalonSpy',
                isDesktop: false,
                isMobile: false,
                modes: [{
                    name: 'View only',
                    isDefault: true
                }, {
                    name: 'Edit',
                    code: 'MarketingEditSalonSpy',
                    isDesktop: false,
                    isMobile: false,
                }]
            },
            {
                _grouping: 'Marketing',
                name: 'Create email templates',
                code: 'MarketingCreateEmailTemplates'
            },
            {
                _grouping: 'Marketing',
                name: 'Send email batch',
                code: 'MarketingSendEmailBatch'
            },

            // Stockroom
            {
                _grouping: 'Stockroom',
                name: 'Select all',
                isSelectAll: true
            },
            {
                _grouping: 'Stockroom',
                name: 'Check stock in/out',
                code: 'StockroomCheckInOut',
                isDesktop: false,
                isMobile: false
            },
            {
                _grouping: 'Stockroom',
                name: 'Stock manager',
                code: 'StockroomViewStockManager',
                isDesktop: false,
                isMobile: false,
                modes: [{
                    name: 'View only',
                    isDefault: true
                }, {
                    name: 'Edit',
                    code: 'StockroomEditStockManager',
                    isDesktop: false,
                    isMobile: false,
                }]
            },
            {
                _grouping: 'Stockroom',
                name: 'Create Stock Orders',
                code: 'StockroomCreateStockOrder'
            },

            // The Office
            {
                _grouping: 'The Office',
                name: 'Select all',
                isSelectAll: true
            },
            {
                _grouping: 'The Office',
                name: 'End of day banking',
                code: 'OfficeViewEndOfDayBanking',
                isDesktop: false,
                isMobile: false,
                modes: [{
                    name: 'View only',
                    isDefault: true
                }, {
                    name: 'Edit',
                    code: 'OfficeEditEndOfDayBanking',
                    isDesktop: false,
                    isMobile: false,
                }]
            },
            {
                _grouping: 'The Office',
                name: 'Reports',
                code: 'OfficeReports',
                isDesktop: false,
                isMobile: false
            },
            {
                _grouping: 'The Office',
                name: 'Stylist targets',
                code: 'OfficeStylistTargets',
                isDesktop: false,
                isMobile: false
            },
            {
                _grouping: 'The Office',
                name: 'Your Profit Target',
                code: 'OfficeYourProfitTarget',
                isDesktop: false,
                isMobile: false
            },
            {
                _grouping: 'The Office',
                name: 'Budget',
                code: 'OfficeViewBudget',
                isDesktop: false,
                isMobile: false,
                modes: [{
                    name: 'View only',
                    isDefault: true
                }, {
                    name: 'Edit',
                    code: 'OfficeEditBudget',
                    isDesktop: false,
                    isMobile: false,
                }]
            },

            // Education
            {
                _grouping: 'Education',
                name: 'Select all',
                isSelectAll: true
            },
            {
                _grouping: 'Education',
                name: 'Tutorials',
                code: 'EducationTutorials',
                isDesktop: false,
                isMobile: false
            },
            {
                _grouping: 'Education',
                name: 'Business training',
                code: 'EducationBusinessTraining',
                isDesktop: false,
                isMobile: false
            },
            {
                _grouping: 'Education',
                name: 'Team training',
                code: 'EducationTeamTraining',
                isDesktop: false,
                isMobile: false
            },

            // Settings
            {
                _grouping: 'Settings',
                name: 'View and edit settings',
                code: 'Settings',
                isDesktop: false,
                isMobile: false
            },
        ];

        this.updateField = this.updateField.bind(this);
        
        this.formHelper = new FormHelper({
            fields: {
                name: { label: 'Name' },
                isDefault: { label: 'Default role for new users', type: 'checkbox' }
            },
            getValue: (fieldName) => this.state.role[fieldName],
            setValue: this.updateField
        });
    }

    componentDidMount() {
        this.load();
    }

    //--------------------------------------------------------------------------------------------------------------------

    async load() {
        const {
            permissions
        } = this.state;
        const roleID = Number(this.props.match.params.roleID) || 0;

        // Load role
        let role;
        if (roleID) {
            role = await RoleService.get(roleID);
        } else {
            role = {
                selectedPermissions: []
            };
        }

        // Create permission lookup
        let selectedPermissions = [];
        role.selectedPermissions.forEach(p => {
            selectedPermissions.push(p);
            let selectedPermission = permissions.find(sp => sp.code == p.selectedPermissionCode);
            if (selectedPermission) {
                selectedPermission.isDesktop = p.isDesktop;
                selectedPermission.isMobile = p.isMobile;
            }

            permissions.forEach(sm => {
                if (sm.modes) {
                    sm.modes.forEach(m => {
                        if (m.code == p.selectedPermissionCode) {
                            m.isDesktop = p.isDesktop;
                            m.isMobile = p.isMobile;
                        }
                        //else if (m.code && selectedPermission.code == m.code) {
                        //    sm.isDesktop = p.isDesktop;
                        //    sm.isMobile = p.isMobile;
                        //}
                    })
                }
            })
        });
        // Update UI
        this.setState({
            isLoading: false,
            role,
            permissions,
            selectedPermissions
        });
    }

    goBack() {
        this.props.history.push('/settings/roles');
    }

    async save() {
        this.setState({ isLoading: true });
        await RoleService.save(this.state.role);
        this.goBack();
        GlobalStateService.getValue('checkLoginDetails')();
    }

    updateField(fieldName, value) {
        const role = { ...this.state.role };
        role[fieldName] = value;
        this.setState({
            role: role
        });
    }

    togglePermission(code) {
        const permission = this.state.permissions.find(p => p.code == code);

        // Toggle permission
        const selectedPermissions = { ...this.state.selectedPermissions };
        if (selectedPermissions[code]) {
            delete selectedPermissions[code];

            // Remove all modes too
            if (permission.modes) {
                permission.modes.forEach(m => {
                    if (m.code) {
                        delete selectedPermissions[m.code];
                    }
                });
            }
        } else {
            selectedPermissions[code] = true;

            // Add default mode
            if (permission.modes) {
                let isSelected = false;
                for (let i = 0; i < permission.modes.length; i++) {
                    const mode = permission.modes[i];
                    if (mode.isDefault && mode.code) {
                        selectedPermissions[mode.code] = true;
                        isSelected = true;
                        break;
                    }
                }
                if (!isSelected && permission.modes.length > 0) {
                    selectedPermissions[permission.modes[0].code] = true;
                }
            }
        }
        
        this.updateSelectedPermissions(selectedPermissions);
    }

    setPermissionMode(permission, isDesktop, isMobile, code) {
        const {
            selectedPermissions,
            permissions
        } = this.state;
        
        permission.modes.forEach(pm => {
            if (pm.code) {
                let selectedPermission = selectedPermissions.find(sp => sp.selectedPermissionCode == pm.code);
                selectedPermissions.splice(selectedPermissions.indexOf(selectedPermission), 1);
                if (pm.code == code) {
                    if (isDesktop) {
                        pm.isDesktop = isDesktop;
                        selectedPermissions.push({
                            selectedPermissionCode: code,
                            isDesktop: isDesktop,
                            isMobile: pm.isMobile
                        });
                    }
                    else if (isMobile) {
                        pm.isMobile = isMobile;
                        selectedPermissions.push({
                            selectedPermissionCode: code,
                            isDesktop: pm.isDesktop,
                            isMobile: isMobile
                        });
                    }
                }

                permissions.forEach(sm => {
                    if (sm.modes) {
                        sm.modes.forEach(m => {
                            if (isDesktop) {
                                permission.modes.forEach(pm => {
                                    if (pm.code && pm.code == m.code) {
                                        m.isDesktop = !isDesktop;
                                    }
                                });
                                if (m.code == code) {
                                    m.isDesktop = isDesktop;
                                }
                            }
                            if (isMobile) {
                                permission.modes.forEach(pm => {
                                    if (pm.code && pm.code == m.code) {
                                        m.isMobile = !isMobile;
                                    }
                                });
                                if (m.code == code)
                                    m.isMobile = isMobile;
                            }
                        })
                    }
                })
            }
        });
       
        // Update
        this.updateSelectedPermissions(selectedPermissions, permissions);
    }

    getIsAllSelected(grouping, desktopOrMobile) {
        const permissions = this.state.permissions.filter(p => p._grouping == grouping && !p.isSelectAll);
        const isAllSelected = permissions.every(p =>
            this.state.selectedPermissions.some(sp =>
                (sp.selectedPermissionCode == p.code || (p.modes && p.modes.some(m => sp.selectedPermissionCode == m.code)))
                && (
                    (desktopOrMobile == 'desktop' && sp.isDesktop)
                    || 
                    (desktopOrMobile == 'mobile' && sp.isMobile)
                )
            )
        );
        return isAllSelected;
    }

    setPermission(permission, isDesktop, isMobile, value) {
        const {
            selectedPermissions,
            permissions
        } = this.state;

        // Determine which permissions to update
        const permissionsToUpdate = [];
        if (permission.isSelectAll) {
            permissions.forEach(p => {
                if (p._grouping == permission._grouping && !p.isSelectAll) {
                    permissionsToUpdate.push(p);
                }
            });
        } else {
            permissionsToUpdate.push(permission);
        }

        // If deselecting, also deselect all modes
        if (!value) {
            permissionsToUpdate.forEach(p => {
                if (p.modes) {
                    p.modes.forEach(pm => {
                        if (pm.code) {
                            permissionsToUpdate.push({
                                code: pm.code,
                                isDesktop: p.isDesktop,
                                isMobile: p.isMobile
                            });
                        }
                    });
                }
            });
        }

        // Update
        permissionsToUpdate.forEach(p => {
            const selectedPermission = selectedPermissions.find(sp => sp.selectedPermissionCode == p.code);
            const permissionUpdate = permissions.find(sp => sp.code == p.code);

            if (isDesktop) {
                if (selectedPermission) {
                    selectedPermission.isDesktop = value;
                } else {
                    selectedPermissions.push({
                        selectedPermissionCode: p.code,
                        isDesktop: value,
                        isMobile: p.isMobile
                    })
                }
                if (permissionUpdate) {
                    permissionUpdate.isDesktop = value;
                }
            }

            if (isMobile) {
                if (selectedPermission) {
                    selectedPermission.isMobile = value;
                } else {
                    selectedPermissions.push({
                        selectedPermissionCode: p,
                        isDesktop: p.isDesktop,
                        isMobile: isMobile
                    })
                }
                if (permissionUpdate) {
                    permissionUpdate.isMobile = value;
                }
            }
        });

        // Update
        this.updateSelectedPermissions(selectedPermissions, permissions);
    }

    updateSelectedPermissions(selectedPermissions, permissions) {
        // Update UI
        this.setState({ selectedPermissions, permissions });

        // Also update the role
        const permissionCodeList = [];
        Object.values(selectedPermissions).forEach(pCode => {
            permissionCodeList.push({
                selectedPermissionCode: pCode.selectedPermissionCode,
                isDesktop: pCode.isDesktop,
                isMobile: pCode.isMobile
            });
        });
        this.updateField('selectedPermissions', permissionCodeList);
    }

    async confirmDelete() {
        const confirm = await BootboxHelper.confirm('Are you sure you want to delete this role?');
        if (confirm) {
            this.delete();
        }
    }

    async delete() {
        this.setState({
            isLoading: true
        });
        await RoleService.delete(this.state.role.roleID);
        this.props.history.replace('/settings/roles');
    }

    getIsPermissionSelected(permission, desktopOrMobile) {
        if (permission.isSelectAll) {
            const value = this.getIsAllSelected(permission._grouping, desktopOrMobile);
            return value || false;
        } else if (desktopOrMobile == 'desktop') {
            return permission.isDesktop || false;
        } else {
            return permission.isMobile || false;
        }
    }

    //--------------------------------------------------------------------------------------------------------------------
    //  Render
    //--------------------------------------------------------------------------------------------------------------------

    renderInfoBar() {
        const {
            role
        } = this.state;

        return (
            <InfoBar className="settings-info-bar" containerClassName="floating-on-mobile" sticky={true}>

                <div className="info-bar-panel-section">

                    <button className="button" onClick={() => this.goBack()}>
                        <span className="fa fa-chevron-left"></span>{' '}
                        Back
                    </button>

                </div>

                <div className="info-bar-panel-section info-bar-panel-section-text desktop-only">
                    {role.roleID ? role.name : 'New role'}
                </div>

                <div className="info-bar-panel-section ml-auto">
                    {role.roleID &&
                        <button className="button" style={{ marginRight: 10 }} onClick={() => this.confirmDelete()}>
                            <span className="fa fa-times"></span>{' '}
                            Delete
                        </button>
                    }

                    <button className="button" onClick={() => this.save()}>
                        <span className="fa fa-check"></span>{' '}
                        Save
                    </button>

                </div>

            </InfoBar>
        );
    }

    render() {
        const {
            isLoading,
            permissions,
            selectedPermissions
        } = this.state;

        if (isLoading) {
            return (<Loader />);
        }

        return (<>
            {this.renderInfoBar()}

            <div className="settings-page-main">
                <div className="panel">
                    <div className="panel-body">

                        {this.formHelper.renderFormGroups([
                            'name',
                            'isDefault'
                        ])}

                        <div>

                            <div className="form-group">
                                <label>Permissions</label>
                            
                                <SuperTable
                                    className="settings-table"
                                    rows={permissions}
                                    keyAccessor={permission => (permission.isSelectAll ? permission._grouping + '-all' : permission.code)}
                                    cellPropsAccessor={(colInfo, row, index) => {
                                        const props = {};
                                        if (colInfo.name == 'isSelected') {
                                            props.width = 60;
                                        }
                                        if (colInfo.name == 'desktopMode' || colInfo.name == 'mobileMode') {
                                            props.width = 160;
                                        }
                                        if (row && row.isSelectAll) {
                                            props.style = { fontWeight: 'bold' };
                                        }
                                        return props;
                                    }}
                                    cols={{
                                        isSelected: {
                                            label: '',
                                        },
                                        name: { label: 'Name', className: 'name-column' },
                                        isDesktop: {
                                            label: 'Desktop',
                                            className: 'desktop-column',
                                            renderCell: (colInfo, permission) =>
                                                <td className="desktop-column"  style={{ textAlign: 'center'}}>
                                                    <input
                                                        type="checkbox"
                                                        id={'permission=' + permission.code}
                                                        checked={this.getIsPermissionSelected(permission, 'desktop')}
                                                        onChange={e => this.setPermission(permission, true, false, e.target.checked)}
                                                    />
                                                </td>
                                        },
                                        desktopMode: {
                                            label: '',
                                            className: 'desktop-mode-column',
                                            renderCell: (colInfo, permission) =>
                                                <td
                                                    className="mode-selector-cell"
                                                    onClick={e => e.stopPropagation()}
                                                >
                                                    {(permission.isDesktop) && permission.modes &&

                                                        <select
                                                            className="mode-selector"
                                                            value={(permission.modes.find(pm => pm.code && pm.isDesktop) || { code: '' }).code || ''}
                                                            onChange={e => this.setPermissionMode(permission, true, false,e.target.value)}
                                                        >
                                                            {permission.modes.map(m =>
                                                                <option key={m.code || m.name} value={m.code}>{m.name}</option>
                                                            )}
                                                        </select>
                                                    }
                                                </td>
                                        },
                                        isMobile: {
                                            label: 'Mobile',
                                            className: 'mobile-column',
                                            renderCell: (colInfo, permission) =>
                                                <td className="mobile-column">
                                                    <input
                                                        type="checkbox"
                                                        id={'permission=' + permission.code}
                                                        checked={this.getIsPermissionSelected(permission, 'mobile')}
                                                        onChange={e => this.setPermission(permission, false, true, e.target.checked)}
                                                    />
                                                </td>
                                        },
                                        mobileMode: {
                                            label: '',
                                            className: 'mobile-mode-column',
                                            renderCell: (colInfo, permission) =>
                                                <td
                                                    className="mode-selector-cell"
                                                    onClick={e => e.stopPropagation()}
                                                >
                                                    {(permission.isMobile) && permission.modes &&

                                                        <select
                                                            className="mode-selector"
                                                            value={(permission.modes.find(pm => pm.code && pm.isMobile) || { code: '' }).code}
                                                            onChange={e => this.setPermissionMode(permission, false, true, e.target.value)}
                                                        >
                                                            {permission.modes.map(m =>
                                                                <option key={m.code || m.name} value={m.code}>{m.name}</option>
                                                            )}
                                                        </select>
                                                    }
                                                </td>
                                        }
                                    }}
                                />
                            </div>

                        </div>
                        
                    </div>

                </div>

            </div>

        </>);
    }

};

export default withRouter(SettingsRoleEdit);