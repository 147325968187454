// Libs
import React from 'react';
import moment from 'moment';
import Modal from 'react-bootstrap/Modal';

// Services & Helpers
import DiaryService from 'services/DiaryService';
import BlockBookingService from 'services/BlockBookingService';
import TextHelpers from 'helpers/TextHelpers';
import DateHelpers from 'helpers/DateHelpers';

// Components
import Loader from 'components/reusable/Loader';
import Alert from 'components/reusable/Alert';
import DatePicker from 'components/reusable/DatePicker';

//--------------------------------------------------------------------------------------

export default class BlockBookingModal extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            isGenerating: false,
            appointments: null,
            haveSettingsChanged: false
        };
    }

    show(opts) {
        return new Promise((resolve, reject) => {
            this.resolve = resolve;
            this.setState({
                blockBooking: { ...opts.blockBooking },
                isOpen: true,
                isLoading: true,
                isGenerating: false,
                appointments: null,
                haveSettingsChanged: false,
                error: null
            }, () => {
                this.load();
            });
        });
    }

    async load() {
        // Load diary settings
        const date = this.state.blockBooking.startDate;
        const settings = await DiaryService.getSettings(date);
        this.diaryInterval = (settings.salonPeriod ? settings.salonPeriod.diaryInterval : 15);
        const dayOfWeek = moment(date).format('dddd').toLowerCase();

        // Get opening/closing time
        let times;
        if (settings.salonPeriod) {
            const salonOpeningTimes = settings.salonPeriod.salonOpeningTimes.find(sot => sot.dayOfWeek == dayOfWeek);

            let openingTimeMins = DateHelpers.parseTimeToNumMins(salonOpeningTimes.openingTime) - 90;
            if (openingTimeMins < 0) openingTimeMins = 0;

            let closingTimeMins = DateHelpers.parseTimeToNumMins(salonOpeningTimes.closingTime) + 90;
            if (closingTimeMins > 24 * 60 - this.diaryInterval) closingTimeMins = 24 * 60 - this.diaryInterval;

            times = DateHelpers.listTimes(
                this.diaryInterval,
                DateHelpers.numMinsToTime(openingTimeMins),
                DateHelpers.numMinsToTime(closingTimeMins)
            );
        } else {
            times = DateHelpers.listTimes(this.diaryInterval);
        }

        // Update UI
        this.setState({
            times,
            isLoading: false
        });
    }
    
    updateFields(values) {
        const blockBooking = { ...this.state.blockBooking };
        for (var fieldName in values) {
            const value = values[fieldName];
            if (blockBooking[fieldName] != value) {
                this.setState({
                    haveSettingsChanged: true
                });
            }
            blockBooking[fieldName] = value;
        }
        this.setState({
            blockBooking
        }, () => {
            this.debounceTryGenerate();
        });
    }

    async debounceTryGenerate() {
        clearTimeout(this.tryGenerateTimeout);
        this.tryGenerateTimeout = setTimeout(() => {
            this.tryGenerate();
        }, 250);
    }

    async tryGenerate() {
        const {
            haveSettingsChanged,
            blockBooking
        } = this.state;

        this.setState({
            appointments: null
        });

        // Validate
        if ((blockBooking.numAppointments || 0) < 2) {
            this.setState({
                error: 'A block booking must have at least 2 appointments'
            });
            return;
        }
        if (blockBooking.numAppointments > 52) {
            this.setState({
                error: 'You can only create up to 52 appointments in a single block booking'
            });
            return;
        }
        if (!blockBooking.startDate) {
            this.setState({
                error: 'Please select a start date'
            })
            return;
        }

        // Warn about settings changing
        //if (this.state.appointments && haveSettingsChanged) {
        //    const confirm = await BootboxHelper.confirm('This will clear the appointment list and generate a new one. Are you sure?');
        //    if (!confirm) {
        //        return;
        //    }
        //}

        // Mark generating
        this.setState({
            isGenerating: true
        });
        
        // Generate
        const appointments = await BlockBookingService.generate(blockBooking);

        // Update UI
        this.setState({
            appointments: appointments,
            isGenerating: false,
            haveSettingsChanged: false
        });
    }

    async save() {
        this.setState({ isLoading: true });
        const appointments = [];
        this.state.appointments.forEach(a => {
            if (a.appointment) {
                appointments.push(a.appointment);
            }
        });
        await BlockBookingService.save(this.state.blockBooking, appointments);
        this.setState({ isOpen: false });
        this.resolve();
    }

    //---------------------------------------------------------------------

    render() {
        const {
            isOpen,
            isLoading,
            isGenerating,
            appointments
        } = this.state;
        const {
            customer
        } = this.props;

        if (!isOpen) {
            return null;
        }

        return (
            <Modal
                show
                className="block-booking-modal"
                onHide={e => this.setState({ isOpen: false })}
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        Block booking {customer ? ' for ' + (customer.name || TextHelpers.formatName(customer.firstName, customer.lastName)) : ''}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    {this.renderInner()}

                </Modal.Body>
                <Modal.Footer>
                    {!isLoading &&
                        <button type="button" className="button button-secondary mr-auto" onClick={e => this.setState({ isOpen: false })}>
                            Cancel
                        </button>
                    }
                    {appointments && !isLoading && !isGenerating &&
                        <button type="button" className="button button-primary ml-auto" onClick={e => this.save()}>
                            Book these appointments
                        </button>
                    }
                </Modal.Footer>
            </Modal>
        );
    }

    renderInner() {
        const {
            isLoading,
            blockBooking,
            times
        } = this.state;

        if (isLoading) {
            return (<Loader />);
        }

        // Build select options
        const frequencyOptions = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
        const leewayOptions = [];
        for (var numMins = this.diaryInterval; numMins < 60; numMins += this.diaryInterval) {
            leewayOptions.push(numMins);
        }
        for (var numHours = 1; numHours < 4; numHours++) {
            leewayOptions.push(numHours * 60);
        }
        
        return (<>

            <div className="row">

                <div className="col-md-3">

                    <div className="form-group">
                        <label>First appointment</label>

                        <DatePicker
                            selected={blockBooking.startDate}
                            onChange={date => this.updateFields({ startDate: date })}
                        />

                    </div>

                </div>

                <div className="col-md-3">

                    <div className="form-group">
                        <label>Frequency</label>

                        <select
                            value={blockBooking.frequencyDays}
                            onChange={e => this.updateFields({ frequencyDays: Number(e.target.value) })}
                        >
                            {frequencyOptions.map(fo =>
                                <option key={fo} value={fo * 7}>
                                    Every {fo == 1 ? 'week' : `${fo} weeks`}
                                </option>
                            )}
                        </select>

                    </div>

                </div>

                <div className="col-md-3">

                    <div className="form-group">
                        <label># appointments</label>
                        <input
                            type="number"
                            max={52}
                            min={2}
                            value={blockBooking.numAppointments || ''}
                            onChange={e => this.updateFields({ numAppointments: Number(e.target.value) })}
                            autoFocus={true}
                        />
                    </div>

                </div>

                <div className="col-md-3">

                    {/*
                    <div className="form-group">
                        <label>Last appointment</label>

                        <DatePicker
                            clearIcon={null}
                            onChange={date => this.updateFields({ endDate: date })}
                            value={blockBooking.endDate}
                        />

                    </div>
                    */}             

                </div>

            </div>

            <div className="row">

                <div className="col-md-3">

                    <div className="form-group">
                        <label>Preferred time</label>

                        <select
                            value={blockBooking.preferredStartTime}
                            onChange={e => this.updateFields({ preferredStartTime: e.target.value })}
                        >
                            <optgroup label="Time of the day">
                                <option value="">Any time</option>
                                <option value="AM">Morning</option>
                                <option value="PM">Afternoon</option>
                            </optgroup>
                            <optgroup label="Specific times">
                                {times.map(t =>
                                    <option key={t.value} value={t.value}>
                                        {t.text}
                                    </option>
                                )}
                            </optgroup>
                        </select>

                    </div>

                </div>

                <div className="col-md-3">

                    {blockBooking.preferredStartTime &&
                        <div className="form-group">
                            <label>Leeway</label>

                            <select
                                value={blockBooking.leewayMins || 0}
                                onChange={e => this.updateFields({ leewayMins: e.target.value })}
                            >
                                <option value="0">This time only</option>
                                {leewayOptions.map(l =>
                                    <option key={l} value={l}>
                                        +/- {TextHelpers.formatDuration(l)}
                                    </option>
                                )}
                            </select>

                        </div>
                    }
                    
                </div>

            </div>

            {this.renderAppointments()}
            
        </>);
    }

    renderAppointments() {
        const {
            isGenerating,
            appointments,
            error,
            haveSettingsChanged
        } = this.state;

        if (isGenerating) {
            return (<Loader />);
        }

        if (!appointments) {
            return (<>
                {error &&
                    <Alert>
                        {error}
                    </Alert>
                }
            </>);
        }
        
        return (<>

            <table className="super-table block-booking-table mb-0">
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Date &amp; Time</th>
                        <th>Service</th>
                        <th>Time</th>
                        <th>Stylist</th>
                    </tr>
                </thead>
                <tbody>
                    {appointments.map((a, index) => {

                        // Couldn't find an appointment slot for this date
                        if (!a.appointment) {
                            return (
                                <tr>
                                    <td rowSpan={1}>
                                        {a.num}
                                    </td>
                                    <td rowSpan={1} className="highlight-text">
                                        {moment(a.date).format('ddd DD MMM YY')}
                                    </td>
                                    <td colSpan={3} className="highlight-text">
                                        No availability
                                    </td>
                                </tr>
                            )
                        }

                        // Slot found, show time for each service
                        return a.appointment.appointmentServices.map((asv, serviceIndex) => {
                            const apptPackage = (asv.appointmentPackageID ?
                                a.appointment.appointmentPackages.find(ap => ap.appointmentPackageID == asv.appointmentPackageID)
                                : null)

                            const rowSpan = (a.appointment.appointmentServices.length + (apptPackage ? 1 : 0));
                            const headerContent = <>
                                <td rowSpan={rowSpan}>
                                    {a.num}
                                </td>
                                <td rowSpan={rowSpan}>
                                    {moment(a.date).format('ddd DD MMM YY')}
                                    <div>
                                        Arrive at <span className="highlight-text">{moment(asv.time).format('HH:mm')}</span>
                                    </div>
                                </td>
                            </>;
                            
                            return (
                                <React.Fragment key={index + '-' + serviceIndex}>
                                    {serviceIndex == 0 && apptPackage &&
                                        <tr>
                                            {headerContent}
                                            
                                            <td colSpan="3">
                                                <b>{apptPackage.package.name}</b>
                                            </td>
                                        </tr>
                                    }
                                    <tr>
                                        {serviceIndex == 0 && !apptPackage && headerContent}
                                        <React.Fragment key={serviceIndex}>
                                            <td className={apptPackage ? 'has-package' : ''}>
                                                {asv.service.name}
                                            </td>
                                            <td>
                                                {moment(asv.time).format('HH:mm')}{' - '}
                                                {moment(moment(asv.time).add(asv.durationMins, 'minutes')).format('HH:mm')}
                                                {' '}
                                                <span className="pink-text">
                                                    ({TextHelpers.formatDuration(asv.durationMins)})
                                                </span>
                                            </td>
                                            <td>
                                                {asv.stylistName}
                                            </td>
                                        </React.Fragment>
                                    </tr>
                                </React.Fragment>
                            );
                        });
                    })}
                    {/*
                    <tr>
                        <td>Cut</td>
                        <td>10:00 (30mins)</td>
                        <td>Rayanne</td>
                        <td>OK</td>
                    </tr>
                    <tr>
                        <td>Cut</td>
                        <td>10:00 (30mins)</td>
                        <td>Rayanne</td>
                        <td>OK</td>
                    </tr>
                    <tr>
                        <td rowSpan={3}>29/01/2022</td>
                        <td>Colour</td>
                        <td>9:00 (60mins)</td>
                        <td>Becky</td>
                        <td>OK</td>
                    </tr>
                    <tr>
                        <td>Cut</td>
                        <td>10:00 (30mins)</td>
                        <td>Rayanne</td>
                        <td>OK</td>
                    </tr>
                    <tr>
                        <td>Cut</td>
                        <td>10:00 (30mins)</td>
                        <td>Rayanne</td>
                        <td>OK</td>
                    </tr>
                    */}
                </tbody>
            </table>
        </>);
    }
}
